import React, { useState, useEffect, useRef } from "react";
import { MdDoubleArrow } from "react-icons/md";
import { HashLink } from "react-router-hash-link";
import { BsGeoAltFill, BsCalendarDate, BsChevronRight } from "react-icons/bs";
import { AiOutlineUsergroupAdd, AiOutlineFileText } from "react-icons/ai";
import { BiTask, BiChevronDown } from "react-icons/bi";
import { MdSupportAgent } from "react-icons/md";
import Login from "./components/Login";
import Register from "./components/Register";
import Contactus from "./components/Contactus";
import logo from "./uploads/logo.png";
import { Link } from "react-router-dom";
import AOS from "aos";

const MainMenu = () => {
  const [active, setActive] = useState(1);
  const ref = useRef(null);
  const [loginShow, setLoginShow] = useState(false);
  const [registerShow, setRegisterShow] = useState(false);
  const [contactShow, setContactShow] = useState(false);
  const navcontrolleropen = () => {
    const nav = document.getElementById("mySidenav");

    nav.style.width = "300px";
    nav.style.boxShadow = "0 0 8px 10px rgba(0,0,0,0.2)";
  };

  const navcontrollerclose = () => {
    const nav = document.getElementById("mySidenav");

    nav.style.width = "0px";
    nav.style.boxShadow = "none";
  };
  const [scrolled, setScrolled] = React.useState(false);
  //fixed header on scrollbar
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    //init AOS
    AOS.init();

    window.addEventListener("scroll", handleScroll); //sticky header on scrollbar
  }, []);
  return (
    <>
      {/* sticky header on scrollbar */}
      {/* {scrolled && <div className="scrolled">
            <div className="container header-section-scrolled">
            <div className="header-logo d-flex justify-content-between align-items-center gap-2">
              {" "}
              <span className="pb-2">
                <img className="site-logo" src={logo} alt="Logo" />
              </span>{" "}
              <span>|</span> <span className="logo-text">ALPHA</span>
            </div>
            <div className="menu-buttons-section">
              <div>Home</div>
              <div>Home</div>
              <div onClick={() => setLoginShow(!loginShow)}>Login</div>
              <div
                onClick={() => setRegisterShow(!registerShow)}
                className="header-shaddow-button"
              >
                Try Free
              </div>
            </div>
          </div>
            </div>} */}
      <div
        className={scrolled ? "fixed-main-menu-section" : " main-menu-section"}
      >
        <div className="container header-section">
          <div className="header-logo d-flex justify-content-between align-items-center gap-2">
            {" "}
            <span className="pb-2">
              <img className="site-logo" src={logo} alt="Logo" />
            </span>{" "}
            <span>|</span> <span className="logo-text">ALPHA</span>
          </div>
          <div className="menu-buttons-section">
            <div className="full-h-menu">
              <Link to="/" onClick={scrollToTop}>
                Home
              </Link>
            </div>

            <div className="full-h-menu">
              <div className="main-dropdown">
                <span className="main-dropbtn">Features</span>
                <span className="main-dropbtn-icon">
                  <BiChevronDown />
                </span>
                <span className="tip"></span>
                <div class="main-dropdown-content">
                  {/* <a href="#app">Client Mobile App</a>
                    <a href="#desktop">Scheduling Software</a>
                    <a href="#features">Task Management</a>
                    <a href="#features">Integrated Support Center</a> */}

                  <div className="div-heading lead">
                    All your business needs at one place.
                  </div>

                  <div className="div-menu-content d-flex justify-content-between align-items-center">
                    <div className="div-menu-content-left d-flex flex-column justify-content-between align-items-start">
                      <Link
                        to="/scheduling"
                        className="div-menu-card d-flex gap-3"
                        onClick={scrollToTop}
                      >
                        <div className="div-menu-card-icon">
                          <BsCalendarDate />
                        </div>
                        <div className="div-menu-card-icon-right d-flex flex-column ">
                          <div className="div-menu-card-header">Scheduling</div>
                          <div className="div-menu-card-text">
                          Schedule efficiently with alpha scheduling module
                          </div>
                        </div>
                        <span className="div-card-direction-arrow">
                          <MdDoubleArrow />
                        </span>
                      </Link>
                      <Link
                        to="/attendance"
                        className="div-menu-card d-flex gap-3"
                        onClick={scrollToTop}
                      >
                        <div className="div-menu-card-icon">
                          <BsGeoAltFill />
                        </div>
                        <div className="div-menu-card-icon-right d-flex flex-column ">
                          <div className="div-menu-card-header">Attendance</div>
                          <div className="div-menu-card-text">
                          Track employee attendance quickly, safely and efficiently
                          </div>
                        </div>
                        <span className="div-card-direction-arrow">
                          <MdDoubleArrow />
                        </span>
                      </Link>
                      <Link
                        to="/reports"
                        className="div-menu-card d-flex gap-3"
                        onClick={scrollToTop}
                      >
                        <div className="div-menu-card-icon">
                          <AiOutlineFileText />
                        </div>
                        <div className="div-menu-card-icon-right d-flex flex-column ">
                          <div className="div-menu-card-header">Reports</div>
                          <div className="div-menu-card-text">
                          Export reports, invoices, pay stubs with one click
                          </div>
                        </div>
                        <span className="div-card-direction-arrow">
                          <MdDoubleArrow />
                        </span>
                      </Link>
                    </div>

                    <div className="div-menu-content-right d-flex flex-column">
                      <Link
                        to="/task-management"
                        className="div-menu-card d-flex gap-3"
                        onClick={scrollToTop}
                      >
                        <div className="div-menu-card-icon">
                          <BiTask />
                        </div>
                        <div className="div-menu-card-icon-right d-flex flex-column ">
                          <div className="div-menu-card-header">
                            Task Management
                          </div>
                          <div className="div-menu-card-text">
                          Keep track of tasks with alpha task management
                          </div>
                        </div>
                        <span className="div-card-direction-arrow">
                          <MdDoubleArrow />
                        </span>
                      </Link>
                      <Link
                        to="/employee-management"
                        className="div-menu-card d-flex gap-3"
                        onClick={scrollToTop}
                      >
                        <div className="div-menu-card-icon">
                          <AiOutlineUsergroupAdd />
                        </div>
                        <div className="div-menu-card-icon-right d-flex flex-column ">
                          <div className="div-menu-card-header">
                            Employee Management
                          </div>
                          <div className="div-menu-card-text">
                          Less administrative burdon with alpha employee management
                          </div>
                        </div>
                        <span className="div-card-direction-arrow">
                          <MdDoubleArrow />
                        </span>
                      </Link>
                      <Link
                        to="/livesupport"
                        className="div-menu-card d-flex gap-3"
                        onClick={scrollToTop}
                      >
                        <div className="div-menu-card-icon">
                          <MdSupportAgent />
                        </div>
                        <div className="div-menu-card-icon-right d-flex flex-column ">
                          <div className="div-menu-card-header">Support</div>
                          <div className="div-menu-card-text">
                          We will always be there when you need us
                          </div>
                        </div>
                        <span className="div-card-direction-arrow">
                          <MdDoubleArrow />
                        </span>
                      </Link>
                    </div>
                  </div>

                  <div className="div-menu-footer d-flex justify-content-between align-items-center">
                    <div>
                      <div>Ready to get started?</div>
                      <div className="div-menu-card-text">
                        Start your 30 days trial. No credit card required.
                      </div>
                    </div>
                    <div
                      className="shaddow-outline-button py-1 px-3 button-font"
                      onClick={() => setRegisterShow(!registerShow)}
                    >
                      Start Trial
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div className="full-h-menu">
              <HashLink to="/#pricing">Pricing</HashLink>
            </div>
            <div
              onClick={() => setLoginShow(!loginShow)}
              className="cursor-pointer full-h-menu"
            >
              Login
            </div>
            <div
              onClick={() => setRegisterShow(!registerShow)}
              className="header-shaddow-button"
            >
              Try Free
            </div>
          </div>

          <div id="sideNavbar" className="sideNavbar">
            <span
              style={{
                fontSize: "32px",
                cursor: "pointer",
                color: "antiquewhite",
              }}
              onClick={navcontrolleropen}
            >
              &#9776;
            </span>

            <div id="mySidenav" className="sidenav">
              <div className="closebtn" onClick={navcontrollerclose}>
                &times;
              </div>

              <div className="nav">
                <HashLink
                  to="/"
                  onClick={() => {
                    navcontrollerclose();
                    scrollToTop();
                  }}
                >
                  Home
                </HashLink>

                <HashLink to="/#app" onClick={navcontrollerclose}>
                  Mobile App
                </HashLink>

                {/* <a href="#features" onClick={navcontrollerclose}>
                Features{" "}
              </a> */}
                <div className="main-mobile-sub-menu-item">
                  <div
                    className={"main-mobile-sub-menu-button"}
                    onClick={() => (active === 2 ? setActive(0) : setActive(2))}
                  >
                    <span className="main-mobile-menu-text cursor-pointer">
                      Features
                    </span>
                    <span className="me-4 text-end main-mobile-menu-text d-flex align-items-center">
                      <span
                        className={`main-mobile-rotationg-icon  ${
                          active === 2 ? "main-mobile-transition-add" : ""
                        }`}
                      >
                        <BsChevronRight />
                      </span>
                    </span>
                  </div>
                  <ul
                    ref={ref}
                    className={`main-mobile-sub-menu-items  ${
                      active === 2
                        ? "main-mobile-sub-menu-expended"
                        : "main-mobile-sub-menu-collapsed"
                    }`}
                  >
                    <li>
                      <Link
                        to="/scheduling"
                        onClick={() => {
                          navcontrollerclose();
                          scrollToTop();
                        }}
                      >
                        <span className="main-mobile-menu-text">
                          Scheduling
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/attendance"
                        onClick={() => {
                          navcontrollerclose();
                          scrollToTop();
                        }}
                      >
                        <span className="main-mobile-menu-text">
                          Attendance
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/reports"
                        onClick={() => {
                          navcontrollerclose();
                          scrollToTop();
                        }}
                      >
                        <span className="main-mobile-menu-text">Reports</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/task-management"
                        onClick={() => {
                          navcontrollerclose();
                          scrollToTop();
                        }}
                      >
                        <span className="main-mobile-menu-text">
                          Task <br />
                          Management
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/employee-management"
                        onClick={() => {
                          navcontrollerclose();
                          scrollToTop();
                        }}
                      >
                        <span className="main-mobile-menu-text">
                          Employee <br />
                          Management
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/livesupport"
                        onClick={() => {
                          navcontrollerclose();
                          scrollToTop();
                        }}
                      >
                        <span className="main-mobile-menu-text">Support</span>
                      </Link>
                    </li>
                  </ul>
                </div>

                <HashLink to="/#pricing" onClick={navcontrollerclose}>
                  Pricing
                </HashLink>
                <HashLink to="/#reviews" onClick={navcontrollerclose}>
                  Reviews
                </HashLink>

                <span
                  onClick={() => setLoginShow(!loginShow)}
                  className="cursor-pointer"
                  style={{ color: "var(--blue-500)" }}
                >
                  Login
                </span>
                <span
                  onClick={() => setRegisterShow(!registerShow)}
                  className="cursor-pointer"
                  style={{ color: "var(--blue-500)" }}
                >
                  Register
                </span>
                <HashLink to="/#faq" onClick={navcontrollerclose}>
                  FAQ
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Login show={loginShow} onHide={() => setLoginShow(false)} />
      <Register show={registerShow} onHide={() => setRegisterShow(false)} />
      <Contactus show={contactShow} onHide={() => setContactShow(false)} />
    </>
  );
};

export default MainMenu;
