import React from "react";
import { useParams } from "react-router-dom";
import Alerts from "../Alerts";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SiteDetails from "./SiteDetails";
import SiteSchedule from "./SiteSchedule";
import SiteNotes from "./SiteNotes";
import SiteOrders from "./SiteOrders";
import SiteRates from "./SiteRates";
import SiteDocuments from "./SiteDocuments";

const DisplaySite = () => {
  let params = useParams();
  return (
   
      <div className="card">
        <Alerts componentName={"displaysite"} />

        <Tabs
          defaultActiveKey="schedule"
          id="justify-tab-example"
          className="mb-3"
          justify
          mountOnEnter
        >
          <Tab eventKey="schedule" title="Schedule">
            <SiteSchedule site_id={params.site_id} />
          </Tab>
          <Tab eventKey="home" title="Details">
            <SiteDetails site_id={params.site_id} />
          </Tab>
          <Tab eventKey="rates" title="Coverage Rates">
            <SiteRates site_id={params.site_id} />
          </Tab>
          <Tab eventKey="notes" title="Notes">
            <SiteNotes site_id={params.site_id} />
          </Tab>
          <Tab eventKey="siteorders" title="Site Orders">
            <SiteOrders site_id={params.site_id} />
          </Tab>
          <Tab eventKey="sitedocuments" title="Documents">
            <SiteDocuments site_id={params.site_id} />
          </Tab>
        </Tabs>
      </div>
      
  );
};

export default DisplaySite;
