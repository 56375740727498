import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadSchedule } from "../../reducers/user";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const MySchedule = () => {
  const dispatch = useDispatch();
  let params = useParams();
  const user_id = params.user_id;
  const userData = useSelector((state) => state.user?.schedule);
  const loading = useSelector((state) => state.user?.scheduleLoading);

  const [offSet, setOffSet] = useState(0);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let today = new Date();
  let startdate = new Date(today.getFullYear(), today.getMonth() + offSet, 1);
  let endDate = new Date(
    today.getFullYear(),
    today.getMonth() + 1 + offSet,
    0,
    23,
    59,
    59,
    0
  );

  useEffect(() => {
    dispatch(
      loadSchedule({
        user_id: user_id,
        startDate: startdate.toISOString(),
        endDate: endDate.toISOString(),
      })
    );
  }, [offSet]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }
  if (!userData) {
    return (
      <div>
        <Alert variant="info">No Data. Please try again later.</Alert>
      </div>
    );
  }
  return (
    <>
      <div className="d-flex justify-content-center align-items-center gap-3 mb-4">
        <Button
          variant="primary"
          onClick={(e) => {
            setOffSet((prev) => prev - 1);
          }}
        >
          <AiOutlineLeft />
        </Button>
        <div>
          {monthNames[startdate.getMonth()] + " " + startdate.getFullYear()}
        </div>
        <Button
          variant="primary"
          onClick={(e) => {
            setOffSet((prev) => prev + 1);
          }}
        >
          <AiOutlineRight />
        </Button>
      </div>
      <div className="d-flex justify-content-center align-items-center" >
        {userData.length === 0 && (
          <Alert variant="info">No Shift assigned to user in this month.</Alert>
        )}
        <div className="date-cards-container">
          {userData.map((item, index) => {
            if (item.shift) {
              return (
                <div className="datecard shift-covered d-flex flex-column justify-content-between ">
                  <div className="display-2 ps-3">{index + 1}</div>
                  <div className="schedule-scoll"> {item.shift.map((shiftitem)=>{
                    const startDate = new Date(shiftitem.startTime);
                    const endDate = new Date(shiftitem.endTime);
                    return (
                      <Link to={`/dashboard/shift/${shiftitem.shift_id}`}><div className="display-timings ps-3 cursor-pointer ">{startDate.getHours().toString().padStart(2, '0')}:{startDate.getMinutes().toString().padStart(2, '0')} - {endDate.getHours().toString().padStart(2, '0')}:{endDate.getMinutes().toString().padStart(2, '0')}</div></Link>
                    )
                  })}</div>
                </div>
              );
            }
            return (
              <div className="datecard shift-previous">
                <div className="display-2 ps-3">{index + 1}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MySchedule;
