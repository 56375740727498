import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

function Items({ currentItems, startDate, endDate }) {
  console.log(currentItems);
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Employee</th>
          <th>Email</th>
          <th>Hours</th>
        </tr>
      </thead>
      <tbody>
        {currentItems.map((item, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>

              {item?._id === null ? (
                <>
                  <td>
                    Open Shifts
                  </td>

                  <td>-</td>
                  <td>
                    {item.count.toLocaleString("en-US", {
                      style: "decimal",
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </td>
                </>
              ) : (
                <>
                  <td>
                    <Link
                      to={`/dashboard/employeereports/${item?.user._id}`}
                      state={{ startDate, endDate }}
                    >
                      {item?.user.username}
                    </Link>
                  </td>

                  <td>{item?.user.email}</td>
                  <td>
                    {item.count.toLocaleString("en-US", {
                      style: "decimal",
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

const DisplayUsersReport = ({
  usersData,
  itemsPerPage,
  startDate,
  endDate,
}) => {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(usersData);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % usersData.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(usersData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(usersData.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, usersData]);

  useEffect(() => {
    setItemOffset(0);
  }, [itemsPerPage]);

  return (
    <>
      <Items
        currentItems={currentItems}
        startDate={startDate}
        endDate={endDate}
      />
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
};

export default DisplayUsersReport;
