import configData from "../config/default.json";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

const API_ENDPOINT = configData.API_ENDPOINT;


const initialState = {
  loading: false,
  agencyprofile: null,
  error: null,
  subLoading: false,
};

export const createAgencyProfile = createAsyncThunk(
  "api/createAgencyProfile",
  async ({
    agencyName,
    phone,
    country,
    provience,
    city,
    unit,
    street,
    geox,
    geoy,
    streetNumber,
    postal,
  }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      agencyName,
      phone,
      country,
      provience,
      city,
      unit,
      street,
      geox,
      geoy,
      streetNumber,
      postal,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/agencyprofile/setagencyprofile`,
      body,
      config
    );
    return response.data;
  }
);

export const createSubscription = createAsyncThunk(
  "api/createSubscription",
  async ({ priceId, customerId, quantity }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      priceId,
      customerId,
      quantity,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/agencyprofile/create-subscription`,
      body,
      config
    );
    return response.data;
  }
);

export const cancelSubscription = createAsyncThunk(
  "api/cancelSubscription",
  async ({ subscription_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      subscription_id,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/agencyprofile/cancel-subscription`,
      body,
      config
    );
    return response.data;
  }
);

export const loadAgencyProfile = createAsyncThunk(
  "api/loadAgencyProfile",
  async () => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const response = await axios.get(
      `${API_ENDPOINT}/api/agencyprofile/getagencyprofile`
    );
    return response.data;
  }
);

export const updatetrialPlan = createAsyncThunk(
  "api/updatetrialPlan",
  async ({status }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      plan_id: configData.PLAN.TIER0,
      status,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/agencyprofile/updateplan`,
      body,
      config
    );
    return response.data;
  }
);

export const agencyProfile = createSlice({
  name: "agencyProfile",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createAgencyProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createAgencyProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createAgencyProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        console.log("error in createAgencyProfile");
        console.log(action);
      })
      .addCase(loadAgencyProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadAgencyProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.agencyprofile = action.payload;
      })
      .addCase(loadAgencyProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.agencyprofile = null;
        console.log("error in loadAgencyProfile");
        console.log(action);
      })
      .addCase(createSubscription.pending, (state, action) => {
        state.subLoading = true;
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.subLoading = false;
        state.error = null;
        state.agencyprofile = action.payload;
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error;
        console.log("error in createSubscription");
        console.log(action);
      })
      .addCase(updatetrialPlan.pending, (state, action) => {})
      .addCase(updatetrialPlan.fulfilled, (state, action) => {})
      .addCase(updatetrialPlan.rejected, (state, action) => {})
      .addCase(cancelSubscription.pending, (state, action) => {
        state.subLoading = true;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.subLoading = false;
        state.error = null;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error;
        console.log("error in cancelSubscription");
        console.log(action);
      });
  },
});

export default agencyProfile.reducer;
