import React from 'react';
import { logout } from '../reducers/auth';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div>
      <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="text-center d-flex flex-column align-items-center justify-content-center">
          <h1 class="display-1 fw-bold">Access Restricted</h1>
          <p class="fs-3">
            {" "}
            <span class="text-danger">Opps!</span> You don't have permission to
            access this page.
          </p>
          <p class="fs-3">Please use alpha app to access your schedule.</p>
          <div className="get-on-play-store">
                <h5 className='lead'>Download Alpha App:</h5>
                <div className="get-on-play-store-logo ">
                  <a
                    href={
                      "https://apps.apple.com/us/app/alpha-scheduling/id1661999942?itsct=apps_box_badge&amp;itscg=30200"
                    }
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      display: "inline-block",
                      "border-radius": "10px",
                      height: "47px",
                    }}
                  >
                    <img
                      src={
                        "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1673395200"
                      }
                      alt="Download on the App Store"
                      style={{
                        "border-radius": "10px",
                        height: "47px",
                      }}
                    />
                  </a>
                  <a
                    href="http://play.google.com/store/apps/details?id=com.deepakpunia.nativeapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      alt="mobile_app"
                      width="180px"
                      height="70px"
                    />
                  </a>
                </div>
              </div>
          
          
            <span className="btn btn-secondary mt-4" onClick={()=>{dispatch(logout());  navigate("/");}}>Logout</span>
         
        </div>
      </div>
    </div>
  )
}

export default Dashboard