import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Alerts from "../../Alerts";
import { setAlert } from "../../../reducers/alert";
import { useSelector, useDispatch } from "react-redux";
import {
  loadPrivateNotes,
  addPrivateProfileNote,
} from "../../../reducers/user";
import { useParams } from "react-router-dom";

import configData from "../../../config/default.json";

const DisplayUsersPrivateNotes = () => {
  let params = useParams();
  const user_id = params.user_id;
  const [noteTitle, setNoteTitle] = useState("");
  const [noteDetails, setNoteDetails] = useState("");
  const [flag, setFlag] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const notes = user?.privateNotes;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!noteTitle || !noteDetails || !flag) {
      dispatch(
        setAlert({
          componentName: "DisplayUsersPrivateNotes",
          alertType: "danger",
          msg: "Please fill all fields.",
        })
      );
      return console.log("please input all fields");
    }
    dispatch(
      addPrivateProfileNote({
        noteTitle,
        noteDetails,
        flag,
        user_id,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(loadPrivateNotes({ user_id }));
        dispatch(
          setAlert({
            componentName: "DisplayUsersPrivateNotes",
            alertType: "success",
            msg: "Note added.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "DisplayUsersPrivateNotes",
            alertType: "danger",
            msg: "Error in Saving Data. Please try again.",
          })
        )
      );

    setNoteTitle("");
    setNoteDetails("");
  };

  useEffect(() => {
    dispatch(loadPrivateNotes({ user_id }));
  }, []);

  if (!notes) {
    return <Alert variant="info">User must be hired to add private notes.</Alert>;
  }

  return (
    <div className="mt-3">
      {notes.length === 0 ? (
        <Alert variant="info">No Notes.</Alert>
      ) : (
        notes.map((note) => {
          return (
            <div>
              <Alert
                variant={
                  note.flag === configData.FLAG.RED
                    ? "danger"
                    : note.flag === configData.FLAG.GREEN
                    ? "success"
                    : "warning"
                }
              >
                <Alert.Heading>{note.noteTitle}</Alert.Heading>
                <p>{note.noteDetails}</p>
                <hr />
                <p className="mb-0">
                  Added by: {note.userName} on {new Date(note.date).toDateString()}
                </p>
              </Alert>
            </div>
          );
        })
      )}

        
        
      <div className="thin-border my-5">
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Note Title"
              value={noteTitle}
              onChange={(e) => setNoteTitle(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" as={Col} controlId="formGridCity">
            <Form.Label>Details</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              placeholder="Note Details"
              value={noteDetails}
              onChange={(e) => setNoteDetails(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-4" as={Col} controlId="formGridState">
            <Form.Label>Flag</Form.Label>
            <Form.Select
              defaultValue={flag}
              onChange={(e) => setFlag(e.target.value)}
            >
              <option>Choose...</option>
              <option value={configData.FLAG.RED}>Red</option>
              <option value={configData.FLAG.GREEN}>Green</option>
              <option value={configData.FLAG.YELLOW}>Yellow</option>
            </Form.Select>
          </Form.Group>
          {user.subLoading ? (
            <Button variant="primary" disabled className="button-flex mb-3">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <>
              <Button
                variant="primary"
                type="submit"
                className="button-flex mb-3"
                onClick={handleSubmit}
              >
                Add
              </Button>
            </>
          )}
          <Alerts componentName={"DisplayUsersPrivateNotes"} />
        </Form>
      </div>
    </div>
  );
};

export default DisplayUsersPrivateNotes;
