import React, { useState, useEffect } from "react";

import Form from "react-bootstrap/Form";

import DisplayHiredUsers from "./DisplayHiredUsers";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Alerts from "../../Alerts";
import { setAlert } from "../../../reducers/alert";
import { useSelector, useDispatch } from "react-redux";
import { loadAgencyUsers } from "../../../reducers/users";
import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineSearch } from "react-icons/ai";

const HiredUsers = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  let usersData = users?.users ? users?.users[0]?.employees : null;
  
  const [serachByName, setSearchByName] = useState("");
  const [serachByEmail, setSearchByEmail] = useState("");
  const [serachByCity, setSearchByCity] = useState("");
  const [perPage, setPerPage] = useState(10);

  // filter sites based on search
  usersData = usersData?.filter((user) =>
    user.user_id.username.toLowerCase().includes(serachByName.toLowerCase())
  );
  usersData = usersData?.filter((user) =>
    user.user_id.email.toLowerCase().includes(serachByEmail.toLowerCase())
  );
  
  usersData = usersData?.filter((user) => {
    if(user.user_id?.profile)
    {return user.user_id?.profile?.city
      .toLowerCase()
      .includes(serachByCity.toLowerCase());}
      else
      return true
  });
  
  useEffect(() => {
    dispatch(loadAgencyUsers());
  }, []);

  if (!usersData) {
    return (
      <div className="card">
        <h3>Employees</h3>

        <hr />
        <Alert variant="info">No Data.</Alert>
      </div>
    );
  }
  if (users.loading) {
    return (
      <div className="card">
        <h3>Employees</h3>

        <hr />
        <Alerts componentName={"displaysites"} />

        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }
  if (!users.users || users.users.length === 0) {
    return (
      <div className="card">
        <h3>Employees</h3>

        <hr />
        <Alert variant="info">No Data.</Alert>
      </div>
    );
  }
  return (
    <div className="card ">
      <h3>Employees</h3>

      <hr />
      {/* filters */}
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">
          <AiOutlineSearch />
        </InputGroup.Text>
        <Form.Control
          placeholder="Search by name"
          aria-label="search"
          aria-describedby="basic-addon1"
          value={serachByName}
          onChange={(e) => setSearchByName(e.target.value)}
        />
        <Form.Control
          placeholder="Search by Email"
          aria-label="search"
          aria-describedby="basic-addon1"
          value={serachByEmail}
          onChange={(e) => setSearchByEmail(e.target.value)}
        />
        <Form.Control
          placeholder="Search by city"
          aria-label="search"
          aria-describedby="basic-addon1"
          value={serachByCity}
          onChange={(e) => setSearchByCity(e.target.value)}
        />

        <InputGroup.Text id="basic-addon2">
          <Form.Select
            defaultValue={perPage}
            onChange={(e) => setPerPage(Number(e.target.value))}
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </Form.Select>
        </InputGroup.Text>
      </InputGroup>
      {/* filters End */}
      <DisplayHiredUsers users={usersData} itemsPerPage={perPage} />
    </div>
  );
};

export default HiredUsers;
