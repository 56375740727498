import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Alerts from "../../Alerts";

import DisplayUsersProfile from "./DisplayUsersProfile";
import DisplayUsersSchedule from "./DisplayUsersSchedule";
import DisplayUsersNotes from "./DisplayUsersNotes";
import DisplayUsersPrivateNotes from "./DisplayUsersPrivateNotes";
import DisplayUserDocs from "./DisplayUserDocs";

const UserProfile = () => {
  return (
    <div className="card">
      <Alerts componentName={"displaysite"} />

      <Tabs
        defaultActiveKey="profile"
        id="justify-tab-example"
        className="mb-3"
        justify
      >
        <Tab eventKey="profile" title="Profile">
          <DisplayUsersProfile  />
        </Tab>
        <Tab eventKey="schedule" title="Schedule">
          <DisplayUsersSchedule />
        </Tab>
        <Tab eventKey="notes" title="Notes">
          <DisplayUsersNotes  />
        </Tab>
        <Tab eventKey="privatenotes" title="Private Notes">
          <DisplayUsersPrivateNotes />
        </Tab>
        <Tab eventKey="userdocs" title="Documents">
          <DisplayUserDocs />
        </Tab>
      </Tabs>
    </div>
  );
};

export default UserProfile;
