import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import { useParams } from "react-router-dom";
import { broadcastShift, localbroadcastShift } from "../../reducers/site";
import { loadShiftDetails, dispatchBreakClockin, dispatchBreakClockout } from "../../reducers/site";
import { setAlert } from "../../reducers/alert";
import DisplayTwoColData from "./DisplayTwoColData";
import { Link } from "react-router-dom";
import AssignUser from "./AssignUser";
import RemoveUserFromShift from "./RemoveUserFromShift";
import DispatchUser from "./DispatchUser";
import Alerts from "../Alerts";
import DeleteShift from "./DeleteShift";
import AttendenceMap from "./AttendenceMap";

const Shift = () => {
  const dispatch = useDispatch();
  let params = useParams();
  const shift_id = params.shift_id;
  const state = useSelector((state) => state.site);
  const shiftData = state ? state.shift : null;
  const loading = state ? state.smallLoading : false;
  let today = shiftData ? new Date(shiftData.shiftDate) : new Date();
  const [selectedInput, setSelectedInput] = useState([shift_id]);
  const [action, setAction] = useState();
  const [broadcastLoading, setBroadcastLoading] = useState(false);
  const [localbroadcastLoading, setLocalBroadcastLoading] = useState(false);
  const [dispatchLoading, setDispatchLoading] = useState(false);
  
  let siteCords = [shiftData?.site_id?.Geoloc?.y ? shiftData.site_id.Geoloc.y : null, shiftData?.site_id?.Geoloc?.x ? shiftData.site_id.Geoloc.x : null];
  let clockinCords = [shiftData?.clockin?.Geoloc?.y ? shiftData.clockin.Geoloc.y : null, shiftData?.clockin?.Geoloc?.x ? shiftData.clockin.Geoloc.x : null];
  let clockoutCords = [shiftData?.clockout?.Geoloc?.y ? shiftData.clockout.Geoloc.y : null, shiftData?.clockout?.Geoloc?.x ? shiftData.clockout.Geoloc.x : null];

  

  const handleBreakClockin = (shift_id, break_id, dispatchDate) =>{
    const tempDate = new Date(dispatchDate);
    setDispatchLoading(true);
    dispatch(dispatchBreakClockin({ shift_id, break_id, dispatchDate:tempDate }))
      .unwrap()
      .then((originalPromiseResult) => {
        setDispatchLoading(false);
        dispatch(
          setAlert({
            componentName: "Shift",
            alertType: "success",
            msg: "User is clocked in for break.",
          })
        );
        dispatch(
          loadShiftDetails({
            shift_id
          })
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setDispatchLoading(false);
        dispatch(
          setAlert({
            componentName: "Shift",
            alertType: "danger",
            msg: "Error. Please try again.",
          })
        );
      });
  };
  
  const handleBreakClockout = (shift_id, break_id, dispatchDate) =>{
    const tempDate = new Date(dispatchDate);
    setDispatchLoading(true);
    dispatch(dispatchBreakClockout({ shift_id, break_id, dispatchDate:tempDate }))
      .unwrap()
      .then((originalPromiseResult) => {
        setDispatchLoading(false);
        dispatch(
          setAlert({
            componentName: "Shift",
            alertType: "success",
            msg: "User is clocked out for break.",
          })
        );
        dispatch(
          loadShiftDetails({
            shift_id
          })
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setDispatchLoading(false);
        dispatch(
          setAlert({
            componentName: "Shift",
            alertType: "danger",
            msg: "Error. Please try again.",
          })
        );
      });
  }

  const handleRadioUpdate1 = () => {
    setBroadcastLoading(true);
    dispatch(broadcastShift({ shift_id: shift_id }))
      .unwrap()
      .then((originalPromiseResult) => {
        setBroadcastLoading(false)
        dispatch(
          setAlert({
            componentName: "Shift",
            alertType: "success",
            msg: "Settings are updated.",
          })
        );
        dispatch(
          loadShiftDetails({
            shift_id
          })
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setBroadcastLoading(false)
        dispatch(
          setAlert({
            componentName: "Shift",
            alertType: "danger",
            msg: "Error. Please try again.",
          })
        );
      });
  };

  const handleRadioUpdate2 = () => {
    setLocalBroadcastLoading(true);
    dispatch(localbroadcastShift({ shift_id: shift_id }))
      .unwrap()
      .then((originalPromiseResult) => {
        setLocalBroadcastLoading(false)
        dispatch(
          setAlert({
            componentName: "Shift",
            alertType: "success",
            msg: "Settings are updated.",
          })
        );
        dispatch(
          loadShiftDetails({
            shift_id
          })
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setLocalBroadcastLoading(false)
        dispatch(
          setAlert({
            componentName: "Shift",
            alertType: "danger",
            msg: "Error. Please try again.",
          })
        );
      });
  };

  useEffect(() => {
    dispatch(
      loadShiftDetails({
        shift_id
      })
    );
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }
  if (!shiftData) {
    return (
      <div>
        <Alert variant="info">No Data. Please try again later.</Alert>
      </div>
    );
  }

  return (
    <>
    <div className="card">
      <div><h3><Link to={`/dashboard/sites/${shiftData?.site_id?._id}`}>{shiftData?.site_id?.name}</Link>, {shiftData?.site_id?.unit} {shiftData?.site_id?.street} {shiftData?.site_id?.city}</h3></div>
      <hr />
      <div className="mt-3">
      <Alerts componentName={"Shift"} />
      <DisplayTwoColData
        field={"Date"}
        data={new Date(
          shiftData.shiftDate
        ).toDateString()}
      />
      
        
          <div className="display-company-data"><div className="display-info">Start :</div> <div className="display-data">{new Date(shiftData.startTime).getHours().toString().padStart(2, '0')}:{new Date(shiftData.startTime).getMinutes().toString().padStart(2, '0')}</div></div>
          <div className="display-company-data"><div className="display-info">Clock In : </div><div className="display-data">{shiftData.clockin.isclockedin ? <>{shiftData.clockin.date ? <>{new Date(shiftData.clockin.date).getHours().toString().padStart(2, '0')}:{new Date(shiftData.clockin.date).getMinutes().toString().padStart(2, '0')}</> : <>{today.setHours(new Date(shiftData.startTime).getHours(),new Date(shiftData.startTime).getMinutes(),0,0) < new Date().getTime() ? <span className="text-danger">Missed</span> : "-"}</>} {shiftData.clockin.dispatchdate && <> D: {new Date(shiftData.clockin.dispatchdate).getHours().toString().padStart(2, '0')}:{new Date(shiftData.clockin.dispatchdate).getMinutes().toString().padStart(2, '0')}</>}</> : <>{today.setHours(new Date(shiftData.startTime).getHours(),new Date(shiftData.startTime).getMinutes(),0,0) < new Date().getTime() ? <span className="text-danger">Missed</span> : "-"}</>}</div></div>

          {shiftData.break.length > 0 && <>
            {shiftData.break.map((breakData, index)=>{
              return (<div className="thin-border mb-3">
              <div className="mb-2">Break #{index + 1}:</div>
  
              <div className="display-company-data"><div className="display-info">Start :</div> <div className="display-data">{new Date(breakData.startTime).getHours().toString().padStart(2, '0')}:{new Date(breakData.startTime).getMinutes().toString().padStart(2, '0')}</div></div>
          <div className="display-company-data"><div className="display-info">Clock In : </div><div className="display-data">{breakData.clockin.isclockedin ? <>{breakData.clockin.date ? <>{new Date(breakData.clockin.date).getHours().toString().padStart(2, '0')}:{new Date(breakData.clockin.date).getMinutes().toString().padStart(2, '0')}</> : <>{today.setHours(new Date(breakData.startTime).getHours(),new Date(breakData.startTime).getMinutes(),0,0) < new Date().getTime() ? <span className="text-danger">Missed</span> : "-"}</>} {breakData.clockin.dispatchdate && <> D: {new Date(breakData.clockin.dispatchdate).getHours().toString().padStart(2, '0')}:{new Date(breakData.clockin.dispatchdate).getMinutes().toString().padStart(2, '0')}</>}</> : <>{today.setHours(new Date(breakData.startTime).getHours(),new Date(breakData.startTime).getMinutes(),0,0) < new Date().getTime() ? <span className="text-danger">Missed</span> : "-"}</>}<Button className="ms-2" variant="outline-primary" size="sm" onClick={()=>handleBreakClockin(shift_id,breakData._id, breakData.startTime)}>Dispatch</Button></div></div>
          <div className="display-company-data"><div className="display-info">End : </div><div className="display-data">{new Date(breakData.endTime).getHours().toString().padStart(2, '0')}:{new Date(breakData.endTime).getMinutes().toString().padStart(2, '0')}</div></div>
          <div className="display-company-data"><div className="display-info">Clock Out :</div><div className="display-data"> {breakData.clockout.isclockedout ? <>{breakData.clockout.date ? <>{new Date(breakData.clockout.date).getHours().toString().padStart(2, '0')}:{new Date(breakData.clockout.date).getMinutes().toString().padStart(2, '0')}</> : <>{new Date(breakData.endTime).getTime() < new Date().getTime() ? <span className="text-danger">Missed</span> : "-"}</>} {breakData.clockout.dispatchdate && <> D: {new Date(breakData.clockout.dispatchdate).getHours().toString().padStart(2, '0')}:{new Date(breakData.clockout.dispatchdate).getMinutes().toString().padStart(2, '0')}</>}</> : <>{new Date(breakData.endTime).getTime() < new Date().getTime() ? <span className="text-danger">Missed</span> : "-"}</>}<Button className="ms-2" variant="outline-primary" size="sm" onClick={()=>handleBreakClockout(shift_id,breakData._id, breakData.endTime)}>Dispatch</Button></div></div>
          <div className="display-company-data"><div className="display-info">Deductible Hours : </div><div className="display-data">{breakData.deductable ? "Yes" : "No"}</div></div>
  
            </div>)
            })}
          </>}
          
        
          <div className="display-company-data"><div className="display-info">End : </div><div className="display-data">{new Date(shiftData.endTime).getHours().toString().padStart(2, '0')}:{new Date(shiftData.endTime).getMinutes().toString().padStart(2, '0')}</div></div>
          <div className="display-company-data"><div className="display-info">Clock Out :</div><div className="display-data"> {shiftData.clockout.isclockedout ? <>{shiftData.clockout.date ? <>{new Date(shiftData.clockout.date).getHours().toString().padStart(2, '0')}:{new Date(shiftData.clockout.date).getMinutes().toString().padStart(2, '0')}</> : <>{new Date(shiftData.endTime).getTime() < new Date().getTime() ? <span className="text-danger">Missed</span> : "-"}</>} {shiftData.clockout.dispatchdate && <> D: {new Date(shiftData.clockout.dispatchdate).getHours().toString().padStart(2, '0')}:{new Date(shiftData.clockout.dispatchdate).getMinutes().toString().padStart(2, '0')}</>}</> : <>{new Date(shiftData.endTime).getTime() < new Date().getTime() ? <span className="text-danger">Missed</span> : "-"}</>}</div></div>
          
          <DisplayTwoColData field={"Status"} data={`${shiftData.status} `} />
        <DisplayTwoColData
          field={"Assigned to"}
          data={shiftData.employeeName ? <Link to={`/dashboard/users/${shiftData.user_id}`}>
          {shiftData.employeeName}
        </Link> : null}
        />
        <DisplayTwoColData
          field={"Broadcasted"}
          data={shiftData.boardcasted ? "Yes" : "No"}
        />
        {shiftData?.boardcasted && <DisplayTwoColData
          field={"Broadcast Group"}
          data={shiftData.localboardcasted ? "Local" : "Alpha Network"}
        /> }

      </div>
      <div className="d-flex gap-3 my-3">
      
        
      <Button
          variant="primary"
          onClick={()=> setAction("dispatch")}
        >
          Dispatch
        </Button>
        <Button
          variant="primary"
          disabled={broadcastLoading}
          onClick={handleRadioUpdate1}
        >
          Broadcast
        </Button>

        {shiftData?.boardcasted && <Button
          variant="primary"
          disabled={localbroadcastLoading}
          onClick={handleRadioUpdate2}
        >
          Local Broadcast
        </Button>}


        <Button
          variant="primary"
          onClick={()=> setAction("assign")}
        >
          Assign
        </Button>
        <Button
          variant="primary"
          onClick={()=> setAction("remove")}
        >
          Remove
        </Button>

        {shiftData?.boardcasted && <Button
          variant="primary"
          onClick={()=> setAction("applied")}
        >
          Applied <Badge bg="secondary">{shiftData?.applied ? shiftData?.applied.length : 0}</Badge>
      <span className="visually-hidden">Applied</span>
        </Button>}

        <Button
          variant="primary"
          onClick={()=> setAction("showmap")}
        >
          Map
        </Button>
        
        <Button
          variant="primary"
          onClick={()=> setAction("delete")}
        >
          Delete
        </Button>
      </div>

      {action === "assign" && (
          <AssignUser
            selectedshifts={selectedInput}
            show={true}
            onHide={() => {
              dispatch(loadShiftDetails({
                shift_id
              }));

              setAction();
            }}
            onCancel={() => {
              dispatch(loadShiftDetails({
                shift_id
              }));

              setAction();
            }}
          />
        )}
      {action === "remove" && (
          <RemoveUserFromShift
            selectedshifts={selectedInput}
            show={true}
            onHide={() => {
              dispatch(loadShiftDetails({
                shift_id
              }));

              setAction();
            }}
            onCancel={() => {
              dispatch(loadShiftDetails({
                shift_id
              }));

              setAction();
            }}
          />
        )}
      {action === "dispatch" && (
          <DispatchUser
            selectedshifts={selectedInput}
            show={true}
            onHide={() => {
              dispatch(loadShiftDetails({
                shift_id
              }));

              setAction();
            }}
            onCancel={() => {
              dispatch(loadShiftDetails({
                shift_id
              }));

              setAction();
            }}
          />
        )}
      {action === "delete" && (
          <DeleteShift
            selectedshifts={selectedInput}
            show={true}
            onHide={() => {
              dispatch(loadShiftDetails({
                shift_id
              }));

              setAction();
            }}
            onCancel={() => {
              dispatch(loadShiftDetails({
                shift_id
              }));

              setAction();
            }}
          />
        )}
        
    </div>
    {action === "showmap" && (
          <div className="card">
            <AttendenceMap site={siteCords.every(element => element === null) ? [] : siteCords} clockin={clockinCords.every(element => element === null) ? [] : clockinCords} clockout={clockoutCords.every(element => element === null) ? [] : clockoutCords} />
          </div>
        )}

{action === "applied" && (
          <div className="card">
            <Table  hover >
      <thead>
      <tr >
          <th>#</th>
          <th>User</th>
          <th>Applied on</th>
        </tr>
      </thead>
      <tbody>
            {shiftData?.applied.map((user, index)=>{
              
       return(<tr key={index}>
          <td>{index + 1 }</td>
          <td><Link to={`/dashboard/users/${user.user_id}`}>{user.userName}</Link></td>
          <td>{new Date(user.date).toDateString()}</td>
          
        </tr>)
        
        
     
            })}
             </tbody>
    </Table>
          </div>
        )}
    </>
  )
}

export default Shift