import React, { useState, useEffect } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { register, loadUser } from "../reducers/auth";
import { setAlert } from "../reducers/alert";
import Alerts from "./Alerts";
import ReactGA from "react-ga4";

//import { useNavigate } from "react-router-dom";
//import UserRoles from '../config/default.json'

const Register = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [phone, setPhone] = useState();

  const dispatch = useDispatch();
  //const navigate = useNavigate();
  const user = useSelector((state) => state.auth);

  // if (user.isAuthenticated && user.user?.roles.includes(UserRoles.ROLE.ADMIN)) {
  //   navigate("admin");
  // }
  // if (user.isAuthenticated && (user.user?.roles.includes(UserRoles.ROLE.AGENCY_ADMIN) || user.user?.roles.includes(UserRoles.ROLE.AGENCY_TEAM) || user.user?.roles.includes(UserRoles.ROLE.AGENCY_USER))) {
  //   navigate("dashboard");
  // }
  // if (user.isAuthenticated && user.user?.roles.includes(UserRoles.ROLE.USER)) {
  //   navigate("userdashboard");
  // }

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  // useEffect(()=>{
  //   if(user.token){
  //     dispatch(loadUser());
  //   }
  // },[]);

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    if (!email || !pass || !phone || !name || !validateEmail(email)) {
      dispatch(
        setAlert({
          componentName: "Register",
          alertType: "danger",
          msg: "Please check required fields.",
        })
      );
      // Send a custom event
      ReactGA.event({
        category: "signup_error",
        action: "signup_error",
        label: `name: ${name} , email: ${email} , phone: ${phone}`, // optional
      });
      return console.log("please input all fields");
    }
    dispatch(register({ name, email, password: pass, phone }))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(loadUser());
        dispatch(
          setAlert({
            componentName: "Register",
            alertType: "success",
            msg: "Resgistration successful.",
          })
        );
        // Send a custom event
        ReactGA.event({
          category: "signup",
          action: "signup",
          label: "signup", // optional
        });
      })
      .catch((e) => {
        dispatch(
          setAlert({
            componentName: "Register",
            alertType: "danger",
            msg: "Error in Registeration. Please try again.",
          })
        );
        // Send a custom event
        ReactGA.event({
          category: "signup_error",
          action: "signup_error",
          label: `name: ${name} , email: ${email} , phone: ${phone}`, // optional
        });
      });

    setEmail("");
    setPass("");
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="custom-homapge-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>Register</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alerts componentName={"Register"} />
        <Form>
          <Form.Group className="mb-3" controlId="formBasicUserName">
            <Form.Label>User Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              size="lg"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              size="lg"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              size="lg"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicUserName">
            <Form.Label>Phone </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter your phone number"
              size="lg"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>

          {user.loading ? (
            <Button variant="primary" size="lg" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="primary"
              size="lg"
              type="submit"
              onClick={handleRegisterSubmit}
            >
              Register
            </Button>
          )}
          <br />
          <Form.Text className="text-muted">
            We'll never share your information with anyone else.
          </Form.Text>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Register;
