import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import DisplayShiftBar from "./DisplayShiftBar";
import Form from "react-bootstrap/Form";
import AssignUser from "./AssignUser";
import RemoveUserFromShift from "./RemoveUserFromShift";
import { loadShifts } from "../../reducers/site";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import DispatchUser from "./DispatchUser";

const DisplaySchedule = ({ view, shifts = [] }) => {
  let params = useParams();
  const site_id = params.site_id;
  const dispatch = useDispatch();
  const [offSet, setOffSet] = useState(0);
  const [selectedInput, setSelectedInput] = React.useState([]);
  const [action, setAction] = useState();

  //display state on screen

  let date = new Date();
  let weekStartDate = new Date();
  let weekEndDate = new Date();

  const renderSelected = (e) => {
    const targtValue = e.currentTarget.value;
    setSelectedInput((prev) =>
      prev.some((data) => data === targtValue)
        ? prev.filter((data) => data !== targtValue)
        : [...prev, targtValue]
    );
  };

  //Filter shifts
  const filterShifts = (shifts, view) => {
    if (view === "daily") {
      date = new Date();
      date.setHours(0, 0, 0, 0);
      if (offSet !== 0) {
        date.setDate(date.getDate() + offSet);
        // setDisplaystartdate(date);
      }
      const month = date.getMonth();
      const year = date.getFullYear();
      const curDate = date.getDate();
      const tempShifts = shifts.filter((shift) => {
        const shiftDateISOString = Date.parse(shift.shift_id?.shiftDate);
        const shiftDate = new Date(shiftDateISOString);
        if (
          shiftDate.getMonth() === month &&
          shiftDate.getDate() === curDate &&
          shiftDate.getFullYear() === year
        ) {
          return true;
        }
        return false;
      });

      return tempShifts;
    }
    if (view === "weekly") {
      let date = new Date();
      date.setHours(0, 0, 0, 0);
      if (offSet !== 0) {
        date.setDate(date.getDate() + offSet * 7);
      }
      const month = date.getMonth();
      const year = date.getFullYear();
      const curDate = date.getDate();
      const weekStart = curDate - date.getDay();
      weekStartDate = new Date(date.setDate(weekStart));
      weekEndDate = new Date(date.setDate(weekStartDate.getDate() + 6));
      // setDisplaystartWeek(weekStartDate);
      // setDisplayEndWeek(weekEndDate);
      weekEndDate.setHours(23, 59, 59, 0);

      const tempShifts = shifts.filter((shift) => {
        const shiftDateISOString = Date.parse(shift.shift_id.shiftDate);
        const shiftDate = new Date(shiftDateISOString);

        if (shiftDate >= weekStartDate && shiftDate <= weekEndDate) {
          return true;
        }
        return false;
      });
      return tempShifts;
    }
  };

  const FilteredShifts = filterShifts(shifts, view);
  //sort shifts
  FilteredShifts.sort(function compareFn(a, b) {
    let sTempDate = new Date(a.shift_id.shiftDate);
    let sTempDate2 = new Date(a.shift_id.startTime);
    let bTempDate = new Date(b.shift_id.shiftDate);
    let bTempDate2 = new Date(b.shift_id.startTime);

    sTempDate.setHours(sTempDate2.getHours(), sTempDate2.getMinutes(), 0, 0);
    bTempDate.setHours(bTempDate2.getHours(), bTempDate2.getMinutes(), 0, 0);

    if (sTempDate.getTime() < bTempDate.getTime()) {
      return -1;
    }
    if (sTempDate.getTime() > bTempDate.getTime()) {
      return 1;
    }
    return 0;
  });

  

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center gap-3">
          <span>Action:</span>
          <span>
            <Form.Select
              className="w-100"
              aria-label="Default select example"
              value={action}
              onChange={(e) => setAction(e.target.value)}
            >
              <option>Select Action</option>
              <option value="assign">Assign</option>
              <option value="remove">Remove</option>
              <option value="dispatch">Clock In/Out</option>
            </Form.Select>
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <Button
            variant="primary"
            onClick={(e) => {
              setOffSet((prev) => prev - 1);
            }}
          >
            <AiOutlineLeft />
          </Button>
          <div>
            {view === "weekly" ? (
              <>
                {" "}
                {weekStartDate.toDateString()} - {weekEndDate.toDateString()}
              </>
            ) : (
              <>{date.toDateString()}</>
            )}
          </div>
          <Button
            variant="primary"
            onClick={(e) => {
              setOffSet((prev) => prev + 1);
            }}
          >
            <AiOutlineRight />
          </Button>
        </div>
        <div></div>
      </div>
      <div>
        {FilteredShifts.map((shift) => {
          return (
            <div>
              <DisplayShiftBar
                renderSelected={renderSelected}
                shift={shift.shift_id}
              />
            </div>
          );
        })}
        {(!shifts || FilteredShifts?.length === 0 || !FilteredShifts) && (
          <div>
            <Alert variant="info">No Shift within selected time period.</Alert>
          </div>
        )}
        {action === "assign" && (
          <AssignUser
            selectedshifts={selectedInput}
            show={true}
            onHide={() => {
              dispatch(loadShifts({ site_id }));
              setAction();
            }}
          />
        )}
        {action === "remove" && (
          <RemoveUserFromShift
            selectedshifts={selectedInput}
            show={true}
            onHide={() => {
              dispatch(loadShifts({ site_id }));

              setAction();
            }}
            onCancel={() => {
              setAction();
            }}
          />
        )}
        {action === "dispatch" && (
          <DispatchUser
            selectedshifts={selectedInput}
            show={true}
            onHide={() => {
              dispatch(loadShifts({ site_id }));

              setAction();
            }}
            onCancel={() => {
              setAction();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DisplaySchedule;
