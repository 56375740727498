import React, { useEffect, useState } from "react";
import Sidebar2 from "./Sidebar2";
import "./agencydasgboard.css";
import NavbarMenu from "./Navbar";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import UserRoles from "../../config/default.json";
import DisplayAgencyProfile from "./DisplayAgencyProfile";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Alerts from "../Alerts";
import { setAlert } from "../../reducers/alert";
import { updateProfile } from "../../reducers/user";
import { loadUser } from "../../reducers/auth";
import { updatetrialPlan } from "../../reducers/agencyProfile";
import configData from "../../config/default.json";
import differenceInDays from "date-fns/differenceInDays";

// Modal
function NoProfile({ show, username }) {
  const dispatch = useDispatch();
  const [showProfileBanner, setShowProfileBanner] = useState(true);
  const [loading, setLoading] = useState(false);

  const [unit, setUnit] = useState(null);
  const [streetNumber, setStreetNumber] = useState(undefined);
  const [postal, setPostal] = useState( undefined);
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [provience, setProvience] = useState("");
  const [country, setCountry] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !street ||
      !city ||
      !provience ||
      !country ||
      !postal ||
      !streetNumber
    ) {
      dispatch(
        setAlert({
          componentName: "NoProfile",
          alertType: "danger",
          msg: "Please fill all fields.",
        })
      );
      return console.log("please input all fields");
    }
    setLoading(true);
    dispatch(
      updateProfile({
        unit,
        street,
        city,
        provience,
        country,
        streetNumber,
        postal
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(loadUser());
        dispatch(
          setAlert({
            componentName: "NoProfile",
            alertType: "success",
            msg: "Company Profile is Saved.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "NoProfile",
            alertType: "danger",
            msg: "Error in Saving Profile. Please try again.",
          })
        )
      );
      setLoading(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() =>
          dispatch(
            setAlert({
              componentName: "NoProfile",
              alertType: "danger",
              msg: "Please setup profile first.",
            })
          )
        }
        dialogClassName="custom-width-modal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="custom-mondal-header">
            <Alerts componentName={"NoProfile"} />
            {showProfileBanner && (
              <Alert
                variant="info"
                className="mt-4"
                onClose={() => setShowProfileBanner(false)}
                dismissible
              >
                Please enter your address to complete your personal profile. (Step 2 of 2)
              </Alert>
            )}
          </div>
          
          






          <Alerts componentName={"agencyprofile"} />
      <Form className="custom-mondal-header my-4">
        <h2>Profile</h2>
        <hr />
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Name"
            value={username}
            disabled={true}
          />
        </Form.Group>

        <Row className="mb-3">
        <Form.Group className="col-12 col-md-3" controlId="formGridunit">
            <Form.Label>Unit</Form.Label>
            <Form.Control
              type="number"
              placeholder="Unit Number(not required)"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="col-12 col-md-3" controlId="formGridstreetNumber">
            <Form.Label>Street Number</Form.Label>
            <Form.Control
              type="number"
              placeholder="Street Number (required)"
              value={streetNumber}
              onChange={(e) => setStreetNumber(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="col-12 col-md-3" controlId="formGridStreet">
            <Form.Label>Street Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Street Name"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
          </Form.Group>

          

          <Form.Group className="col-12 col-md-3" controlId="formGridCity">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
        

        <Form.Group className="col-12 col-md-4" controlId="formGridstate">
            <Form.Label>Provience/State</Form.Label>
            <Form.Control
              type="text"
              placeholder="Provience"
              value={provience}
              onChange={(e) => setProvience(e.target.value)}
            />
          </Form.Group>
          
        <Form.Group className="col-12 col-md-4" controlId="formGridCountry">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              placeholder="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </Form.Group>

          

          <Form.Group className="col-12 col-md-4" controlId="formGridpostal">
            <Form.Label>Zip</Form.Label>
            <Form.Control
              type="text"
              placeholder="Postal"
              value={postal}
              onChange={(e) => setPostal(e.target.value)}
            />
          </Form.Group>
        </Row>

        {loading ? 
          <Button variant="primary" disabled className="button-flex">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="visually-hidden">Loading...</span>
          </Button>
         : 
            <>
            <Button variant="primary" type="submit" className="button-flex" onClick={handleSubmit}>
              Save
            </Button>
            
          </> }
      </Form>




        </Modal.Body>
      </Modal>
    </>
  );
}
// Modal
function NoCompany({ show }) {
  const dispatch = useDispatch();
  const [showBanner, setShowBanner] = useState(true);
  return (
    <>
      <Modal
        show={show}
        onHide={() =>
          dispatch(
            setAlert({
              componentName: "NoCompany",
              alertType: "danger",
              msg: "Please setup company's profile first.",
            })
          )
        }
        dialogClassName="custom-width-modal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="custom-mondal-header">
            <Alerts componentName={"NoCompany"} />
            {showBanner && (
              <Alert
                variant="info"
                className="mt-4"
                onClose={() => setShowBanner(false)}
                dismissible
              >
                Please setup Company profile to proceed. (Step 1 of 2)
              </Alert>
            )}
          </div>
          <DisplayAgencyProfile />
        </Modal.Body>
      </Modal>
    </>
  );
}
// Modal End

const AgencyDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  let askCompany = null
  let askProfile = null
  
  const user = useSelector((state) => state.auth);
  const plan = useSelector((state) => state.agencyProfile.agencyprofile);
  //check roles
  let isAdmin = user?.user?.roles.includes(UserRoles.ROLE.AGENCY_ADMIN);
  //check profile
  let hasProfile = user?.user?.profile;

  //check company ptofile
  let hasCompanyProfile = user?.user?.company;
  
 
    if(hasProfile === null ){
      askProfile = 1;
      
    }
    if(hasCompanyProfile === null){
      askCompany = 1;
    }
    
 
  

  // //check user auth
  // useEffect(() => {
  //   if (
  //     !user.isAuthenticated &&
  //     (!user.user?.roles.includes(UserRoles.ROLE.AGENCY_ADMIN) ||
  //       !user.user?.roles.includes(UserRoles.ROLE.AGENCY_TEAM) ||
  //       !user.user?.roles.includes(UserRoles.ROLE.AGENCY_USER))
  //   ) {
  //     navigate("/");
  //   }
  // }, []);

  if (isAdmin && !askCompany && !askProfile && (Array.isArray(plan?.plan) && plan?.plan?.length === 0) && window.location.pathname !== "/dashboard/plan" && window.location.pathname !== "/dashboard/support") {
     navigate("/dashboard/plan");
  
  }

  const trial_plan = plan?.plan ? plan?.plan?.find(e => (e.plan_id === configData.PLAN.TIER0 && e.status === 'active')) : null;
  let diffInDays = null;
  if (isAdmin && ( Array.isArray(plan?.plan) && trial_plan ) ) {
    const today_date = new Date();
    const trial_start_date = new Date(trial_plan.date)
    let trial_end_date = new Date(trial_start_date)
    trial_end_date.setDate(trial_start_date.getDate() + configData.PLAN.TIER0_TRIAL_DAYS)
    if(today_date.getTime() > trial_end_date.getTime() ){
      dispatch(
      updatetrialPlan({status: "expired"})
    )
    }
    if(today_date.getTime() < trial_end_date.getTime()){
      diffInDays = differenceInDays(new Date(trial_end_date), new Date(today_date));
    }
    console.log(trial_start_date)
    console.log(trial_end_date)
    console.log(diffInDays)
 }

  return (
    <>
      <NavbarMenu />

      <div className="AgencyDashboard">
        <Sidebar2 trialDaysLeft={diffInDays} />
        <div className="AgencyDashboard_panel">
          {isAdmin && askCompany && <NoCompany show={true} />}
          {isAdmin && !askCompany && askProfile && (
            <NoProfile show={true} username={user?.user?.username}/>
          )}
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default AgencyDashboard;
