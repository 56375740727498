import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getChartData } from "../../../reducers/dashboard";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  elements: {
    line: {
      tension: 0.3, // smooth lines
    },
  },
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: false,
      text: "Chart.js Line Chart - Multi Axis",
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      type: "linear",
      display: true,
      position: "left",
      grid: {
        display: false,
      },
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        display: false,
        drawOnChartArea: false,
      },
    },
  },
};

const Chart = () => {
  const dispatch = useDispatch();
  const chartData = useSelector((state) => state.dashboardData);

  const labels = chartData?.chartData?.days;

  const [offSet, setOffSet] = useState(0);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let today = new Date();
  let startdate = new Date(today.getFullYear(), today.getMonth() + offSet, 1);
  let endDate = new Date(
    today.getFullYear(),
    today.getMonth() + 1 + offSet,
    0,
    23,
    59,
    59,
    0
  );

  useEffect(() => {
    dispatch(
      getChartData({
        startDate: startdate.toISOString(),
        endDate: endDate.toISOString(),
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      getChartData({
        startDate: startdate.toISOString(),
        endDate: endDate.toISOString(),
      })
    );
  }, [offSet]);

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Open Shifts",
        data: chartData?.chartData?.open,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "y",
      },
      {
        fill: true,
        label: "Covered Shifts",
        data: chartData?.chartData?.covered,
        borderColor: "RGB(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        yAxisID: "y1",
      },
    ],
  };

  if (chartData.chartLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }
  if (!chartData.chartData) {
    return (
      <div>
        <Alert variant="info">No Data. Please try again later.</Alert>
      </div>
    );
  }
  return (
    <div>
      <div className="d-flex justify-content-between">
        <h3>Monthly Data</h3>
        <div className="d-flex gap-3">
          <span
            className="dashboard-arrow"
            onClick={() => setOffSet((prev) => prev - 1)}
          >
            <AiOutlineLeft />
          </span>
          <span>
            {monthNames[startdate.getMonth()] + " " + startdate.getFullYear()}
          </span>
          <span
            className="dashboard-arrow"
            onClick={() => setOffSet((prev) => prev + 1)}
          >
            <AiOutlineRight />
          </span>
        </div>
      </div>
      <hr />
      <Line options={options} data={data} />
    </div>
  );
};

export default Chart;
