import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";
function ScrooltoTop({ children }) {
  const location = useLocation();
  const navType = useNavigationType();
  useEffect(() => {
    if (navType !== "POP") {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
    }
  }, [location]);
  return <>{children}</>;
}
export default ScrooltoTop;