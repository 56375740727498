import React from "react";
import BroadcastChart from "./dashboardData/BroadcastChart";
import Chart from "./dashboardData/Chart";
import ShiftsCard from "./dashboardData/ShiftsCard";
import SitesCard from "./dashboardData/SitesCard";
import UsersCard from "./dashboardData/UsersCard";

const DashboardHome = () => {
  return (
    <div className="dashboard-grid">
      <div className="card sites-card-header"><UsersCard /></div>
      <div className="card sites-card-header"><BroadcastChart /></div>
      <div className="card sites-card-header"><SitesCard /></div>

      <div className="card sites-card-header-2">
        <Chart />
      </div>
      <div className="card sites-card-header"><ShiftsCard /></div>
    </div>
  );
};

export default DashboardHome;
