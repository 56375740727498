import configData from "../config/default.json";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

const API_ENDPOINT = configData.API_ENDPOINT;

const initialState = {
  tasks: [],
  tasksLoading: false,
  tasksError: null,
  task: null,
  taskLoading: false,
  taskError: null,
  updatestatusLoading: false,
  updatepriorityLoading: false,
  updateacptLoading: false,
  updatenoteLoading: false,
  taskCount: null,
};

//create task
export const createTask = createAsyncThunk(
  "api/createTask",
  async ({
    taskTitle,
    taskDetails,
    priority,
    assignedTo,
    assignedToId,
    department,
    deadline,
    startDate,
  }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      taskTitle,
      taskDetails,
      priority,
      assignedTo,
      assignedToId,
      department,
      deadline,
      startDate,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/tasks/createtask`,
      body,
      config
    );
    return response.data;
  }
);

//load user tasks by ID
export const getUserTasksbyID = createAsyncThunk(
  "api/getUserTasksbyID",
  async ({ user_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const response = await axios.get(
      `${API_ENDPOINT}/api/tasks/gettasks/${user_id}`
    );
    return response.data;
  }
);

//get tasks by task ID
export const getTasksbyID = createAsyncThunk(
  "api/getTasksbyID",
  async ({ task_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const response = await axios.get(
      `${API_ENDPOINT}/api/tasks/gettask/${task_id}`
    );
    return response.data;
  }
);

// get logged in user tasks
export const getUserTasks = createAsyncThunk("api/getUserTasks", async () => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const response = await axios.get(`${API_ENDPOINT}/api/tasks/getusertasks`);
  return response.data;
});

//   load all company tasks
export const loadAgencyTasks = createAsyncThunk(
  "api/loadAgencyTasks",
  async ({ startDate, endDate }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      startDate,
      endDate,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/tasks/getagencytasks`,
      body,
      config
    );
    return response.data;
  }
);

// update task status with ID
export const updateTaskStatus = createAsyncThunk(
  "api/updateTaskStatus",
  async ({ task_id, taskStatus }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      taskStatus,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/tasks/update/${task_id}`,
      body,
      config
    );
    return response.data;
  }
);
// Update task priority with ID
export const updateTaskPriority = createAsyncThunk(
  "api/updateTaskPriority",
  async ({ task_id, priority }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      priority,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/tasks/updatepriority/${task_id}`,
      body,
      config
    );
    return response.data;
  }
);

// Update task accepted with ID
export const updateTaskAccepted = createAsyncThunk(
  "api/updateTaskAccepted",
  async ({ task_id, accepted }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      accepted,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/tasks/updatestatus/${task_id}`,
      body,
      config
    );
    return response.data;
  }
);

// add note on task with ID
export const addTaskNote = createAsyncThunk(
  "api/addTaskNote",
  async ({ task_id, note }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      note,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/tasks/addnote/${task_id}`,
      body,
      config
    );
    return response.data;
  }
);

// get tasks count for notification badge
export const getTaskCount = createAsyncThunk("api/getTaskCount", async () => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const response = await axios.get(
    `${API_ENDPOINT}/api/tasks/getusertaskscount`
  );
  return response.data;
});

export const tasksData = createSlice({
  name: "tasksData",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createTask.pending, (state, action) => {
        state.tasksLoading = true;
      })
      .addCase(createTask.fulfilled, (state, action) => {
        state.tasksLoading = false;
        state.tasks = [...state.tasks, action.payload];
        state.tasksError = null;
      })
      .addCase(createTask.rejected, (state, action) => {
        state.tasksLoading = false;
        state.tasks = null;
        state.tasksError = action.error;
        console.log("error in createTask");
        console.log(action);
      })
      .addCase(getUserTasksbyID.pending, (state, action) => {
        state.tasksLoading = true;
      })
      .addCase(getUserTasksbyID.fulfilled, (state, action) => {
        state.tasksLoading = false;
        state.tasks = action.payload;
        state.tasksError = null;
      })
      .addCase(getUserTasksbyID.rejected, (state, action) => {
        state.tasksLoading = false;
        state.tasks = null;
        state.tasksError = action.error;
        console.log("error in getUserTasksbyID");
        console.log(action);
      })
      .addCase(getTasksbyID.pending, (state, action) => {
        state.taskLoading = true;
      })
      .addCase(getTasksbyID.fulfilled, (state, action) => {
        state.taskLoading = false;
        state.task = action.payload;
        state.taskError = null;
      })
      .addCase(getTasksbyID.rejected, (state, action) => {
        state.taskLoading = false;
        state.task = null;
        state.taskError = action.error;
        console.log("error in getTasksbyID");
        console.log(action);
      })
      .addCase(getUserTasks.pending, (state, action) => {
        state.tasksLoading = true;
      })
      .addCase(getUserTasks.fulfilled, (state, action) => {
        state.tasksLoading = false;
        state.tasks = action.payload;
        state.tasksError = null;
      })
      .addCase(getUserTasks.rejected, (state, action) => {
        state.tasksLoading = false;
        state.tasks = null;
        state.tasksError = action.error;
        console.log("error in getUserTasks");
        console.log(action);
      })
      .addCase(loadAgencyTasks.pending, (state, action) => {
        state.tasksLoading = true;
      })
      .addCase(loadAgencyTasks.fulfilled, (state, action) => {
        state.tasksLoading = false;
        state.tasks = action.payload;
        state.tasksError = null;
      })
      .addCase(loadAgencyTasks.rejected, (state, action) => {
        state.tasksLoading = false;
        state.tasks = null;
        state.tasksError = action.error;
        console.log("error in loadAgencyTasks");
        console.log(action);
      })
      .addCase(updateTaskStatus.pending, (state, action) => {
        state.updatestatusLoading = true;
      })
      .addCase(updateTaskStatus.fulfilled, (state, action) => {
        state.updatestatusLoading = false;
        state.task = action.payload;
        state.taskError = null;
      })
      .addCase(updateTaskStatus.rejected, (state, action) => {
        state.updatestatusLoading = false;
        state.taskError = action.error;
        console.log("error in updateTaskStatus");
        console.log(action);
      })
      .addCase(updateTaskPriority.pending, (state, action) => {
        state.updatepriorityLoading = true;
      })
      .addCase(updateTaskPriority.fulfilled, (state, action) => {
        state.updatepriorityLoading = false;
        state.task = action.payload;
        state.taskError = null;
      })
      .addCase(updateTaskPriority.rejected, (state, action) => {
        state.updatepriorityLoading = false;
        state.taskError = action.error;
        console.log("error in updateTaskPriority");
        console.log(action);
      })
      .addCase(updateTaskAccepted.pending, (state, action) => {
        state.updateacptLoading = true;
      })
      .addCase(updateTaskAccepted.fulfilled, (state, action) => {
        state.updateacptLoading = false;
        state.task = action.payload;
        state.taskError = null;
      })
      .addCase(updateTaskAccepted.rejected, (state, action) => {
        state.updateacptLoading = false;
        state.taskError = action.error;
        console.log("error in updateTaskAccepted");
        console.log(action);
      })
      .addCase(addTaskNote.pending, (state, action) => {
        state.updatenoteLoading = true;
      })
      .addCase(addTaskNote.fulfilled, (state, action) => {
        state.updatenoteLoading = false;
        state.task = action.payload;
        state.taskError = null;
      })
      .addCase(addTaskNote.rejected, (state, action) => {
        state.updatenoteLoading = false;
        state.taskError = action.error;
        console.log("error in addTaskNote");
        console.log(action);
      })
      .addCase(getTaskCount.pending, (state, action) => {
        state.taskCount = null;
      })
      .addCase(getTaskCount.fulfilled, (state, action) => {
        state.taskCount = action.payload;
      })
      .addCase(getTaskCount.rejected, (state, action) => {
        state.taskCount = null;
        console.log("error in getTaskCount");
        console.log(action);
      });
  },
});

export default tasksData.reducer;
