import React from "react";
import logo from "./uploads/logo.png";
import {
  BsGeoAltFill,
  BsWifi,
  BsFillCheckSquareFill,
  BsFillHandThumbsUpFill,
  BsCheck2Square,
} from "react-icons/bs";

const SchedulingApp = () => {
  return (
    <>
      <div className="privacypolicy-header">
        <div className="container header-section ">
          <div className="header-logo d-flex justify-content-between align-items-center gap-2">
            {" "}
            <span className="pb-2">
              <img className="site-logo" src={logo} alt="Logo" />
            </span>{" "}
            <span>|</span> <span className="logo-text">ALPHA</span>
          </div>
        </div>
      </div>
      
        

        {/* Feature #1 */}
        <div className="feature-container" id="app">
          <div className="container feature-section">
            <div className="container">
              
              <div className="feature-section-content d-flex justify-content-between align-items-center gap-2 my-5">
                <div className="feature-left-content d-flex align-items-center">
                  <div className="feature-phone-image-container">
                    <div class="phone-graphic">
                      <div class="phone-case">
                        <div class="phone-container-outer">
                          <div class="phone-container-inner"></div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div className="feature-right-content">
                  <h1 className="display-5 fw-bold lh-1 mb-3">
                    Client Mobile App
                  </h1>
                  <p className="lead">
                    Schedule your remote employees with Alpha Scheduling
                    software. If you have a team that is spread out, alpha will
                    help keep track of your remote team. Our mobile app is
                    loaded with powerfull features.
                  </p>
                  <div className="d-flex flex-column justify-content-start lead my-4">
                    <div className="d-flex justify-content-start align-items-center mb-2">
                      <span className="list-check">
                        <BsCheck2Square />
                      </span>
                      <span className="ms-5">
                        <strong>Free to use</strong>
                      </span>
                    </div>
                    <div className="d-flex justify-content-start align-items-center mb-2">
                      <span className="list-check">
                        <BsCheck2Square />
                      </span>
                      <span className="ms-5">
                        <strong>Complete Tasks on App</strong>
                      </span>
                    </div>
                    <div className="d-flex justify-content-start align-items-center mb-2">
                      <span className="list-check">
                        <BsCheck2Square />
                      </span>
                      <span className="ms-5">
                        <strong>GPS Tracking</strong>
                      </span>
                    </div>
                    <div className="d-flex justify-content-start align-items-center mb-2">
                      <span className="list-check">
                        <BsCheck2Square />
                      </span>
                      <span className="ms-5">
                        <strong>Apply for Available Shifts</strong>
                      </span>
                    </div>
                    <div className="d-flex justify-content-start align-items-center mb-2">
                      <span className="list-check">
                        <BsCheck2Square />
                      </span>
                      <span className="ms-5">
                        <strong>Track Working Hours</strong>
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center gap-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Feature #1 End*/}
      
      {/* Footer */}
      <div className="container-fluid">
        <hr />
        <div className="container d-flex justify-content-between align-items-center pb-3">
          <div>© 2022 Alpha Scheduling. All rights reserved.</div>
          <div>Privacy Policy</div>
        </div>
      </div>
      {/* Footer End */}
    </>
  );
};

export default SchedulingApp;
