import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { deleteShift } from "../../reducers/site";
import { setAlert } from "../../reducers/alert";
import Spinner from "react-bootstrap/Spinner";
import Alerts from "../Alerts";

const DeleteShift = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.site?.deleteLoading);
  const shiftids = props.selectedshifts;

  const handleDelete = (user_id) => {
    if (!shiftids || shiftids.length === 0) {
      dispatch(
        setAlert({
          componentName: "DeleteShift",
          alertType: "danger",
          msg: "Please Select Shifts.",
        })
      );
      return console.log("Please Select Shifts.");
    }
    shiftids.forEach((id, index) => {
      dispatch(
        deleteShift({
          shift_id: shiftids[index],
        })
      )
        .unwrap()
        .then((originalPromiseResult) => {
          dispatch(
            setAlert({
              componentName: "DeleteShift",
              alertType: "success",
              msg: ` Deleted shift #${shiftids[index]}.`,
            })
          );
        })
        .catch((e) =>
          dispatch(
            setAlert({
              componentName: "DeleteShift",
              alertType: "danger",
              msg: `Error in saving data for shift #${shiftids[index]}. Please try again.`,
            })
          )
        );
    });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {loading && (
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      )}

      <>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Shift
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Alerts componentName={"DeleteShift"} />
          <p> Do you want to delete selected shifts?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};

export default DeleteShift;
