import React, { useState } from "react";

import Form from "react-bootstrap/Form";

import Spinner from "react-bootstrap/Spinner";
import Alerts from "../../Alerts";
import { setAlert } from "../../../reducers/alert";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import { createTicket } from "../../../reducers/support";

const CreateTicket = () => {
  const dispatch = useDispatch();
  //   const loading = useSelector((state) => state.support?.ticketsLoading);
  const [ticketTitle, setTicketTitle] = useState("");
  const [ticketDetails, setTicketDetails] = useState("");
  const [priority, setPriority] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!ticketTitle || !ticketDetails || !priority) {
      dispatch(
        setAlert({
          componentName: "createticket",
          alertType: "danger",
          msg: "Please fill all fields.",
        })
      );
      return console.log("please input all fields");
    }
    setLoading(true);
    dispatch(
      createTicket({
        ticketTitle,
        ticketDetails,
        priority,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(
          setAlert({
            componentName: "createticket",
            alertType: "success",
            msg: "Support Ticket is Created.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "createticket",
            alertType: "danger",
            msg: "Error in Saving Data. Please try again.",
          })
        )
      );
    setLoading(false);
    setTicketTitle("");
    setTicketDetails("");
    setPriority("");
  };

  return (
    <div className="thin-border my-3 card-no-shadow">
      <Alerts componentName={"createticket"} />
      <div className="w-50">
        <Form.Group
          className="mb-3 d-flex gap-5 align-items-center"
          controlId="formtext"
        >
          <div className="w-50">
            <Form.Label>Title :</Form.Label>
          </div>
          <Form.Control
            type="text"
            placeholder="Title"
            value={ticketTitle}
            onChange={(e) => setTicketTitle(e.target.value)}
          />
        </Form.Group>

        <Form.Group
          className="mb-3 d-flex gap-5 align-items-center"
          controlId="formtext"
        >
          <div className="w-50">
            <Form.Label>Priority :</Form.Label>
          </div>
          <Form.Select
            aria-label="Default select example"
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
          >
            <option>Select</option>
            <option value="HIGH">High</option>
            <option value="NORMAL">Normal</option>
            <option value="LOW">Low</option>
          </Form.Select>
        </Form.Group>

        <Form.Group
          className="mb-3 d-flex gap-5 align-items-center"
          controlId="formtext"
        >
          <div className="w-50">
            <Form.Label>Details :</Form.Label>
          </div>
          <Form.Control
            as="textarea"
            placeholder="Please give as much details as possible."
            value={ticketDetails}
            onChange={(e) => setTicketDetails(e.target.value)}
            style={{ minHeight: "100px" }}
          />
        </Form.Group>
        {loading ? (
          <Button variant="primary" disabled className="button-flex">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="visually-hidden">Loading...</span>
          </Button>
        ) : (
          <>
            <Button
              variant="primary"
              type="submit"
              className="button-flex"
              onClick={handleSubmit}
            >
              Create
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateTicket;
