import React, { useState, useEffect, useRef } from "react";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import markerIconPng from "../../uploads/site.png";
import Alert from "react-bootstrap/Alert";

const AddressMap = ({ address, city }) => {
  const provider = new OpenStreetMapProvider();
  const mapRef = useRef();
  const [center, setCenter] = useState([]);
  const [cords, setCords] = useState([]);


  const getCords = async (address) => {
    let data = await provider.search({ query: address });
    data.forEach((obj, index) => {
      // filter result  by city if multiple results ins search
      if (obj.label.toLowerCase().includes(city.toLowerCase())) {
        setCenter([obj.y, obj.x]);
      }
    })
    setCords(data);
  };

  useEffect(() => {
    getCords(address);
  }, []);

  if (cords.length === 0 || center.length === 0 ) {
    return <div className="d-flex align-items-center justify-content-center h-100 w-100"><Alert variant="info">Map is not available. please check address and try again.</Alert></div>;
  }
  return (
    <>
      <MapContainer
        style={{ width: "600px", height: "440px" }}
        center={center}
        zoom={13}
        scrollWheelZoom={false}
        ref={mapRef}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {cords.map((obj, index) => {
          // filter result  by city if multiple results ins search
          if (!obj.label.toLowerCase().includes(city.toLowerCase())) {
            return <span key={index}></span>;
          }
          return (
            
            <Marker
              key={index}
              icon={
                new Icon({
                  iconUrl: markerIconPng,
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                })
              }
              position={[obj.y, obj.x]}
            >
              <Popup>{obj.label}</Popup>
            </Marker>
          );
        })}
      </MapContainer>
    </>
  );
};

export default AddressMap;
