import React, { useEffect, useState } from "react";
import "./invoiceStyle.css";
import {
  loadEmpReport, updatePaymentReport
} from "../../../reducers/reports";
import { setAlert } from "../../../reducers/alert";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import { loadAgencyProfile } from "../../../reducers/agencyProfile";
import Button from "react-bootstrap/Button";

import { Link } from "react-router-dom";
import { BsX, BsExclamation, BsClock, BsCheck, BsHash } from "react-icons/bs";
import Alerts from "../../Alerts";
import {
  startOfWeek,
  endOfWeek,
  addWeeks,
  addMonths,
  endOfDay,
  endOfMonth,
  startOfMonth,
  format
} from "date-fns";

import { useParams, useLocation } from "react-router-dom";
import DisplayTwoColData from "../DisplayTwoColData";
import SiteReportTable from "./SiteReportTable";
import EmpReportTable from "./EmpReportTable";

const EmpReport = () => {
  let params = useParams();
  const location = useLocation()
  // get dates from Link
  const startDatePrev = location.state?.startDate
  const endDatePrev = location.state?.endDate

  const user_id = params.user_id;

  const dispatch = useDispatch();
  const agencyprofile = useSelector((state) => state.agencyProfile.agencyprofile);
  const reportData = useSelector((state) => state.report.userreport);
  const loading = useSelector((state) => state.report.userreportLoading);
  const actionLoading = useSelector((state) => state.report.actionLoading);
  let transformedData = [];
  
  const [view, setView] = useState("weekly");
  const offSet = 1;
  const [useClockHours, setuseClockHours] = useState(false)
  //OT
  const [ot, setot] = useState(44)

  const [startDate, setStartDate] = useState(startOfWeek(new Date()));

  const [endDate, setEndDate] = useState(endOfWeek(new Date()));

  const handlePrevClick = () => {
    switch (view) {
      case "monthly":
        setStartDate((prev) => startOfMonth(addMonths(prev, -offSet)));
        setEndDate((prev) => endOfMonth(addMonths(prev, -offSet)));
        break;
      default:
        setStartDate((prev) => addWeeks(prev, -offSet));
        setEndDate((prev) => addWeeks(prev, -offSet));
    }
  };

  const handleNextClick = () => {
    switch (view) {
      case "monthly":
        setStartDate((prev) => startOfMonth(addMonths(prev, offSet)));
        setEndDate((prev) => endOfMonth(addMonths(prev, offSet)));
        break;
      default:
        setStartDate((prev) => addWeeks(prev, offSet));
        setEndDate((prev) => addWeeks(prev, offSet));
    }
  };

  const handleViewChange = (e) => {
    setView(e.target.value);
    switch (e.target.value) {
      case "weekly":
        setStartDate(startOfWeek(new Date()));
        setEndDate(endOfWeek(new Date()));
        break;
      case "monthly":
        setStartDate(startOfMonth(new Date()));
        setEndDate(endOfMonth(new Date()));
        break;
      default:
    }
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end ? endOfDay(end) : end);
  };

  //update invoice handler
  const handleUpdate=(userpaymentType, selected_ids)=>{
    dispatch(
      updatePaymentReport({
        userpaymentType,
        shift_id:selected_ids 
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(
          loadEmpReport({
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            user_id
          })
        );
        dispatch(
          setAlert({
            componentName: "empployeeReportUpdate",
            alertType: "success",
            msg: "Payment status updated.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "empployeeReportUpdate",
            alertType: "danger",
            msg: "Error in Saving Data. Please try again.",
          })
        )
      );
  }
  
  useEffect(() => {
    
    dispatch(loadAgencyProfile());
  }, []);

  useEffect(() => {
    if (!endDate) {
      return;
    }
    dispatch(
      loadEmpReport({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        user_id
      })
    );
  }, [endDate]);

  return (
    <>
    <div>
      <div className="card d-flex flex-row gap-2">
        <span className="lead fw-bold">Employee Report: </span> <span className="lead">{reportData?.user?.username} - {reportData?.user?.email} </span>
      </div>
        <div className="reports-stats-panel d-flex gap-3">
          <div className="card card-1">
            <div className="d-flex mb-2">
              <div className="me-3">
                <span
                  style={{ color: "white" }}
                  className=" d-flex justify-content-center align-items-center user-card-badge p-2 display-3"
                >
                  <BsHash />
                </span>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column ">
                  <div className="display-5 lh-1 ">
                    {reportData?.ShiftCount}
                  </div>{" "}
                  <div
                    className="align-self-end fw-light"
                    style={{ fontSize: "12px" }}
                  >
                    Shifts
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-2">
            <div className="d-flex mb-2">
              <div className="me-3">
                <span
                  style={{ color: "white" }}
                  className=" d-flex justify-content-center align-items-center broadcasted-card-logo p-2 display-3"
                >
                  <BsExclamation />
                </span>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column ">
                  <div className="display-5 lh-1 ">
                    {reportData?.userPaymentOpen}
                  </div>{" "}
                  <div
                    className="align-self-end fw-light"
                    style={{ fontSize: "12px" }}
                  >
                    Open Payment
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-3">
            <div className="d-flex mb-2">
              <div className="me-3">
                <span
                  style={{ color: "white" }}
                  className=" d-flex justify-content-center align-items-center broadcasted-card-logo p-2 display-3"
                >
                  <BsClock />
                </span>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column ">
                  <div className="display-5 lh-1 ">
                    {reportData?.userPaymentPending}
                  </div>{" "}
                  <div
                    className="align-self-end fw-light"
                    style={{ fontSize: "12px" }}
                  >
                    Pending Payment
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-4">
            <div className="d-flex mb-2">
              <div className="me-3">
                <span
                  style={{ color: "white" }}
                  className=" d-flex justify-content-center align-items-center user-card-badge p-2 display-3"
                >
                  <BsCheck />
                </span>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column ">
                  <div className="display-5 lh-1 ">
                    {reportData?.userPaymentPaid}
                  </div>{" "}
                  <div
                    className="align-self-end fw-light"
                    style={{ fontSize: "12px" }}
                  >
                    Paid Payment
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-5">
            <div className="d-flex mb-2">
              <div className="me-3">
                <span
                  style={{ color: "white" }}
                  className=" d-flex justify-content-center align-items-center user-card-logo p-2 display-3"
                >
                  <BsX />
                </span>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column ">
                  <div className="display-5 lh-1 ">
                    {reportData?.userPaymentCanceled}
                  </div>{" "}
                  <div
                    className="align-self-end fw-light"
                    style={{ fontSize: "12px" }}
                  >
                    Canceled Payment
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card ">
          <div className="d-flex justify-content-between align-items-start">
            <div className="d-flex justify-content-center align-items-center gap-5 mb-3 me-5">
              <span  className="fs-3">Show: </span>
              <span>
                <Form.Select
                  className="w-100"
                  aria-label="Default select example"
                  value={view}
                  onChange={(e) => handleViewChange(e)}
                >
                  <option value="weekly">Weekly</option>

                  <option value="monthly">Monthly</option>
                  <option value="custom">Custom</option>
                </Form.Select>
              </span>
            </div>
            {view === "custom" ? (
              <div className="d-flex justify-content-center align-items-center gap-5 mb-3 me-5">
                {" "}
                <div>Date:</div>
                <div>
                  <DatePicker
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center gap-5 mb-3 ms-5">
                <Button variant="primary" onClick={handlePrevClick}>
                  <AiOutlineLeft />
                </Button>
                <div>
                  {startDate.toDateString()} - {endDate?.toDateString()}
                </div>
                <Button variant="primary" onClick={handleNextClick}>
                  <AiOutlineRight />
                </Button>
              </div>
            )}
            <div className="d-flex justify-content-center align-items-center gap-5">
            <span className="fs-3">OT Hours: </span>  
          <input name="productLineDescription" type="text" class="ot-input" placeholder="" value={ot}  onChange={(e)=>setot(e.target.value)} />
          </div>
          </div>
          
        </div>

        
        <Alerts componentName={"empployeeReportUpdate"} />
        {loading ? (
          <div className="card">
            <h3>
              <Spinner animation="border" />
              Loading...
            </h3></div>
          ) : reportData?.shiftData?.length === 0 ? (
            <div className="card">
            <Alert variant="info">
              No Data. Please try selecting different dates.
            </Alert>
            </div>
          ) : 
          <>
          
          <EmpReportTable data={reportData?.shiftData} handleUpdate={handleUpdate} user={reportData?.user?.username} agencyprofile={agencyprofile} ot={ot} startDate={format(new Date(startDate), 'MM/dd/yyyy')} endDate={format(new Date(endDate), 'MM/dd/yyyy')}/>
          </>
          }
       
        </div>
    </>
  )
}

export default EmpReport;