import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Alerts from "../Alerts";
import { setAlert } from "../../reducers/alert";
import { useSelector, useDispatch } from "react-redux";
import { loadShifts } from "../../reducers/site";
import { loadAgencySites } from "../../reducers/sites";
import Form from "react-bootstrap/Form";
import CreateShift from "./CreateShift";
import DisplaySchedule from "./DisplaySchedule";

const SiteSchedule = ({ site_id }) => {
  const dispatch = useDispatch();
  const site = useSelector((state) => state.site);
  const sites = useSelector((state) => state.sites);
  const siteData = sites?.sites?.sites?.filter((site) => {
    return site.site_id._id === site_id;
  });
  const shiftTypesData = siteData !== undefined ? siteData[0].site_id.rates : null;
  const shiftTypes = shiftTypesData?.map((item)=>{
    return ({
      "shiftType": item.shiftType
    })
  })

  const [view, setView] = useState("daily");
  
  const [createShift, serCreateShift] = useState(false);

  useEffect(() => {
    dispatch(loadShifts({ site_id }));
    dispatch(loadAgencySites());
  }, []);

  // if (site.shifts.shifts.length === 0 || !site) {
  //   return (
  //     <div>
  //       <Alert variant="info">No Data found. Please try again.</Alert>
  //     </div>
  //   );
  // }

  
  return (
    <div>
      <div className="display-site-schedule-container display-flex my-3">
        <div className="display-flex flex-1">
          {" "}
          <span>View: </span>
          <span><Form.Select
            className="w-100"
            aria-label="Default select example"
            value={view}
            onChange={(e) => setView(e.target.value)}
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
          </Form.Select></span>
        </div>
        <div>
          {" "}
          <Button
            className="button-flex-lg"
            variant="primary"
            onClick={(e) => {
              serCreateShift((prev) => !prev);
            }}
          >
            Create Shift
          </Button>
        </div>
      </div>
      {createShift && <CreateShift site_id={site_id} shiftTypes={shiftTypes}/>}
      <div className="my-5">
        {site.loading ? (
          <h3>
            <Spinner animation="border" />
            Loading...
          </h3>
        ) : (
          <DisplaySchedule view={view} shifts={site?.shifts?.shifts}  />
        )}
      </div>
    </div>
  );
};

export default SiteSchedule;
