import React,{ useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Alert from "react-bootstrap/Alert";
import { ImFlag } from "react-icons/im";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

function Items({ currentItems }) {
  if (!currentItems) {
    return <Alert variant="info">No Data.</Alert>;
  }
  return (
    <div className='task-data-table'>
        <Table hover >
      <thead>
        <tr>
          <th>#</th>
          <th>Title</th>
          <th>Assigned By</th>
          <th>Assigned To</th>
          <th>Deadline</th>
          <th>Priority</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {currentItems.map((task, index)=>{
            const end = new Date(task.deadline);
            return(
                <tr key={index}>
                <td><Link to={`/dashboard/tasks/${task._id}`}>{index + 1}</Link></td>
                <td><Link to={`/dashboard/tasks/${task._id}`}>{task.taskTitle.substring(0, 20)}</Link></td>
                <td>{task.createdBy}</td>
                <td>{task.assignedTo}</td>
                <td className={new Date(end).getTime() < new Date().getTime() ? "text-danger" : ""}>{end.getDay().toString().padStart(2, '0')}/{end.getMonth().toString().padStart(2, '0')}/{end.getFullYear().toString().padStart(2, '0')}</td>
                <td>{task.priority === "HIGH" ? <span style={{color:"red"}}><ImFlag /></span> : (task.priority === "NORMAL" ? <span style={{color:"green"}}><ImFlag /></span> : <span style={{color:"yellow"}}><ImFlag /></span>)}</td>
                <td className={task.status === "NOT STARTED" ? 'task-status-style' : (task.status === "STARTED" ? "task-status-style-1" : "task-status-style-2")}>{task.status.toLowerCase()}</td>
                
              </tr>
            )
        })}
      </tbody>
    </Table>
        
    </div>
  );
}


const DisplayTasks = ({tasks}) => {
  const itemsPerPage=100;
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(tasks);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % tasks.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(tasks.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(tasks.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, tasks]);

  useEffect(() => {
    setItemOffset(0);
  }, [itemsPerPage]);
    
      if (!tasks || tasks.length === 0) {
        return (
          <div className="d-flex justify-content-center align-items-center h-100">
            <div>
              <Alert variant="info">No Tasks found.</Alert>
            </div>
          </div>
        );
      }
  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  )
}

export default DisplayTasks