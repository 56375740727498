import React from 'react';
import ImageModal from "./ImageModal";
import Image1 from "./assets/tasks-0.jpg";
import Image2 from "./assets/tasks-1.jpg";
import { ImFlag } from "react-icons/im";

const MonitorTasksDocs = () => {
  const [imageShow, setImageShow] = React.useState(false);
    const [imageData, setImageData] = React.useState(null);
      const onThisPage = [
          {
            link: "#tasks",
            desc: "Tasks",
          },
          {
            link: "#dashboard",
            desc: "Dashboard",
          },
          {
            link: "#filters",
            desc: "Filters",
          },
          {
            link: "#priority",
            desc: "Task Priority",
          },
          {
            link: "#status",
            desc: "Task Status",
          }
        ];
        return (
          <div className="d-flex">
            <div className="docs-sub-left-content">
              <h1 id="tasks">Tasks</h1>
              <p>
              A Task is a piece of work to be done or undertaken.
              </p>
              
              <div className="bd-callout-info bd-callout ">
              <h5>Permissions</h5>
          <p>
            Admin, Administrator and Schedular has permission to monitor tasks.
          </p>
        </div>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">Monitor</span> under Tasks on menu.</p>
        
        

        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image1);
            setImageShow(true);
          }}
        >
          <img src={Image1} alt="create site 1" width={"100%"}></img>
        </div>

              <h2 id="dashboard">Dashboard</h2>
        <p><i class="docs-arrow"></i><span className="text-highlight">Overview</span><sup>1</sup> Tasks overview.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Donut Chart</span><sup>2</sup> Donut chart to give detailed overview.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Filters</span><sup>3</sup> Filters.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Date Range</span><sup>4</sup> Date range to show tasks.</p>
        
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image2);
            setImageShow(true);
          }}
        >
          <img src={Image2} alt="create site 1" width={"100%"}></img>
        </div>


        <h2 id="filters">Filters</h2>
        <p><i class="docs-arrow"></i><span className="text-highlight">Completed</span> Show completed tasks.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Due</span> Show due tasks.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Not Accepted</span> Show tasks which are not accepted by assigned employee.</p>


        <h2 id="priority">Task Priority</h2>
        <p><i class="docs-arrow"></i><span className="text-highlight" style={{"color":"yellow"}}><ImFlag /></span> Low priority task.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight" style={{"color":"green"}}><ImFlag /></span> Medium priority task.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight" style={{"color":"red"}}><ImFlag /></span> High priority task.</p>


        <h2 id="status">Task Status</h2>
        <p><i class="docs-arrow"></i><span className="text-highlight">Not Started</span> Task not started by assigned employee.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Started</span> Task started by assigned employee.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Completed</span> Task completed by assigned employee.</p>


              {imageShow && imageData && (
            <ImageModal
              show={imageShow}
              onHide={() => setImageShow(false)}
              imageData={imageData}
            />
          )}
          
            </div>

            


            <div className="docs-sub-right-content">
              <div>On this page</div>
              <hr />
              <ul>
                {onThisPage.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.link}>{item.desc}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        
  );
}

export default MonitorTasksDocs