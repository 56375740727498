import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Button from "react-bootstrap/Button";
import CreateSite from "./CreateSite";
import { useNavigate } from "react-router-dom";

const Sites = () => {
  const navigate = useNavigate();
  const [createSite, setCreateSite] = useState(false);
  return (
    <>
      <div className="card ">
        <div className="displaysites-header">
          <h2>Sites</h2>
          <span className="displaysites-header-btn-container">
            <Button
              className="button-flex-lg"
              variant="primary"
              onClick={(e) => {setCreateSite((prev)=> !prev)}}
              
            >
              Create Site
            </Button>
            <Button
              className="button-flex-lg"
              variant="primary"
              onClick={(e) => {setCreateSite(false); navigate("/dashboard/sites");}}
              
            >
              All Sites
            </Button>
          </span>
        </div>
      </div>
      {createSite && <div><CreateSite /></div>}
      <Outlet />
    </>
  );
};

export default Sites;
