import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Alerts from "../Alerts";
import { setAlert } from "../../reducers/alert";
import { useSelector, useDispatch } from "react-redux";
import { addSiteNote, loadAgencySites } from "../../reducers/sites";
import configData from "../../config/default.json";

const SiteNotes = ({ site_id }) => {
  const [noteTitle, setNoteTitle] = useState("");
  const [noteDetails, setNoteDetails] = useState("");
  const [flag, setFlag] = useState("");

  const dispatch = useDispatch();
  const sites = useSelector((state) => state.sites);
  const siteData = sites?.sites?.sites?.filter((site) => {
    return site.site_id._id === site_id;
  });
  const site = siteData !== undefined ? siteData[0].site_id : null;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!noteTitle || !noteDetails || !flag) {
      dispatch(
        setAlert({
          componentName: "SiteNotes",
          alertType: "danger",
          msg: "Please fill all fields.",
        })
      );
      return console.log("please input all fields");
    }
    dispatch(
      addSiteNote({
        noteTitle,
        noteDetails,
        flag,
        site_id,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(loadAgencySites());
        dispatch(
          setAlert({
            componentName: "SiteNotes",
            alertType: "success",
            msg: "Note added.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "SiteNotes",
            alertType: "danger",
            msg: "Error in Saving Data. Please try again.",
          })
        )
      );

    setNoteTitle("");
    setNoteDetails("");
    
  };

  if (!site || !siteData || !sites) {
    return <Alert variant="info">No Data found. Please try again.</Alert>;
  }

  return (
    <div className="mt-3">
      {site.notes.length === 0 ? (
        <Alert variant="info">No Notes.</Alert>
      ) : (
        site.notes.map((note) => {
          return (
            <div>
              <Alert
                variant={
                  note.flag === configData.FLAG.RED
                    ? "danger"
                    : note.flag === configData.FLAG.GREEN
                    ? "success"
                    : "warning"
                }
              >
                <Alert.Heading>{note.noteTitle}</Alert.Heading>
                <p>{note.noteDetails}</p>
                <hr />
                <p className="mb-0">
                  Added by: {note.userName} on {new Date(note.date).toDateString()}
                </p>
              </Alert>
            </div>
          );
        })
      )}

      <div className="thin-border my-5">
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Note Title"
              value={noteTitle}
              onChange={(e) => setNoteTitle(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" as={Col} controlId="formGridCity">
            <Form.Label>Details</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              placeholder="Note Details"
              value={noteDetails}
              onChange={(e) => setNoteDetails(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-4" as={Col} controlId="formGridState">
            <Form.Label>Flag</Form.Label>
            <Form.Select
              defaultValue={flag}
              onChange={(e) => setFlag(e.target.value)}
            >
              <option>Choose...</option>
              <option value={configData.FLAG.RED}>Red</option>
              <option value={configData.FLAG.GREEN}>Green</option>
              <option value={configData.FLAG.YELLOW}>Yellow</option>
            </Form.Select>
          </Form.Group>
          {sites.loading ? (
            <Button variant="primary" disabled className="button-flex mb-3">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <>
              <Button
                variant="primary"
                type="submit"
                className="button-flex mb-3"
                onClick={handleSubmit}
              >
                Add
              </Button>
            </>
          )}
          <Alerts componentName={"SiteNotes"} />
        </Form>
      </div>
    </div>
  );
};

export default SiteNotes;
