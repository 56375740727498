import React from 'react';
import ImageModal from "./ImageModal";
import Image1 from "./assets/emp-10.jpg";

const EmployeePnotesDocs = () => {
  const [imageShow, setImageShow] = React.useState(false);
  const [imageData, setImageData] = React.useState(null);
    const onThisPage = [
        {
          link: "#pnotes",
          desc: "Private Notes",
        },
        {
          link: "#delete",
          desc: "Delete Private Note",
        }
      ];
      return (
        <div className="d-flex">
          <div className="docs-sub-left-content">
            <h1 id="pnotes">Private Notes</h1>
            <p>
            Private note are only visible to company.
            </p>
            
            <div className="bd-callout-info bd-callout ">
            <h5>Permissions</h5>
        <p>
          Admin, Administrator and Schedular has permission to add provate note.
        </p>
      </div>
      <p><i class="docs-arrow"></i>Click on fourth tab<span className="text-highlight">Private Notes</span> to access private notes.</p>
      <p><i class="docs-arrow"></i>New notes can be added on same page.</p>
      
      

      <div
        className="docs-image-lg"
        onClick={() => {
          setImageData(Image1);
          setImageShow(true);
        }}
      >
        <img src={Image1} alt="create site 1" width={"100%"}></img>
      </div>

      <h2 id="delete">Delete Private Note</h2>
        <p><i class="docs-arrow"></i>Notes are permanent and can not be deleted. All private notes are deleted when user is removed from employee list.</p>

            {imageShow && imageData && (
          <ImageModal
            show={imageShow}
            onHide={() => setImageShow(false)}
            imageData={imageData}
          />
        )}
        
          </div>

          


          <div className="docs-sub-right-content">
            <div>On this page</div>
            <hr />
            <ul>
              {onThisPage.map((item, index) => {
                return (
                  <li key={index}>
                    <a href={item.link}>{item.desc}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      
);
}

export default EmployeePnotesDocs