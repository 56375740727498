import React, { useEffect } from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import App from "./App";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminDashboard from "./components/admin/AdminDashboard";
import AgencyDashboard from "./components/agency/AgencyDashboard";
import Dashboard from "./components/Dashboard";
import DisplayAgencyProfile from "./components/agency/DisplayAgencyProfile";
import DashboardHome from "./components/agency/DashboardHome";
import DisplaySites from "./components/agency/DisplaySites";
import DisplaySite from "./components/agency/DisplaySite";
import Sites from "./components/agency/Sites";
import Users from "./components/agency/Users/Users";
import AllUsers from "./components/agency/Users/AllUsers";
import UserProfile from "./components/agency/Users/UserProfile";
import HiredUsers from "./components/agency/Users/HiredUsers";
import { loadUser } from "./reducers/auth";
import { useSelector, useDispatch } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import Shifts from "./components/agency/shifts/Shifts";
import Attendence from "./components/agency/shifts/Attendence";
import Profile from "./components/agency/Profile";
import NotFound from "./NotFound";
import Schedule from "./components/agency/Schedule";
import Shift from "./components/agency/Shift";
import AgencyTasks from "./components/agency/tasks/AgencyTasks";
import TaskDetails from "./components/agency/tasks/TaskDetails";
import UserTasks from "./components/agency/tasks/UserTasks";
import CreateTask from "./components/agency/tasks/CreateTask";
import AgencyTickets from "./components/agency/support/AgencyTickets";
import TicketDetails from "./components/agency/support/TicketDetails";
import SearchUser from "./components/agency/Users/SearchUser";
import AgencyPlan from "./components/agency/AgencyPlan";
import PrivacyPolicy from "./PrivacyPolicy";
import WelcomeDocs from "./components/Docs/WelcomeDocs";
import CreateSiteDocs from "./components/Docs/CreateSiteDocs";
import CreateShiftDocs from "./components/Docs/CreateShiftDocs";
import ShiftActionsDocs from "./components/Docs/ShiftActionsDocs";
import MonitorAttendenceDocs from "./components/Docs/MonitorAttendenceDocs";
import MonitorShiftsDocs from "./components/Docs/MonitorShiftsDocs";
import MonitorTasksDocs from "./components/Docs/MonitorTasksDocs";
import CreateTaskDocs from "./components/Docs/CreateTaskDocs";
import MyTasksDocs from "./components/Docs/MyTasksDocs";
import EmployeeMgmtDocs from "./components/Docs/EmployeeMgmtDocs";
import EmployeePnotesDocs from "./components/Docs/EmployeePnotesDocs";
import EmployeeGnotesDocs from "./components/Docs/EmployeeGnotesDocs";
import EmployeeRoleDocs from "./components/Docs/EmployeeRoleDocs";
import AppProfileDocs from "./components/Docs/AppProfileDocs";
import AppScheduleDocs from "./components/Docs/AppScheduleDocs";
import AppShiftsDocs from "./components/Docs/AppShiftsDocs";
import AppTasksDocs from "./components/Docs/AppTasksDocs";
import SchedulingApp from "./SchedulingApp";
import { AllContactUs } from "./components/admin/AllContactUs";
import AllEmailList from "./components/admin/AllEmailList";
import AllUsersList from "./components/admin/AllUsersList";
import AgencySitesReport from "./components/agency/reports/AgencySitesReport";
import SitesReport from "./components/agency/reports/SitesReport";
import SiteReport from "./components/agency/reports/SiteReport";
import AgencyEmpReport from "./components/agency/reports/AgencyEmpReport";
import EmpsReports from "./components/agency/reports/EmpsReports";
import EmpReport from "./components/agency/reports/EmpReport";
import MobileAppFront from "./MobileAppFront";
import SchedulingFront from "./SchedulingFront";
import AttenddanceFront from "./AttenddanceFront";
import EmpMgmtFront from "./EmpMgmtFront";
import TaskFront from "./TaskFront";
import SupportFront from "./SupportFront";
import ReportsFront from "./ReportsFront";
import ScrooltoTop from "./ScrooltoTop";

const Docs = React.lazy(() => import("./components/Docs/Docs"));

const AllRoutes = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  useEffect(() => {
    if (user.token) {
      dispatch(loadUser());
    }
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        
        <Route path="/" element={<App />} />
        <Route path="/mobileapp" element={<MobileAppFront />} />
        <Route path="/scheduling" element={<SchedulingFront />} />
        <Route path="/attendance" element={<AttenddanceFront />} />
        <Route path="/employee-management" element={<EmpMgmtFront />} />
        <Route path="/task-management" element={<TaskFront />} />
        <Route path="/livesupport" element={<SupportFront />} />
        <Route path="/reports" element={<ReportsFront />} />
        
        <Route
          path="admin"
          element={
            <PrivateRoute role={["admin"]}>
              <AdminDashboard />
            </PrivateRoute>
          }
        >
          <Route index element={<DashboardHome />} />
        </Route>
        <Route
          path="dashboard"
          element={
            <PrivateRoute role={["agency_admin", "admin","agency_team" ,"agency_user"]}>
              {" "}
              <AgencyDashboard />
            </PrivateRoute>
          }
        >
          <Route index element={<DashboardHome />} />
          <Route
            path="profile"
            element={
              <PrivateRoute role={["agency_admin", "admin"]}>
                {" "}
                <DisplayAgencyProfile />
              </PrivateRoute>
            }
          />
          <Route path="myprofile" element={<Profile />} />
          <Route path="schedule/:user_id" element={<Schedule />} />
          <Route path="shift/:shift_id" element={<Shift />} />
          <Route path="shifts" element={<Shifts />} />
          <Route path="tasks" element={<AgencyTasks />} />
          <Route path="mytasks" element={<UserTasks />} />
          <Route path="createtask" element={<CreateTask />} />
          <Route path="support" element={<AgencyTickets />} />
          <Route path="support/:ticket_id" element={<TicketDetails />} />
          <Route path="tasks/:task_id" element={<TaskDetails />} />
          <Route path="attendance" element={<Attendence />} />
          <Route path="plan" element={<AgencyPlan />} />

          <Route path="allcontactus" element={<PrivateRoute role={["admin"]}><AllContactUs /></PrivateRoute>} />
          <Route path="allemaillist" element={<PrivateRoute role={["admin"]}><AllEmailList /></PrivateRoute>} />
          <Route path="alluserslist" element={<PrivateRoute role={["admin"]}><AllUsersList /></PrivateRoute>} />

          <Route path="sites" element={<Sites />}>
            <Route index element={<DisplaySites />} />
            <Route path=":site_id" element={<DisplaySite />} />
          </Route>
          <Route path="users" element={<Users />}>
            <Route index element={<HiredUsers />} />
            <Route path="employees" element={<AllUsers />} />
            <Route path="search" element={<SearchUser />} />
            <Route path=":user_id" element={<UserProfile />} />
          </Route>

          <Route path="sitereports" element={<AgencySitesReport />}>
            <Route index element={<SitesReport />} />
            <Route path=":site_id" element={<SiteReport />} />
          </Route>

          <Route path="employeereports" element={<AgencyEmpReport />}>
            <Route index element={<EmpsReports />} />
            <Route path=":user_id" element={<EmpReport />} />
          </Route>


          <Route
            path="docs"
            element={
              <React.Suspense fallback={<>Loading...</>}>
                <Docs />
              </React.Suspense>
            }
          >
            <Route index element={<WelcomeDocs />} />

            <Route path="createsite" element={<CreateSiteDocs />} />
            <Route path="createshift" element={<CreateShiftDocs />} />
            <Route path="shiftactions" element={<ShiftActionsDocs />} />
            <Route
              path="monitorattendance"
              element={<MonitorAttendenceDocs />}
            />
            <Route path="monitorshifts" element={<MonitorShiftsDocs />} />

            <Route path="monitortasks" element={<MonitorTasksDocs />} />
            <Route path="createtask" element={<CreateTaskDocs />} />
            <Route path="mytasks" element={<MyTasksDocs />} />
            <Route path="manageemployees" element={<EmployeeMgmtDocs />} />
            <Route path="manageemployeeroles" element={<EmployeeRoleDocs />} />
            <Route
              path="manageemployeepnotes"
              element={<EmployeePnotesDocs />}
            />
            <Route
              path="manageemployeegnotes"
              element={<EmployeeGnotesDocs />}
            />
            <Route path="apptasks" element={<AppTasksDocs />} />
            <Route path="appshifts" element={<AppShiftsDocs />} />
            <Route path="appprofile" element={<AppProfileDocs />} />
            <Route path="appschedule" element={<AppScheduleDocs />} />
          </Route>
        </Route>

        <Route path="userdashboard" element={<Dashboard />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        <Route path="scheduling-mobile-app" element={<SchedulingApp />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
