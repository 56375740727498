import React from "react";
import Image1 from "./assets/create-site-1.jpg";
import Image2 from "./assets/create-site-2.jpg";
import Image3 from "./assets/create-site-3.jpg";
import Image4 from "./assets/create-site-4.jpg";
import Image5 from "./assets/create-site-5.jpg";
import Image6 from "./assets/create-site-6.jpg";
import Image7 from "./assets/create-site-7.jpg";
import Image8 from "./assets/create-site-8.jpg";
import Image9 from "./assets/create-site-9.jpg";
import Image10 from "./assets/create-site-10.jpg";
import Image11 from "./assets/create-site-11.jpg";
import Image12 from "./assets/site-documents.jpg";
import Image13 from "./assets/site-rate.jpg";
import ImageModal from "./ImageModal";

const CreateSite = () => {
  const [imageShow, setImageShow] = React.useState(false);
  const [imageData, setImageData] = React.useState(null);
  const onThisPage = [
    {
      link: "#createsite",
      desc: "Create Site",
    },
    {
      link: "#viewsitedetails",
      desc: "View Site Details",
    },
    {
      link: "#siteschedule",
      desc: "Site Schedule",
    },
    {
      link: "#sitelocation",
      desc: "Site Location",
    },
    {
      link: "#coveragerates",
      desc: "Coverage Rates",
    },
    {
      link: "#sitenotes",
      desc: "Site Notes",
    },
    {
      link: "#siteorders",
      desc: "Site Orders",
    },
    {
      link: "#viewsiteorderssignatures",
      desc: "View Site Order signatures",
    },
    {
      link: "#sitedocuments",
      desc: "Site Documents",
    },
    {
      link: "#editsite",
      desc: "Edit Site",
    },
    
  ];
  return (
    <div className="d-flex">
      <div className="docs-sub-left-content">
        <h1 id="createsite">Create site</h1>
        <p>
          The meaning of SITE is the spatial location of an actual or planned
          structure or set of structures (such as a building, town, or
          monuments).
        </p>

        <div className="bd-callout-info bd-callout ">
          <h5>Permissions</h5>
          <p>
            Only Admin and Administrator has permission to create site.
            Schedular don't have permission to create site.
          </p>
        </div>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">Sites</span> from menu.<sup>1</sup></p>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">Create Site</span> button.<sup>2</sup></p>
        <p><i class="docs-arrow"></i>Inpute site name and site address.</p>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">Validate button</span> to validate address.<sup>3</sup></p>
        <div className="bd-callout-alert bd-callout ">
          <h5>Site address</h5>
          <p>
            Double check the site address. Incorrect site address may result in improper rendering on map.
          </p>
        </div>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image1);
            setImageShow(true);
          }}
        >
          <img src={Image1} alt="create site 1" width={"100%"}></img>
        </div>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">Create</span> button</p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image2);
            setImageShow(true);
          }}
        >
          <img src={Image2} alt="create site 1" width={"100%"}></img>
        </div>

        <p><i class="docs-arrow"></i>If site is created successfully, a success message will show up on screen. An error message will show up on screen in case of any errors.</p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image3);
            setImageShow(true);
          }}
        >
          <img src={Image3} alt="create site 1" width={"100%"}></img>
        </div>


        <h2 id="viewsitedetails">View Site Details</h2>
        
        <p><i class="docs-arrow"></i>Once site is created. It will be added in sites list.</p>
        <p><i class="docs-arrow"></i>Click on site name to view site details.</p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image4);
            setImageShow(true);
          }}
        >
          <img src={Image4} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="siteschedule">Site Schedule</h2>
        <p><i class="docs-arrow"></i>Site schedule can be viewed on first tab <span className="text-highlight">Schedule</span></p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image11);
            setImageShow(true);
          }}
        >
          <img src={Image11} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="sitelocation">Site location</h2>
        <p><i class="docs-arrow"></i>Site details including location can be viewed on second tab <span className="text-highlight">Details</span></p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image5);
            setImageShow(true);
          }}
        >
          <img src={Image5} alt="create site 1" width={"100%"}></img>
        </div>

        {/* site coverage rates */}

        <h2 id="coveragerates">Site Coverage Rates</h2>
        <p><i class="docs-arrow"></i>Site coverage reates can be viewed on third tab <span className="text-highlight">Coverage Rates</span><sup>1</sup></p>
        <p><i class="docs-arrow"></i>To add new coverage type and rates, fill out the rates at the end and click <span className="text-highlight">Add</span><sup>2</sup> button.</p>
        <p><i class="docs-arrow"></i>Rates can be edited or deleted by clicking on respective icons.<sup>3</sup></p>
        <p><i class="docs-arrow"></i>Coverage type name should me different for each type.</p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image13);
            setImageShow(true);
          }}
        >
          <img src={Image13} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="sitenotes">Site Notes</h2>
        <p><i class="docs-arrow"></i>Site Notes can be viewed on third tab <span className="text-highlight">Notes</span></p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image6);
            setImageShow(true);
          }}
        >
          <img src={Image6} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="siteorders">Site Orders</h2>
        <p><i class="docs-arrow"></i>Site orders can be viewed on fourth tab <span className="text-highlight">Site Orders</span></p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image7);
            setImageShow(true);
          }}
        >
          <img src={Image7} alt="create site 1" width={"100%"}></img>
        </div>
        <p><i class="docs-arrow"></i>New site orders can be added by inputting <span className="text-highlight">Title</span> and <span className="text-highlight">Details</span> and then clicking on <span className="text-highlight">Add</span> button<sup>1</sup></p>
        <p><i class="docs-arrow"></i>Number of employees signed site orders.<sup>2</sup></p>
        <p><i class="docs-arrow"></i>Delete site orders<sup>3</sup></p>
        
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image8);
            setImageShow(true);
          }}
        >
          <img src={Image8} alt="create site 1" width={"100%"}></img>
        </div>

        

        <h2 id="viewsiteorderssignatures">View Site Order signatures</h2>
        <p><i class="docs-arrow"></i>Click on count of employees for signatures to view detailed signatures.</p>
        
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image9);
            setImageShow(true);
          }}
        >
          <img src={Image9} alt="create site 1" width={"100%"}></img>
        </div>

          {/* site documents */}

          <h2 id="sitedocuments">Site Documents</h2>
          
        <p><i class="docs-arrow"></i>Site documents can be viewed on last tab <span className="text-highlight">Site Documents</span><sup>1</sup></p>
        <p><i class="docs-arrow"></i>To upload new document, enter document name, select file and click <span className="text-highlight">Upload</span><sup>2</sup> button.</p>
        <p><i class="docs-arrow"></i>Site documents can be deleted on downloaded to device by click on respective icons.<sup>3</sup></p>
        <div className="bd-callout-info bd-callout">
          <h5>Allowed document types</h5>
          <p>
          ".pdf", ".doc", ".docx", ".xls", ".xlsx", ".csv", ".txt", ".rtf", ".png", ".jpg", ".jpeg"
          </p>
        </div>
        <div className="bd-callout-info bd-callout">
          <h5>Allowed file size</h5>
          <p>
            Maximum document size is 10MB and upto 10 documents.
          </p>
        </div>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image12);
            setImageShow(true);
          }}
        >
          <img src={Image12} alt="create site 1" width={"100%"}></img>
        </div>
        
      
        


        <h2 id="editsite">Edit Site</h2>
        <p><i class="docs-arrow"></i>Go to second Tab <span className="text-highlight">Details</span></p>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">Edit</span> button to edit site information.</p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image10);
            setImageShow(true);
          }}
        >
          <img src={Image10} alt="create site 1" width={"100%"}></img>
        </div>
        {imageShow && imageData && (
          <ImageModal
            show={imageShow}
            onHide={() => setImageShow(false)}
            imageData={imageData}
          />
        )}
      </div>
      <div className="docs-sub-right-content">
        <div>On this page</div>
        <hr />
        <ul>
          {onThisPage.map((item, index) => {
            return (
              <li key={index}>
                <a href={item.link}>{item.desc}</a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CreateSite;
