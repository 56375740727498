import React, { useState, useEffect } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { contactus } from "../reducers/support";
import { setAlert } from "../reducers/alert";
import Alerts from "./Alerts";

const Contactus = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [phone, setPhone] = useState();
  const [details, setDetails] = useState("");

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.support.subLoading);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !details || !phone || !name || !validateEmail(email)) {
      dispatch(
        setAlert({
          componentName: "Contactus",
          alertType: "danger",
          msg: "Please check required fields.",
        })
      );
      return console.log("please input all fields");
    }
    dispatch(contactus({ name, email, agencyName, phone, details }))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(
          setAlert({
            componentName: "Contactus",
            alertType: "success",
            msg: "Your query is submitted. We will contact you within 24 hours.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "Contactus",
            alertType: "danger",
            msg: "Error in submitting query. Please try again.",
          })
        )
      );
    setEmail("");
    setDetails("");
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="custom-homapge-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>Contact Us</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alerts componentName={"Contactus"} />
        <Form>
          <Form.Group className="mb-3" controlId="formBasicUserName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              size="lg"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter company name"
              size="lg"
              value={agencyName}
              onChange={(e) => setAgencyName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              size="lg"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicUserName">
            <Form.Label>Phone </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter your phone number"
              size="lg"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicUserName">
            <Form.Label>Details </Form.Label>
            <Form.Control
              as="textarea"
              aria-label="With textarea"
              placeholder="Enter your query"
              size="lg"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />
          </Form.Group>

          {loading ? (
            <Button variant="primary" size="lg" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="primary"
              size="lg"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )}
          <br />
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Contactus;
