import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Alerts from "../Alerts";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { setAlert } from "../../reducers/alert";
import { useSelector, useDispatch } from "react-redux";
import {
  createAgencyProfile,
  loadAgencyProfile,
} from "../../reducers/agencyProfile";
import { loadUser } from "../../reducers/auth";

const AgencyProfile = ({ setEditProfile }) => {
  const provider = new OpenStreetMapProvider();
  const agencyprofile = useSelector((state) => state.agencyProfile);

  const [agencyName, setAgencyName] = useState(
    agencyprofile?.agencyprofile?.agencyName
      ? agencyprofile.agencyprofile.agencyName
      : ""
  );
  const [phone, setPhone] = useState(
    agencyprofile?.agencyprofile?.phone
      ? agencyprofile.agencyprofile.phone
      : undefined
  );
  const [unit, setUnit] = useState(
    agencyprofile?.agencyprofile?.unit
      ? agencyprofile.agencyprofile.unit
      : null
  );
  const [streetNumber, setStreetNumber] = useState(
    agencyprofile?.agencyprofile?.streetNumber
      ? agencyprofile.agencyprofile.streetNumber
      : undefined
  );
  const [postal, setPostal] = useState(
    agencyprofile?.agencyprofile?.postal
      ? agencyprofile.agencyprofile.postal
      : undefined
  );
  const [geox, setGeox] = useState(null);
  const [geoy, setGeoy] = useState(null);
  const [validate, setValidate] = useState(false);
  const [validating, setValidating] = useState(false);

  const [street, setStreet] = useState(
    agencyprofile?.agencyprofile?.street
      ? agencyprofile.agencyprofile.street
      : ""
  );
  const [city, setCity] = useState(
    agencyprofile?.agencyprofile?.city ? agencyprofile.agencyprofile.city : ""
  );
  const [provience, setProvience] = useState(
    agencyprofile?.agencyprofile?.provience
      ? agencyprofile.agencyprofile.provience
      : ""
  );
  const [country, setCountry] = useState(
    agencyprofile?.agencyprofile?.country
      ? agencyprofile.agencyprofile.country
      : ""
  );

  const getCords = async (address, city) => {
    setValidating(true)
    let data = await provider.search({ query: address });
    data.forEach((obj, index) => {
      // filter result  by city if multiple results ins search
      if (obj.label.toLowerCase().includes(city.toLowerCase())) {
        setGeox(obj.x);
        setGeoy(obj.y);
      }
    })
    setValidating(false);
    setValidate(true);
    
  };
  
  const dispatch = useDispatch();

  const validateHandle =(e)=>{
    e.preventDefault();
    if (
      !agencyName ||
      !phone ||
      !street ||
      !city ||
      !provience ||
      !country ||
      !postal ||
      !streetNumber
    ) {
      dispatch(
        setAlert({
          componentName: "agencyprofile",
          alertType: "danger",
          msg: "Please fill all fields.",
        })
      );
      return console.log("please input all fields");
    }
    getCords(`${streetNumber} ${street} ${city} ${provience} ${country}`, city);

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !agencyName ||
      !phone ||
      !street ||
      !city ||
      !provience ||
      !country ||
      !postal ||
      !streetNumber
    ) {
      dispatch(
        setAlert({
          componentName: "agencyprofile",
          alertType: "danger",
          msg: "Please fill all fields.",
        })
      );
      return console.log("please input all fields");
    }

    dispatch(
      createAgencyProfile({
        agencyName,
        phone,
        unit,
        street,
        city,
        provience,
        country,
        geox,
        geoy,
        streetNumber,
        postal
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(loadUser());
        dispatch(
          setAlert({
            componentName: "agencyprofile",
            alertType: "success",
            msg: "Company Profile is Saved.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "agencyprofile",
            alertType: "danger",
            msg: "Error in Saving Profile. Please try again.",
          })
        )
      );
  };

  return (
    <div className="card">
      <Alerts componentName={"agencyprofile"} />
      <Form>
        <h2>Company Profile</h2>
        <hr />
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Company Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Company Name"
            value={agencyName}
            onChange={(e) => setAgencyName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Company Phone</Form.Label>
          <Form.Control
            type="number"
            placeholder="Company Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Form.Group>

        <Row className="mb-3">
        <Form.Group className="col-12 col-md-3" controlId="formGridunit">
            <Form.Label>Unit</Form.Label>
            <Form.Control
              type="number"
              placeholder="Unit Number(not required)"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="col-12 col-md-3" controlId="formGridstreetNumber">
            <Form.Label>Street Number</Form.Label>
            <Form.Control
              type="number"
              placeholder="Street Number (required)"
              value={streetNumber}
              onChange={(e) => setStreetNumber(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="col-12 col-md-3" controlId="formGridStreet">
            <Form.Label>Street Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Street Name"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
          </Form.Group>

          

          <Form.Group className="col-12 col-md-3" controlId="formGridCity">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
        

        <Form.Group  className="col-12 col-md-4" controlId="formGridstate">
            <Form.Label>Provience/State</Form.Label>
            <Form.Control
              type="text"
              placeholder="Provience"
              value={provience}
              onChange={(e) => setProvience(e.target.value)}
            />
          </Form.Group>
          
        <Form.Group  className="col-12 col-md-4" controlId="formGridCountry">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              placeholder="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </Form.Group>

          

          <Form.Group  className="col-12 col-md-4" controlId="formGridpostal">
            <Form.Label>Zip</Form.Label>
            <Form.Control
              type="text"
              placeholder="Postal"
              value={postal}
              onChange={(e) => setPostal(e.target.value)}
            />
          </Form.Group>
        </Row>

        {agencyprofile.loading || validating ? (
          <Button variant="primary" disabled className="button-flex">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="visually-hidden">Loading...</span>
          </Button>
        ) : ( validate ?
            <>
            <Button variant="primary" type="submit" className="button-flex" onClick={handleSubmit}>
              Save
            </Button>
            <Button
              variant="primary" className="button-flex"
              onClick={(e) => {
                setEditProfile(false);
              }}
            >
              Go Back
            </Button>
          </> : <>
            <Button variant="primary" type="submit" className="button-flex" onClick={validateHandle}>
              validate
            </Button>
            <Button
              variant="primary" className="button-flex"
              onClick={(e) => {
                setEditProfile(false);
              }}
            >
              Go Back
            </Button>
          </> 
        )}
      </Form>
    </div>
  );
};

export default AgencyProfile;
