import configData from "../config/default.json";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

const API_ENDPOINT = configData.API_ENDPOINT;

const initialState = {
  loading: false,
  users: null,
  error: null,
};

export const loadAgencyUsers = createAsyncThunk(
  "api/loadAgencyUsers",
  async () => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const response = await axios.get(`${API_ENDPOINT}/api/employees/hiredemployees`);
    return response.data;
  }
);

export const loadAllUsers = createAsyncThunk(
    "api/loadAllUsers",
    async () => {
      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }
      const response = await axios.get(`${API_ENDPOINT}/api/users/userslist`);
      return response.data;
    }
  );

export const users = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadAllUsers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.users = action.payload;
      })
      .addCase(loadAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        console.log("error in loadAllUsers");
        console.log(action);
      })
      .addCase(loadAgencyUsers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadAgencyUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.users = action.payload;
      })
      .addCase(loadAgencyUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        console.log("error in loadAgencyUsers");
        console.log(action);
      });
  },
});

export default users.reducer;
