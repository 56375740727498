import { useSelector } from "react-redux";
import { Alert, Container, Row, Col } from "react-bootstrap";
import { BsCheckCircle, BsXCircle } from "react-icons/bs";

const Alerts = ({ componentName }) => {
  const alert = useSelector((state) => state.alert);

  return (
    alert !== null &&
    alert.length > 0 &&
    alert.map((item) => (
      <>
        {item.componentName === componentName ? (
          <Alert
            key={item.id}
            variant={item.alertType}
            className="d-flex align-items-center gap-2"
          >
            <span
              className="d-flex align-items-center"
              style={{ fontSize: "1.3rem" }}
            >
              {item.alertType === "success" ? <BsCheckCircle /> : <BsXCircle />}
            </span>{" "}
            <span>{item.msg}</span>
          </Alert>
        ) : (
          <></>
        )}
      </>
    ))
  );
};

export default Alerts;
