import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Spinner from "react-bootstrap/Spinner";
import Alerts from "../Alerts";
import { setAlert } from "../../reducers/alert";
import { useSelector, useDispatch } from "react-redux";
import {
  addSiteOrders,
  deleteSiteOrders,
  loadAgencySites,
} from "../../reducers/sites";
import { AiOutlineDelete, AiFillDelete } from "react-icons/ai";


function Signatures({show, setShow, data}) {
  

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Signatures</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Table  hover >
      <thead>
      <tr >
          <th>#</th>
          <th>User</th>
          <th>Signature</th>
        </tr>
      </thead>
      <tbody>
            {data.map((signature, index)=>{
              
       return(<tr key={index}>
          <td>{index + 1 }</td>
          <td>{signature.name}</td>
          <td><img src={signature.signature} alt={`signature${index}`} height="80px" width="200px" /></td>
          
        </tr>)
        
        
     
            })}
             </tbody>
    </Table>
        </Modal.Body>
        
      </Modal>
    </>
  );
}

const SiteOrders = ({ site_id }) => {
  const [siteOrderTitle, setSiteOrderTitle] = useState("");
  const [siteOrderDetails, setSiteOrderDetails] = useState("");

  const dispatch = useDispatch();
  const sites = useSelector((state) => state.sites);
  const siteData = sites?.sites?.sites?.filter((site) => {
    return site.site_id._id === site_id;
  });
  const site = siteData !== undefined ? siteData[0].site_id : null;

  // signatures modal
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!siteOrderTitle || !siteOrderDetails) {
      dispatch(
        setAlert({
          componentName: "SiteOrders",
          alertType: "danger",
          msg: "Please fill all fields.",
        })
      );
      return console.log("please input all fields");
    }
    dispatch(
      addSiteOrders({
        SiteOrderTitle: siteOrderTitle,
        SiteOrderDetails: siteOrderDetails,
        site_id,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(loadAgencySites());
        dispatch(
          setAlert({
            componentName: "SiteOrders",
            alertType: "success",
            msg: "Site Orders added.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "SiteOrders",
            alertType: "danger",
            msg: "Error in Saving Data. Please try again.",
          })
        )
      );

    setSiteOrderTitle("");
    setSiteOrderDetails("");
  };

  const handleDelete = (siteorders_id) => (e) => {
    e.preventDefault();

    dispatch(
      deleteSiteOrders({
        siteorders_id,
        site_id,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(loadAgencySites());
        dispatch(
          setAlert({
            componentName: "SiteOrdersDelete",
            alertType: "success",
            msg: "Site Orders Deleted.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "SiteOrdersDelete",
            alertType: "danger",
            msg: "Error in Saving Data. Please try again.",
          })
        )
      );

    setSiteOrderTitle("");
    setSiteOrderDetails("");
  };
  if (!site || !siteData || !sites) {
    return <Alert variant="info">No Data found. Please try again.</Alert>;
  }

  return (
    <div className="mt-3">
      <Alerts componentName={"SiteOrdersDelete"} />
      {site.siteOrders.length === 0 ? (
        <Alert variant="info">No Site Orders.</Alert>
      ) : (
        site.siteOrders.map((siteOrder) => {
          return (
            <div>
              <Alert variant={"light"} className="thin-border">
                <Alert.Heading>{siteOrder.SiteOrderTitle}</Alert.Heading>
                <p>{siteOrder.SiteOrderDetails}</p>
                <hr />

                <div className="d-flex justify-content-between">
                  <span >
                    Added by: {siteOrder.addedby} on {new Date(siteOrder.date).toDateString()}
                    
                  </span>
                  <span style={{"cursor": "pointer"}} onClick={()=> {setData(siteOrder.signedBy); setShow(true)}}>Signatures: {siteOrder.signedBy.length}</span>
                  <span style={{"cursor": "pointer"}} onClick={handleDelete(siteOrder._id)}>
                    <AiFillDelete /> 
                  </span>
                </div>
              </Alert>
            </div>
          );
        })
      )}

      <div className="thin-border my-5">
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Title"
              value={siteOrderTitle}
              onChange={(e) => setSiteOrderTitle(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" as={Col} controlId="formGridCity">
            <Form.Label>Details</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              placeholder="Site Orders Details"
              value={siteOrderDetails}
              onChange={(e) => setSiteOrderDetails(e.target.value)}
            />
          </Form.Group>

          {sites.loading ? (
            <Button variant="primary" disabled className="button-flex mb-3">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <>
              <Button
                variant="primary"
                type="submit"
                className="button-flex mb-3"
                onClick={handleSubmit}
              >
                Add
              </Button>
            </>
          )}
          <Alerts componentName={"SiteOrders"} />
        </Form>

        {data && <Signatures show={show} setShow={setShow} data={data} />}
      </div>
    </div>
  );
};

export default SiteOrders;
