import React, { useState } from "react";
import "./schedulingfront.css";
import Footer from "./Footer";
import MainMenu from "./MainMenu";
import Login from "./components/Login";
import Register from "./components/Register";
import Cta from "./Cta";
import { Link } from "react-router-dom";
import {
  BsGeoAltFill,
  BsWifi,
  BsFillCheckSquareFill,
  BsFillHandThumbsUpFill,
  BsCheck2Square,
  BsArrowRight,
  BsArrowLeft,
  BsCalendarDate,
} from "react-icons/bs";
import { AiOutlineUsergroupAdd, AiOutlineFileSearch } from "react-icons/ai";
import { BiTask } from "react-icons/bi";
import { FaUserShield } from "react-icons/fa";
import { MdSupportAgent } from "react-icons/md";
import Image1 from "./uploads/schedule-1.jpg";
import Image2 from "./uploads/schedule-2.jpg";
import Image3 from "./uploads/schedule-5.jpg";
import Image4 from "./uploads/schedule-6.jpg";



const SchedulingFront = () => {
  const [loginShow, setLoginShow] = useState(false);
  const [registerShow, setRegisterShow] = useState(false);
  return (
    <>
      <MainMenu />
      <div className="header-container">
        <div className="container hero-section" id="home">
          
          <div className="container">
            <div className="header-content-section">
              <div className="left-content">
                <h1
                  className="display-5 fw-bold lh-2 mb-3"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="20"
                  data-aos-easing="ease-in-out"
                >
                  Scheduling Module
                </h1>
                <p
                  className="lead pe-1"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="100"
                  data-aos-easing="ease-in-out"
                >
                  Schedule your employees efficiently with our lighting fast
                  alpha scheduling module. Try us for a month!
                </p>
                <div
                  className="d-flex justify-content-start align-items-center gap-3 mt-2"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="200"
                  data-aos-easing="ease-in-out"
                >
                  <div
                    className="shaddow-button py-3 px-5 button-font"
                    onClick={() => setRegisterShow(!registerShow)}
                  >
                    Try Free
                  </div>
                  <div
                    className="shaddow-outline-button py-3 px-5 button-font"
                    onClick={() => setRegisterShow(!registerShow)}
                  >
                    Sign Up
                  </div>
                </div>
              </div>
              <div
                className="right-content"
                data-aos="fade-left"
                data-aos-duration="20"
                data-aos-delay="100"
                data-aos-easing="ease-in-out"
              >
                <div className="schedulingfront-hero-card"></div>
              </div>
            </div>
          </div>
          <Login show={loginShow} onHide={() => setLoginShow(false)} />
          <Register show={registerShow} onHide={() => setRegisterShow(false)} />
        </div>
        <div class="hero-wave">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
      <div className="container mt-5">
        <div className="container">
          <div
            className="d-flex flex-column justify-content-start align-items-center "
            data-aos="fade-up"
            data-aos-duration="20"
            data-aos-delay="200"
            data-aos-offset="10"
            data-aos-easing="ease-in-out"
          >
            <div>
              <h2 className="display-5 fw-bold lh-1 mb-3 mt-5">
                 Features
              </h2>
            </div>
            <div className="mb-5">
              <h3 className="lead">
                The only scheduling software you will need.
              </h3>
            </div>
          </div>

          <div className="feature-section-content d-flex justify-content-between align-items-center gap-2 my-5">
            <div
              className="feature-left-content d-flex align-items-center "
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="200"
              data-aos-offset="10"
              data-aos-easing="ease-in-out"
            >
              <div className="feature-main-image-container">
                <img
                  src={Image2}
                  alt="showcase"
                  width="100%"
                  className="feature-main-image-itself"
                />
                <div className="top-left-floating-patter"> </div>
                {/* <div className="bottom-left-floating-patter"> </div>
                <div className="top-right-floating-patter"> </div> */}
                <div className="bottom-right-floating-patter"> </div>
              </div>
            </div>
            <div className="feature-right-content">
              <h2
                className="display-5 fw-bold lh-1 mb-3"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="20"
                data-aos-easing="ease-in-out"
              >
                Lighting Fast
              </h2>
              <p
                className="lead"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="100"
                data-aos-easing="ease-in-out"
              >
                “I feel the need … the need for speed!” <br />
                Belive it or not, any time a business can do something faster than their competition, they are going to get better results
                and that's why we focus on speed, without sacrificing quality.
              </p>
              <div
                className="d-flex flex-column justify-content-start lead my-4"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="150"
                data-aos-easing="ease-in-out"
              >
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <span className="list-check">
                    <BsCheck2Square />
                  </span>
                  <span className="ms-5">
                    <strong>Increase Efficiency</strong>
                  </span>
                </div>
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <span className="list-check">
                    <BsCheck2Square />
                  </span>
                  <span className="ms-5">
                    <strong>No More Long Page Loads</strong>
                  </span>
                </div>
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <span className="list-check">
                    <BsCheck2Square />
                  </span>
                  <span className="ms-5">
                    <strong>Unmattched Performance and Speed</strong>
                  </span>
                </div>
              </div>
              {/* <div
                  className="d-flex justify-content-start align-items-center gap-3"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="200"
                  data-aos-easing="ease-in-out"
                >
                  <div
                    className="shaddow-button py-3 px-5 button-font"
                    onClick={() => setRegisterShow(!registerShow)}
                  >
                    Try Free
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Feature #2 */}
      <div className="py-5">
        <div className="container ">
          <div className="container ">
            <div className="feature2-section-content d-flex justify-content-between align-items-center gap-2 my-5">
              <div className="feature-left-content">
                <h1
                  className="display-5 fw-bold lh-2 mb-3"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="20"
                  data-aos-easing="ease-in-out"
                >
                  Multiple Roles
                </h1>
                <p
                  className="lead"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="100"
                  data-aos-easing="ease-in-out"
                >
                  Alpha gives you role based access control functionality to limit the permissions of some members within your team.
                  You can manage what your employees have access to.
                </p>
                <div
                  className="d-flex flex-column justify-content-start lead my-4"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="150"
                  data-aos-easing="ease-in-out"
                >
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>Multiple Employees With Same Role</strong>
                    </span>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>Increase Ease of Access</strong>
                    </span>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>Prevent Against Data Breaches</strong>
                    </span>
                  </div>
                  
                </div>
              </div>

              <div
                className="feature-left-content d-flex align-items-center justify-content-center"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="200"
                data-aos-offset="10"
                data-aos-easing="ease-in-out"
              >
                <div className="feature-main-image-with-icon-container ">
                  <div className="feature-main-image-with-icon">
                    <FaUserShield />
                    <div className="bottom-left-floating-image"> </div>
                  <div className="top-right-floating-image"> </div>
                    </div>
                  {/* <div className="top-left-floating-patter"> </div> */}
                  <div className="bottom-left-floating-patter"> </div>
                  <div className="top-right-floating-patter"> </div>
                  {/* <div className="bottom-right-floating-patter"> </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Feature #2 End*/}
      {/* feature 3 */}
      <div className="container py-5">
        <div className="container">
          

          <div className="feature-section-content d-flex justify-content-between align-items-center gap-2 my-5">
            <div
              className="feature-left-content d-flex align-items-center "
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="200"
              data-aos-offset="10"
              data-aos-easing="ease-in-out"
            >
              <div className="feature-main-image-container">
                <img
                  src={Image3}
                  alt="showcase"
                  width="100%"
                  className="feature-main-image-itself"
                />
                <div className="top-left-floating-patter"> </div>
                {/* <div className="bottom-left-floating-patter"> </div>
                <div className="top-right-floating-patter"> </div> */}
                <div className="bottom-right-floating-patter"> </div>
              </div>
            </div>
            <div className="feature-right-content">
              <h2
                className="display-5 fw-bold lh-1 mb-3"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="20"
                data-aos-easing="ease-in-out"
              >
                Manage Multiple Locations
              </h2>
              <p
                className="lead"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="100"
                data-aos-easing="ease-in-out"
              >
                Alpha gives you powerfull tools to manage multiple locations. Sign up for 30 days free trial to check out all the features.
              </p>
              <div
                className="d-flex flex-column justify-content-start lead my-4"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="150"
                data-aos-easing="ease-in-out"
              >
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <span className="list-check">
                    <BsCheck2Square />
                  </span>
                  <span className="ms-5">
                    <strong>Add Site Instructions</strong>
                  </span>
                </div>
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <span className="list-check">
                    <BsCheck2Square />
                  </span>
                  <span className="ms-5">
                    <strong>Track of Signatures on Instructions</strong>
                  </span>
                </div>
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <span className="list-check">
                    <BsCheck2Square />
                  </span>
                  <span className="ms-5">
                    <strong>Manage Site Documents</strong>
                  </span>
                </div>
              </div>
              {/* <div
                  className="d-flex justify-content-start align-items-center gap-3"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="200"
                  data-aos-easing="ease-in-out"
                >
                  <div
                    className="shaddow-button py-3 px-5 button-font"
                    onClick={() => setRegisterShow(!registerShow)}
                  >
                    Try Free
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      </div>

      
      {/* Feature #4 */}
      <div className="py-5">
        <div className="container ">
          <div className="container ">
            <div className="feature2-section-content d-flex justify-content-between align-items-center gap-2 my-5">
              <div className="feature-left-content">
                <h1
                  className="display-5 fw-bold lh-2 mb-3"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="20"
                  data-aos-easing="ease-in-out"
                >
                  Never Miss a Shift
                </h1>
                <p
                  className="lead"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="100"
                  data-aos-easing="ease-in-out"
                >
                  Eliminate missed shifts to help your business run without overhead. Stay on top of shifts with alpha schedule views. 
                </p>
                <div
                  className="d-flex flex-column justify-content-start lead my-4"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="150"
                  data-aos-easing="ease-in-out"
                >
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>View Open Shifts</strong>
                    </span>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>View Covered Shifts</strong>
                    </span>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>Never Leave a Unscheduled Shift</strong>
                    </span>
                  </div>
                  
                </div>
              </div>

              <div
              className="feature-left-content d-flex align-items-center justify-content-center"
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="200"
              data-aos-offset="10"
              data-aos-easing="ease-in-out"
            >
              <div className="feature-main-image-container">
                <img
                  src={Image4}
                  alt="showcase"
                  width="100%"
                  className="feature-main-image-itself"
                />
                <div className="feature-main-image-top-floating"> </div>
                {/* <div className="top-left-floating-patter"> </div> */}
                <div className="bottom-left-floating-patter"> </div>
                <div className="top-right-floating-patter"> </div>
                {/* <div className="bottom-right-floating-patter"> </div> */}
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      {/* Feature #4 End*/}
      {/* Feature #5 */}
      <div className="container py-5">
        <div className="container">
          

          <div className="feature-section-content d-flex justify-content-between align-items-center gap-2 my-5">
          <div
                className="feature-left-content d-flex align-items-center "
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="200"
                data-aos-offset="10"
                data-aos-easing="ease-in-out"
              >
                <div className="feature-main-image-with-icon-container ">
                  <div className="feature-main-image-with-icon">
                    <BsWifi />
                    {/* <div className="bottom-left-floating-image"> </div>
                  <div className="top-right-floating-image"> </div> */}
                  <div className="bottom-right-floating-image"> </div>
                  <div className="top-left-floating-image"> </div>
                    </div>
                  <div className="top-left-floating-patter"> </div>
                  {/* <div className="bottom-left-floating-patter"> </div>
                  <div className="top-right-floating-patter"> </div> */}
                  <div className="bottom-right-floating-patter"> </div>
                </div>
              </div>
            <div className="feature-right-content">
              <h2
                className="display-5 fw-bold lh-1 mb-3"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="20"
                data-aos-easing="ease-in-out"
              >
                Shift Broadcasting
              </h2>
              <p
                className="lead"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="100"
                data-aos-easing="ease-in-out"
              >
                Let your employees know about open shifts and allow employee to apply for open shifts. <br />
                No employee to cover a shift? Broadcast shift to alpha network.
              </p>
              <div
                className="d-flex flex-column justify-content-start lead my-4"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="150"
                data-aos-easing="ease-in-out"
              >
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <span className="list-check">
                    <BsCheck2Square />
                  </span>
                  <span className="ms-5">
                    <strong>Broadcast Shift to Employees</strong>
                  </span>
                </div>
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <span className="list-check">
                    <BsCheck2Square />
                  </span>
                  <span className="ms-5">
                    <strong>Broadcast Shift to Alpha Network</strong>
                  </span>
                </div>
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <span className="list-check">
                    <BsCheck2Square />
                  </span>
                  <span className="ms-5">
                    <strong>Check Who Applied for Shift</strong>
                  </span>
                </div>
              </div>
              {/* <div
                  className="d-flex justify-content-start align-items-center gap-3"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="200"
                  data-aos-easing="ease-in-out"
                >
                  <div
                    className="shaddow-button py-3 px-5 button-font"
                    onClick={() => setRegisterShow(!registerShow)}
                  >
                    Try Free
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Feature #5 End*/}

      {/* Services */}
      <div className="services-container" id="features">
        <div className="container services-section py-5">
          <div className="servicess-section container d-flex flex-column justify-content-center align-items-center">
            <div
              className="d-flex flex-column justify-content-start align-items-center mt-5 mb-3"
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="10"
              data-aos-easing="ease-in-out"
            >
              <div>
                <h2 className="display-5 fw-bold lh-1 my-3">More Features</h2>
              </div>
              <div>
                <h3 className="lead">All powerfull features at one place.</h3>
              </div>
            </div>

            <div
              class="row g-5 pt-5"
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="200"
              data-aos-easing="ease-in-out"
            >
              <div class="col-sm-6 col-lg-4 ">
              <div class="feature-box-front">
              <Link
                      to="/employee-management" onClick={()=>{window.scrollTo(0, 0)}}
                    >
                  <div class="icon">
                    <AiOutlineUsergroupAdd />
                  </div>
                  <div class="feature-content">
                    <h2>Employee Management</h2>
                    <p className="lead">
                      Employee management module makes staff management quick
                      and easy. Alpha database will give you access to new
                      profiles.
                    </p>
                  </div>
                 </Link>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 ">
                <div class="feature-box-front">
                <Link
                      to="/reports" onClick={()=>{window.scrollTo(0, 0)}}
                    >
                  <div class="icon">
                    <AiOutlineFileSearch />
                  </div>
                  <div class="feature-content">
                    <h2>Reports</h2>
                    <p className="lead">
                      Generate site reports and employee reports. 
                      Powerfull module includes payroll and invoice generator.
                    </p>
                  </div></Link>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4 ">
                <div class="feature-box-front">
                <Link
                      to="/attendance" onClick={()=>{window.scrollTo(0, 0)}}
                    >
                  <div class="icon">
                    <BsGeoAltFill />
                  </div>
                  <div class="feature-content">
                    <h2>Attendance</h2>
                    <p className="lead">
                      Monitor remote employees attendance with location tags.
                      Smart tracking will make sure employee is at right
                      location.
                    </p>
                  </div></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services End */}
      <Cta />
      <Footer />
    </>
  );
};

export default SchedulingFront;
