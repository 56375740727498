import React, { useEffect, useState } from "react";
import Alerts from "../../Alerts";
import { setAlert } from "../../../reducers/alert";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  updateTicketStatus,
  addTicketReply,
  markTicketRead,
  getTicketbyID,
} from "../../../reducers/support";

import DisplayTwoColData from "../DisplayTwoColData";
import { ImFlag } from "react-icons/im";

const TicketDetails = () => {
  let params = useParams();

  const ticket_id = params.ticket_id;
  const dispatch = useDispatch();

  const ticket = useSelector((state) => state.support?.ticket);
  const loading = useSelector((state) => state.support?.ticketLoading);

  const updatereplyLoading = useSelector(
    (state) => state.support?.updatereplyLoading
  );
  const updatestatusLoading = useSelector(
    (state) => state.support?.updatestatusLoading
  );
  const [note, setNote] = useState("");

  const handleStatus = () => {
    dispatch(updateTicketStatus({ ticket_id, solved: "true" }))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(
          setAlert({
            componentName: "ticketdetails",
            alertType: "success",
            msg: "Ticket is marked as Solved.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "ticketdetails",
            alertType: "danger",
            msg: "Error in Saving Data. Please try again.",
          })
        )
      );

    setNote("");
  };

  const handleAddReply = () => {
    dispatch(addTicketReply({ ticket_id, reply: note }))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(
          setAlert({
            componentName: "ticketdetails",
            alertType: "success",
            msg: "Your response is added.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "ticketdetails",
            alertType: "danger",
            msg: "Error in Saving Data. Please try again.",
          })
        )
      );

    setNote("");
  };

  useEffect(() => {
    dispatch(getTicketbyID({ ticket_id }));
    dispatch(markTicketRead({ ticket_id, read: true }));
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }
  if (!ticket) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div>
          <Alert variant="info">No Tasks found.</Alert>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="card tictket-details-style">
        <div className="displaysites-header tictket-details-style">
          <h2>Support Ticket</h2>
          <div>
            {ticket.solved ? (
              <>
                <Button className="button-flex-lg" variant="success" disabled>
                  Solved
                </Button>
              </>
            ) : updatestatusLoading ? (
              <Button variant="primary" disabled className="button-flex">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </Button>
            ) : (
              <>
                <Button
                  className="button-flex-lg"
                  variant="warning"
                  onClick={handleStatus}
                >
                  Mark Solved
                </Button>
              </>
            )}
          </div>
        </div>

        <hr />
        <Alerts componentName={"ticketdetails"} />
        <div>
          <DisplayTwoColData field={"Title"} data={ticket.ticketTitle} />
          <DisplayTwoColData field={"Assigned By"} data={ticket.createdBy} />
          <DisplayTwoColData field={"Company"} data={ticket.agencyName} />

          <div className="display-company-data">
            <div className="display-info">Priority :</div>
            <div className="display-data">
              {ticket.priority === "HIGH" ? (
                <span style={{ color: "red" }}>
                  <ImFlag />
                </span>
              ) : ticket.priority === "NORMAL" ? (
                <span style={{ color: "green" }}>
                  <ImFlag />
                </span>
              ) : (
                <span style={{ color: "yellow" }}>
                  <ImFlag />
                </span>
              )}
            </div>
          </div>
          <div className="display-company-data">
            <div className="display-info">Status :</div>
            <div className="display-data text-capitalize">
              {ticket.solved ? "Solved" : "Pending"}
            </div>
          </div>

          <div className="d-flex flex-column gap-5 thin-border w-75">
            <div className="bubble-style align-self-start">
              <div>{ticket.ticketDetails}</div>
              <div className="fw-light" style={{fontSize:"0.9rem"}}>- {ticket.createdBy}, {new Date(ticket.date).toDateString()} </div>
            </div>
            {ticket.replies.length !== 0 ? (
              ticket.replies.map((note, index) => {
                return (
                  <div
                    className={
                      note.username.trim().toLowerCase() ===
                      ticket.createdBy.trim().toLowerCase()
                        ? "bubble-style align-self-start"
                        : "bubble-style-2 align-self-end"
                    }
                    key={index}
                  >
                    <div>{note.reply}</div>
                    <div className="fw-light" style={{fontSize:"0.9rem"}}>- {note.username}, {new Date(ticket.date).toDateString()}</div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
            <InputGroup className="mt-5">
              <Form.Control
                placeholder="Reply"
                aria-label="Reply"
                aria-describedby="basic-addon2"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              {updatereplyLoading ? (
                <Button variant="outline-primary" id="button-addon2" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button
                  variant="outline-primary"
                  id="button-addon2"
                  onClick={handleAddReply}
                >
                  Reply
                </Button>
              )}
            </InputGroup>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketDetails;
