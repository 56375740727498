import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const TicketChartCard = ({ total, solved, due, loading }) => {
  console.log(total);
  console.log(solved);
  console.log(due);
  console.log(loading);
  const data = {
    labels: ["Solved", "Due"],
    datasets: [
      {
        label: "Tickets",
        data: [solved, due],
        backgroundColor: ["rgba(255, 99, 132, 0.9)", "rgba(75, 192, 192, 0.9)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-5">
        <Pie data={data} />
      </div>
      <hr />
      <div className="d-flex flex-column mt-4">
        <div className="d-flex justify-content-between">
          <span className="dashboard-chart-text-1 fw-lighter">
            Total Tickets:
          </span>
          <span className="dashboard-chart-text">{total}</span>{" "}
        </div>
        <div className="d-flex justify-content-between">
          <span className="dashboard-chart-text-1 fw-lighter">Solved:</span>
          <span className="dashboard-chart-text">{solved}</span>{" "}
        </div>
        <div className="d-flex justify-content-between">
          <span className="dashboard-chart-text-1 fw-lighter">Due:</span>
          <span className="dashboard-chart-text">{due}</span>{" "}
        </div>
      </div>
    </div>
  );
};

export default TicketChartCard;
