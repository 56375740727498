import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import Alerts from "../Alerts";
import { setAlert } from "../../reducers/alert";
import { useSelector, useDispatch } from "react-redux";
import Alert from "react-bootstrap/Alert";
import {
  loadAgencyProfile,
  createSubscription,
  cancelSubscription,
} from "../../reducers/agencyProfile";
import AgencyProfile from "./AgencyProfile";
import DisplayTwoColData from "./DisplayTwoColData";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import configData from "../../config/default.json";
import { useSearchParams } from "react-router-dom";
import Contactus from "../Contactus";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_live_51MAg18IB8T7X7G7tXSJ4m48ZLQFSLOtBb3KM0XE4rMovauu0vH2f9eIchWVVoeNknUlbHr7WgDQEmkZmLLgmKYxc00Gn4uhKhl"
);

const CheckoutForm = () => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    dispatch(
      setAlert({
        componentName: "SelectPlanPayment",
        alertType: "info",
        msg: `Processing... Please do not reload while processing.`,
      })
    );

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://www.alphastaffscheduling.com/dashboard/plan",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      dispatch(
        setAlert({
          componentName: "SelectPlanPayment",
          alertType: "danger",
          msg: `Error! Payment is not processed. Please try again later.`,
        })
      );
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      dispatch(
        setAlert({
          componentName: "SelectPlanPayment",
          alertType: "success",
          msg: `Payment Succesfull.`,
        })
      );
    }
  };
  return (
    <div className="card">
      <Alerts componentName={"SelectPlanPayment"} />
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <Button variant="primary" disabled={!stripe} type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
};

const Payment = ({ id }) => {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: id,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  );
};

const SelectPlan = ({ plan, price, setshowselect, planName, cust_id }) => {
  const [quantity, setquantity] = useState(10);
  const dispatch = useDispatch();
  const agencyprofile = useSelector((state) => state.agencyProfile);
  const handleCheckout = () => {
    dispatch(
      createSubscription({
        priceId: plan,
        customerId: cust_id,
        quantity,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(
          setAlert({
            componentName: "SelectPlan",
            alertType: "success",
            msg: `Redirecting to Payment page....`,
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "SelectPlan",
            alertType: "danger",
            msg: `Error in saving data. Please try again.`,
          })
        )
      );
  };
  return (
    <div className="card">
      <div className="displaysites-header">
        <h2>Confirm Plan</h2>
        <Button
          className="m-2"
          variant="primary"
          onClick={() => {
            setshowselect((prev) => !prev);
          }}
        >
          Go Back
        </Button>
      </div>
      <hr />
      <Alerts componentName={"SelectPlan"} />
      <div className="w-50">
        <div className="d-flex my-4 justify-content-between">
          <div className="fs-2">Selected plan</div>
          <div className="fs-2">{planName}</div>
        </div>
        <div className="d-flex my-4  justify-content-between">
          <div className="fs-2">Users</div>
          <div className="fs-2 d-flex gap-1 ">
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => setquantity((val) => val - 10 < 0 ? val : val - 10)}
            >
              -10
            </Button>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => setquantity((val) => val - 1 < 0 ? val : val - 1)}
            >
              -
            </Button>
            {quantity}
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => setquantity((val) => val + 1)}
            >
              +
            </Button>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => setquantity((val) => val + 10)}
            >
              +10
            </Button>
          </div>
        </div>
        <div className="d-flex my-4  justify-content-between">
          <div className="fs-2">Total</div>
          <div className="fs-2">${(+price * +quantity).toFixed(2)}</div>
        </div>
        <div>
          {agencyprofile?.subLoading ? (
            <Button variant="primary" disabled className="button-flex">
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              className="m-2"
              variant="primary"
              size="lg"
              onClick={handleCheckout}
            >
              Checkout
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const AgencyPlan = () => {
  const agencyprofile = useSelector((state) => state.agencyProfile);
  const dispatch = useDispatch();
  const [contactShow, setContactShow] = useState(false);
  const [yearly, setYearly] = useState(false);
  const [plan, setplan] = useState(null);
  const [planName, setplanName] = useState(null);
  const [price, setprice] = useState(null);
  const [show, setShow] = useState(true);
  const [showselect, setshowselect] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  let payment_status = searchParams.get("redirect_status");
  const handleCancel = (subscription_id) => () => {
    dispatch(
      cancelSubscription({
        subscription_id,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(
          setAlert({
            componentName: "AgencyPlan",
            alertType: "success",
            msg: `Plan is canceled. Please reload to see all updated plans.`,
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "AgencyPlan",
            alertType: "danger",
            msg: `Error in saving data. Please try again.`,
          })
        )
      );
  };
  useEffect(() => {
    dispatch(loadAgencyProfile());
  }, []);

  if (agencyprofile.loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }
  if (!agencyprofile) {
    return (
      <div>
        <Alert variant="info">
          No Data. Please create company profile and try again later.
        </Alert>
      </div>
    );
  }
  if (agencyprofile?.agencyprofile?.client_secret) {
    return <Payment id={agencyprofile?.agencyprofile?.client_secret} />;
  }
  if (showselect) {
    return (
      <SelectPlan
        plan={plan}
        price={price}
        setshowselect={setshowselect}
        planName={planName}
        cust_id={agencyprofile?.agencyprofile?.cust_id}
      />
    );
  }
  return (
    <>
      {agencyprofile?.agencyprofile?.plan &&
        agencyprofile?.agencyprofile?.plan.length !== 0 && (
          <div className="card">
            <Alerts componentName={"AgencyPlan"} />
            {agencyprofile?.agencyprofile?.plan.map((plan) => {
              return (
                <div className="dashboard-plans-container d-flex justify-content-between align-items-center my-2">
                  <div>Plan : {configData.PLAN[plan.plan_id] ? configData.PLAN[plan.plan_id] : "PLAN"}</div>
                  <div> Users : {plan.quantity}</div>
                  <div> Status : {plan.status}</div>
                  {plan.plan_id === configData.PLAN.TIER0 ? <Button
                    variant={"danger"}
                    
                    disabled={true}
                  >
                    Cancel{" "}
                  </Button> : <Button
                    variant={"danger"}
                    onClick={handleCancel(plan?.subsscription_id)}
                    disabled={(plan.status === "canceled" || plan.status === "incomplete_expired") ? true : false}
                  >
                    Cancel{" "}
                  </Button>}
                </div>
              );
            })}
          </div>
        )}
      <div className="card">
        {/* cus ID : {agencyprofile?.agencyprofile?.cust_id} <Button
    className="button-flex"
    variant="primary"
    onClick={handleSubmit}
  >
    Submit
  </Button> */}
  <Contactus show={contactShow} onHide={() => setContactShow(false)} />
        <Alerts componentName={"agencyPlan"} />
        {payment_status === "succeeded" && (
          <Alert show={show} variant="success">
            <Alert.Heading>Payment Successfull!</Alert.Heading>
            <p>
              Payment is successfully processed. Please reload to see all active
              plans.
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShow(false)} variant="outline-success">
                Okay!
              </Button>
            </div>
          </Alert>
        )}
        {(payment_status === "requires_payment_method" ||
          payment_status === "requires_action") && (
          <Alert show={show} variant="danger">
            <Alert.Heading>Error! Please try again.</Alert.Heading>
            <p>
              Payment is not processed. Please try using different card. Please
              contact support for further help.
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShow(false)} variant="outline-danger">
                Okay!
              </Button>
            </div>
          </Alert>
        )}
        {agencyprofile?.agencyprofile?.plan.length === 0 && (
          <Alert show={show} variant="info">
            {/* <Alert.Heading>Start Trial!</Alert.Heading> */}
            <p>
              Congratulation! your profile is active. Please select a plan to
              begin your Free Trial. You will not be charged untill your trail
              period ends.
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShow(false)} variant="outline-success">
                Okay!
              </Button>
            </div>
          </Alert>
        )}
        {/* pricing cards */}
        <div className="d-flex justify-content-center my-5">
          <ButtonGroup aria-label="Basic example">
            <Button
              variant={yearly ? "secondary" : "primary"}
              onClick={() => setYearly(false)}
            >
              Monthly
            </Button>
            <Button
              variant={yearly ? "primary" : "secondary"}
              onClick={() => setYearly(true)}
            >
              Yearly{" "}
            </Button>
          </ButtonGroup>
        </div>
        <div className="dashboard-pricing-cards-container d-flex justify-content-center gap-5">
          <div className="card p-0 pricing-cards text-center">
            <div className="card-header">
              <h4 className="my-2 font-weight-normal">Basic</h4>
            </div>
            <div className="card-body">
              <h1 className="card-title pricing-card-title">
                $
                {yearly
                  ? configData.PLAN.TIER1_YEAR_PRICE
                  : configData.PLAN.TIER1_PRICE}
              </h1>
              <div className="text-muted">
                /{yearly ? "year" : "month"} /user
              </div>
              <ul class="list-unstyled pt-4">
                <li class="list-group-item">Employee Management</li>
                <li class="list-group-item">Access to Alpha DB</li>
                <li class="list-group-item">Local Shift Broadcast</li>
                <li class="list-group-item">Multiple User Roles</li>
                <li class="list-group-item">Geofencing - Medium accuracy</li>
                <li class="list-group-item">Upto 20 employees</li>
                <li class="list-group-item">24/7 Support</li>
              </ul>
            </div>

            <Button
              className="m-2"
              variant="primary"
              size="lg"
              onClick={() => {
                setplan(
                  yearly ? configData.PLAN.TIER1_YEAR : configData.PLAN.TIER1
                );
                setprice(
                  yearly
                    ? configData.PLAN.TIER1_YEAR_PRICE
                    : configData.PLAN.TIER1_PRICE
                );
                setplanName("Basic");
                setshowselect((prev) => !prev);
              }}
            >
              Select Plan
            </Button>
          </div>
          <div className="card p-0 pricing-cards text-center">
            <div className="card-header">
              <h4 className="my-2 font-weight-normal">Pro</h4>
            </div>
            <div className="card-body">
              <h1 className="card-title pricing-card-title">
                $
                {yearly
                  ? configData.PLAN.TIER2_YEAR_PRICE
                  : configData.PLAN.TIER2_PRICE}
              </h1>
              <div className="text-muted">
                /{yearly ? "year" : "month"} /user
              </div>
              <ul class="list-unstyled pt-4">
                <li class="list-group-item">Everything in Basic</li>
                <li class="list-group-item">Global Shift Broadcast</li>
                <li class="list-group-item">Geofencing - High accuracy</li>
                <li class="list-group-item">Task Management</li>
                <li class="list-group-item">Upto 1000 employees</li>
                <li class="list-group-item">1 Custom Feature</li>
              </ul>
            </div>

            <Button
              className="m-2"
              variant="primary"
              size="lg"
              onClick={() => {
                setplan(
                  yearly ? configData.PLAN.TIER2_YEAR : configData.PLAN.TIER2
                );
                setprice(
                  yearly
                    ? configData.PLAN.TIER2_YEAR_PRICE
                    : configData.PLAN.TIER2_PRICE
                );
                setplanName("PRO");
                setshowselect((prev) => !prev);
              }}
            >
              Select Plan
            </Button>
          </div>
          <div className="card p-0 pricing-cards text-center">
            <div className="card-header">
              <h4 className="my-2 font-weight-normal">Enterprice</h4>
            </div>
            <div className="card-body">
              <h1 className="card-title pricing-card-title">Contact Us</h1>
              <div className="text-muted">custom pricing</div>
              <ul class="list-unstyled pt-4">
                <li class="list-group-item">Everything in Basic & Pro</li>
                <li class="list-group-item">5 Custom features</li>
                <li class="list-group-item">Unlimited employees</li>
              </ul>
            </div>

            <Button className="m-2" variant="primary" size="lg" onClick={() => setContactShow(!contactShow)}>
              Contact Sales
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgencyPlan;
