import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alerts from "../Alerts";
import { setAlert } from "../../reducers/alert";
import { useDispatch, useSelector } from "react-redux";
import { loadShifts, createShift, createmultipleShift } from "../../reducers/site";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import { AiFillPlusCircle } from "react-icons/ai";

const CreateShift = ({ site_id, shiftTypes }) => {
  const dispatch = useDispatch();
  const site = useSelector((state) => state.site);

  const [shiftDate, setShiftDate] = useState(new Date());
  const [endDateTemp, setEndDateTemp] = useState("");

  //shiftType 
  const [shiftType, setShiftType] = useState("");

  const [breakStart, setBreakSttart] = useState("");
  const [breakEnd, setBreakEnd] = useState("");

  const [breakStart2, setBreakSttart2] = useState("");
  const [breakEnd2, setBreakEnd2] = useState("");

  const [breakStart3, setBreakSttart3] = useState("");
  const [breakEnd3, setBreakEnd3] = useState("");

  const [break1, setBreak1] = useState(false);
  const [exclude1, setExclude1] = useState(false);
  const [break2, setBreak2] = useState(false);
  const [exclude2, setExclude2] = useState(false);
  const [break3, setBreak3] = useState(false);
  const [exclude3, setExclude3] = useState(false);

  let deductableHours = 0;
  let deductableHours2 = 0;
  let deductableHours3 = 0;
  //   const [endTime, setEndTime] = useState("");

  // regular shift ?
  const [everySun, setEverySun] = useState(false);
  const [everyMon, setEveryMon] = useState(false);
  const [everyTue, setEveryTue] = useState(false);
  const [everyWed, setEveryWed] = useState(false);
  const [everyThr, setEveryThr] = useState(false);
  const [everyFri, setEveryFri] = useState(false);
  const [everySat, setEverySat] = useState(false);
  const [nextDay, setnextDay] = useState(false);
  const [weeks, setWeeks] = useState(0);


  //get specific day dates
  function getDaysBetweenDates(start, end, dayName) {
    var result = [];
    var days = {sun:0,mon:1,tue:2,wed:3,thu:4,fri:5,sat:6};
    var day = days[dayName.toLowerCase().substr(0,3)];
    // Copy start date
    var current = new Date(start);
    // Shift to next of required days
    current.setDate(current.getDate() + (day - current.getDay() + 7) % 7);
    // While less than end date, add dates to result array
    while (current < end) {
      result.push(new Date(+current));
      current.setDate(current.getDate() + 7);
    }
    return result;  
  }
  
  

  function diff_minutes(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let breakTime = [];

    //dates for shifts
    let shiftDates = [];

    

    if (break1) {
      if (!breakStart || !breakEnd || !endDateTemp || !shiftDate ) {
        dispatch(
          setAlert({
            componentName: "createshift",
            alertType: "danger",
            msg: "Please fill all fields. Break #1 Error.",
          })
        );
        return console.log("please input all fields");
      }
      if (
        breakEnd.getTime() <= breakStart.getTime() ||
        breakEnd.getTime() > endDateTemp.getTime() ||
        breakStart.getTime() < shiftDate.getTime()
      ) {
        dispatch(
          setAlert({
            componentName: "createshift",
            alertType: "danger",
            msg: "Please select Break Timing carefully. Break #1 Error.",
          })
        );
        return console.log("please input all fields");
      }
      if (exclude1) {
        deductableHours = diff_minutes(breakEnd, breakStart) / 60;
      }

      breakTime.push({
        startTime: breakStart,
        endTime: breakEnd,
        deductable: exclude1,
        breakHours: diff_minutes(breakEnd, breakStart) / 60,
      });
    }

    if (break2) {
      if (!breakStart2 || !breakEnd2 || !endDateTemp || !shiftDate) {
        dispatch(
          setAlert({
            componentName: "createshift",
            alertType: "danger",
            msg: "Please fill all fields. Break #2 Error.",
          })
        );
        return console.log("please input all fields");
      }
      if (
        breakEnd2.getTime() <= breakStart2.getTime() ||
        breakEnd2.getTime() > endDateTemp.getTime() ||
        breakStart2.getTime() < shiftDate.getTime()
      ) {
        dispatch(
          setAlert({
            componentName: "createshift",
            alertType: "danger",
            msg: "Please select Break Timing carefully. Break #2 Error.",
          })
        );
        return console.log("please input all fields");
      }

      if (exclude2) {
        deductableHours2 = diff_minutes(breakEnd2, breakStart2) / 60;
      }

      breakTime.push({
        startTime: breakStart2,
        endTime: breakEnd2,
        deductable: exclude2,
        breakHours: diff_minutes(breakEnd2, breakStart2) / 60,
      });
    }

    if (break3) {
      if (!breakStart3 || !breakEnd3 || !endDateTemp || !shiftDate) {
        dispatch(
          setAlert({
            componentName: "createshift",
            alertType: "danger",
            msg: "Please fill all fields. Break #3 Error.",
          })
        );
        return console.log("please input all fields");
      }
      if (
        breakEnd3.getTime() <= breakStart3.getTime() ||
        breakEnd3.getTime() > endDateTemp.getTime() ||
        breakStart3.getTime() < shiftDate.getTime()
      ) {
        dispatch(
          setAlert({
            componentName: "createshift",
            alertType: "danger",
            msg: "Please select Break Timing carefully. Break #3 Error.",
          })
        );
        return console.log("please input all fields");
      }
      if (exclude3) {
        deductableHours3 = diff_minutes(breakEnd3, breakStart3) / 60;
      }

      breakTime.push({
        startTime: breakStart3,
        endTime: breakEnd3,
        deductable: exclude3,
        breakHours: diff_minutes(breakEnd3, breakStart3) / 60,
      });
    }

    if (!shiftDate || !endDateTemp ) {
      dispatch(
        setAlert({
          componentName: "createshift",
          alertType: "danger",
          msg: "Please fill all fields.",
        })
      );
      return console.log("please input all fields");
    }
    if (!shiftType ) {
      dispatch(
        setAlert({
          componentName: "createshift",
          alertType: "danger",
          msg: "Please select coverage type.",
        })
      );
      return console.log("Please select coverage type.");
    }
    // Checking start date and end date is correct
    if (
      (shiftDate.getDate() > endDateTemp.getDate() &&
        shiftDate.getMonth() === endDateTemp.getMonth()) ||
      (shiftDate.getDate() + 1 < endDateTemp.getDate() &&
        shiftDate.getMonth() === endDateTemp.getMonth())
    ) {
      dispatch(
        setAlert({
          componentName: "createshift",
          alertType: "danger",
          msg: "Error! Please make sure shift date is selected correctly.",
        })
      );
      return console.log(
        "please input all fields",
        shiftDate.getDate(),
        endDateTemp.getDate(),
        shiftDate.getMonth(),
        endDateTemp.getMonth()
      );
    }
    // Checking start time and end time is correct
    if (
      shiftDate.getDate() === endDateTemp.getDate() &&
      shiftDate.getHours() > endDateTemp.getHours()
    ) {
      dispatch(
        setAlert({
          componentName: "createshift",
          alertType: "danger",
          msg: "Error! Please make sure shift Timings are selected correctly.",
        })
      );
      return console.log("2please input all fields");
    }
    if (
      shiftDate.getDate() === endDateTemp.getDate() &&
      shiftDate.getHours() === endDateTemp.getHours() &&
      shiftDate.getMinutes() >= endDateTemp.getMinutes()
    ) {
      dispatch(
        setAlert({
          componentName: "createshift",
          alertType: "danger",
          msg: "Error! Please make sure shift Timings are selected correctly!",
        })
      );
      return console.log("3please input all fields");
    }

    //checking for regular shifts
    let enddate = new Date(endDateTemp);
    const addDays = 7 * weeks;
    enddate.setDate(enddate.getDate() + addDays)
    if(everySun){
      shiftDates = getDaysBetweenDates(shiftDate,enddate, "sun")
    }
    if(everyMon){
      shiftDates = [...shiftDates ,...getDaysBetweenDates(shiftDate,enddate, "mon")]
    }
    if(everyTue){
      shiftDates = [...shiftDates ,...getDaysBetweenDates(shiftDate,enddate, "tue")]
    }
    if(everyWed){
      shiftDates = [...shiftDates ,...getDaysBetweenDates(shiftDate,enddate, "wed")]
    }
    if(everyThr){
      shiftDates = [...shiftDates ,...getDaysBetweenDates(shiftDate,enddate, "thr")]
    }
    if(everyFri){
      shiftDates = [...shiftDates ,...getDaysBetweenDates(shiftDate,enddate, "fri")]
    }
    if(everySat){
      shiftDates = [...shiftDates ,...getDaysBetweenDates(shiftDate,enddate, "sat")]
    }

    if(shiftDate.getDay() !== endDateTemp.getDay()){
      setnextDay(true);
    }

    if(shiftDates.length !== 0){
      //date format dd/mm/yyyy/hh/mm
      breakTime.forEach((item)=>{
        item.startTime = diff_minutes(shiftDate, item.startTime);
        item.endTime = diff_minutes(shiftDate, item.endTime)
      })
     
    dispatch(
      createmultipleShift({
        shiftType,
        shiftDates,
        nextDay,
        site_id,
        shiftDate: shiftDate.toString(),
        startTime: shiftDate.toString(),
        endTime: endDateTemp.toString(),
        shiftHours:
          diff_minutes(endDateTemp, shiftDate) / 60 -
          (deductableHours + deductableHours2 + deductableHours3),
        breakTime,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(loadShifts({ site_id }));
        dispatch(
          setAlert({
            componentName: "createshift",
            alertType: "success",
            msg: "Shifts are created.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "createshift",
            alertType: "danger",
            msg: "Error in Creating Shift. Please try again.",
          })
        )
      );
      setEndDateTemp("");
      return;
    }

    //date format dd/mm/yyyy/hh/mm
    dispatch(
      createShift({
        site_id,
        shiftType,
        shiftDate: shiftDate.toString(),
        startTime: shiftDate.toString(),
        endTime: endDateTemp.toString(),
        shiftHours:
          diff_minutes(endDateTemp, shiftDate) / 60 -
          (deductableHours + deductableHours2 + deductableHours3),
        breakTime,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(loadShifts({ site_id }));
        dispatch(
          setAlert({
            componentName: "createshift",
            alertType: "success",
            msg: "Shift is created.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "createshift",
            alertType: "danger",
            msg: "Error in Creating Shift. Please try again.",
          })
        )
      );

    setEndDateTemp("");
  };
  if (!site_id) {
    return <Alert variant="info">Error:no Site id Please try again.</Alert>;
  }
  if (!shiftTypes || shiftTypes.length === 0) {
    return <Alert variant="info">Please add atleast one coverage type to create shift. You can add coverage types and rates under Rates tab.</Alert>;
  }
  return (
    <div className="thin-border my-3 card-no-shadow">
      <Alerts componentName={"createshift"} />
      <Form>
        <h5>Create Shift</h5>
        <hr />
        <Row className="mt-3 mb-4">
          <Col>
            Shift Date:
            <DatePicker
              selected={shiftDate}
              onChange={(date) => {
                setShiftDate(date);
                setEndDateTemp(date)
              }}
            />
          </Col>

          <Col>
            Start Time:
            <DatePicker
              selected={shiftDate}
              onChange={(date) => {
                setShiftDate(date);
                setEndDateTemp(date)
                setBreakSttart(date);
                setBreakSttart2(date);
                setBreakSttart3(date);
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              timeCaption="Time"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
            />
          </Col>

          <Col>
            End Date:
            <DatePicker
              selected={endDateTemp}
              onChange={(date) => setEndDateTemp(date)}
              minDate={shiftDate}
              maxDate={addDays(shiftDate, 1)}
            />
          </Col>

          <Col>
            End Time:
            <DatePicker
              selected={endDateTemp}
              onChange={(date) => setEndDateTemp(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              timeCaption="Time"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
            />
          </Col>
        </Row>
        <hr />
        {/* add shift Type */}
        <Row className="my-5">
          <div className="d-flex ">
            <div className="align-self-center" style={{ width: "300px"}} >
              <span style={{ color: "grey", fontSize: "20px" }}>
                <AiFillPlusCircle />
              </span>{" "}
              Coverage Type
            </div>
            <div className="d-flex flex-fill justify-content-center align-items-center">
              <div className="flex-fill d-flex flex-column gap-3 ">
              <Form.Select
              className="w-25"
              aria-label="Default select example"
              value={shiftType}
              onChange={(e) => setShiftType(e.target.value)}
            >
              <option>Select Coverage Type</option>
      
              {shiftTypes.map((item, index)=>{
                return(
                  <option value={item.shiftType} key={index}>{item.shiftType}</option>
                )
              })}
            </Form.Select>
                
              </div>
              
             
            </div>
          </div>
        </Row>
        <hr />
        {/* Select Break */}
        <Row className="my-5">
          <div className="d-flex ">
            <div className="align-self-center" style={{ width: "300px"}} >
              <span style={{ color: "grey", fontSize: "20px" }}>
                <AiFillPlusCircle />
              </span>{" "}
              Add Breaks
            </div>
            <div className="d-flex flex-fill justify-content-center align-items-center">
              <div className="flex-fill d-flex flex-column gap-3 ">
                <div className="d-flex gap-2 ">
                  Break #1
                  <Form.Check
                    type={"checkbox"}
                    id={`break-checkbox-11`}
                    onChange={() => setBreak1((prev) => !prev)}
                  />
                </div>
                <div>
                  Break Time:
                  <DatePicker
                    selected={breakStart}
                    onChange={(date) => {
                      setBreakSttart(date);
                      setBreakEnd(date);
                    }}
                    showTimeSelect
                    minDate={shiftDate}
                    timeIntervals={5}
                    maxDate={addDays(shiftDate, 1)}
                    dateFormat="MM/dd/yyyy HH:mm"
                    timeFormat="HH:mm"
                    disabled={!break1}
                  />
                </div>
                <div>
                  End Time:
                  <DatePicker
                    selected={breakEnd}
                    onChange={(date) => setBreakEnd(date)}
                    showTimeSelect
                    timeIntervals={5}
                    minDate={shiftDate}
                    maxDate={addDays(shiftDate, 1)}
                    dateFormat="MM/dd/yyyy HH:mm"
                    timeFormat="HH:mm"
                    disabled={!break1}
                  />
                </div>
                <div>
                  Break Time:{" "}
                  {breakStart && breakEnd
                    ? diff_minutes(breakEnd, breakStart)
                    : " 0 "}{" "}
                  Mins
                </div>
                <div className="d-flex gap-2">
                  Deductible:
                  <Form.Check
                    type={"checkbox"}
                    id={`break-checkbox-1-exclude`}
                    onChange={() => setExclude1((prev) => !prev)}
                    disabled={!break1}
                  />
                </div>
              </div>
              <div className="flex-fill d-flex flex-column gap-3 ">
                <div className="d-flex gap-2 ">
                  Break #2
                  <Form.Check
                    type={"checkbox"}
                    id={`break-checkbox-21`}
                    onChange={() => setBreak2((prev) => !prev)}
                  />
                </div>
                <div>
                  Break Time:
                  <DatePicker
                    selected={breakStart2}
                    onChange={(date) => {
                      setBreakSttart2(date);
                      setBreakEnd2(date);
                    }}
                    showTimeSelect
                    minDate={shiftDate}
                    timeIntervals={5}
                    maxDate={addDays(shiftDate, 1)}
                    dateFormat="MM/dd/yyyy HH:mm"
                    timeFormat="HH:mm"
                    disabled={!break2}
                  />
                </div>
                <div>
                  End Time:
                  <DatePicker
                    selected={breakEnd2}
                    onChange={(date) => setBreakEnd2(date)}
                    showTimeSelect
                    timeIntervals={5}
                    minDate={shiftDate}
                    maxDate={addDays(shiftDate, 1)}
                    dateFormat="MM/dd/yyyy HH:mm"
                    timeFormat="HH:mm"
                    disabled={!break2}
                  />
                </div>
                <div>
                  Break Time:{" "}
                  {breakStart2 && breakEnd2
                    ? diff_minutes(breakEnd2, breakStart2)
                    : " 0 "}{" "}
                  Mins
                </div>
                <div className="d-flex gap-2">
                  Deductible:
                  <Form.Check
                    type={"checkbox"}
                    id={`break-checkbox-2-exclude`}
                    onChange={() => setExclude2((prev) => !prev)}
                    disabled={!break2}
                  />
                </div>
              </div>
              <div className="flex-fill d-flex flex-column gap-3 ">
                <div className="d-flex gap-2 ">
                  Break #3
                  <Form.Check
                    type={"checkbox"}
                    id={`break-checkbox-31`}
                    onChange={() => setBreak3((prev) => !prev)}
                  />
                </div>
                <div>
                  Break Time:
                  <DatePicker
                    selected={breakStart3}
                    onChange={(date) => {
                      setBreakSttart3(date);
                      setBreakEnd3(date);
                    }}
                    showTimeSelect
                    minDate={shiftDate}
                    timeIntervals={5}
                    maxDate={addDays(shiftDate, 1)}
                    dateFormat="MM/dd/yyyy HH:mm"
                    timeFormat="HH:mm"
                    disabled={!break3}
                  />
                </div>
                <div>
                  End Time:
                  <DatePicker
                    selected={breakEnd3}
                    onChange={(date) => setBreakEnd3(date)}
                    showTimeSelect
                    timeIntervals={5}
                    minDate={shiftDate}
                    maxDate={addDays(shiftDate, 1)}
                    dateFormat="MM/dd/yyyy HH:mm"
                    timeFormat="HH:mm"
                    disabled={!break3}
                  />
                </div>
                <div>
                  Break Time:{" "}
                  {breakStart3 && breakEnd3
                    ? diff_minutes(breakEnd3, breakStart3)
                    : " 0 "}{" "}
                  Mins
                </div>
                <div className="d-flex gap-2">
                  Deductible:
                  <Form.Check
                    type={"checkbox"}
                    id={`break-checkbox-3-exclude`}
                    onChange={() => setExclude3((prev) => !prev)}
                    disabled={!break3}
                  />
                </div>
              </div>
            </div>
          </div>
        </Row>
        {/* create shift for everyday */}
        <hr />
        <Row className="my-5">
          <div className="d-flex ">
            <div className="align-self-center" style={{ width: "300px"}}>
              <span style={{ color: "grey", fontSize: "20px" }}>
                <AiFillPlusCircle />
              </span>{" "}
              Add Days
            </div>
            <div className="d-flex flex-fill justify-content-center align-items-center">
              <div className="flex-fill d-flex flex-column gap-3 ">
                <div className="d-flex gap-2 ">
                  Every Sun
                  <Form.Check
                    type={"checkbox"}
                    id={`break-checkbox-sun`}
                    onChange={() => setEverySun((prev) => !prev)}
                  />
                </div>
                
                
              </div>
              <div className="flex-fill d-flex flex-column gap-3 ">
                <div className="d-flex gap-2 ">
                  Every Mon
                  <Form.Check
                    type={"checkbox"}
                    id={`break-checkbox-mon`}
                    onChange={() => setEveryMon((prev) => !prev)}
                  />
                </div>
                
                
              </div>
              <div className="flex-fill d-flex flex-column gap-3 ">
                <div className="d-flex gap-2 ">
                  Every Tue
                  <Form.Check
                    type={"checkbox"}
                    id={`break-checkbox-tue`}
                    onChange={() => setEveryTue((prev) => !prev)}
                  />
                </div>
                
                
              </div>
              <div className="flex-fill d-flex flex-column gap-3 ">
                <div className="d-flex gap-2 ">
                  Every Wed
                  <Form.Check
                    type={"checkbox"}
                    id={`break-checkbox-wed`}
                    onChange={() => setEveryWed((prev) => !prev)}
                  />
                </div>
                
                
              </div>
              <div className="flex-fill d-flex flex-column gap-3 ">
                <div className="d-flex gap-2 ">
                  Every Thr
                  <Form.Check
                    type={"checkbox"}
                    id={`break-checkbox-thr`}
                    onChange={() => setEveryThr((prev) => !prev)}
                  />
                </div>
                
                
              </div>
              <div className="flex-fill d-flex flex-column gap-3 ">
                <div className="d-flex gap-2 ">
                  Every Fri
                  <Form.Check
                    type={"checkbox"}
                    id={`break-checkbox-fri`}
                    onChange={() => setEveryFri((prev) => !prev)}
                  />
                </div>
                
                
              </div>
              <div className="flex-fill d-flex flex-column gap-3 ">
                <div className="d-flex gap-2 ">
                  Every Sat
                  <Form.Check
                    type={"checkbox"}
                    id={`break-checkbox-sat`}
                    onChange={() => setEverySat((prev) => !prev)}
                  />
                </div>
                
                
              </div>
              <div className="flex-fill d-flex flex-column gap-3 ">
                <div className="d-flex gap-2 align-items-center">
                  <div>Weeks</div>
                  <div><Form.Select aria-label="Default select example" onChange={(e) => setWeeks(e.target.value)}>
      <option>0</option>
      {Array(52).fill().map((_, i)=>{
        return (
          <option value={i + 1}>{i + 1}</option>
        )
      })}
    </Form.Select></div>
                </div>
                
                
              </div>
            </div>
          </div>
        </Row>

        {site.loading ? (
          <Button variant="primary" disabled className="button-flex">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="visually-hidden">Loading...</span>
          </Button>
        ) : (
          <>
            <Button
              variant="primary"
              type="submit"
              className="button-flex"
              onClick={handleSubmit}
            >
              Create
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};

export default CreateShift;
