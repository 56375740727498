import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { resendpass } from "../reducers/auth";
import { setAlert } from "../reducers/alert";
import Alerts from "./Alerts";

const Forgotpass = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.resendpassLoading);

  const [email, setEmail] = useState("");

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !validateEmail(email)) {
      dispatch(
        setAlert({
          componentName: "Forgotpass",
          alertType: "danger",
          msg: "Please fill all required fields.",
        })
      );
      return;
    }
    dispatch(
      resendpass({
        email,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(
          setAlert({
            componentName: "Forgotpass",
            alertType: "success",
            msg: "If this email is registered, an email will be sent with instructions.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "Forgotpass",
            alertType: "danger",
            msg: "Error. Please try again.",
          })
        )
      );
      setEmail("");
  };

  return (
    <div>
      <h2>Forgot Password</h2>
      <hr />
      <Alerts componentName={"Forgotpass"} />
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            size="lg"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        {loading ? (
          <Button variant="primary" size="lg" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="visually-hidden">Loading...</span>
          </Button>
        ) : (
          <Button
            variant="primary"
            size="lg"
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </Form>
    </div>
  );
};

export default Forgotpass;
