import React from 'react';
import ImageModal from "./ImageModal";
import Image1 from "./assets/shift-0.jpg";
import Image2 from "./assets/shift-1.jpg";
import Image3 from "./assets/shift-2.jpg";
import Image4 from "./assets/shift-3.jpg";
import { AiOutlineWifi } from "react-icons/ai";

const MonitorShiftsDocs = () => {
    const [imageShow, setImageShow] = React.useState(false);
    const [imageData, setImageData] = React.useState(null);
      const onThisPage = [
          {
            link: "#monitorshifts",
            desc: "Monitor Shifts",
          },
          {
            link: "#descriptions",
            desc: "Descriptions",
          },
          {
            link: "#broadcasted",
            desc: "Broadcasted Shifts",
          },
          {
            link: "#coveredshifts",
            desc: "Covered Shifts",
          },
          {
            link: "#weekly",
            desc: "Weekly View",
          }
        ];
        return (
          <div className="d-flex">
            <div className="docs-sub-left-content">
              <h1 id="monitorshifts">Monitor Shifts</h1>
              <p>
              Monitoring shifts makes it easy to manage all shifts from one place.
              </p>
              
              <div className="bd-callout-info bd-callout ">
              <h5>Permissions</h5>
          <p>
            Admin, Administrator and Schedular has permission to manage shifts.
          </p>
        </div>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">Shifts</span> under Schedule on menu.</p>
        
        

        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image1);
            setImageShow(true);
          }}
        >
          <img src={Image1} alt="create site 1" width={"100%"}></img>
        </div>

              <h2 id="descriptions">Descriptions</h2>
        <p><i class="docs-arrow"></i><span className="text-highlight">Shifts</span><sup>1</sup> To view Open or Covered shifts.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Site Name</span><sup>2</sup> Site name.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Shift Date</span><sup>3</sup> Date of shifts.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Shift Status</span><sup>4</sup> Shifts status,  <span className="text-highlight">OPEN</span>
        if shift is open. <span className="text-highlight">COVERED</span> if shifts is covered.</p>
        <p id="broadcasted"><i class="docs-arrow"></i><span className="text-highlight"><AiOutlineWifi /></span><sup>4</sup> if shift is broadcasted.</p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image2);
            setImageShow(true);
          }}
        >
          <img src={Image2} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="coveredshifts">Covered Shifts</h2>
        <p><i class="docs-arrow"></i>Select <span className="text-highlight">Covered</span> from <span className="text-highlight">Shifts</span> dropdown to view covered shifts.</p>
        
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image3);
            setImageShow(true);
          }}
        >
          <img src={Image3} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="weekly">Weekly View</h2>
        <p><i class="docs-arrow"></i><span className="text-highlight">Show</span><sup>1</sup> Change view to <span className="text-highlight">Daily</span> or <span className="text-highlight">Weekly</span></p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Date Range</span><sup>2</sup> Date range.</p>
        
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image4);
            setImageShow(true);
          }}
        >
          <img src={Image4} alt="create site 1" width={"100%"}></img>
        </div>
              {imageShow && imageData && (
            <ImageModal
              show={imageShow}
              onHide={() => setImageShow(false)}
              imageData={imageData}
            />
          )}
            </div>
            <div className="docs-sub-right-content">
              <div>On this page</div>
              <hr />
              <ul>
                {onThisPage.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.link}>{item.desc}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        
  );
}

export default MonitorShiftsDocs