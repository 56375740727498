import React from "react";
import ImageModal from "./ImageModal";
import Image1 from "./assets/emp-1.jpg";
import Image2 from "./assets/emp-2.jpg";
import Image3 from "./assets/emp-3.jpg";
import Image4 from "./assets/emp-4.jpg";
import Image5 from "./assets/emp-5.jpg";
import Image6 from "./assets/emp-6.jpg";
import Image7 from "./assets/user-documents.jpg";

const EmployeeMgmtDocs = () => {
  const [imageShow, setImageShow] = React.useState(false);
  const [imageData, setImageData] = React.useState(null);
  const onThisPage = [
    {
      link: "#empmgmt",
      desc: "Employees Management",
    },
    {
      link: "#dashboard",
      desc: "Dashboard",
    },
    {
      link: "#alphadb",
      desc: "Alpha Users Database",
    },
    {
      link: "#userprofile",
      desc: "User Profile",
    },
    {
      link: "#adduser",
      desc: "Add User To Employees",
    },
    {
      link: "#employeedocuments",
      desc: "Employee Documents",
    },
  ];
  return (
    <div className="d-flex">
      <div className="docs-sub-left-content">
        <h1 id="empmgmt">Employees Management</h1>
        <p>
          Employee management is the effort to help employees do their best work
          each day in order to achieve the larger goals of the organization.
        </p>

        <div className="bd-callout-info bd-callout ">
          <h5>Permissions</h5>
          <p>
            Admin, Administrator and Schedular has permission to view company
            employees and Alpha user database.
          </p>
        </div>
        <p>
          <i class="docs-arrow"></i>Click on{" "}
          <span className="text-highlight">Employees</span> on menu.
        </p>

        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image1);
            setImageShow(true);
          }}
        >
          <img src={Image1} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="dashboard">Dashboard</h2>
        <p>
          <i class="docs-arrow"></i>
          <span className="text-highlight">Employees</span>
          <sup>1</sup> Company employees.
        </p>
        <p>
          <i class="docs-arrow"></i>
          <span className="text-highlight">Database</span>
          <sup>2</sup> Alpha database.
        </p>
        <p>
          <i class="docs-arrow"></i>
          <span className="text-highlight">Search</span>
          <sup>3</sup> Global search in alpha database.
        </p>
        <p>
          <i class="docs-arrow"></i>
          <span className="text-highlight">Search</span>
          <sup>4</sup> Local search.
        </p>
        <p>
          <i class="docs-arrow"></i>
          <span className="text-highlight">Per Page</span>
          <sup>5</sup> User to display per page.
        </p>
        <p>
          <i class="docs-arrow"></i>
          <span className="text-highlight">Name</span>
          <sup>6</sup> User Name. Click on{" "}
          <span className="text-highlight">User Name</span> to view user
          profile.
        </p>
        <p>
          <i class="docs-arrow"></i>
          <span className="text-highlight">Availability</span>
          <sup>7</sup> Availability of user or employee.
        </p>

        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image2);
            setImageShow(true);
          }}
        >
          <img src={Image2} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="alphadb">Alpha Users Database</h2>
        <p>
          <i class="docs-arrow"></i>Access to Alpha user database.
        </p>
        <p>
          <i class="docs-arrow"></i>Company can broadcast a shift to Alpha
          database.
        </p>
        <p>
          <i class="docs-arrow"></i>Company can view who applied for shift.
        </p>
        <p>
          <i class="docs-arrow"></i>Company can not assign untill a user is
          hired.
        </p>

        <h2 id="userprofile">User Profile</h2>
        <p>
          <i class="docs-arrow"></i>
          <span className="text-highlight">User Profile</span> page will show
          user details, availability, schedule and notes.
        </p>

        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image3);
            setImageShow(true);
          }}
        >
          <img src={Image3} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="adduser">Add user to employees list</h2>
        <p>
          <i class="docs-arrow"></i>{" "}
          <span className="text-highlight">User Profile</span> will show if a
          user is hired or not.
        </p>
        <p>
          <i class="docs-arrow"></i>{" "}
          <span className="text-highlight">Not Hired</span> means user is not in
          employees list. Click to add into employees list.
        </p>
        <p>
          <i class="docs-arrow"></i>{" "}
          <span className="text-highlight">Hired</span> means user is in
          employees list. Click to remove from employees list.
        </p>

        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image4);
            setImageShow(true);
          }}
        >
          <img src={Image4} alt="create site 1" width={"100%"}></img>
        </div>

        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image5);
            setImageShow(true);
          }}
        >
          <img src={Image5} alt="create site 1" width={"100%"}></img>
        </div>
        <p>
          <i class="docs-arrow"></i> Success message will show up once user is
          added into employee list.
        </p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image6);
            setImageShow(true);
          }}
        >
          <img src={Image6} alt="create site 1" width={"100%"}></img>
        </div>

        {/* user documents */}

        <h2 id="employeedocuments">Employee Documents</h2>

        <p>
          <i class="docs-arrow"></i>Employee documents can be viewed on last tab{" "}
          <span className="text-highlight">Documents</span>
          <sup>1</sup>
        </p>
        <p>
          <i class="docs-arrow"></i>To upload new document, enter document name,
          select file and click <span className="text-highlight">Upload</span>
          <sup>2</sup> button.
        </p>
        <p>
          <i class="docs-arrow"></i>Employee documents can be deleted on
          downloaded to device by click on respective icons.
        </p>
        <div className="bd-callout-info bd-callout">
          <h5>Allowed document types</h5>
          <p>
            ".pdf", ".doc", ".docx", ".xls", ".xlsx", ".csv", ".txt", ".rtf",
            ".png", ".jpg", ".jpeg"
          </p>
        </div>
        <div className="bd-callout-info bd-callout">
          <h5>Allowed file size</h5>
          <p>
            Maximum document size is 10MB and upto 10 documents.
          </p>
        </div>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image7);
            setImageShow(true);
          }}
        >
          <img src={Image7} alt="create site 1" width={"100%"}></img>
        </div>

        {imageShow && imageData && (
          <ImageModal
            show={imageShow}
            onHide={() => setImageShow(false)}
            imageData={imageData}
          />
        )}
      </div>

      <div className="docs-sub-right-content">
        <div>On this page</div>
        <hr />
        <ul>
          {onThisPage.map((item, index) => {
            return (
              <li key={index}>
                <a href={item.link}>{item.desc}</a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default EmployeeMgmtDocs;
