import configData from "../config/default.json";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

const API_ENDPOINT = configData.API_ENDPOINT;

const initialState = {
  loading: false,
  user: null,
  error: null,
  privateNotes: null,
  subLoading: false,
  schedule: [],
  scheduleLoading: false,
  searchUsers: [],
  searchLoading: false,
  loggedUserProfile: null,
  loadingUserProfile: false,
  userDocuments: [],
  gettingUserDocLoading: false,
  uploadUserDocLoading: false,
  deleteUserDocLoading: false,
};

export const uploadUserDoc = createAsyncThunk(
  "api/uploadUserDoc",
  async ({ formData }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const response = await axios.post(
      `${API_ENDPOINT}/api/userprofile/uploaddocument`,
      formData
    );
    return response.data;
  }
);

export const deleteUserDoc = createAsyncThunk(
  "api/deleteUserDoc",
  async ({ user_id, document_id, key }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      user_id,
      document_id,
      key,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/userprofile/deletedocument`,
      body,
      config
    );
    return response.data;
  }
);

export const loadUserDocs = createAsyncThunk(
  "api/loadUserDocs",
  async ({ user_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const response = await axios.get(
      `${API_ENDPOINT}/api/userprofile/getuserdocumentsbyid/${user_id}`
    );
    return response.data;
  }
);

export const addUserRole = createAsyncThunk(
  "api/addUserRole",
  async ({ role, user_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      role,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/users/updaterole/${user_id}`,
      body,
      config
    );
    return response.data;
  }
);

export const removeUserRole = createAsyncThunk(
  "api/removeUserRole",
  async ({ role, user_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      role,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/users/deleterole/${user_id}`,
      body,
      config
    );
    return response.data;
  }
);

export const addProfileNote = createAsyncThunk(
  "api/addProfileNote",
  async ({ noteTitle, noteDetails, flag, user_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      noteTitle,
      noteDetails,
      flag,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/userprofile/addnote/${user_id}`,
      body,
      config
    );
    return response.data;
  }
);

export const addPrivateProfileNote = createAsyncThunk(
  "api/addPrivateProfileNote",
  async ({ noteTitle, noteDetails, flag, user_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      noteTitle,
      noteDetails,
      flag,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/employees/addprivatenote/${user_id}`,
      body,
      config
    );
    return response.data;
  }
);

export const hireUser = createAsyncThunk(
  "api/hireUser",
  async ({ user_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      user_id,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/employees/hire`,
      body,
      config
    );
    return response.data;
  }
);
export const unhireUser = createAsyncThunk(
  "api/unhireUser",
  async ({ user_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      user_id,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/employees/unhire`,
      body,
      config
    );
    return response.data;
  }
);

export const loadUserProfile = createAsyncThunk(
  "api/loadUserProfile",
  async ({ user_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const response = await axios.get(
      `${API_ENDPOINT}/api/users/userprofile/${user_id}`
    );
    return response.data;
  }
);

export const loadLoggedUserProfile = createAsyncThunk(
  "api/loadLoggedUserProfile",
  async ({ user_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const response = await axios.get(
      `${API_ENDPOINT}/api/users/userprofile/${user_id}`
    );
    return response.data;
  }
);
export const searchUser = createAsyncThunk(
  "api/searchUser",
  async ({ email }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const response = await axios.get(
      `${API_ENDPOINT}/api/users/search/${email}`
    );
    return response.data;
  }
);

export const loadPrivateNotes = createAsyncThunk(
  "api/loadPrivateNotes",
  async ({ user_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const response = await axios.get(
      `${API_ENDPOINT}/api/employees/getprivatenotes/${user_id}`
    );
    return response.data;
  }
);

export const addProfilePicture = createAsyncThunk(
  "api/addProfilePicture",
  async ({ formData }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const response = await axios.post(
      `${API_ENDPOINT}/api/userprofile/setuserprofilepicture`,
      formData
    );
    return response.data;
  }
);

export const loadSchedule = createAsyncThunk(
  "api/loadSchedule",
  async ({ user_id, startDate, endDate }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      endDate,
      startDate,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts/getusershiftsbyid/${user_id}`,
      body,
      config
    );
    console.log(response.data);
    return response.data;
  }
);

export const updateProfile = createAsyncThunk(
  "api/updateProfile",
  async ({
    unit,
    streetNumber,
    street,
    city,
    provience,
    postal,
    country,
    status = "full-time",
    availability = [
      {
        mon: null,
        tue: null,
        wed: null,
        thr: null,
        fri: null,
        sat: null,
        sun: null,
        monTill: null,
        tueTill: null,
        wedTill: null,
        thrTill: null,
        friTill: null,
        satTill: null,
        sunTill: null,
      },
    ],
    drivingLicense = false,
    vehicle = false,
    public1 = false,
    travelTime = 1,
  }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const skills = [{ 0: "0" }];
    const certifications = [{ 0: "0" }];
    const body = JSON.stringify({
      unit,
      streetNumber,
      street,
      city,
      provience,
      postal,
      country,
      status,
      availability,
      drivingLicense,
      vehicle,
      public: public1,
      travelTime,
      skills,
      certifications,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/userprofile/setuserprofile`,
      body,
      config
    );
    return response.data;
  }
);

export const user = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadUserProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.user = action.payload;
      })
      .addCase(loadUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        console.log("error in loadUserProfile");
        console.log(action);
      })
      .addCase(hireUser.pending, (state, action) => {
        state.subLoading = true;
      })
      .addCase(hireUser.fulfilled, (state, action) => {
        state.subLoading = false;
        state.error = null;
      })
      .addCase(hireUser.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error;
        console.log("error in hireUser");
        console.log(action);
      })
      .addCase(unhireUser.pending, (state, action) => {
        state.subLoading = true;
      })
      .addCase(unhireUser.fulfilled, (state, action) => {
        state.subLoading = false;
        state.error = null;
      })
      .addCase(unhireUser.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error;
        console.log("error in unhireUser");
        console.log(action);
      })
      .addCase(addProfileNote.pending, (state, action) => {
        state.subLoading = true;
      })
      .addCase(addProfileNote.fulfilled, (state, action) => {
        state.subLoading = false;
        state.error = null;
      })
      .addCase(addProfileNote.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error;
        console.log("error in addProfileNote");
        console.log(action);
      })
      .addCase(addPrivateProfileNote.pending, (state, action) => {
        state.subLoading = true;
      })
      .addCase(addPrivateProfileNote.fulfilled, (state, action) => {
        state.subLoading = false;
        state.error = null;
      })
      .addCase(addPrivateProfileNote.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error;
        console.log("error in addPrivateProfileNote");
        console.log(action);
      })
      .addCase(loadPrivateNotes.pending, (state, action) => {
        state.subLoading = true;
      })
      .addCase(loadPrivateNotes.fulfilled, (state, action) => {
        state.subLoading = false;
        state.privateNotes = action.payload;
        state.error = null;
      })
      .addCase(loadPrivateNotes.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error;
        state.privateNotes = null;
        console.log("error in loadPrivateNotes");
        console.log(action);
      })
      .addCase(addProfilePicture.pending, (state, action) => {
        state.subLoading = true;
      })
      .addCase(addProfilePicture.fulfilled, (state, action) => {
        state.subLoading = false;
        state.error = null;
      })
      .addCase(addProfilePicture.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error;
        console.log("error in addProfilePicture");
        console.log(action);
      })
      .addCase(loadSchedule.pending, (state, action) => {
        state.scheduleLoading = true;
      })
      .addCase(loadSchedule.fulfilled, (state, action) => {
        state.scheduleLoading = false;
        state.schedule = action.payload;
      })
      .addCase(loadSchedule.rejected, (state, action) => {
        state.scheduleLoading = false;
        state.error = action.error;
        console.log("error in loadSchedule");
        console.log(action);
      })
      .addCase(addUserRole.pending, (state, action) => {
        state.subLoading = true;
      })
      .addCase(addUserRole.fulfilled, (state, action) => {
        state.subLoading = false;
      })
      .addCase(addUserRole.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error;
        console.log("error in addUserRole");
        console.log(action);
      })
      .addCase(removeUserRole.pending, (state, action) => {
        state.subLoading = true;
      })
      .addCase(removeUserRole.fulfilled, (state, action) => {
        state.subLoading = false;
      })
      .addCase(removeUserRole.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error;
        console.log("error in removeUserRole");
        console.log(action);
      })
      .addCase(searchUser.pending, (state, action) => {
        state.searchLoading = true;
      })
      .addCase(searchUser.fulfilled, (state, action) => {
        state.searchLoading = false;
        state.searchUsers = action.payload;
      })
      .addCase(searchUser.rejected, (state, action) => {
        state.searchLoading = false;
        state.searchUsers = [];
        console.log("error in searchUser");
        console.log(action);
      })
      .addCase(updateProfile.pending, (state, action) => {
        state.subLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.subLoading = false;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error;
        console.log("error in updateProfile");
        console.log(action);
      })
      .addCase(loadLoggedUserProfile.pending, (state, action) => {
        state.loadingUserProfile = true;
      })
      .addCase(loadLoggedUserProfile.fulfilled, (state, action) => {
        state.loadingUserProfile = false;
        state.loggedUserProfile = action.payload;
      })
      .addCase(loadLoggedUserProfile.rejected, (state, action) => {
        state.loadingUserProfile = false;
        state.loggedUserProfile = null;
        state.error = action.error;
        console.log("error in loadLoggedUserProfile");
        console.log(action);
      })
      .addCase(uploadUserDoc.pending, (state, action) => {
        state.uploadUserDocLoading = true;
      })
      .addCase(uploadUserDoc.fulfilled, (state, action) => {
        state.uploadUserDocLoading = false;
      })
      .addCase(uploadUserDoc.rejected, (state, action) => {
        state.error = action.error;
        state.uploadUserDocLoading = false;
        console.log("error in uploadUserDoc");
        console.log(action);
      })
      .addCase(deleteUserDoc.pending, (state, action) => {
        state.deleteUserDocLoading = true;
      })
      .addCase(deleteUserDoc.fulfilled, (state, action) => {
        state.deleteUserDocLoading = false;
      })
      .addCase(deleteUserDoc.rejected, (state, action) => {
        state.error = action.error;
        state.deleteUserDocLoading = false;
        console.log("error in deleteUserDoc");
        console.log(action);
      })
      .addCase(loadUserDocs.pending, (state, action) => {
        state.gettingUserDocLoading = true;
      })
      .addCase(loadUserDocs.fulfilled, (state, action) => {
        state.gettingUserDocLoading = false;
        state.userDocuments = action.payload;
      })
      .addCase(loadUserDocs.rejected, (state, action) => {
        state.error = action.error;
        state.gettingUserDocLoading = false;
        state.userDocuments= [];
        console.log("error in loadUserDocs");
        console.log(action);
      });
  },
});

export default user.reducer;
