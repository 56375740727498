import React from 'react';
import ImageModal from "./ImageModal";
import Image1 from "./assets/create-shift-1.jpg";
import Image2 from "./assets/create-shift-3.jpg";
import Image3 from "./assets/create-shift-4.jpg";

const CreateShiftDocs = () => {
  const [imageShow, setImageShow] = React.useState(false);
  const [imageData, setImageData] = React.useState(null);
    const onThisPage = [
        {
          link: "#createshift",
          desc: "Create Shift",
        },
        {
          link: "#addbreaks",
          desc: "Add Breaks",
        },
        {
          link: "#rotatingshift",
          desc: "Rotating Shifts",
        }
      ];
      return (
        <div className="d-flex">
          <div className="docs-sub-left-content">
            <h1 id="createshift">Create Shift</h1>
            <p>
            The term shift work refers to any work schedule. Shift work can include evening, night, and early morning shifts, as well as fixed or rotating schedules.
            </p>
            <div className="bd-callout-info bd-callout ">
          <h5>Permissions</h5>
          <p>
            Only Admin and Administrator has permission to create shifts.
            Schedular don't have permission to create shifts.
          </p>
        </div>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">Create Shifts</span> button on Schedule tab.</p>
        
        

        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image1);
            setImageShow(true);
          }}
        >
          <img src={Image1} alt="create site 1" width={"100%"}></img>
        </div>
        <div className="bd-callout-alert bd-callout ">
          <h5>Shift and break timing</h5>
          <p>
            Double check the shift and break timings. Incorrect timings can cause error in shift actions.
          </p>
        </div>

        <p><i class="docs-arrow"></i><span className="text-highlight">Shift Timinig </span><sup>1</sup> Select shift start and end date and time.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Coverage Type </span><sup>5</sup> Select coverage type.</p>
        <p id="addbreaks"><i class="docs-arrow"></i><span className="text-highlight">Add Breaks </span><sup>2</sup> Add upto 3 breaks. Breaks are optional. A shift can be created without breaks.
        If deductible is checked, break hours will be deducted from shift total hours.</p>
        <p id="rotatingshift"><i class="docs-arrow"></i><span className="text-highlight">Rotating Shift </span><sup>3</sup> Select days and number of weeks to create shift for future dates.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Create </span><sup>4</sup> Click on create button to create shift.</p>
        
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image2);
            setImageShow(true);
          }}
        >
          <img src={Image2} alt="create site 1" width={"100%"}></img>
        </div>

        <p><i class="docs-arrow"></i>A success message will show up once shift is created.</p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image3);
            setImageShow(true);
          }}
        >
          <img src={Image3} alt="create site 1" width={"100%"}></img>
        </div>


            {imageShow && imageData && (
          <ImageModal
            show={imageShow}
            onHide={() => setImageShow(false)}
            imageData={imageData}
          />
        )}
          </div>
          <div className="docs-sub-right-content">
            <div>On this page</div>
            <hr />
            <ul>
              {onThisPage.map((item, index) => {
                return (
                  <li key={index}>
                    <a href={item.link}>{item.desc}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      
);
}

export default CreateShiftDocs