import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { changepass } from "../reducers/auth";
import { setAlert } from "../reducers/alert";
import Alerts from "./Alerts";


const Changepass = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.changepassLoading);

  const [password, setPassword] = useState("");
  const [oldpassword, setOldPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!password || !oldpassword) {
      dispatch(
        setAlert({
          componentName: "Changepass",
          alertType: "danger",
          msg: "Please fill all required fields.",
        })
      );
      return;
    }
    dispatch(
      changepass({
        password,
        oldpassword,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(
          setAlert({
            componentName: "Changepass",
            alertType: "success",
            msg: "Password is updated.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "Changepass",
            alertType: "danger",
            msg: "Error. Please try again.",
          })
        )
      );
      setPassword("");
      setOldPassword("");
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Change Password
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Alerts componentName={"Changepass"} />
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your current passsword"
              value={oldpassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>{" "}
          {loading ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Save
            </Button>
          )}
        </Modal.Footer>
      </>
    </Modal>
  );
};

export default Changepass;
