import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./sidebar2.css";
import Button from "react-bootstrap/Button";
import UserRoles from "../../config/default.json";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../reducers/auth";
import { BsCalendarDate, BsChevronRight, BsCartCheck } from "react-icons/bs";
import {
  AiOutlineDashboard,
  AiOutlineProfile,
  AiOutlineBars,
  AiOutlineUsergroupAdd,
  AiOutlineMonitor,
  AiOutlineEye,
  AiOutlineSchedule,
  AiOutlineLogout,
  AiOutlineUser,
  AiOutlineFileSearch,
  AiOutlineUserAdd,
  AiOutlineFileText
} from "react-icons/ai";
import { BiTask } from "react-icons/bi";
import { MdSupportAgent } from "react-icons/md";

const Sidebar2 = ({ trialDaysLeft }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth);
  const roles = useSelector((state) => state.auth.user.roles);
  const userName = user.user ? user.user.username : "Welcome";
  const ref = useRef(null);
  const taskref = useRef(null);
  const reportref = useRef(null);
  const [sidebar, setsidebar] = useState(
    window.matchMedia("(min-width: 480px)").matches ? true : false
  );
  const [submenu, setSubmenu] = useState(false);
  const [submenuTask, setSubmenuTask] = useState(false);
  const [submenuReport, setSubmenuReport] = useState(false);

  const sidebarcontrolleropen = () => {
    setsidebar(true);
  };

  const sidebarcontrollerclose = () => {
    setsidebar(false);
  };
  const submenucontrollerclose = () => {
    setSubmenu((prevCheck) => !prevCheck);
    submenuTask ? setSubmenuTask(false) : <></>;
    submenuReport ? setSubmenuReport(false) : <></>;
  };

  const submenucontrollercloseTask = () => {
    setSubmenuTask((prevCheck) => !prevCheck);
    submenu ? setSubmenu(false) : <></>;
    submenuReport ? setSubmenuReport(false) : <></>;
  };

  const submenucontrollercloseReport = () => {
    setSubmenuReport((prevCheck) => !prevCheck);
    submenu ? setSubmenu(false) : <></>;
    submenuTask ? setSubmenuTask(false) : <></>;
  };

  return (
    <div
      id="sidebar-container"
      className={
        sidebar ? "sidebar-container expanded" : "sidebar-container closed"
      }
    >
      <div className="username"> {userName.split(" ")[0]}</div>

      {sidebar ? (
        <span
          className="closebtn"
          style={{ fontSize: "35px", cursor: "pointer" }}
          onClick={sidebarcontrollerclose}
        >
          &#9776;
        </span>
      ) : (
        <span
          className="closebtn"
          style={{ fontSize: "35px", cursor: "pointer" }}
          onClick={sidebarcontrolleropen}
        >
          &#9776;
        </span>
      )}

      <ul>
        <li className="menu-item">
          <Link to="/dashboard">
            <span className="menu-icon">
              <AiOutlineDashboard />
            </span>
            <span className="menu-text">Dashboard</span>
          </Link>
        </li>
        {(roles.includes(UserRoles.ROLE.AGENCY_ADMIN) ||
          roles.includes(UserRoles.ROLE.ADMIN)) && (
          <li className="menu-item">
            <Link to="/dashboard/profile">
              <span className="menu-icon">
                <AiOutlineProfile />
              </span>
              <span className="menu-text">Company Profile</span>
            </Link>
          </li>
        )}
        <li className="menu-item">
          <Link to={`/dashboard/users/${user.user._id}`}>
            <span className="menu-icon">
              <AiOutlineUser />
            </span>
            <span className="menu-text">My Profile</span>
          </Link>
        </li>
        <li className="menu-item">
          <Link to={`/dashboard/schedule/${user.user._id}`}>
            <span className="menu-icon">
              <BsCalendarDate />
            </span>
            <span className="menu-text">My Schedule</span>
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/dashboard/sites">
            <span className="menu-icon">
              <AiOutlineBars />
            </span>
            <span className="menu-text">Sites</span>
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/dashboard/users">
            <span className="menu-icon">
              <AiOutlineUsergroupAdd />
            </span>
            <span className="menu-text">Employees</span>
          </Link>
        </li>
        <li className="sub-menu-item">
          <div
            className={
              sidebar
                ? "sub-menu-button"
                : "sub-menu-button sub-menu-button-collapsed"
            }
            onClick={submenucontrollerclose}
          >
            <span className="menu-icon">
              <AiOutlineSchedule />
            </span>
            <span className="menu-text">Schedule</span>
            <span className="w-100 me-4 text-end menu-text">
              <span
                className={
                  submenu ? "rotationg-icon trsnition-add" : "trsnition-add"
                }
              >
                <BsChevronRight />
              </span>
            </span>
          </div>
          <ul
            ref={ref}
            className={
              submenu
                ? "sub-menu-items sub-menu-expended"
                : "sub-menu-items sub-menu-collapsed"
            }
          >
            <li>
              <Link to="/dashboard/shifts">
                <span className="menu-icon">
                  <AiOutlineMonitor />
                </span>
                <span className="menu-text">Shifts</span>
              </Link>
            </li>
            <li>
              <Link to="/dashboard/attendance">
                <span className="menu-icon">
                  <AiOutlineEye />
                </span>
                <span className="menu-text">Attendance</span>
              </Link>
            </li>
          </ul>
        </li>
        {/* Tasks */}
        <li className="sub-menu-item">
          <div
            className={
              sidebar
                ? "sub-menu-button"
                : "sub-menu-button sub-menu-button-collapsed"
            }
            onClick={submenucontrollercloseTask}
          >
            <span className="menu-icon">
              <BiTask />
            </span>
            <span className="menu-text">Tasks</span>
            <span className="w-100 me-4 text-end menu-text">
              <span
                className={
                  submenuTask ? "rotationg-icon trsnition-add" : "trsnition-add"
                }
              >
                <BsChevronRight />
              </span>
            </span>
          </div>
          <ul
            ref={taskref}
            className={
              submenuTask
                ? "sub-menu-items sub-menu-expended"
                : "sub-menu-items sub-menu-collapsed"
            }
          >
            <li>
              <Link to="/dashboard/tasks">
                <span className="menu-icon">
                  <AiOutlineMonitor />
                </span>
                <span className="menu-text">Monitor</span>
              </Link>
            </li>
            <li>
              <Link to="/dashboard/mytasks">
                <span className="menu-icon">
                  <AiOutlineEye />
                </span>
                <span className="menu-text">My Tasks</span>
              </Link>
            </li>
          </ul>
        </li>
        {/* Reports Links */}
        {(roles.includes(UserRoles.ROLE.AGENCY_ADMIN) ||
          roles.includes(UserRoles.ROLE.ADMIN) || roles.includes(UserRoles.ROLE.AGENCY_TEAM)) && (
            <li className="sub-menu-item">
            <div
              className={
                sidebar
                  ? "sub-menu-button"
                  : "sub-menu-button sub-menu-button-collapsed"
              }
              onClick={submenucontrollercloseReport}
            >
              <span className="menu-icon">
                <AiOutlineFileText />
              </span>
              <span className="menu-text">Reports</span>
              <span className="w-100 me-4 text-end menu-text">
                <span
                  className={
                    submenuReport ? "rotationg-icon trsnition-add" : "trsnition-add"
                  }
                >
                  <BsChevronRight />
                </span>
              </span>
            </div>
            <ul
              ref={reportref}
              className={
                submenuReport
                  ? "sub-menu-items sub-menu-expended"
                  : "sub-menu-items sub-menu-collapsed"
              }
            >
              <li>
                <Link to="/dashboard/sitereports">
                  <span className="menu-icon">
                    <AiOutlineBars />
                  </span>
                  <span className="menu-text">Sites</span>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/employeereports">
                  <span className="menu-icon">
                    <AiOutlineUsergroupAdd />
                  </span>
                  <span className="menu-text">Employees</span>
                </Link>
              </li>
            </ul>
          </li>
        )}
        <li className="menu-item">
          <Link to={`/dashboard/support`}>
            <span className="menu-icon">
              <MdSupportAgent />
            </span>
            <span className="menu-text">Support</span>
          </Link>
        </li>
        {(roles.includes(UserRoles.ROLE.AGENCY_ADMIN) ||
          roles.includes(UserRoles.ROLE.ADMIN)) && (
          <li className="menu-item">
            <Link to={`/dashboard/plan`}>
              <span className="menu-icon">
                <BsCartCheck />
              </span>
              <span className="menu-text">Plans</span>
            </Link>
          </li>
        )}
        <li className="menu-item">
          <Link to={`/dashboard/docs`}>
            <span className="menu-icon">
              <AiOutlineFileSearch />
            </span>
            <span className="menu-text">Documentation</span>
          </Link>
        </li>
        {roles.includes(UserRoles.ROLE.ADMIN) && (
          <li className="menu-item">
            <Link to={`/dashboard/allcontactus`}>
              <span className="menu-icon">
                <AiOutlineUserAdd />
              </span>
              <span className="menu-text">Admin-Contact Us</span>
            </Link>
          </li>
        )}
        {roles.includes(UserRoles.ROLE.ADMIN) && (
          <li className="menu-item">
            <Link to={`/dashboard/allemaillist`}>
              <span className="menu-icon">
                <AiOutlineUserAdd />
              </span>
              <span className="menu-text">Admin-Email Subs</span>
            </Link>
          </li>
        )}
        {roles.includes(UserRoles.ROLE.ADMIN) && (
          <li className="menu-item">
            <Link to={`/dashboard/alluserslist`}>
              <span className="menu-icon">
                <AiOutlineUserAdd />
              </span>
              <span className="menu-text">Admin-Users</span>
            </Link>
          </li>
        )}
        <li className="menu-item" onClick={() => dispatch(logout())}>
          <Link to="#">
            <span className="menu-icon">
              <AiOutlineLogout />
            </span>
            <span className="menu-text">Logout</span>
          </Link>
        </li>
      </ul>
      {trialDaysLeft && (
        <div className="upgrade-plan">
          {" "}
          <span>{trialDaysLeft} days left of your trial.</span>{" "}
          <Button
            variant="success"
            onClick={() => {
              navigate("/dashboard/plan");
            }}
          >
            Upgrade Plan
          </Button>
        </div>
      )}
    </div>
  );
};

export default Sidebar2;
