import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { loadContactUs } from "../../reducers/admindata";
import Table from "react-bootstrap/Table";

export const AllContactUs = () => {
  const dispatch = useDispatch();
  const contactusdata = useSelector((state) => state.admindata);
  const data = useSelector((state) => state.admindata.contactus);

  useEffect(() => {
    dispatch(loadContactUs());
  }, []);

  if (contactusdata.contactusLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }
  if (!contactusdata.contactus) {
    return (
      <div>
        <Alert variant="info">No Data. Please try again later.</Alert>
      </div>
    );
  }
  return (
    <div className="card min-content">
      <h2>Contact Forms</h2>
      <hr />
      <Table striped hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Company</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Details</th>
            <th>Response</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.agencyName ? item.agencyName : "-"}</td>
                <td>{item.email}</td>
                <td>{item.phone}</td>
                <td>{item.details}</td>
                <td>{item.responded ? "Yes" : "No"}</td>
                <td>{new Date(item.date).toDateString()}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
