import React from 'react';
import ImageModal from "./ImageModal";
import Image1 from "./assets/tasks-create.jpg";
import Image2 from "./assets/tasks-2.jpg";

const CreateTaskDocs = () => {
  const [imageShow, setImageShow] = React.useState(false);
    const [imageData, setImageData] = React.useState(null);
      const onThisPage = [
          {
            link: "#createtask",
            desc: "Create Task",
          },
          {
            link: "#descriptions",
            desc: "Descriptions",
          }
        ];
        return (
          <div className="d-flex">
            <div className="docs-sub-left-content">
              <h1 id="createtask">Create Task</h1>
              <p>
              This page will guide how to create a task.
              </p>
              
              <div className="bd-callout-info bd-callout ">
              <h5>Permissions</h5>
          <p>
            Admin, Administrator and Schedular has permission to create task.
          </p>
        </div>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">Create Task</span> on monitor tasks page.</p>
        
        

        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image1);
            setImageShow(true);
          }}
        >
          <img src={Image1} alt="create site 1" width={"100%"}></img>
        </div>

              <h2 id="descriptions">Descriptions</h2>
        <p><i class="docs-arrow"></i>Fill all the detailed on page.</p>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">Create</span> nutton to create task.</p>
        
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image2);
            setImageShow(true);
          }}
        >
          <img src={Image2} alt="create site 1" width={"100%"}></img>
        </div>
              {imageShow && imageData && (
            <ImageModal
              show={imageShow}
              onHide={() => setImageShow(false)}
              imageData={imageData}
            />
          )}
            </div>
            <div className="docs-sub-right-content">
              <div>On this page</div>
              <hr />
              <ul>
                {onThisPage.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.link}>{item.desc}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        
  );
}

export default CreateTaskDocs