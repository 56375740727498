import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
    loadUserProfile,
    addProfilePicture,
  } from "../../reducers/user";
  import Alerts from "../Alerts";
import { setAlert } from "../../reducers/alert";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";


const UpdateProfilePicture = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user);
  const user = useSelector((state) => state.auth.user);
  const [uploadfile, setUploadfile] = useState("");

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("profilePicture", uploadfile);
    formData.append("user_id", user._id);

    dispatch(
      addProfilePicture({
        formData,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(loadUserProfile({ user_id: user._id }));
        dispatch(
          setAlert({
            componentName: "updateProfilePicture",
            alertType: "success",
            msg: "Profile Picture updated. Please reload page to see updated settings.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "updateProfilePicture",
            alertType: "danger",
            msg: "Error. Please try again.",
          })
        )
      );
  };
  //handle file upload
  const onFileChange = (e) => {
    setUploadfile(e.target.files[0]);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {users.subloading && (
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      )}
      
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Change Profile Picture
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Alerts componentName={"updateProfilePicture"} />
            <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Select Profile Picture</Form.Label>
                <Form.Control type="file" onChange={onFileChange} />
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                onClick={handleRegisterSubmit}
              >
                Submit
              </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </>
      
    </Modal>
  );
};

export default UpdateProfilePicture;
