import React, { useEffect, useState } from "react";
import Alerts from "../../Alerts";
import { setAlert } from "../../../reducers/alert";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getTasksbyID,
  addTaskNote,
  updateTaskAccepted,
  updateTaskStatus,
} from "../../../reducers/tasks";

import DisplayTwoColData from "../DisplayTwoColData";
import { ImFlag } from "react-icons/im";

const TaskDetails = () => {
  let params = useParams();

  const task_id = params.task_id;
  const dispatch = useDispatch();

  const task = useSelector((state) => state.tasks?.task);
  const loading = useSelector((state) => state.tasks?.taskLoading);
  const updatenoteLoading = useSelector(
    (state) => state.tasks?.updatenoteLoading
  );
  const updateacptLoading = useSelector(
    (state) => state.tasks?.updateacptLoading
  );
  const [note, setNote] = useState("");

  const started = () => {
    dispatch(updateTaskStatus({ task_id, taskStatus: "STARTED" }))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(
          setAlert({
            componentName: "taskdetails",
            alertType: "success",
            msg: "Task status is updated.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "taskdetails",
            alertType: "danger",
            msg: "Error in Saving Data. Please try again.",
          })
        )
      );

    setNote("");
  };

  const completed = () => {
    dispatch(updateTaskStatus({ task_id, taskStatus: "COMPLETED" }))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(
          setAlert({
            componentName: "taskdetails",
            alertType: "success",
            msg: "Task is completed.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "taskdetails",
            alertType: "danger",
            msg: "Error in Saving Data. Please try again.",
          })
        )
      );

    setNote("");
  };
  const handleAccept = () => {
    dispatch(updateTaskAccepted({ task_id, accepted: true }))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(
          setAlert({
            componentName: "taskdetails",
            alertType: "success",
            msg: "Task is Accepted.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "taskdetails",
            alertType: "danger",
            msg: "Error in Saving Data. Please try again.",
          })
        )
      );

    setNote("");
  };

  const handleAddNote = () => {
    dispatch(addTaskNote({ task_id, note }))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(
          setAlert({
            componentName: "taskdetails",
            alertType: "success",
            msg: "Note is added.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "taskdetails",
            alertType: "danger",
            msg: "Error in Saving Data. Please try again.",
          })
        )
      );

    setNote("");
  };

  useEffect(() => {
    dispatch(getTasksbyID({ task_id }));
  }, []);
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }
  if (!task) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div>
          <Alert variant="info">No Tasks found.</Alert>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="card ">
        <div className="displaysites-header">
          <h2>Task Details</h2>
          <div>
            {task.accepted ? (
              task.status === "STARTED" ? (
                <>
                  <Button
                    className="button-flex-lg"
                    variant="success"
                    onClick={completed}
                  >
                    Complete
                  </Button>
                  <Button className="button-flex-lg" variant="primary" disabled>
                    Accepted
                  </Button>
                </>
              ) : task.status === "COMPLETED" ? (
                <>
                  <Button className="button-flex-lg" variant="success" disabled>
                    Complete
                  </Button>
                  <Button className="button-flex-lg" variant="primary" disabled>
                    Accepted
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="button-flex-lg"
                    variant="warning"
                    onClick={started}
                  >
                    Start
                  </Button>
                  <Button className="button-flex-lg" variant="primary" disabled>
                    Accepted
                  </Button>
                </>
              )
            ) : updateacptLoading ? (
              <Button variant="primary" disabled className="button-flex">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </Button>
            ) : (
              <Button
                className="button-flex-lg"
                variant="danger"
                onClick={handleAccept}
              >
                Accept Task
              </Button>
            )}
          </div>
        </div>

        <hr />
        <Alerts componentName={"taskdetails"} />
        <div>
          <DisplayTwoColData field={"Title"} data={task.taskTitle} />
          <DisplayTwoColData field={"Assigned By"} data={task.createdBy} />
          <DisplayTwoColData field={"Company"} data={task.agencyName} />
          <DisplayTwoColData field={"Assigned To"} data={task.assignedTo} />
          <DisplayTwoColData
            field={"Start"}
            data={new Date(task.startDate).toDateString()}
          />
          <DisplayTwoColData
            field={"Deadline"}
            data={new Date(task.deadline).toDateString()}
          />
          <div className="display-company-data">
            <div className="display-info">Priority :</div>
            <div className="display-data">
              {task.priority === "HIGH" ? (
                <span style={{ color: "red" }}>
                  <ImFlag />
                </span>
              ) : task.priority === "NORMAL" ? (
                <span style={{ color: "green" }}>
                  <ImFlag />
                </span>
              ) : (
                <span style={{ color: "yellow" }}>
                  <ImFlag />
                </span>
              )}
            </div>
          </div>
          <div className="display-company-data">
            <div className="display-info">Status :</div>
            <div className="display-data text-capitalize">
              {task.status.toLowerCase()}
            </div>
          </div>

          <div className="thin-border w-75">
            <span>{task.taskDetails}</span>
            <hr />
            {task.notes.length !== 0 ? (
              task.notes.map((note, index) => {
                return (
                  <div
                    className="d-flex justify-content-between mb-3"
                    key={index}
                  >
                    <span className="ms-3">{note.reply} </span>{" "}
                    <span className="fw-light">- {note.username}</span>
                  </div>
                );
              })
            ) : (
              <></>
            )}
            <InputGroup className="mt-5">
              <Form.Control
                placeholder="Add Note"
                aria-label="Add Note"
                aria-describedby="basic-addon2"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              {updatenoteLoading ? (
                <Button variant="outline-primary" id="button-addon2" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button
                  variant="outline-primary"
                  id="button-addon2"
                  onClick={handleAddNote}
                >
                  Add Note
                </Button>
              )}
            </InputGroup>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskDetails;
