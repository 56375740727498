import React, {useState, useEffect} from 'react';
import Button from "react-bootstrap/Button";
import { BsDownload } from "react-icons/bs";
import { PDFDownloadLink, Document, Page ,Text, View, StyleSheet,Font  } from '@react-pdf/renderer';
import { format } from "date-fns";

const Invoice = ({data, total, client, agencyprofile}) => {
    const [download, setdownload] = useState(false);
    const [subTotal, setSubTotal] = useState(0)
    const [saleTax, setSaleTax] = useState(0)
    // const initialAdditions = {
    //     description: '',
    //     quantity: '1',
    //     rate: '0.00',
    //   }
      console.log(parseFloat(total))
      const invoiceDate= {
        title: 'INVOICE',
        companyName: agencyprofile ? agencyprofile.agencyName : '',
        name: '',
        companyAddress: agencyprofile ? ((agencyprofile.unit ? agencyprofile.unit + " ": "")  + (agencyprofile.streetNumber ? agencyprofile.streetNumber : "") + " " + agencyprofile.street) :'',
        companyAddress2: agencyprofile ? (agencyprofile.city + " " + agencyprofile.provience + " " + agencyprofile.postal) :'',
        companyCountry: agencyprofile ? agencyprofile.country :'Canada',
        billTo: 'Bill To:',
        clientName: client ? client.name : '',
        clientAddress: client ? ((client.unit ? client.unit + " ": "")  + (client.streetNumber ? client.streetNumber : "") + " " + client.street) :'',
        clientAddress2: client ? (client.city + " " + client.provience + " " + client.postal) :'',
        clientCountry: client ? client.country :'Canada',
        invoiceTitleLabel: 'Invoice#',
        invoiceTitle: '',
        invoiceDateLabel: 'Invoice Date',
        invoiceDate: format(new Date(), 'MM/dd/yyyy'),
        invoiceDueDateLabel: 'Due Date',
        invoiceDueDate: '',
        productLineDescription: 'Item Description',
        productLineQuantity: 'Hours',
        productLineQuantityRate: 'Rate',
        productLineQuantityAmount: 'Amount',
        productLines: [],
        additionalLines: [],
        subTotalLabel: 'Sub Total',
        taxLabel: 'Sale Tax (13%)',
        totalLabel: 'TOTAL',
        currency: '$',
        notesLabel: 'Notes',
        notes: 'It was great doing business with you.',
        termLabel: 'Terms & Conditions',
        term: 'Please make the payment by the due date.',
      }
      const [initialInvoice, setinitialInvoice] = useState(invoiceDate)
      const handleDataInput = (e)=>{
        let {name, value} = e.target;
        const newInvoice = { ...initialInvoice };
        newInvoice[name] = value;
        setinitialInvoice(newInvoice)
      }
      useEffect(() => {
        setSubTotal(parseFloat(total))
      }, [total])

      //calulate Total amount after tax
      useEffect(() => {
        const match = initialInvoice.taxLabel.match(/(\d+)%/)
        const taxRate = match ? parseFloat(match[1]) : 0
        const saleTax = subTotal ? (parseFloat(subTotal) * taxRate) / 100 : 0
        
        setSaleTax(saleTax)
      }, [total, subTotal, initialInvoice.taxLabel])

      Font.register({
        family: 'Nunito',
        fonts: [
          { src: 'https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaE.ttf' },
          { src: 'https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUYevN.ttf', fontWeight: 600 },
        ],
      })
      //create stylesheet for pdf
      const styles = StyleSheet.create({
        dark: {
          color: '#222',
        },
      
        white: {
          color: '#fff',
        },
      
        bgdark: {
          backgroundColor: '#666',
        },
      
        bggray: {
          backgroundColor: '#e3e3e3',
        },
      
        flex: {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
        },
      
        wauto: {
          flex: 1,
          paddingRight: '8px',
        },
      
        ml30: {
          flex: 1,
        },
      
        w100: {
          width: '100%',
        },
      
        w50: {
          width: '50%',
        },
      
        w55: {
          width: '55%',
        },
      
        w45: {
          width: '45%',
        },
      
        w60: {
          width: '60%',
        },
      
        w40: {
          width: '40%',
        },
      
        w48: {
          width: '48%',
        },
      
        w17: {
          width: '17%',
        },
      
        'w18': {
          width: '18%',
        },
      
        row: {
          borderBottom: `1px solid #e3e3e3`,
        },
      
        mt40: {
          marginTop: '40px',
        },
      
        mt30: {
          marginTop: '30px',
        },
      
        mt20: {
          marginTop: '20px',
        },
      
        mt10: {
          marginTop: '10px',
        },
      
        mb5: {
          marginBottom: '5px',
        },
      
        p48: {
          padding: '4px 8px',
        },
      
        p5: {
          padding: '5px',
        },
      
        pb10: {
          paddingBottom: '10px',
        },
      
        right: {
          textAlign: 'right',
        },
      
        bold: {
          fontWeight: 'bold',
        },
      
        fs20: {
          fontSize: '20px',
        },
      
        fs45: {
          fontSize: '45px',
        },
      
        page: {
          fontFamily: 'Nunito',
          fontSize: '13px',
          color: '#555',
          padding: '40px 35px',
        },
      
        span: {
          padding: '4px 12px 4px 0',
        },
      
        logo: {
          display: 'block',
        }
      });
    //   create pdf file
    const MyDoc = () => (
        <Document>
          <Page style={styles.page}>
          
        
            <View style={styles.flex} >
                <View style={styles.w50} >
                    <Text style={[styles.fs20, styles.bold]} >{initialInvoice.companyName}</Text>
                    <Text  style={styles.input} >{initialInvoice.name} </Text>
                    <Text  style={styles.input}>{initialInvoice.companyAddress}</Text>
                    <Text  style={styles.input}>{initialInvoice.companyAddress2}</Text>
                    <Text style={styles.input}>{initialInvoice.companyCountry}</Text>
                </View>
                <View style={styles.w50}>
                    <Text style={[styles.input, styles.fs45,styles.right,styles.bold]}>{initialInvoice.title}</Text>
                </View>
            </View>
            
            <View style={[styles.flex, styles.mt40]} >
                <View style={styles.w55} >
                    <Text style={[styles.dark, styles.bold, styles.mb5]} >{initialInvoice.billTo}</Text>
                    <Text style={styles.input} >{initialInvoice.clientName} </Text>
                    <Text style={styles.input} >{initialInvoice.clientAddress}</Text>
                    <Text style={styles.input} >{initialInvoice.clientAddress2}</Text>
                    <Text style={styles.input}  >{initialInvoice.clientCountry}</Text>
                    
                </View>
                <View style={styles.w45} >
                    <View style={[styles.flex, styles.mb5]}>
                        <View style={styles.w40}>
                            <Text style={[styles.input, styles.bold]} >{initialInvoice.invoiceTitleLabel}</Text>
                        </View>
                        <View style={styles.w60}>
                            <Text style={styles.input} >{initialInvoice.invoiceTitle}</Text>
                        </View>
                    </View>
                    <View style={[styles.flex, styles.mb5]}>
                        <View style={styles.w40}>
                            <Text  style={[styles.input, styles.bold]}  >{initialInvoice.invoiceDateLabel}</Text>
                        </View>
                        <View style={styles.w60}>
                            <Text style={styles.input} >{initialInvoice.invoiceDate}</Text>
                        </View>
                    </View>
                    <View style={[styles.flex, styles.mb5]}>
                        <View style={styles.w40}>
                            <Text style={[styles.input, styles.bold]}  >{initialInvoice.invoiceDueDateLabel}</Text>
                        </View>
                        <View style={styles.w60}>
                            <Text style={styles.input} >{initialInvoice.invoiceDueDate}</Text>
                        </View>
                    </View>
                </View>
            </View>

            <View style={[styles.flex, styles.bgdark, styles.mt30]}>
                <View style={[styles.w48, styles.p48]}>
                    <Text style={[styles.input, styles.white, styles.bold]}  >{initialInvoice.productLineDescription}</Text>
                </View>
                <View style={[styles.w17, styles.p48]} >
                    <Text style={[styles.input, styles.white, styles.bold, styles.right]} >{initialInvoice.productLineQuantity}</Text>
                </View>
                <View style={[styles.w17, styles.p48]}>
                    <Text  style={[styles.input, styles.white, styles.bold, styles.right]}   >{initialInvoice.productLineQuantityRate}</Text>
                </View>
                <View  style={[styles.w18, styles.p48]}>
                    <Text  style={[styles.input, styles.white, styles.bold, styles.right]}  >{initialInvoice.productLineQuantityAmount}</Text>
                </View>
            </View>
            {/* this dic will be iterated over product items OR coverage types with rates */}
            {data ? data.map((item, index)=>{
                return(
                <View key={index} style={[styles.flex, styles.row]}>
                    <View style={[styles.w48, styles.p48]}>
                        <Text  style={[styles.span, styles.dark]}> {item.Type} </Text>
                    </View>
                    <View style={[styles.w17, styles.p48]}>
                        <Text   style={[styles.span, styles.dark, styles.right]} >{(item.Hours_Charged).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                    </View>
                    <View style={[styles.w17, styles.p48]}>
                        <Text  style={[styles.span, styles.dark, styles.right]} >{(item.Rate).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                    </View>
                    <View style={[styles.w18, styles.p48]}>
                        <Text  style={[styles.span, styles.dark, styles.right]} >{(item.Amount).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                    </View>
                 </View>
                )
            }) : <></>}
            

            <View style={styles.flex}>
                <View style={[styles.w50, styles.mt10]}>
                    {/* logic to add additional cost */}
                </View>
                <View style={[styles.w50, styles.mt20]}>
                    <View style={styles.flex}>
                        <View style={[styles.w50, styles.p5]}>
                            <Text style={styles.input}   >{initialInvoice.subTotalLabel}</Text>
                        </View>
                        <View style={[styles.w50, styles.p5]}>
                            <Text style={[styles.span, styles.dark, styles.right, styles.bold]}>{(subTotal).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                        </View>
                    </View>
                    <View style={styles.flex}>
                        <View style={[styles.w50, styles.p5]}>
                            <Text  style={styles.input} >{initialInvoice.taxLabel} </Text>
                        </View>
                        <View style={[styles.w50, styles.p5]}>
                            <Text style={[styles.span, styles.dark, styles.right, styles.bold]}>{(saleTax).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                        </View>
                    </View>
                    <View style={[styles.flex, styles.p5, styles.bggray]}>
                        <View style={[styles.w50, styles.p5]}>
                            <Text style={[styles.input, styles.bold]}  >{initialInvoice.totalLabel}</Text>
                        </View>
                        <View style={[styles.flex, styles.w50, styles.p5]}>
                            <Text style={[styles.input, styles.dark, styles.right, styles.bold, styles.ml30]} >{initialInvoice.currency}</Text>
                            <Text style={[styles.span, styles.dark, styles.right, styles.bold, styles.wauto]}>{(+subTotal + +saleTax).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                        </View>
                    </View>
                </View>
            </View>

            <View  style={styles.mt20}>
                <Text style={[styles.input, styles.bold, styles.w100]}   >{initialInvoice.notesLabel}</Text>
                <Text style={[styles.input, styles.w100]} >{initialInvoice.notes}</Text>
            </View>

            <View style={styles.mt20}>
                <Text style={[styles.input, styles.bold, styles.w100]} >{initialInvoice.termLabel} </Text>
                <Text style={[styles.input, styles.w100]} >{initialInvoice.term}</Text>
            </View>

            
        
            
        
    
          </Page>
        </Document>
      );
      console.log(total)
  return (
    <div className='card'>
    <div className='d-flex justify-content-between'>
        <div> <h2>Invoice</h2></div>
        <div>
        <PDFDownloadLink document={<MyDoc />} fileName="invoice.pdf">
            {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : <Button variant="secondary" ><BsDownload /> Download</Button>
            }
            </PDFDownloadLink>
            </div>
        </div>
    <hr />
    <div className='w-100 invoice d-flex justify-content-center'>
        <div className='invoice-wrapper'>
            <div className='d-flex'>
                <div className='xw-50'>
                    <input name="companyName" type="text" class="input xfs-20 xbold" placeholder="Your Company" value={initialInvoice.companyName}  onChange={(e)=>handleDataInput(e)}/>
                    <input name="name" type="text" class="input " placeholder="Your Name"  value={initialInvoice.name}  onChange={(e)=>handleDataInput(e)} />
                    <input name="companyAddress" type="text" class="input " placeholder="Company's Address"value={initialInvoice.companyAddress}  onChange={(e)=>handleDataInput(e)} />
                    <input name="companyAddress2" type="text" class="input " placeholder="City, State Zip" value={initialInvoice.companyAddress2}  onChange={(e)=>handleDataInput(e)} ></input>
                    <input name="companyCountry" type="text" class="input " placeholder="Country" value={initialInvoice.companyCountry}  onChange={(e)=>handleDataInput(e)} ></input>
                </div>
                <div className='xw-50'>
                    <input name="title" type="text" class="input xfs-45 xright xbold" placeholder="Invoice" value={initialInvoice.title}  onChange={(e)=>handleDataInput(e)} />
                </div>
            </div>
            <div className='d-flex xmt-40'>
                <div className='xw-55'>
                    <input name="billTo" type="text" class="input xbold xdark xmb-5" placeholder="" value={initialInvoice.billTo}  onChange={(e)=>handleDataInput(e)}  />
                    <input name="clientName" type="text" class="input " placeholder="Your Client's Name" value={initialInvoice.clientName}  onChange={(e)=>handleDataInput(e)} />
                    <input name="clientAddress" type="text" class="input " placeholder="Client's Address" value={initialInvoice.clientAddress}  onChange={(e)=>handleDataInput(e)}  />
                    <input name="clientAddress2" type="text" class="input " placeholder="City, State, Zip" value={initialInvoice.clientAddress2}  onChange={(e)=>handleDataInput(e)}  />
                    <input name="clientCountry" type="text" class="input " placeholder="Country" value={initialInvoice.clientCountry}  onChange={(e)=>handleDataInput(e)} ></input>
                    
                </div>
                <div className='xw-45'>
                    <div className='d-flex xmb-5'>
                        <div className='xw-40'>
                            <input name="invoiceTitleLabel" type="text" class="input xbold" placeholder="" value={initialInvoice.invoiceTitleLabel}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                        <div className='xw-60'>
                            <input name="invoiceTitle" type="text" class="input" placeholder="12345" value={initialInvoice.invoiceTitle}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                    </div>
                    <div className='d-flex xmb-5'>
                        <div className='xw-40'>
                            <input name="invoiceDateLabel" type="text" class="input xbold" placeholder="" value={initialInvoice.invoiceDateLabel}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                        <div className='xw-60'>
                            <input name="invoiceDate" type="text" class="input" placeholder="00/00/0000" value={initialInvoice.invoiceDate}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                    </div>
                    <div className='d-flex xmb-5'>
                        <div className='xw-40'>
                            <input name="invoiceDueDateLabel" type="text" class="input xbold" placeholder="" value={initialInvoice.invoiceDueDateLabel}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                        <div className='xw-60'>
                            <input name="invoiceDueDate" type="text" class="input" placeholder="00/00/0000" value={initialInvoice.invoiceDueDate}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-flex xbg-dark xmt-30'>
                <div className='xw-48 xp-4-8'>
                    <input name="productLineDescription" type="text" class="input xwhite xbold" placeholder="" value={initialInvoice.productLineDescription}  onChange={(e)=>handleDataInput(e)} />
                </div>
                <div className='xw-17 xp-4-8'>
                    <input name="productLineQuantity" type="text" class="input xwhite xbold xright" placeholder="" value={initialInvoice.productLineQuantity}  onChange={(e)=>handleDataInput(e)}/>
                </div>
                <div className='xw-17 xp-4-8'>
                    <input name="productLineQuantityRate" type="text" class="input xwhite xbold xright" placeholder="" value={initialInvoice.productLineQuantityRate}  onChange={(e)=>handleDataInput(e)} />
                </div>
                <div className='xw-18 xp-4-8'>
                    <input name="productLineQuantityAmount" type="text" class="input xwhite xbold xright" placeholder="" value={initialInvoice.productLineQuantityAmount}  onChange={(e)=>handleDataInput(e)} />
                </div>
            </div>
            {/* this dic will be iterated over product items OR coverage types with rates */}
            {data ? data.map((item, index)=>{
                return(
                <div key={index} className='d-flex xrow'>
                    <div className='xw-48 xp-4-8'>
                        <span  class="span xdark" > {item.Type} </span>
                    </div>
                    <div className='xw-17 xp-4-8'>
                        <span  class="span xdark xright" >{(item.Hours_Charged).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</span>
                    </div>
                    <div className='xw-17 xp-4-8'>
                        <span  class="span xdark xright" >{(item.Rate).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</span>
                    </div>
                    <div className='xw-18 xp-4-8'>
                        <span  class="span xdark xright" >{(item.Amount).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</span>
                    </div>
                 </div>
                )
            }) : <></>}
            

            <div className='d-flex'>
                <div className='xw-50 xmt-10'>
                    {/* logic to add additional cost */}
                </div>
                <div className='xw-50 xmt-20'>
                    <div className='d-flex'>
                        <div className='xw-50 xp-5'>
                            <input name="subTotalLabel" type="text" class="input " placeholder="" value={initialInvoice.subTotalLabel}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                        <div className='xw-50 xp-5'>
                            <span class="span xright xbold xdark">{(subTotal).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className='xw-50 xp-5'>
                            <input name="taxLabel" type="text" class="input " placeholder="" value={initialInvoice.taxLabel}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                        <div className='xw-50 xp-5'>
                            <span class="span xright xbold xdark">{(saleTax).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</span>
                        </div>
                    </div>
                    <div className='d-flex xbg-gray xp-5'>
                        <div className='xw-50 xp-5'>
                            <input name="totalLabel" type="text" class="input xbold" placeholder="" value={initialInvoice.totalLabel}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                        <div className='d-flex xw-50 xp-5'>
                            <input name="currency" type="text" class="input xdark xbold xright xml-30" placeholder="" value={initialInvoice.currency}  onChange={(e)=>handleDataInput(e)} />
                            <span class="span xright xbold xdark xw-auto">{(+subTotal + +saleTax).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='xmt-20'>
                <input name="notesLabel" type="text" class="input xbold xw-100" placeholder="" value={initialInvoice.notesLabel}  onChange={(e)=>handleDataInput(e)} />
                <input name="notes" type="text" class="input xw-100" placeholder="" value={initialInvoice.notes}  onChange={(e)=>handleDataInput(e)} />
            </div>

            <div className='xmt-20'>
                <input name="termLabel" type="text" class="input xbold xw-100" placeholder="" value={initialInvoice.termLabel}  onChange={(e)=>handleDataInput(e)} />
                <input name="term" type="text" class="input xw-100" placeholder="" value={initialInvoice.term}  onChange={(e)=>handleDataInput(e)} />
            </div>

            
        </div>
    </div>
    </div>
  )
}

export default Invoice