import configData from "../config/default.json";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

const API_ENDPOINT = configData.API_ENDPOINT;

const initialState = {
  contactus: [],
  contactusLoading: false,
  contactusError: null,

  emaillist: [],
  emaillistLoading: false,
  emaillistError: null,

  userslist: [],
  userslistLoading: false,
  userslistError: null,

  subLoading: false,
};

//   load contact us for admin
export const loadContactUs = createAsyncThunk("api/loadContactUs", async () => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await axios.get(
    `${API_ENDPOINT}/api/support/getcontactus`,

    config
  );
  return response.data;
});

//   load email list for admin
export const loadallemaillist = createAsyncThunk(
  "api/loadallemaillist",
  async () => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${API_ENDPOINT}/api/support/getemailist`,

      config
    );
    return response.data;
  }
);

//   load all users for admin
export const loadalluserslist = createAsyncThunk(
  "api/loadalluserslist",
  async () => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${API_ENDPOINT}/api/users/alluserslist`,

      config
    );
    return response.data;
  }
);

export const adminData = createSlice({
  name: "adminData",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadContactUs.pending, (state, action) => {
        state.contactusLoading = true;
      })
      .addCase(loadContactUs.fulfilled, (state, action) => {
        state.contactusLoading = false;
        state.contactus = action.payload;
        state.contactusError = null;
      })
      .addCase(loadContactUs.rejected, (state, action) => {
        state.contactusLoading = false;
        state.contactus = null;
        state.contactusError = action.error;
        console.log("error in loadContactUs");
        console.log(action);
      })

      .addCase(loadallemaillist.pending, (state, action) => {
        state.emaillistLoading = true;
      })
      .addCase(loadallemaillist.fulfilled, (state, action) => {
        state.emaillistLoading = false;
        state.emaillist = action.payload;
        state.emaillistError = null;
      })
      .addCase(loadallemaillist.rejected, (state, action) => {
        state.emaillistLoading = false;
        state.emaillist = null;
        state.emaillistError = action.error;
        console.log("error in loadallemaillist");
        console.log(action);
      })

      .addCase(loadalluserslist.pending, (state, action) => {
        state.userslistLoading = true;
      })
      .addCase(loadalluserslist.fulfilled, (state, action) => {
        state.userslistLoading = false;
        state.userslist = action.payload;
        state.userslistError = null;
      })
      .addCase(loadalluserslist.rejected, (state, action) => {
        state.userslistLoading = false;
        state.userslist = null;
        state.userslistError = action.error;
        console.log("error in loadalluserslist");
        console.log(action);
      });
  },
});

export default adminData.reducer;
