import React, {  useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { loadallemaillist } from "../../reducers/admindata";
import Table from "react-bootstrap/Table";

const AllEmailList = () => {
    const dispatch = useDispatch();
    const emaillistdata = useSelector((state) => state.admindata);
    const data = useSelector((state) => state.admindata.emaillist);
  
    useEffect(() => {
      dispatch(loadallemaillist());
  
    }, []);
  
    if (emaillistdata.emaillistLoading) {
      return (
        <div className="d-flex justify-content-center align-items-center h-100">
          <h3>
            <Spinner animation="border" />
            Loading...
          </h3>
        </div>
      );
    }
    if (!emaillistdata.emaillist) {
      return (
        <div>
          <Alert variant="info">No Data. Please try again later.</Alert>
        </div>
      );
    }
    return (
        <div className="card min-content">
          <h2>Email Subscribers</h2>
      <hr />
        <Table striped hover>
            <thead>
            <tr>
              <th>#</th>
              <th>Email</th>
              <th>Active</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index)=>{
                return(
                    <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.email}</td>
                  <td>{item.active ? "Yes" : "No"}</td>
                  <td>{new Date(item.date).toDateString()}</td>
                  </tr>
                )
            })}
          </tbody>
        </Table>
        </div>
      )
  }

export default AllEmailList