import configData from "../config/default.json";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

const API_ENDPOINT = configData.API_ENDPOINT;

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: false,
  user: null,
  error: null,
  authpending: true,
  changepassLoading: false,
  resendpassLoading: false,
  registerAppLoading: false,
};

export const login = createAsyncThunk(
  "api/login",
  async ({ email, password }) => {
    const body = JSON.stringify({ email, password });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/users/login`,
      body,
      config
    );
    return response.data;
  }
);

//changepass
export const changepass = createAsyncThunk(
  "api/changepass",
  async ({ oldpassword, password }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({ oldpassword, password });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/users/changepass`,
      body,
      config
    );
    return response.data;
  }
);

//resendpass
export const resendpass = createAsyncThunk(
  "api/resendpass",
  async ({ email }) => {
    const body = JSON.stringify({ email });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/users/resendpass`,
      body,
      config
    );
    return response.data;
  }
);

export const register = createAsyncThunk(
  "api/register",
  async ({ name, email, phone, password }) => {
    const body = JSON.stringify({
      name,
      email,
      phone,
      password,
      role: configData.ROLE.AGENCY_ADMIN,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/users/register`,
      body,
      config
    );
    return response.data;
  }
);

export const registerApp = createAsyncThunk(
  "api/registerApp",
  async ({ name, email, phone, password }) => {
    const body = JSON.stringify({
      name,
      email,
      phone,
      password,
      role: configData.ROLE.USER,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/users/register`,
      body,
      config
    );
    return response.data;
  }
);
export const createUser = createAsyncThunk(
  "api/createUser",
  async ({ name, email, phone, password }) => {
    const body = JSON.stringify({
      name,
      email,
      phone,
      password,
      role: configData.ROLE.USER,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/users/createuser`,
      body,
      config
    );
    return response.data;
  }
);

export const loadUser = createAsyncThunk("api/loadUser", async () => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const response = await axios.get(`${API_ENDPOINT}/api/users/getuserdata`);
  return response.data;
});

export const auth = createSlice({
  name: "login",
  initialState,
  reducers: {
    logout(state, action) {
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.error = null;
        localStorage.setItem("token", action.payload.token);
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.isAuthenticated = false;
        state.error = action.error;
        console.log("error in Login");
        console.log(action);
      })
      .addCase(register.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.error = null;
        localStorage.setItem("token", action.payload.token);
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.isAuthenticated = false;
        state.error = action.payload;
        console.log("error in register");
      })
      .addCase(registerApp.pending, (state, action) => {
        state.registerAppLoading = true;
      })
      .addCase(registerApp.fulfilled, (state, action) => {
        state.registerAppLoading = false;
      })
      .addCase(registerApp.rejected, (state, action) => {
        state.registerAppLoading = false;
        console.log("error in registerApp");
      })
      .addCase(createUser.pending, (state, action) => {
        state.registerAppLoading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.registerAppLoading = false;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.registerAppLoading = false;
        console.log("error in createUser");
      })
      .addCase(loadUser.pending, (state, action) => {
        state.loading = true;
        state.authpending = true;
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.error = null;
        state.user = action.payload;
        state.authpending = false;
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.isAuthenticated = false;
        state.error = action.payload;
        state.authpending = false;
        console.log("error in Loading User data.");
      })
      .addCase(changepass.pending, (state, action) => {
        state.changepassLoading = true;
      })
      .addCase(changepass.fulfilled, (state, action) => {
        state.changepassLoading = false;
      })
      .addCase(changepass.rejected, (state, action) => {
        state.changepassLoading = false;
      })
      .addCase(resendpass.pending, (state, action) => {
        state.resendpassLoading = true;
      })
      .addCase(resendpass.fulfilled, (state, action) => {
        state.resendpassLoading = false;
      })
      .addCase(resendpass.rejected, (state, action) => {
        state.resendpassLoading = false;
        console.log("error in resendpass.");
      });
  },
});

export const { logout } = auth.actions;
export default auth.reducer;
