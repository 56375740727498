import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DisplayTwoColData from "./DisplayTwoColData";
import { useSelector, useDispatch } from "react-redux";
import Alert from "react-bootstrap/Alert";
import EditSite from "./EditSite";
import AddressMap from "./AddressMap";

const SiteDetails = ({ site_id }) => {
  const dispatch = useDispatch();
  const [editProfile, setEditProfile] = useState(false);
  

  const sites = useSelector((state) => state.sites);
  const siteData = sites?.sites?.sites?.filter((site) => {
    return site.site_id._id === site_id;
  });
  const site = siteData !== undefined ? siteData[0].site_id : null;



  if (!site || !siteData || !sites) {
    return <Alert variant="info">No Data found. Please try again.</Alert>;
  }

  if (editProfile) {
    return (
      <div>
        <EditSite
          setEditProfile={setEditProfile}
          siteData={site}
          site_id={site_id}
        />
      </div>
    );
  }
  return (
    <>
    <div className="d-flex justify-content-between gap-5">
      <div className="flex-fill mt-4" style={{minWidth:"500px"}}>
        <div className="display-company-data justify-content-between">
          <div className="display-info-2">Site Name</div>
          <div className="display-data ">
          {site?.name}
          </div>
        </div>
        
        <div className="display-company-data">
          <div className="display-info-2">Unit</div>
          <div className="display-data">
          {site.unit ? site.unit : "-"}
          </div>
        </div>

        <div className="display-company-data">
          <div className="display-info-2">Street Number</div>
          <div className="display-data">
          {site.streetNumber ? site.streetNumber : "-"}
          </div>
        </div>
        
        <div className="display-company-data">
          <div className="display-info-2">Street</div>
          <div className="display-data">
          {site?.street}
          </div>
        </div>
        
        <div className="display-company-data">
          <div className="display-info-2">City</div>
          <div className="display-data">
          {site?.city}
          </div>
        </div>
        
        <div className="display-company-data">
          <div className="display-info-2">Provience</div>
          <div className="display-data">
          {site?.provience}
          </div>
        </div>
       
        <div className="display-company-data">
          <div className="display-info-2">Country</div>
          <div className="display-data">
          {site?.country}
          </div>
        </div>

        <div className="display-company-data">
          <div className="display-info-2">Zip</div>
          <div className="display-data">
          {site?.postal}
          </div>
        </div>
        
        <div className="display-company-data">
          <div className="display-info-2">Created By</div>
          <div className="display-data">
          {site?.createdBy}
          </div>
        </div>
        
        <div className="display-company-data">
          <div className="display-info-2">Created On</div>
          <div className="display-data">
          {new Date(site?.date).toDateString()}
          </div>
        </div>

        
      </div>
      <div className="thin-border card-no-shadow " style={{width:"630px"}}>
      {/* {`${site?.unit} ${site?.street} ${site?.city} ${site?.provience} ${site?.country}`} city={site?.city} */}
      <AddressMap address={`${site?.streetNumber} ${site?.street} ${site?.city} ${site?.provience} ${site?.country}`} city={site?.city} />
      </div>
      </div>
      <Button
          className="button-flex"
          variant="primary"
          onClick={(e) => {
            setEditProfile(true);
          }}
        >
          Edit
        </Button>
    </>
  );
};

export default SiteDetails;
