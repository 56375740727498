import React from 'react';
import ImageModal from "./ImageModal";
import Image1 from "./assets/emp-3.jpg";
import Image2 from "./assets/emp-7.jpg";

const EmployeeRoleDocs = () => {
  const [imageShow, setImageShow] = React.useState(false);
    const [imageData, setImageData] = React.useState(null);
      const onThisPage = [
          {
            link: "#employeeroles",
            desc: "Employee Roles",
          },
          {
            link: "#assign",
            desc: "Assign Role",
          }
        ];
        return (
          <div className="d-flex">
            <div className="docs-sub-left-content">
              <h1 id="employeeroles">Employee Roles</h1>
              <p>
              This Page will show how to add roles to employees.
              </p>
              
              <div className="bd-callout-info bd-callout ">
              <h5>Permissions</h5>
          <p>
            Only Admin has permission to assign roles.
          </p>
        </div>
        <p><i class="docs-arrow"></i>Employee roles are displayed on <span className="text-highlight">User Profile</span>.</p>
        
        

        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image1);
            setImageShow(true);
          }}
        >
          <img src={Image1} alt="create site 1" width={"100%"}></img>
        </div>

              <h2 id="assign">Assign Role</h2>
        <p><i class="docs-arrow"></i>Click on any role under<span className="text-highlight">User Roles</span> to assign or remove role.</p>
        
        
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image2);
            setImageShow(true);
          }}
        >
          <img src={Image2} alt="create site 1" width={"100%"}></img>
        </div>



              {imageShow && imageData && (
            <ImageModal
              show={imageShow}
              onHide={() => setImageShow(false)}
              imageData={imageData}
            />
          )}
          
            </div>

            


            <div className="docs-sub-right-content">
              <div>On this page</div>
              <hr />
              <ul>
                {onThisPage.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.link}>{item.desc}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        
  );
}

export default EmployeeRoleDocs