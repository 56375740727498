import configData from "../config/default.json";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

const API_ENDPOINT = configData.API_ENDPOINT;

const initialState = {
  tickets: [],
  ticketsLoading: false,
  ticketsError: null,
  ticket: null,
  ticketLoading: false,
  ticketError: null,
  updatestatusLoading: false,
  updatereplyLoading: false,
  ticketCount: null,
  ticketRead: null,
  subLoading: false,
};

//create ticket
export const createTicket = createAsyncThunk(
  "api/createTicket",
  async ({ ticketTitle, ticketDetails, priority }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      ticketTitle,
      ticketDetails,
      priority,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/support/createticket`,
      body,
      config
    );
    return response.data;
  }
);

// update ticket status (SOLVE) with ID
export const updateTicketStatus = createAsyncThunk(
  "api/updateTicketStatus",
  async ({ ticket_id, solved }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      solved,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/support/solve/${ticket_id}`,
      body,
      config
    );

    return response.data;
  }
);

// add reply on ticket with ID
export const addTicketReply = createAsyncThunk(
  "api/addTicketReply",
  async ({ ticket_id, reply }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      reply,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/support/reply/${ticket_id}`,
      body,
      config
    );

    return response.data;
  }
);

//   load all company tickets
export const loadAgencyTickets = createAsyncThunk(
  "api/loadAgencyTickets",
  async ({ startDate, endDate }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      startDate,
      endDate,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/support/getagencytickets`,
      body,
      config
    );
    return response.data;
  }
);

// mark ticket read
export const markTicketRead = createAsyncThunk(
  "api/markTicketRead",
  async ({ ticket_id, read }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      read,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/support/read/${ticket_id}`,
      body,
      config
    );
    return response.data;
  }
);

//get ticket by task ID
export const getTicketbyID = createAsyncThunk(
  "api/getTicketbyID",
  async ({ ticket_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const response = await axios.get(
      `${API_ENDPOINT}/api/support/getticket/${ticket_id}`
    );
    return response.data;
  }
);

// get ticket count for notification badge
export const getTicketCount = createAsyncThunk(
  "api/getTicketCount",
  async () => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const response = await axios.get(
      `${API_ENDPOINT}/api/support/getticketcount`
    );
    return response.data;
  }
);

// get all support tickets for admin
export const getAllsupportTicketsAdmin = createAsyncThunk(
  "api/getAllsupportTicketsAdmin",
  async () => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const response = await axios.get(
      `${API_ENDPOINT}/api/support/getalltickets`
    );
    return response.data;
  }
);

//contactus
export const contactus = createAsyncThunk(
  "api/contactus",
  async ({ name, agencyName, email, phone, details }) => {
    const body = JSON.stringify({
      name,
      agencyName,
      email,
      phone,
      details,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/support/contactus`,
      body,
      config
    );

    return response.data;
  }
);

//submit to emaillist
export const emaillistadd = createAsyncThunk(
  "api/emaillistadd",
  async ({ email }) => {
    const body = JSON.stringify({
      email,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/support/emaillist`,
      body,
      config
    );

    return response.data;
  }
);

export const supportData = createSlice({
  name: "supportData",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createTicket.pending, (state, action) => {})
      .addCase(createTicket.fulfilled, (state, action) => {
        state.tickets = [...state.tickets, action.payload];
        state.ticketsError = null;
      })
      .addCase(createTicket.rejected, (state, action) => {
        state.tickets = null;
        state.ticketsError = action.error;
        console.log("error in createTicket");
        console.log(action);
      })
      .addCase(updateTicketStatus.pending, (state, action) => {
        state.updatestatusLoading = true;
      })
      .addCase(updateTicketStatus.fulfilled, (state, action) => {
        state.updatestatusLoading = false;
        state.ticket = action.payload;
        state.ticketError = null;
      })
      .addCase(updateTicketStatus.rejected, (state, action) => {
        state.updatestatusLoading = false;
        state.ticketError = action.error;
        console.log("error in updateTicketStatus");
        console.log(action);
      })
      .addCase(addTicketReply.pending, (state, action) => {
        state.updatereplyLoading = true;
      })
      .addCase(addTicketReply.fulfilled, (state, action) => {
        state.updatereplyLoading = false;
        state.ticket = action.payload;
        state.ticketError = null;
      })
      .addCase(addTicketReply.rejected, (state, action) => {
        state.updatereplyLoading = false;
        state.ticketError = action.error;
        console.log("error in addTicketReply");
        console.log(action);
      })
      .addCase(loadAgencyTickets.pending, (state, action) => {
        state.ticketsLoading = true;
      })
      .addCase(loadAgencyTickets.fulfilled, (state, action) => {
        state.ticketsLoading = false;
        state.tickets = action.payload;
        state.ticketsError = null;
      })
      .addCase(loadAgencyTickets.rejected, (state, action) => {
        state.ticketsLoading = false;
        state.ticketsError = action.error;
        state.tickets = [];
        console.log("error in loadAgencyTickets");
        console.log(action);
      })
      .addCase(getAllsupportTicketsAdmin.pending, (state, action) => {
        state.ticketsLoading = true;
      })
      .addCase(getAllsupportTicketsAdmin.fulfilled, (state, action) => {
        state.ticketsLoading = false;
        state.tickets = action.payload;
        state.ticketsError = null;
      })
      .addCase(getAllsupportTicketsAdmin.rejected, (state, action) => {
        state.ticketsLoading = false;
        state.ticketsError = action.error;
        state.tickets = [];
        console.log("error in getAllsupportTicketsAdmin");
        console.log(action);
      })
      .addCase(markTicketRead.pending, (state, action) => {
        state.ticketRead = false;
      })
      .addCase(markTicketRead.fulfilled, (state, action) => {
        state.ticketRead = true;
      })
      .addCase(markTicketRead.rejected, (state, action) => {
        state.ticketRead = false;
        console.log("error in markTicketRead");
        console.log(action);
      })
      .addCase(getTicketbyID.pending, (state, action) => {
        state.ticketLoading = true;
      })
      .addCase(getTicketbyID.fulfilled, (state, action) => {
        state.ticketLoading = false;
        state.ticket = action.payload;
        state.ticketError = null;
      })
      .addCase(getTicketbyID.rejected, (state, action) => {
        state.ticketLoading = false;
        state.ticket = null;
        state.ticketError = action.error;
        console.log("error in getTicketbyID");
        console.log(action);
      })
      .addCase(getTicketCount.pending, (state, action) => {
        state.ticketCount = null;
      })
      .addCase(getTicketCount.fulfilled, (state, action) => {
        state.ticketCount = action.payload;
      })
      .addCase(getTicketCount.rejected, (state, action) => {
        state.ticketCount = null;
        console.log("error in getTicketCount");
        console.log(action);
      })
      .addCase(contactus.pending, (state, action) => {
        state.subLoading = true;
      })
      .addCase(contactus.fulfilled, (state, action) => {
        state.subLoading = false;
      })
      .addCase(contactus.rejected, (state, action) => {
        state.subLoading = false;
        console.log("error in contactus");
        console.log(action);
      })
      .addCase(emaillistadd.pending, (state, action) => {
        state.subLoading = true;
      })
      .addCase(emaillistadd.fulfilled, (state, action) => {
        state.subLoading = false;
      })
      .addCase(emaillistadd.rejected, (state, action) => {
        state.subLoading = false;
        console.log("error in emaillistadd");
        console.log(action);
      });
  },
});

export default supportData.reducer;
