import React from 'react';
import ImageModal from "./ImageModal";
import Image1 from "./assets/emp-9.jpg";

const EmployeeGnotesDocs = () => {
  const [imageShow, setImageShow] = React.useState(false);
  const [imageData, setImageData] = React.useState(null);
    const onThisPage = [
      {
        link: "#gnotes",
        desc: "Global Notes",
      },
      {
        link: "#delete",
        desc: "Delete Global Note",
      }
      ];
      return (
        <div className="d-flex">
          <div className="docs-sub-left-content">
            <h1 id="gnotes">Global Notes</h1>
            <p>
            Global notes are visible to everyone, including whoever has access to Alpha database.
            </p>
            
            <div className="bd-callout-info bd-callout ">
            <h5>Permissions</h5>
        <p>
          Admin, Administrator and Schedular has permission to add global note.
        </p>
      </div>
      <p><i class="docs-arrow"></i>Click on third tab<span className="text-highlight">Notes</span> to access global notes.</p>
      <p><i class="docs-arrow"></i>New notes can be added on same page.</p>
      
      

      <div
        className="docs-image-lg"
        onClick={() => {
          setImageData(Image1);
          setImageShow(true);
        }}
      >
        <img src={Image1} alt="create site 1" width={"100%"}></img>
      </div>

      <h2 id="delete">Delete Global Note</h2>
        <p><i class="docs-arrow"></i>Global notes are permanent and can not be deleted.</p>
        {imageShow && imageData && (
          <ImageModal
            show={imageShow}
            onHide={() => setImageShow(false)}
            imageData={imageData}
          />
        )}
        
          </div>
          


          <div className="docs-sub-right-content">
            <div>On this page</div>
            <hr />
            <ul>
              {onThisPage.map((item, index) => {
                return (
                  <li key={index}>
                    <a href={item.link}>{item.desc}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      
);
}

export default EmployeeGnotesDocs