// PrivateRoute.tsx in v6
import { useSelector } from "react-redux";
import { Navigate, Route, useLocation } from "react-router-dom";
import UserRoles from "./config/default.json";
import NoAccess from "./NoAccess";
import Spinner from "react-bootstrap/Spinner";

const PrivateRoute = ({ role, children }) => {
  let location = useLocation();

  const user = useSelector((state) => state.auth);

  //check roles
  let isAuthorized = user?.user?.roles.some((ele) => role.includes(ele));

  if (!user.token && !user.isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  if (user.authpending) {
    return (
      <div>
        <div class="d-flex align-items-center justify-content-center vh-100">
          <div class="text-center">
            <h3>
              <Spinner animation="border" />
              Checking Permission...
            </h3>
          </div>
        </div>
      </div>
    );
  }

  if (!user.isAuthenticated) {
    console.log("2", user);
    return <Navigate to="/" state={{ from: location }} />;
  }
  if (isAuthorized) {
    console.log("match", role);
    return children;
  }

  return <NoAccess />;
};

export default PrivateRoute;
