import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Alerts from "../../Alerts";
import { setAlert } from "../../../reducers/alert";
import { useSelector, useDispatch } from "react-redux";
import { createTask } from "../../../reducers/tasks";
import DatePicker from "react-datepicker";
import { loadAgencyUsers } from "../../../reducers/users";

import Button from "react-bootstrap/Button";

const CreateTask = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const loading = useSelector((state) => state.tasks?.tasksLoading);
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDetails, setTaskDetails] = useState("");
  const [priority, setPriority] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [assignedToId, setAssignedToId] = useState("");
  const [department, setDepartment] = useState("USER");
  const [startDate, setDtartDate] = useState(new Date());
  const [deadline, setDeadline] = useState(new Date());

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !taskTitle ||
      !taskDetails ||
      !priority ||
      !assignedTo ||
      !assignedToId ||
      !startDate ||
      !deadline ||
      !department
    ) {
      dispatch(
        setAlert({
          componentName: "createtask",
          alertType: "danger",
          msg: "Please fill all fields.",
        })
      );
      return console.log("please input all fields");
    }
    dispatch(
      createTask({
        taskTitle,
        taskDetails,
        priority,
        assignedTo,
        assignedToId,
        startDate,
        deadline,
        department,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(
          setAlert({
            componentName: "createtask",
            alertType: "success",
            msg: "Task is created.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "createtask",
            alertType: "danger",
            msg: "Error in Saving Data. Please try again.",
          })
        )
      );

    setTaskTitle("");
    setTaskDetails("");
    setPriority("");
    setAssignedTo("");
    setAssignedToId("");
  };

  useEffect(() => {
    dispatch(loadAgencyUsers());
  }, []);
  return (
    <>
      <div className="card ">
        <div className="displaysites-header">
          <h2>Create Task</h2>
        </div>

        <hr />
        <Alerts componentName={"createtask"} />
        <div className="task-create-task">
          <Form.Group
            className="mb-3 d-flex gap-5 align-items-center"
            controlId="formtext"
          >
            <div className="w-50">
              <Form.Label>Title :</Form.Label>
            </div>
            <Form.Control
              type="text"
              placeholder="Task Title"
              value={taskTitle}
              onChange={(e) => setTaskTitle(e.target.value)}
            />
          </Form.Group>

          <div className="mb-3 d-flex gap-5 align-items-center">
            <span className="w-50">Date :</span>
            <DatePicker
              selected={startDate}
              onChange={(date) => setDtartDate(date)}
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="mb-3 d-flex gap-5 align-items-center">
            <span className="w-50">Deadline :</span>
            <DatePicker
              selected={deadline}
              onChange={(date) => setDeadline(date)}
              minDate={startDate}
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <Form.Group
            className="mb-3 d-flex gap-5 align-items-center"
            controlId="formtext"
          >
            <div className="w-50">
              <Form.Label>Priority :</Form.Label>
            </div>
            <Form.Select
              aria-label="Default select example"
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
            >
              <option>Select</option>
              <option value="HIGH">High</option>
              <option value="NORMAL">Normal</option>
              <option value="LOW">Low</option>
            </Form.Select>
          </Form.Group>

          {/* Select users */}

          <Form.Group
            className="mb-3 d-flex gap-5 align-items-center"
            controlId="formtext"
          >
            <div className="w-50">
              <Form.Label>Assign to :</Form.Label>
            </div>
            <Form.Select
              aria-label="Default select example"
              value={assignedToId + "_" + assignedTo}
              onChange={(e) => {
                const myArray = e.target.value.split("_");
                setAssignedToId(myArray[0]);
                setAssignedTo(myArray[1]);
              }}
            >
              <option>Select</option>

              {users.users ? (
                users.users[0].employees.map((user, index) => {
                  return (
                    <option
                      value={user.user_id._id + "_" + user.user_id.username}
                    >
                      {user.user_id.username}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </Form.Select>
          </Form.Group>

          {/* Select users End */}
          <Form.Group
            className="mb-3 d-flex gap-5 align-items-center"
            controlId="formtext"
          >
            <div className="w-50">
              <Form.Label>Details :</Form.Label>
            </div>
            <Form.Control
              as="textarea"
              placeholder="Task Details"
              value={taskDetails}
              onChange={(e) => setTaskDetails(e.target.value)}
            />
          </Form.Group>
          {loading ? (
            <Button variant="primary" disabled className="button-flex">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <>
              <Button
                variant="primary"
                type="submit"
                className="button-flex"
                onClick={handleSubmit}
              >
                Create
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateTask;
