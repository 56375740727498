import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import Login from "./components/Login";
import Register from "./components/Register";
import Contactus from "./components/Contactus";
import AOS from "aos";
import "aos/dist/aos.css";
import Plx from "react-plx";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Accordion from "react-bootstrap/Accordion";
import {
  BsGeoAltFill,
  BsWifi,
  BsFillCheckSquareFill,
  BsFillHandThumbsUpFill,
  BsCheck2Square,
  BsArrowRight,
  BsArrowLeft,
  BsCalendarDate,
} from "react-icons/bs";
import { AiOutlineUsergroupAdd, AiOutlineFileSearch } from "react-icons/ai";
import { BiTask } from "react-icons/bi";
import { MdSupportAgent } from "react-icons/md";
import configData from "./config/default.json";
import FeatureImage from "./uploads/support.jpg";
import Dashboard from "./uploads/Dashboard.jpg";
import FeatureImage2 from "./uploads/tasks.jpg";
import FeatureImage3 from "./uploads/map.jpg";
import FeatureImage4 from "./uploads/pie-chart.jpg";
import ShowcaseImage from "./uploads/feature-shift.jpg";
import ShowcaseImage3 from "./uploads/documentation.jpg";
import ShowcaseImage2 from "./uploads/feature-support.jpg";
import { useLocation } from "react-router-dom";

import MainMenu from "./MainMenu";
import Footer from "./Footer";
import Cta from "./Cta";
import ReactGA from "react-ga4";


function App() {
  ReactGA.initialize("G-0WH2CQ5CBV");
  // Send pageview with a custom path
  ReactGA.send({ hitType: "pageview", page: "/homePage" });
  const location = useLocation()
  const [loginShow, setLoginShow] = useState(false);
  const [registerShow, setRegisterShow] = useState(false);
  const [contactShow, setContactShow] = useState(false);
  const [showCase, setShowCase] = useState(1);
  const [reviews, setreviews] = useState(1);
  // plans
  const [yearly, setYearly] = useState(false);
  const [plan, setplan] = useState(null);
  const [planName, setplanName] = useState(null);
  const [price, setprice] = useState(null);
  const [show, setShow] = useState(true);
  const [showselect, setshowselect] = useState(false);

  console.log(process.env.NODE_ENV)
  //const [scrolled, setScrolled] = React.useState(false);
  let bounds;
  const inputRef = useRef();
  const priceRef = useRef();
  const handleReviewNextClick = () => {
    setreviews((prev) => (prev === 2 ? 1 : 2));
  };
  const handleReviewPrevClick = () => {
    setreviews((prev) => (prev === 1 ? 2 : 1));
  };

  // sticky header on scrollbar
  // const handleScroll = () => {
  //   const offset = window.scrollY;
  //   if (offset > 50) {
  //     setScrolled(true);
  //   } else {
  //     setScrolled(false);
  //   }
  // };

  const parallaxData = [
    {
      start: 200,
      end: 1000,
      properties: [
        {
          startValue: 0,
          endValue: 60,
          duration: 1000,
          property: "translateX",
        },
        {
          startValue: 0,
          endValue: -100,
          duration: 1000,
          property: "translateY",
        },
      ],
      easing: "easeInOut",
    },
  ];
  const parallaxData2 = [
    {
      start: 200,
      end: 1000,
      properties: [
        {
          startValue: 0,
          endValue: -60,
          duration: 1000,
          property: "translateX",
        },
        {
          startValue: 0,
          endValue: -100,
          duration: 1000,
          property: "translateY",
        },
      ],
      easing: "easeInOut",
    },
  ];
  const parallaxData3 = [
    {
      start: 200,
      end: 1000,
      properties: [
        {
          startValue: 0,
          endValue: 60,
          duration: 1000,
          property: "translateX",
        },
        {
          startValue: 0,
          endValue: 100,
          duration: 1000,
          property: "translateY",
        },
      ],
      easing: "easeInOut",
    },
  ];
  const parallaxData4 = [
    {
      start: 200,
      end: 1000,
      properties: [
        {
          startValue: 0,
          endValue: -60,
          duration: 1000,
          property: "translateX",
        },
        {
          startValue: 0,
          endValue: 100,
          duration: 1000,
          property: "translateY",
        },
      ],
      easing: "easeInOut",
    },
  ];
  const rotateToMouse = (e) => {
    bounds = inputRef.current.getBoundingClientRect();
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const leftX = mouseX - bounds.x;
    const topY = mouseY - bounds.y;
    const center = {
      x: leftX - bounds.width / 2,
      y: topY - bounds.height / 2,
    };
    const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

    inputRef.current.style.transform = `
      scale3d(1.07, 1.07, 1.07)
      rotate3d(
        ${center.y / 100},
        ${-center.x / 100},
        0,
        ${Math.log(distance) * 2}deg
      )
    `;
  };
  const removeListener = (e) => {
    inputRef.current.style.transform = "";
    inputRef.current.style.background = "";
  };

  useEffect(() => {
    //init AOS
    AOS.init();

    // window.addEventListener("scroll", handleScroll); //sticky header on scrollbar
  },[]);
  return (
    <div className="App">
      {/* hero section */}
      <MainMenu />
      <Login show={loginShow} onHide={() => setLoginShow(false)} />
          <Register show={registerShow} onHide={() => setRegisterShow(false)} />
          <Contactus show={contactShow} onHide={() => setContactShow(false)} />
      <div className="header-container">
      
        <div className="container hero-section" id="home">
          
          <div className="container">
            <div className="header-content-section">
              <div className="left-content pe-2">
                <h1
                  className="display-5 fw-bold lh-2 mb-3"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="20"
                  data-aos-easing="ease-in-out"
                >
                  Fastest Employee Scheduling Software
                </h1>
                <p
                  className="lead pe-1"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="100"
                  data-aos-easing="ease-in-out"
                >
                  Alpha scheduling is fastest employee scheduling software. Alpha
                  software is easy to use and has a user friendly interface with
                  the ability to schedule and track your employees in real time.
                  Try us for a month!
                </p>
                <div
                  className="d-flex justify-content-start align-items-center gap-3 mt-2"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="200"
                  data-aos-easing="ease-in-out"
                >
                  <div
                    className="shaddow-button py-3 px-5 button-font"
                    onClick={() => setRegisterShow(!registerShow)}
                  >
                    Try Free
                  </div>
                  <div
                    className="shaddow-outline-button py-3 px-5 button-font"
                    onClick={() => setRegisterShow(!registerShow)}
                  >
                    Sign Up
                  </div>
                </div>
              </div>
              <div
                className="right-content"
                data-aos="fade-left"
                data-aos-duration="20"
                data-aos-delay="100"
                data-aos-easing="ease-in-out"
              >
                <div
                  ref={inputRef}
                  className="hero-card"
                  onMouseLeave={removeListener}
                  onMouseMove={rotateToMouse}
                >
                  <div className="phone-image-container">
                    <div className="mobile-phone">
                      <div className="brove">
                        <span className="speaker"></span>
                      </div>
                      <div className="screen"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
        </div>
        <div class="hero-wave">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </div>

      {/* hero section end */}

      {/* Feature #1 */}
      <div className="feature-container" id="app">
        <div className="container feature-section">
          <div className="container">
            <div
              className="d-flex flex-column justify-content-start align-items-center"
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="200"
              data-aos-offset="10"
              data-aos-easing="ease-in-out"
            >
              <div>
                <h2 className="display-5 fw-bold lh-1 mb-3">Why Alpha?</h2>
              </div>
              <div>
                <h3 className="lead">
                  The only scheduling software you will need.
                </h3>
              </div>
            </div>
            <div className="feature-section-content d-flex justify-content-between align-items-center gap-2 my-5">
              <div className="feature-left-content d-flex align-items-center">
                <div className="feature-phone-image-container">
                  <div
                    class="phone-graphic"
                    data-aos="fade-right"
                    data-aos-duration="20"
                    data-aos-easing="ease-in-out"
                  >
                    <div class="phone-case">
                      <div class="phone-container-outer">
                        <div class="phone-container-inner"></div>
                      </div>
                    </div>
                    <div className="floating-card-1 ">
                      <Plx
                        className="MyAwesomeParallax"
                        parallaxData={parallaxData}
                      >
                        <div className="landing-card-1">
                          <BsGeoAltFill />
                        </div>
                      </Plx>
                    </div>
                    <div className="floating-card-2 ">
                      <Plx
                        className="MyAwesomeParallax"
                        parallaxData={parallaxData2}
                      >
                        <div className="landing-card-1">
                          <BsWifi />
                        </div>
                      </Plx>
                    </div>
                    <div className="floating-card-3 ">
                      <Plx
                        className="MyAwesomeParallax"
                        parallaxData={parallaxData3}
                      >
                        <div className="landing-card-1">
                          <BsFillCheckSquareFill />
                        </div>
                      </Plx>
                    </div>
                    <div className="floating-card-4 ">
                      <Plx
                        className="MyAwesomeParallax"
                        parallaxData={parallaxData4}
                      >
                        <div className="landing-card-1">
                          <BsFillHandThumbsUpFill />
                        </div>
                      </Plx>
                    </div>
                  </div>
                </div>
              </div>
              <div className="feature-right-content">
                <h1
                  className="display-5 fw-bold lh-1 mb-3"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="20"
                  data-aos-easing="ease-in-out"
                >
                  Client Mobile App
                </h1>
                <p
                  className="lead"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="100"
                  data-aos-easing="ease-in-out"
                >
                  Schedule your remote employees with Alpha Scheduling software.
                  If you have a team that is spread out, alpha will help keep
                  track of your remote team. Our mobile app is loaded with
                  powerfull features.
                </p>
                <div
                  className="d-flex flex-column justify-content-start lead my-4"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="150"
                  data-aos-easing="ease-in-out"
                >
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>Complete Tasks on App</strong>
                    </span>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>GPS Tracking</strong>
                    </span>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>Apply for Available Shifts</strong>
                    </span>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>Track Working Hours</strong>
                    </span>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-start align-items-center gap-3"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="200"
                  data-aos-easing="ease-in-out"
                >
                  <div
                    className="shaddow-button py-3 px-5 button-font"
                    onClick={() => setRegisterShow(!registerShow)}
                  >
                    Try Free
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Feature #1 End*/}

      {/* Feature #2 */}
      <div className="feature-container-2 " id="desktop">
        <div className="container feature-section ">
          <div className="container ">
            <div className="feature2-section-content d-flex justify-content-between align-items-center gap-2 my-5">
              <div className="feature-left-content">
                <h1
                  className="display-5 fw-bold lh-2 mb-3"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="20"
                  data-aos-easing="ease-in-out"
                >
                  Powerful Scheduling Software for Businesses
                </h1>
                <p
                  className="lead"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="100"
                  data-aos-easing="ease-in-out"
                >
                  Alpha scheduling software keeps your business operations in
                  check with visual information right on your fingertips.
                  Multiple dashboard with smart tracking allows businesses to
                  stay on top of important tasks.
                </p>
                <div
                  className="d-flex flex-column justify-content-start lead my-4"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="150"
                  data-aos-easing="ease-in-out"
                >
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>Employee Management</strong>
                    </span>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>Task Management</strong>
                    </span>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>Smart Tracking With Geofencing</strong>
                    </span>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>24/7 Support</strong>
                    </span>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-start align-items-center gap-3"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="200"
                  data-aos-easing="ease-in-out"
                >
                  <div
                    className="shaddow-button py-3 px-5 button-font"
                    onClick={() => setRegisterShow(!registerShow)}
                  >
                    Try Free
                  </div>
                </div>
              </div>
              <div className="feature2-right-content d-flex align-items-center">
                <div
                  className="feature-2-image-container feature-2-img-1"
                  data-aos="fade-left"
                  data-aos-duration="20"
                  data-aos-delay="100"
                  data-aos-easing="ease-in-out"
                >
                  <img
                    src={ShowcaseImage}
                    alt="feature2-1"
                    height="450px"
                    width="650px"
                  />
                </div>
                <div
                  className="feature-2-image-container feature-2-img-2"
                  data-aos="fade-left"
                  data-aos-duration="20"
                  data-aos-delay="400"
                  data-aos-easing="ease-in-out"
                >
                  <img
                    src={FeatureImage3}
                    alt="feature2-1"
                    height="256px"
                    width="400px"
                  />
                </div>
                <div
                  className="feature-2-image-container feature-2-img-3"
                  data-aos="fade-left"
                  data-aos-duration="20"
                  data-aos-delay="700"
                  data-aos-easing="ease-in-out"
                >
                  <img
                    src={FeatureImage4}
                    alt="feature2-1"
                    height="301px"
                    width="223px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Feature #2 End*/}

      {/* Showcase */}
      <div className="showcase-container" id="showcase">
        <div className="container showcase-section py-3">
          <div className="container">
            <div
              className="d-flex flex-column justify-content-start align-items-center mt-5"
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="10"
              data-aos-easing="ease-in-out"
            >
              <div>
                <h2 className="display-5 fw-bold lh-1 my-3">Showcase</h2>
              </div>
              <div>
                <h3 className="lead">Revolutionize your business.</h3>
              </div>
            </div>
            <div
              className="showcase-menu d-flex justify-content-center align-items-center"
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="100"
              data-aos-easing="ease-in-out"
            >
              <div className="showcase-buttons w-70 d-flex justify-content-between align-items-center py-5">
                <div className="showcase-buttons-container">
                  <span
                    className={
                      showCase === 1 ? "shaddow-button py-3 px-5" : "py-3 px-5"
                    }
                    onClick={() => setShowCase(1)}
                  >
                    Scheduling
                  </span>
                </div>
                <div className="showcase-buttons-container">
                  <span
                    className={
                      showCase === 2 ? "shaddow-button py-3 px-5" : "py-3 px-5"
                    }
                    onClick={() => setShowCase(2)}
                  >
                    Support
                  </span>{" "}
                </div>
                <div className="showcase-buttons-container">
                  <span
                    className={
                      showCase === 3 ? "shaddow-button py-3 px-5" : "py-3 px-5"
                    }
                    onClick={() => setShowCase(3)}
                  >
                    Dashboard
                  </span>{" "}
                </div>
                <div className="showcase-buttons-container">
                  <span
                    className={
                      showCase === 4 ? "shaddow-button py-3 px-5" : "py-3 px-5"
                    }
                    onClick={() => setShowCase(4)}
                  >
                    Tasks
                  </span>{" "}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="showcase-images-container"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="150"
                data-aos-easing="ease-in-out"
              >
                <img
                  src={ShowcaseImage}
                  alt="showcase1"
                  width="100%"
                  className={
                    showCase === 1 ? "showcase-visible" : "showcase-hidden"
                  }
                />

                <img
                  src={FeatureImage}
                  alt="showcase"
                  width="100%"
                  className={
                    showCase === 2 ? "showcase-visible" : "showcase-hidden"
                  }
                />

                <img
                  src={Dashboard}
                  alt="showcase"
                  width="100%"
                  className={
                    showCase === 3 ? "showcase-visible" : "showcase-hidden"
                  }
                />

                <img
                  src={FeatureImage2}
                  alt="showcase1"
                  width="100%"
                  className={
                    showCase === 4 ? "showcase-visible" : "showcase-hidden"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Showcase End */}

      {/* Services */}
      <div className="services-container" id="features">
        <div className="container services-section py-5">
          <div className="servicess-section container d-flex flex-column justify-content-center align-items-center">
            <div
              className="d-flex flex-column justify-content-start align-items-center mt-5 mb-3"
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="10"
              data-aos-easing="ease-in-out"
            >
              <div>
                <h2 className="display-5 fw-bold lh-1 my-3">Features</h2>
              </div>
              <div>
                <h3 className="lead">Why is it so great?</h3>
              </div>
            </div>

            <div
              class="row g-5 pt-5"
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="200"
              data-aos-easing="ease-in-out"
            >
              <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                  <div class="icon">
                    <BsCalendarDate />
                  </div>
                  <div class="feature-content">
                    <h2>Scheduling</h2>
                    <p className="lead">
                      Alpha scheduling is fastest scheduling software, loaded
                      with tons of features to meet business needs.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                  <div class="icon">
                    <BsGeoAltFill />
                  </div>
                  <div class="feature-content">
                    <h2>Attendance</h2>
                    <p className="lead">
                      Monitor remote employees attendance with location tags.
                      Smart tracking will make sure employee is at right
                      location.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                  <div class="icon">
                    <MdSupportAgent />
                  </div>
                  <div class="feature-content">
                    <h2>24/7 Support</h2>
                    <p className="lead">
                      Help is available 24 hours 7 days with integrated support
                      system. We will always be there when you need us.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                  <div class="icon">
                    <AiOutlineUsergroupAdd />
                  </div>
                  <div class="feature-content">
                    <h2>Employee Management</h2>
                    <p className="lead">
                      Employee management module makes staff management quick
                      and easy. Alpha database will give you access to new
                      profiles.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                  <div class="icon">
                    <BiTask />
                  </div>
                  <div class="feature-content">
                    <h2>Task Management</h2>
                    <p className="lead">
                      Create, assigned, track tasks with just few clicks.
                      Monitor tasks status with powerfull admin dashboards.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                  <div class="icon">
                    <AiOutlineFileSearch />
                  </div>
                  <div class="feature-content">
                    <h2>Documentation</h2>
                    <p className="lead">
                      Access to full alpha documentation. Find how-to content,
                      training, and technical articles all in one place.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services End */}
      {/* Reviews */}
      <div className="testimonial-container" id="reviews">
        <div className="container testimonial-section py-5">
          <div className="testimonials-section container d-flex flex-column justify-content-center align-items-center">
            <div
              className="d-flex flex-column justify-content-start align-items-center my-5"
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="10"
              data-aos-easing="ease-in-out"
            >
              <div>
                <h2 className="display-5 fw-bold lh-1 my-3">Reviews</h2>
              </div>
              <div>
                <h3 className="lead">What our clients says about us</h3>
              </div>
            </div>
            <div
              className="slider__inner my-5 "
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="100"
              data-aos-easing="ease-in-out"
            >
              <span
                className="testimonital-rightarrow"
                onClick={handleReviewNextClick}
              >
                <BsArrowRight />
              </span>
              <span
                className="testimonital-leftarrow"
                onClick={handleReviewPrevClick}
              >
                <BsArrowLeft />
              </span>
              {reviews === 1 && (
                <div className="slider__contents d-flex flex-column justify-content-between align-items-center h-100 py-5">
                  <quote>&rdquo;</quote>
                  <p className="slider__txt lead">
                    It's easy to use. I will rate support 10/10, we get answers
                    within minutes that works. Alpha scheduling team added custom
                    features for us that helped our company grow faster.
                  </p>
                  <h2 className="slider__caption">Param | GPA Construction</h2>
                </div>
              )}
              {reviews === 2 && (
                <div className="slider__contents d-flex flex-column justify-content-between align-items-center h-100 py-5">
                  <quote>&rdquo;</quote>
                  <p className="slider__txt lead">
                    With Alpha Scheduling, we are able and happy to manage our
                    remote employees. Shift brocasting reduced workload by half.
                    It's best scheduling software we have used so far.
                  </p>
                  <h2 className="slider__caption">Sam | BlueHill Mgmt</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Reviews End */}

      {/* Pricing */}
      <div className="pricing-container"  ref={priceRef}>
        <div className="container pricing-section py-5">
          <div id="pricing" className="pricings-section container d-flex flex-column justify-content-center align-items-center">
            <div
              className="d-flex flex-column justify-content-start align-items-center"
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="10"
              data-aos-easing="ease-in-out"
            >
              <div>
                <h2 className="display-5 fw-bold lh-1 mb-3">Pricing</h2>
              </div>
              <div>
                <h3 className="lead">Transparent Pricing</h3>
              </div>
            </div>
            <div
              className="d-flex justify-content-center my-5"
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="50"
              data-aos-easing="ease-in-out"
            >
              <ButtonGroup aria-label="Basic example">
                <Button
                  variant={yearly ? "secondary" : "primary"}
                  onClick={() => setYearly(false)}
                >
                  Monthly
                </Button>
                <Button
                  variant={yearly ? "primary" : "secondary"}
                  onClick={() => setYearly(true)}
                >
                  Yearly{" "}
                </Button>
              </ButtonGroup>
            </div>
            <div className="pricing-cards-container d-flex justify-content-between gap-5 w-100">
              <div
                className="card p-0 pricing-cards text-center"
                data-aos="fade-right"
                data-aos-duration="20"
                data-aos-delay="200"
                data-aos-easing="ease-in-out"
              >
                <div className="card-header">
                  <h4 className="my-2 font-weight-normal">Basic</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">
                    $
                    {yearly
                      ? configData.PLAN.TIER1_YEAR_PRICE
                      : configData.PLAN.TIER1_PRICE}
                  </h1>
                  <div className="text-muted">
                    /{yearly ? "year" : "month"} /user
                  </div>
                  <ul class="list-unstyled pt-4">
                    <li class="list-group-item">Employee Management</li>
                    <li class="list-group-item">Access to Alpha DB</li>
                    <li class="list-group-item">Local Shift Broadcast</li>
                    <li class="list-group-item">Multiple User Roles</li>
                    <li class="list-group-item">
                      Geofencing - Medium accuracy
                    </li>
                    <li class="list-group-item">Upto 20 employees</li>
                    <li class="list-group-item">24/7 Support</li>
                  </ul>
                </div>

                <div
                  className="shaddow-button py-3 px-5 m-3"
                  variant="primary"
                  size="lg"
                  onClick={() => setRegisterShow(!registerShow)}
                >
                  Try free for 30 days
                </div>
              </div>
              <div
                className="card p-0 pricing-cards text-center"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="200"
                data-aos-easing="ease-in-out"
              >
                <div className="card-header">
                  <h4 className="my-2 font-weight-normal">Pro</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">
                    $
                    {yearly
                      ? configData.PLAN.TIER2_YEAR_PRICE
                      : configData.PLAN.TIER2_PRICE}
                  </h1>
                  <div className="text-muted">
                    /{yearly ? "year" : "month"} /user
                  </div>
                  <ul class="list-unstyled pt-4">
                    <li class="list-group-item">Everything in Basic</li>
                    <li class="list-group-item">Global Shift Broadcast</li>
                    <li class="list-group-item">Geofencing - High accuracy</li>
                    <li class="list-group-item">Task Management</li>
                    <li class="list-group-item">Upto 500 employees</li>
                    <li class="list-group-item">1 Custom Feature</li>
                  </ul>
                </div>

                <div
                  className="shaddow-button py-3 px-5 m-3"
                  variant="primary"
                  size="lg"
                  onClick={() => setRegisterShow(!registerShow)}
                >
                  Try free for 30 days
                </div>
              </div>
              <div
                className="card p-0 pricing-cards text-center"
                data-aos="fade-left"
                data-aos-duration="20"
                data-aos-delay="200"
                data-aos-easing="ease-in-out"
              >
                <div className="card-header">
                  <h4 className="my-2 font-weight-normal">Enterprice</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">Contact Us</h1>
                  <div className="text-muted">custom pricing</div>
                  <ul class="list-unstyled pt-4">
                    <li class="list-group-item">Everything in Basic & Pro</li>
                    <li class="list-group-item">5 Custom features</li>
                    <li class="list-group-item">Unlimited employees</li>
                  </ul>
                </div>

                <div
                  className="shaddow-outline-button py-3 px-5 m-3"
                  variant="primary"
                  size="lg"
                  onClick={() => setContactShow(!contactShow)}
                >
                  Contact Sales
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pricing End */}

      {/* FAQ */}
      <div className="faq-container" id="faq">
        <div className="container faqq-section ">
          <div className="faqs-section container d-flex flex-column justify-content-center align-items-center">
            <div
              className="d-flex flex-column justify-content-start align-items-center"
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="10"
              data-aos-easing="ease-in-out"
            >
              <div>
                <h2 className="display-5 fw-bold lh-1 mb-3">
                  FREQUENTLY ASKED QUESTIONS
                </h2>
              </div>
              <div>
                <h3 className="lead">Got questions? Find your answere here</h3>
              </div>
            </div>
            <div
              className="faq-sub-container my-5"
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="100"
              data-aos-easing="ease-in-out"
            >
              <Accordion defaultActiveKey={["0"]}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    How do I start my free trial?
                  </Accordion.Header>
                  <Accordion.Body className="lead">
                    We offer free 30 days trial. No credit card is required for trial. You can try alpha scheduling
                    for free by signing up for trial. Click on Try Free button
                    and create an account to start your trial. After creating an account you will
                    be prompt to setup your company's profile. You will also
                    need to complete your personal profile. These steps are
                    required as this information is required in order to run
                    alpha scheduling smoothly. Once everything is setup you will
                    be able to see your active trial plan on plans page.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    What are the cancelation policies?
                  </Accordion.Header>
                  <Accordion.Body className="lead">
                    Your plan can be cancelled anytime in the “Plans” section of
                    your alpha scheduling account. There will be no cancellation
                    fee. You can cancel anytime with just one click.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Do I have to pay for mobile app as well?
                  </Accordion.Header>
                  <Accordion.Body className="lead">
                    Out Client Mobile App can we downloaded for free. We do not
                    charge for app from clients. If you don't have any active
                    plan in your account, some features of mobile app will not
                    be accessible. Make sure you have active plan on your
                    account to use all features of app.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>What if I need help?</Accordion.Header>
                  <Accordion.Body className="lead">
                    You will have access to Support Tickets System in your
                    account. If you need help at any point with anything, just
                    create a new ticket in support center and we will get in
                    touch within 24 hours. If you need help right away due to
                    nature of problem you can create support ticket with high
                    priority and we will reach out to you within few hours.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    Alpha scheduling fits our businees need but we need more
                    features
                  </Accordion.Header>
                  <Accordion.Body className="lead">
                    We add new features regulary. If you think alpha scheduling
                    fits your organization needs but you need custom features
                    according to your business requirements, please contact our
                    sales team. Customer satisfaction is our first priority. We
                    have team of dedicated software developers who can help with
                    any businees needs.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      {/* FAQ End */}

      <Cta />
      <Footer />
    </div>
  );
}

export default App;
