import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Alerts from "../Alerts";
import { setAlert } from "../../reducers/alert";
import { useSelector, useDispatch } from "react-redux";
import { updateSite, loadAgencySites } from "../../reducers/sites";
import { OpenStreetMapProvider } from "leaflet-geosearch";

const EditSite = ({ setEditProfile, siteData, site_id }) => {
  const dispatch = useDispatch();
  const sites = useSelector((state) => state.sites);
  const provider = new OpenStreetMapProvider();

  const [name, setName] = useState(siteData.name ? siteData.name : "");
  const [geox, setGeox] = useState(null);
  const [geoy, setGeoy] = useState(null);
  const [validate, setValidate] = useState(false);
  const [validating, setValidating] = useState(false);
  const [streetNumber, setStreetNumber] = useState(
    siteData.streetNumber ? siteData.streetNumber : ""
  );
  const [postal, setPostal] = useState(siteData.postal ? siteData.postal : "");
  const [unit, setUnit] = useState(siteData.unit ? siteData.unit : "");
  const [street, setStreet] = useState(siteData.street ? siteData.street : "");
  const [city, setCity] = useState(siteData.city ? siteData.city : "");
  const [provience, setProvience] = useState(
    siteData.provience ? siteData.provience : ""
  );
  const [country, setCountry] = useState(
    siteData.country ? siteData.country : ""
  );

  const getCords = async (address, city) => {
    setValidating(true);
    let data = await provider.search({ query: address });
    data.forEach((obj, index) => {
      // filter result  by city if multiple results ins search
      if (obj.label.toLowerCase().includes(city.toLowerCase())) {
        setGeox(obj.x);
        setGeoy(obj.y);
      }
    });
    setValidating(false);
    setValidate(true);
  };

  const validateHandle = (e) => {
    e.preventDefault();
    if (
      !name ||
      !street ||
      !city ||
      !provience ||
      !country ||
      !postal ||
      !streetNumber
    ) {
      dispatch(
        setAlert({
          componentName: "editsite",
          alertType: "danger",
          msg: "Please fill all fields.",
        })
      );
      return console.log("please input all fields");
    }
    getCords(`${streetNumber} ${street} ${city} ${provience} ${country}`, city);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !name ||
      !street ||
      !city ||
      !provience ||
      !country ||
      !streetNumber ||
      !postal
    ) {
      dispatch(
        setAlert({
          componentName: "editsite",
          alertType: "danger",
          msg: "Please fill all fields.",
        })
      );
      return console.log("please input all fields");
    }
    dispatch(
      updateSite({
        site_id,
        name,
        unit,
        street,
        city,
        provience,
        country,
        geox,
        geoy,
        streetNumber,
        postal,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(loadAgencySites());
        dispatch(
          setAlert({
            componentName: "editsite",
            alertType: "success",
            msg: "Site is Updated.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "editsite",
            alertType: "danger",
            msg: "Error in Saving Data. Please try again.",
          })
        )
      );
  };
  if (!siteData || !site_id) {
    return <Alert variant="info">No Data found. Please try again.</Alert>;
  }
  return (
    <div>
      <Alerts componentName={"editsite"} />
      <Form>
        <h4>Update Site</h4>
        <hr />
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Site Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Site Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Unit</Form.Label>
            <Form.Control
              type="number"
              placeholder="Unit (Not Required)"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridstreetNumber">
            <Form.Label>Street Number</Form.Label>
            <Form.Control
              type="number"
              placeholder="Street Number (required)"
              value={streetNumber}
              onChange={(e) => setStreetNumber(e.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Street</Form.Label>
            <Form.Control
              type="text"
              placeholder="Street Name"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridZip">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              placeholder="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridstate">
            <Form.Label>Provience/State</Form.Label>
            <Form.Control
              type="text"
              placeholder="Provience"
              value={provience}
              onChange={(e) => setProvience(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridpostal">
            <Form.Label>Zip</Form.Label>
            <Form.Control
              type="text"
              placeholder="Provience"
              value={postal}
              onChange={(e) => setPostal(e.target.value)}
            />
          </Form.Group>
        </Row>

        {sites.loading || validating ? (
          <Button variant="primary" disabled className="button-flex">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="visually-hidden">Loading...</span>
          </Button>
        ) : (validate ?
          <>
            <Button
              variant="primary"
              type="submit"
              className="button-flex"
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="primary"
              type="submit"
              className="button-flex"
              onClick={(e) => {
                setEditProfile(false);
              }}
            >
              Go Back
            </Button>
          </> : <>
            <Button
              variant="primary"
              type="submit"
              className="button-flex"
              onClick={validateHandle}
            >
              Validate
            </Button>
            <Button
              variant="primary"
              type="submit"
              className="button-flex"
              onClick={(e) => {
                setEditProfile(false);
              }}
            >
              Go Back
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};

export default EditSite;
