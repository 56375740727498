import React, { useEffect, useState } from "react";
import Alerts from "../Alerts";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import { loadAgencySites } from "../../reducers/sites";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import DisplaySitesTable from "./DisplaySitesTable";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { AiOutlineSearch } from "react-icons/ai";


const DisplaySites = () => {
  const dispatch = useDispatch();
  const sites = useSelector((state) => state.sites);
  let sitesData = sites?.sites?.sites;

  const [serachByName, setSearchByName] = useState("");
  const [serachByAddress, setSearchByAddress] = useState("");
  const [serachByCity, setSearchByCity] = useState("");
  const [perPage, setPerPage] = useState(10);

  // filter sites based on search
  sitesData = sitesData?.filter((site) =>
    site.site_id.name.toLowerCase().includes(serachByName.toLowerCase())
  );
  sitesData = sitesData?.filter(
    (site) =>
    //uncomment below line in production
      // site.site_id?.streetNumber.toString().includes(serachByAddress.toLowerCase()) ||
      site.site_id.street.toLowerCase().includes(serachByAddress.toLowerCase())
  );
  sitesData = sitesData?.filter((site) =>
    site.site_id.city.toLowerCase().includes(serachByCity.toLowerCase())
  );

  useEffect(() => {
    dispatch(loadAgencySites());
  }, []);

  if (sites.loading ) {
    return (
      <div className="card">
        <Alerts componentName={"displaysites"} />

        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>

        <hr />
      </div>
    );
  }

  if (!sites.sites || !sitesData) {
    return (
      <div className="card">
        <Alert variant="info">No Sites created yet.</Alert>
      </div>
    );
  }

  return (
    <div className="card">
      <Alerts componentName={"displaysites"} />

      <h3>Sites</h3>

      <hr />
      {/* filters */}
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">
          <AiOutlineSearch />
        </InputGroup.Text>
        <Form.Control
          placeholder="Search by name"
          aria-label="search"
          aria-describedby="basic-addon1"
          value={serachByName}
          onChange={(e) => setSearchByName(e.target.value)}
        />
        <Form.Control
          placeholder="Search by address"
          aria-label="search"
          aria-describedby="basic-addon1"
          value={serachByAddress}
          onChange={(e) => setSearchByAddress(e.target.value)}
        />
        <Form.Control
          placeholder="Search by city"
          aria-label="search"
          aria-describedby="basic-addon1"
          value={serachByCity}
          onChange={(e) => setSearchByCity(e.target.value)}
        />

        <InputGroup.Text id="basic-addon2">
          <Form.Select
            defaultValue={perPage}
            onChange={(e) => setPerPage(Number(e.target.value))}
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </Form.Select>
        </InputGroup.Text>
      </InputGroup>
      {/* filters End */}
      <DisplaySitesTable sitesData={sitesData} itemsPerPage={perPage} />
    </div>
  );
};

export default DisplaySites;
