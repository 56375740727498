import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getShiftsData } from "../../../reducers/dashboard";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import DatePicker from "react-datepicker";

ChartJS.register(ArcElement, Tooltip, Legend);

const ShiftsCard = () => {
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.dashboardData);
  const selectedData = dashboardData.shiftsData;

  

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    startDate.setHours(0,0,0,0)
    endDate.setHours(23,59,59,0)
    dispatch(
      getShiftsData({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      })
    );
  }, []);
  useEffect(() => {
    if(!endDate){
      return;
    }
    startDate.setHours(0,0,0,0)
    endDate.setHours(23,59,59,0)
    dispatch(
      getShiftsData({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      })
    );
  }, [endDate]);

  const data = {
    labels: ["Open", "Covered"],
    datasets: [
      {
        label: "Shifts",
        data: [selectedData?.openShifts, selectedData?.coveredShifts],
        backgroundColor: ["rgba(255, 99, 132, 0.9)", "rgba(75, 192, 192, 0.9)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };

  if (dashboardData.shiftsLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }
  if (!dashboardData.shiftsData) {
    return (
      <div>
        <Alert variant="info">No Data. Please try again later.</Alert>
      </div>
    );
  }

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h3>Shifts</h3>
        <div>
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
          />
        </div>
      </div>
      <hr />
      <div className="dashboard-pieChart">
        <Pie data={data} />
      </div>
      <hr />
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-around">
          <div>
            <span className="dashboard-chart-text-1 fw-lighter">
              Total Shifts:
            </span>
            <span className="dashboard-chart-text">
              {selectedData.totalShifts}
            </span>{" "}
          </div>
          <div>
            <span className="dashboard-chart-text-1 fw-lighter">
              Total Hours:
            </span>
            <span className="dashboard-chart-text">
              {selectedData.totalShiftsHours.toFixed(2)}
            </span>{" "}
          </div>
        </div>
        <div className="d-flex justify-content-around">
          <div>
            <span className="dashboard-chart-text-1 fw-lighter">
              Open Shifts:
            </span>
            <span className="dashboard-chart-text">
              {selectedData.openShifts}
            </span>{" "}
          </div>
          <div>
            <span className="dashboard-chart-text-1 fw-lighter">
              Open Hours:
            </span>
            <span className="dashboard-chart-text">
              {selectedData.openShiftHours.toFixed(2)}
            </span>{" "}
          </div>
        </div>
        <div className="d-flex justify-content-around">
          <div>
            <span className="dashboard-chart-text-1 fw-lighter">
              Covered Shifts:
            </span>
            <span className="dashboard-chart-text">
              {selectedData.coveredShifts}
            </span>{" "}
          </div>
          <div>
            <span className="dashboard-chart-text-1 fw-lighter">
              Covered Shifts:
            </span>
            <span className="dashboard-chart-text">
              {selectedData.coveredShiftsHours.toFixed(2)}
            </span>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftsCard;
