import React, {useState, useEffect} from 'react';
import Button from "react-bootstrap/Button";
import { BsDownload, BsPlusSquare, BsX } from "react-icons/bs";
import { PDFDownloadLink, Document, Page ,Text, View, StyleSheet,Font  } from '@react-pdf/renderer';

const Paystub = ({hours, total, user, agencyprofile, startDate, endDate}) => {
  
    const [subTotal, setSubTotal] = useState(0)
    const [subTotalytd, setSubTotalytd] = useState(0)
    const [totaldeductions, settotaldeductions] = useState(0)
    const [totaldeductionsYtd, settotaldeductionsYtd] = useState(0)
    const [net, setnet] = useState(0)
    const [saleTax, setSaleTax] = useState(0)
    const additionsObj = {
        description: 'Description',
        rate: '4%',
        amount: 0,
        amountYtd: 0,
      }
      const deductionsObj = {
        description: 'Description',
        rate: '4%',
        amount: 0,
        amountYtd: 0,
      }
      const topFieldsObj = {
        label: 'Earnings',
        description: '4%',
      }
      
      const invoiceDate= {
        title: 'PAY STUB',
        companyName: agencyprofile ? agencyprofile.agencyName : '',
        companyAddress: agencyprofile ? ((agencyprofile.unit ? agencyprofile.unit + " ": "")  + (agencyprofile.streetNumber ? agencyprofile.streetNumber : "") + " " + agencyprofile.street) :'',
        companyAddress2: agencyprofile ? (agencyprofile.city + " " + agencyprofile.provience + " " + agencyprofile.postal) :'',
        companyCountry: agencyprofile ? agencyprofile.country :'Canada',
        empNameLabel: 'Employee Name',
        empName: user ? user: '',
        payPeriodfromLable: 'Pay period from:',
        payPeriodfrom: startDate ? startDate : '',
        payPeriodtoLabel: 'Pay period to:',
        payPeriodto: endDate ? endDate : '',
        payDateLabel: 'Pay Date',
        payDate: '',

        earningDescription: 'Earnings',
        earningQuantity: 'Curr Hours',
        earningQuantityRate: 'Curr Rate',
        earningQuantityAmount: 'Curr Amount',
        earningQuantityYtd: 'YTD Hours',
        earningQuantityAmountYtd: 'YTD Amount',
        earningTotalLabel: 'Total',

        deductionsDescription: 'Deductions',
        deductionsQuantityAmount: 'Curr Amount',
        deductionsQuantityAmountYtd: 'YTD Amount',
        deductionsTotalLabel: 'Total',

        earnings: 
          {
            earningDescription: 'Pay',
            earningQuantity: hours ? hours : 0,
            earningQuantityRate: 0,
            earningQuantityAmount: total ? total : 0,
            earningQuantityYtd: 0,
            earningQuantityAmountYtd: 0,
            }
        ,
        additions:[{
          description: 'Vacation Pay',
          rate: '0%',
          amount: 0,
          amountYtd: 0,
        }],
        additionsTotalLable:'Total',
        additionsTotalAmt:0,
        additionsTotalYtdAmt:0,
        deductions: [deductionsObj],
        deductionsTotalLable:'Total',
        topFields:[],

        totalLabel: 'Net',
        currency: '$',
      }
      const [initialInvoice, setinitialInvoice] = useState(invoiceDate)
      
      
      useEffect(() => {
        let subTotal = parseFloat(initialInvoice.earnings.earningQuantityAmount);
        let subTotalYtd = parseFloat(initialInvoice.earnings.earningQuantityAmountYtd);
    
        initialInvoice.additions.forEach((addition) => {
          
          const amount = addition.amount ? parseFloat(addition.amount) : 0;
          const amountYtd = addition.amountYtd ? parseFloat(addition.amountYtd) : 0;
          
          subTotal += amount;
          subTotalYtd += amountYtd;
        })
    
        setSubTotal(subTotal);
        setSubTotalytd(subTotalYtd);
      }, [initialInvoice.additions, initialInvoice.earnings.earningQuantityAmount,initialInvoice.earnings.earningQuantityAmountYtd])
      
      //deductions total calc
      useEffect(() => {
        let subTotal = 0;
        let subTotalYtd = 0;
    
        initialInvoice.deductions.forEach((addition) => {
          
          const amount = addition.amount ? parseFloat(addition.amount) : 0;
          const amountYtd = addition.amountYtd ? parseFloat(addition.amountYtd) : 0;
          
          subTotal += amount;
          subTotalYtd += amountYtd;
        })
    
        settotaldeductions(subTotal);
        settotaldeductionsYtd(subTotalYtd);
      }, [initialInvoice.deductions, subTotal])
      
      const handleDataInput = (e)=>{
        let {name, value} = e.target;
        const newInvoice = { ...initialInvoice };
        newInvoice[name] = value;
        setinitialInvoice(newInvoice)
      }

      const handleearningsChange = (e)=>{
        let {name, value} = e.target;
        const newInvoice = { ...initialInvoice };
        if (name === "earningDescription" ) {
          newInvoice.earnings[name] = value;
          
        } else {
          if (
            value[value.length - 1] === '.' ||
            (value[value.length - 1] === '0' && value.includes('.'))
          ) {
            newInvoice.earnings[name] = value;
            
          } else {
            const n = parseFloat(value)
            
            newInvoice.earnings[name] = (n ? n : 0).toString()
          }
        }

        
        
        setinitialInvoice(newInvoice)
      }

      const handleadditionsObjChange = (e,i) =>{
        let {name, value} = e.target;
        
        const additions = initialInvoice.additions.map((item, index) => {
          
          if (index === i) {
            const newitem = { ...item }
            
            //calulate amt
            if (name === "rate") {
              const match = value.match(/(\d+)%/);
              const quantityNumber = parseFloat(initialInvoice.earnings.earningQuantityAmount);
              const taxRate = match ? parseFloat(match[1]) : 0

              const amount = quantityNumber ? (quantityNumber * taxRate) / 100 : 0
              newitem["amount"] = amount;
              
            }
            

            if (name === "description" || name === "rate") {
              newitem[name] = value
              
            } else {
              if (
                value[value.length - 1] === '.' ||
                (value[value.length - 1] === '0' && value.includes('.'))
              ) {
                newitem[name] = value
                
              } else {
                const n = parseFloat(value)
                
                newitem[name] = (n ? n : 0).toString()
              }
            }
            
            return newitem
          }
          
          return { ...item }
        })
        
        setinitialInvoice({ ...initialInvoice, additions })
      }
      
      const handleadditionsAdd = () => {
        const additions = [...initialInvoice.additions, { ...additionsObj }]
    
        setinitialInvoice({ ...initialInvoice, additions })
      }

      const handleadditionsRemove = (i) => {
        const additions = initialInvoice.additions.filter((addition, index) => index !== i)
    
        setinitialInvoice({ ...initialInvoice, additions })
      }

      // deductions handlers

      const handledeductionsObjChange = (e,i) =>{
        let {name, value} = e.target;
        
        const deductions = initialInvoice.deductions.map((item, index) => {
          
          if (index === i) {
            const newitem = { ...item }
            //calulate amt
            if (name === "rate") {
              const match = value.match(/(\d+)%/);
              const quantityNumber = parseFloat(subTotal);
              const taxRate = match ? parseFloat(match[1]) : 0

              const amount = quantityNumber ? (quantityNumber * taxRate) / 100 : 0
              newitem["amount"] = amount;
              
            }
            if (name === "description" || name === "rate") {
              newitem[name] = value
              
            } else {
              if (
                value[value.length - 1] === '.' ||
                (value[value.length - 1] === '0' && value.includes('.'))
              ) {
                newitem[name] = value
                
              } else {
                const n = parseFloat(value)
                
                newitem[name] = (n ? n : 0).toString()
              }
            }
            
            return newitem
          }
          
          return { ...item }
        })
        
        setinitialInvoice({ ...initialInvoice, deductions })
      }

      const handledeductionsAdd = () => {
        const deductions = [...initialInvoice.deductions, { ...deductionsObj }]
    
        setinitialInvoice({ ...initialInvoice, deductions })
      }

      const handledeductionsRemove = (i) => {
        const deductions = initialInvoice.deductions.filter((deduction, index) => index !== i)
    
        setinitialInvoice({ ...initialInvoice, deductions })
      }

      useEffect(() => {
        setinitialInvoice({...initialInvoice, earnings: {...initialInvoice.earnings, earningQuantityAmount: total, earningQuantity: hours}})
      }, [total, hours])

    

      Font.register({
        family: 'Nunito',
        fonts: [
          { src: 'https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaE.ttf' },
          { src: 'https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUYevN.ttf', fontWeight: 600 },
        ],
      })
      //create stylesheet for pdf
      const styles = StyleSheet.create({
        dark: {
          color: '#222',
        },
      
        white: {
          color: '#fff',
        },
      
        bgdark: {
          backgroundColor: '#666',
        },
      
        bggray: {
          backgroundColor: '#e3e3e3',
        },
      
        flex: {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
        },
      
        wauto: {
          flex: 1,
          paddingRight: '8px',
        },
      
        ml30: {
          flex: 1,
        },
      
        w100: {
          width: '100%',
        },
      
        w50: {
          width: '50%',
        },
      
        w55: {
          width: '55%',
        },
      
        w45: {
          width: '45%',
        },
      
        w60: {
          width: '60%',
        },
      
        w40: {
          width: '40%',
        },
      
        w48: {
          width: '48%',
        },
        w16: {
          width: '16%',
        },
      
        w17: {
          width: '17%',
        },
      
        w18: {
          width: '18%',
        },
        w20: {
          width: '20%',
        },
      
        row: {
          borderBottom: `1px solid #e3e3e3`,
        },
      
        mt40: {
          marginTop: '40px',
        },
      
        mt30: {
          marginTop: '30px',
        },
      
        mt20: {
          marginTop: '20px',
        },
      
        mt10: {
          marginTop: '10px',
        },
      
        mb5: {
          marginBottom: '5px',
        },
      
        p48: {
          padding: '4px 8px',
        },
        p41: {
          padding: '4px 2px',
        },
      
        p5: {
          padding: '5px',
        },
      
        pb10: {
          paddingBottom: '10px',
        },
      
        right: {
          textAlign: 'right',
        },
      
        bold: {
          fontWeight: 'bold',
        },
      
        fs20: {
          fontSize: '20px',
        },
        fs30: {
          fontSize: '30px',
        },
        fs12: {
          fontSize: '12px',
        },
      
        fs45: {
          fontSize: '45px',
        },
      
        page: {
          fontFamily: 'Nunito',
          fontSize: '13px',
          color: '#555',
          padding: '40px 35px',
        },
      
        span: {
          padding: '4px 12px 4px 0',
        },
      
        logo: {
          display: 'block',
        }
      });
    //   create pdf file
    const MyDoc = () => (
        <Document>
          <Page style={styles.page}>
          
                <View>
                    <Text style={[styles.input, styles.fs30,styles.right,styles.bold]}>{initialInvoice.title}</Text>
                </View>
            <View style={styles.flex} >
                <View style={styles.w50} >
                    <Text style={[styles.fs20,  styles.bold]} >{initialInvoice.companyName}</Text>
        
                    <Text  style={styles.input}>{initialInvoice.companyAddress}</Text>
                    <Text  style={styles.input}>{initialInvoice.companyAddress2}</Text>
                    <Text style={styles.input}>{initialInvoice.companyCountry}</Text>
                </View>
                <View style={styles.w50} >
                    <View style={[styles.flex]}>
                        <View style={styles.w40}>
                            <Text style={[styles.input, styles.bold]} >{initialInvoice.empNameLabel}</Text>
                        </View>
                        <View style={styles.w60}>
                            <Text style={styles.input} >{initialInvoice.empName}</Text>
                        </View>
                    </View>
                    <View style={[styles.flex]}>
                        <View style={styles.w40}>
                            <Text  style={[styles.input, styles.bold]}  >{initialInvoice.payPeriodfromLable}</Text>
                        </View>
                        <View style={styles.w60}>
                            <Text style={styles.input} >{initialInvoice.payPeriodfrom}</Text>
                        </View>
                    </View>
                    <View style={[styles.flex]}>
                        <View style={styles.w40}>
                            <Text style={[styles.input, styles.bold]}  >{initialInvoice.payPeriodtoLabel}</Text>
                        </View>
                        <View style={styles.w60}>
                            <Text style={styles.input} >{initialInvoice.payPeriodto}</Text>
                        </View>
                    </View>
                    <View style={[styles.flex]}>
                        <View style={styles.w40}>
                            <Text style={[styles.input, styles.bold]}  >{initialInvoice.payDateLabel}</Text>
                        </View>
                        <View style={styles.w60}>
                            <Text style={styles.input} >{initialInvoice.payDate}</Text>
                        </View>
                    </View>
                </View>
                
            </View>
            
            

            <View style={[styles.flex, styles.bgdark, styles.mt10]}>
                <View style={[styles.w20, styles.p41]}>
                    <Text style={[styles.fs12, styles.input, styles.white, styles.bold]}  >{initialInvoice.earningDescription}</Text>
                </View>
                <View style={[styles.w16, styles.p41]} >
                    <Text style={[styles.fs12, styles.input, styles.white, styles.bold, styles.right]} >{initialInvoice.earningQuantity}</Text>
                </View>
                <View style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.white, styles.bold, styles.right]}   >{initialInvoice.earningQuantityRate}</Text>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.white, styles.bold, styles.right]}  >{initialInvoice.earningQuantityAmount}</Text>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.white, styles.bold, styles.right]}  >{initialInvoice.earningQuantityYtd}</Text>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.white, styles.bold, styles.right]}  >{initialInvoice.earningQuantityAmountYtd}</Text>
                </View>
            </View>
            {/* initial hours */}
            <View style={[styles.flex, styles.row]}>
                <View style={[styles.w20, styles.p41]}>
                    <Text style={[styles.fs12, styles.input, styles.dark] }  >{initialInvoice.earnings.earningDescription}</Text>
                </View>
                <View style={[styles.w16, styles.p41]} >
                    <Text style={[styles.fs12, styles.input, styles.dark,  styles.right]} >{(initialInvoice.earnings.earningQuantity).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                </View>
                <View style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.dark,  styles.right]}   >{(initialInvoice.earnings.earningQuantityRate).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.dark,  styles.right]}  >{(initialInvoice.earnings.earningQuantityAmount).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.dark,  styles.right]}  >{(initialInvoice.earnings.earningQuantityYtd).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.dark,  styles.right]}  >{(initialInvoice.earnings.earningQuantityAmountYtd).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                </View>
            </View>
            {/* this dic will be iterated over product items OR coverage types with rates */}
            {initialInvoice.additions ? initialInvoice.additions.map((item, index)=>{
                return(
                <View key={index} style={[styles.flex, styles.row]}>
                    <View style={[styles.w20, styles.p41]}>
                    <Text style={[styles.fs12, styles.input, styles.dark] }  >{item.description}</Text>
                </View>
                <View style={[styles.w16, styles.p41]} >
                    <Text style={[styles.fs12, styles.input, styles.dark,  styles.right]} ></Text>
                </View>
                <View style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.dark,  styles.right]}   ></Text>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.dark,  styles.right]}  >{(item.amount).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.dark,  styles.right]}  ></Text>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.dark,  styles.right]}  >{(item.amountYtd).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                </View>
                 </View>
                )
            }) : <></>}
            {/* additionals total */}
            <View style={[styles.flex, styles.bggray]}>
                <View style={[styles.w20, styles.p41]}>
                    <Text style={[styles.fs12, styles.input, styles.bold] }  >{initialInvoice.additionsTotalLable}</Text>
                </View>
                <View style={[styles.w16, styles.p41]} >
                </View>
                <View style={[styles.w16, styles.p41]}>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.bold,  styles.right]}  >{(subTotal).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.bold,  styles.right]}  >{(subTotalytd).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                </View>
            </View>

            {/* deductions */}
            <View style={[styles.flex, styles.bgdark, styles.mt10]}>
                <View style={[styles.w20, styles.p41]}>
                    <Text style={[styles.fs12, styles.input, styles.white, styles.bold]}  >{initialInvoice.deductionsDescription}</Text>
                </View>
                <View style={[styles.w16, styles.p41]} >
                </View>
                <View style={[styles.w16, styles.p41]}>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.white, styles.bold, styles.right]}  >{initialInvoice.deductionsQuantityAmount}</Text>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.white, styles.bold, styles.right]}  >{initialInvoice.deductionsQuantityAmountYtd}</Text>
                </View>
            </View>


            {/* decutions array mapping */}
            {initialInvoice.deductions ? initialInvoice.deductions.map((item, index)=>{
                return(
                <View key={index} style={[styles.flex, styles.row]}>
                <View style={[styles.w20, styles.p41]}>
                    <Text style={[styles.fs12, styles.input, styles.dark] }  >{item.description}</Text>
                </View>
                <View style={[styles.w16, styles.p41]} >
                 </View>
                <View style={[styles.w16, styles.p41]}>
                    
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.dark,  styles.right]}  >{(item.amount).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.dark,  styles.right]}  >{(item.amountYtd).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                </View>
                 </View>
                )
            }) : <></>}

            {/* deductions total  */}
            <View style={[styles.flex, styles.bggray]}>
                <View style={[styles.w20, styles.p41]}>
                    <Text style={[styles.fs12, styles.input, styles.bold] }  >{initialInvoice.deductionsDescription}</Text>
                </View>
                <View style={[styles.w16, styles.p41]} >
                </View>
                <View style={[styles.w16, styles.p41]}>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.bold,  styles.right]}  >{(totaldeductions).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                </View>
                <View  style={[styles.w16, styles.p41]}>
                    <Text  style={[styles.fs12, styles.input, styles.bold,  styles.right]}  >{(totaldeductionsYtd).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                </View>
            </View>


            <View style={styles.flex}>
                <View style={[styles.w50, styles.mt10]}>
                    {/* logic to add additional cost */}
                </View>
                <View style={[styles.w50, styles.mt20]}>
                    
                    <View style={[styles.flex, styles.p5, styles.bggray]}>
                        <View style={[styles.w50, styles.p5]}>
                            <Text style={[styles.input, styles.bold]}  >{initialInvoice.totalLabel}</Text>
                        </View>
                        <View style={[styles.flex, styles.w50, styles.p5]}>
                            <Text style={[styles.input, styles.dark, styles.right, styles.bold, styles.ml30]} >{initialInvoice.currency}</Text>
                            <Text style={[styles.span, styles.dark, styles.right, styles.bold, styles.wauto]}>{((subTotal - totaldeductions) > 0 ? (subTotal - totaldeductions): 0  ).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</Text>
                        </View>
                    </View>
                </View>
            </View>


            
        
            
        
    
          </Page>
        </Document>
      );
      
  return (
    <div className='card'>
    <div className='d-flex justify-content-between'>
        <div> <h2>Pay Stub</h2></div>
        <div>
        <PDFDownloadLink document={<MyDoc />} fileName="paystub.pdf">
            {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : <Button variant="secondary" ><BsDownload /> Download</Button>
            }
            </PDFDownloadLink>
            </div>
        </div>
    <hr />
    <div className='w-100 invoice d-flex justify-content-center'>
        <div className='invoice-wrapper'>
            <div>
            <input name="title" type="text" class="input xfs-30 xright xbold" placeholder="Invoice" value={initialInvoice.title}  onChange={(e)=>handleDataInput(e)} />
            </div>
            <div className='d-flex'>
                <div className='xw-50 '>
                    <input name="companyName" type="text" class="input xfs-20 xbold" placeholder="Your Company" value={initialInvoice.companyName}  onChange={(e)=>handleDataInput(e)}/>
                    
                    <input name="companyAddress" type="text" class="input " placeholder="Company's Address"value={initialInvoice.companyAddress}  onChange={(e)=>handleDataInput(e)} />
                    <input name="companyAddress2" type="text" class="input " placeholder="City, State Zip" value={initialInvoice.companyAddress2}  onChange={(e)=>handleDataInput(e)} ></input>
                    <input name="companyCountry" type="text" class="input " placeholder="Country" value={initialInvoice.companyCountry}  onChange={(e)=>handleDataInput(e)} ></input>
                </div>
                <div className='xw-50'>
                    
                    <div class="">
                    <div className='d-flex '>
                        <div className='xw-40'>
                            <input name="empNameLabel" type="text" class="input xbold" placeholder="" value={initialInvoice.empNameLabel}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                        <div className='xw-60'>
                            <input name="empName" type="text" class="input" placeholder="12345" value={initialInvoice.empName}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                    </div>
                    <div className='d-flex '>
                        <div className='xw-40'>
                            <input name="payPeriodfromLable" type="text" class="input xbold" placeholder="" value={initialInvoice.payPeriodfromLable}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                        <div className='xw-60'>
                            <input name="payPeriodfrom" type="text" class="input" placeholder="12345" value={initialInvoice.payPeriodfrom}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                    </div>
                    <div className='d-flex '>
                        <div className='xw-40'>
                            <input name="payPeriodtoLabel" type="text" class="input xbold" placeholder="" value={initialInvoice.payPeriodtoLabel}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                        <div className='xw-60'>
                            <input name="payPeriodto" type="text" class="input" placeholder="00/00/0000" value={initialInvoice.payPeriodto}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                    </div>
                    <div className='d-flex '>
                        <div className='xw-40'>
                            <input name="payDateLabel" type="text" class="input xbold" placeholder="" value={initialInvoice.payDateLabel}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                        <div className='xw-60'>
                            <input name="payDate" type="text" class="input" placeholder="00/00/0000" value={initialInvoice.payDate}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            

            <div className='d-flex xbg-dark xmt-10'>
                <div className='xw-20 xp-4-1 '>
                    <input name="earningDescription" type="text" class="xfs-12 xp-4-1 input xwhite xbold" placeholder="" value={initialInvoice.earningDescription}  onChange={(e)=>handleDataInput(e)} />
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="earningQuantity" type="text" class="xfs-12 xp-4-1 input xwhite xbold xright" placeholder="" value={initialInvoice.earningQuantity}  onChange={(e)=>handleDataInput(e)}/>
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="earningQuantityRate" type="text" class="xfs-12 xp-4-1 input xwhite xbold xright" placeholder="" value={initialInvoice.earningQuantityRate}  onChange={(e)=>handleDataInput(e)} />
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="earningQuantityAmount" type="text" class="xfs-12 xp-4-1 input xwhite xbold xright" placeholder="" value={initialInvoice.earningQuantityAmount}  onChange={(e)=>handleDataInput(e)} />
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="earningQuantityYtd" type="text" class="xfs-12 xp-4-1 input xwhite xbold xright" placeholder="" value={initialInvoice.earningQuantityYtd}  onChange={(e)=>handleDataInput(e)} />
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="earningQuantityAmountYtd" type="text" class="xfs-12 xp-4-1 input xwhite xbold xright" placeholder="" value={initialInvoice.earningQuantityAmountYtd}  onChange={(e)=>handleDataInput(e)} />
                </div>
            </div>
            {/* initian cost */}
            <div className='d-flex xrow'>
                    
                    <div className='xw-20 xp-4-1'>
                    <input name="earningDescription" type="text" class="xfs-12 xp-4-1 input xdark" placeholder="" value={initialInvoice.earnings.earningDescription}  onChange={(e)=>handleearningsChange(e)} />
                </div>
                    
                    <div className='xw-16 xp-4-1'>
                    <input name="earningQuantity" type="text" class="xfs-12 xp-4-1 input xdark xright" placeholder="" value={(initialInvoice.earnings.earningQuantity).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}  onChange={(e)=>handleearningsChange(e)} />
                </div>

                    
                    <div className='xw-16 xp-4-1'>
                    <input name="earningQuantityRate" type="text" class="xfs-12 xp-4-1 input xdark xright" placeholder="" value={(initialInvoice.earnings.earningQuantityRate).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}  onChange={(e)=>handleearningsChange(e)} />
                </div>

                    
                    <div className='xw-16 xp-4-1'>
                    <input name="earningQuantityAmount" type="text" class="xfs-12 xp-4-1 input xdark xright" placeholder="" value={(initialInvoice.earnings.earningQuantityAmount).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}  onChange={(e)=>handleearningsChange(e)} />
                </div>

                    
                    <div className='xw-16 xp-4-1'>
                    <input name="earningQuantityYtd" type="text" class="xfs-12 xp-4-1 input xdark xright" placeholder="" value={(initialInvoice.earnings.earningQuantityYtd).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}  onChange={(e)=>handleearningsChange(e)} />
                </div>

                    
                    <div className='xw-16 xp-4-1'>
                    <input name="earningQuantityAmountYtd" type="text" class="xfs-12 xp-4-1 input xdark xright" placeholder="" value={(initialInvoice.earnings.earningQuantityAmountYtd).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}  onChange={(e)=>handleearningsChange(e)} />
                </div>
                
                 </div>
            {/* this dic will be iterated over product items OR coverage types with rates */}
            {initialInvoice.additions ? initialInvoice.additions.map((item, index)=>{
                return(
                <div key={index} className='d-flex xrow'>
                 <div className="row__remove"> <BsX className="icon icon-remove" onClick={()=>handleadditionsRemove(index)}/></div>
                <div className='xw-20 xp-4-1'>
                    <input name="description" type="text" class="xfs-12 xp-4-1 input xdark" placeholder="" value={item.description}  onChange={(e)=>handleadditionsObjChange(e, index)} />
                </div>
                <div className='xw-16 xp-4-1'>
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="rate" type="text" class="xfs-12 xp-4-1 input xdark xright row-hidden-input" placeholder="" value={item.rate}  onChange={(e)=>handleadditionsObjChange(e, index)} />
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="amount" type="text" class="xfs-12 xp-4-1 input xdark xright" placeholder="" value={(item.amount).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}  onChange={(e)=>handleadditionsObjChange(e, index)} />
                </div>
                <div className='xw-16 xp-4-1'>
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="amountYtd" type="text" class="xfs-12 xp-4-1 input xdark xright" placeholder="" value={item.amountYtd}  onChange={(e)=>handleadditionsObjChange(e, index)} />
                </div>
                    
                 </div>
                )
            }) : <></>}
            
            {/* START of add new addition */}
            <div className="w-50 mt-10" >
           
              <BsPlusSquare className="icon icon-add" onClick={handleadditionsAdd}/>
            
          </div>
            {/* END of add new addition  */}
            {/* START of addtions total */}
            <div className='d-flex xbg-gray'>
                <div className='xw-20 xp-4-1 '>
                    <input name="additionsTotalLable" type="text" class="xfs-12 xp-4-1 input xbold" placeholder="" value={initialInvoice.additionsTotalLable}  onChange={(e)=>handleDataInput(e)} />
                </div>
                <div className='xw-16 xp-4-1'>
                    
                </div>
                <div className='xw-16 xp-4-1'>
                    
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="additionsTotalAmt" type="text" class="xfs-12 xp-4-1 input xbold xright" placeholder="" value={(subTotal).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}   />
                </div>
                <div className='xw-16 xp-4-1'>
                    
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="additionsTotalYtdAmt" type="text" class="xfs-12 xp-4-1 input xbold xright" placeholder="" value={(subTotalytd).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}   />
                </div>
            </div>
            {/* END of additions total */}

            {/* START of deductions */}
            <div className='d-flex xbg-dark xmt-10'>
                <div className='xw-20 xp-4-1 '>
                    <input name="deductionsDescription" type="text" class="xfs-12 xp-4-1 input xwhite xbold" placeholder="" value={initialInvoice.deductionsDescription}  onChange={(e)=>handleDataInput(e)} />
                </div>
                <div className='xw-16 xp-4-1'>
                </div>
                <div className='xw-16 xp-4-1'>
                  
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="deductionsQuantityAmount" type="text" class="xfs-12 xp-4-1 input xwhite xbold xright" placeholder="" value={initialInvoice.deductionsQuantityAmount}  onChange={(e)=>handleDataInput(e)}/>
                </div>
                <div className='xw-16 xp-4-1'>
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="deductionsQuantityAmountYtd" type="text" class="xfs-12 xp-4-1 input xwhite xbold xright" placeholder="" value={initialInvoice.deductionsQuantityAmountYtd}  onChange={(e)=>handleDataInput(e)} />
                </div>
                
            </div>
            
            {/* this dic will be iterated over product items OR coverage types with rates */}
            {initialInvoice.deductions ? initialInvoice.deductions.map((item, index)=>{
                return(
                <div key={index} className='d-flex xrow'>
                 <div className="row__remove"> <BsX className="icon icon-remove" onClick={()=>handledeductionsRemove(index)}/></div>
                <div className='xw-20 xp-4-1'>
                    <input name="description" type="text" class="xfs-12 xp-4-1 input xdark" placeholder="" value={item.description}  onChange={(e)=>handledeductionsObjChange(e, index)} />
                </div>
                <div className='xw-16 xp-4-1'>
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="rate" type="text" class="xfs-12 xp-4-1 input xdark xright row-hidden-input" placeholder="" value={item.rate}  onChange={(e)=>handledeductionsObjChange(e, index)} />
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="amount" type="text" class="xfs-12 xp-4-1 input xdark xright" placeholder="" value={(item.amount).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}  onChange={(e)=>handledeductionsObjChange(e, index)} />
                </div>
                <div className='xw-16 xp-4-1'>
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="amountYtd" type="text" class="xfs-12 xp-4-1 input xdark xright" placeholder="" value={(item.amountYtd).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}  onChange={(e)=>handledeductionsObjChange(e, index)} />
                </div>
                    
                 </div>
                )
            }) : <></>}
            
            {/* START of add new addition */}
            <div className="w-50 mt-10" >
           
              <BsPlusSquare className="icon icon-add" onClick={handledeductionsAdd}/>
            
          </div>
            {/* END of add new addition  */}

            {/* START of deductions total */}
            <div className='d-flex xbg-gray'>
                <div className='xw-20 xp-4-1 '>
                    <input name="deductionsDescription" type="text" class="xfs-12 xp-4-1 input xbold" placeholder="" value={initialInvoice.deductionsDescription}  onChange={(e)=>handleDataInput(e)} />
                </div>
                <div className='xw-16 xp-4-1'>
                </div>
                <div className='xw-16 xp-4-1'>
                  
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="deductionsQuantityAmount" type="text" class="xfs-12 xp-4-1 input xbold xright" placeholder="" value={(totaldeductions).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}  />
                </div>
                <div className='xw-16 xp-4-1'>
                </div>
                <div className='xw-16 xp-4-1'>
                    <input name="deductionsQuantityAmountYtd" type="text" class="xfs-12 xp-4-1 input xbold xright" placeholder="" value={(totaldeductionsYtd).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}   />
                </div>
                
            </div>
            {/* END of deductions total */}
            {/* END of deductions */}
            <div className='d-flex'>
                <div className='xw-50 xmt-10'>
                    {/* logic to add additional cost */}
                </div>
                <div className='xw-50 xmt-20'>
                    <div className='d-flex xbg-gray xp-5'>
                        <div className='xw-50 xp-5'>
                            <input name="totalLabel" type="text" class="input xbold" placeholder="" value={initialInvoice.totalLabel}  onChange={(e)=>handleDataInput(e)} />
                        </div>
                        <div className='d-flex xw-50 xp-5'>
                            <input name="currency" type="text" class="input xdark xbold xright xml-30" placeholder="" value={initialInvoice.currency}  onChange={(e)=>handleDataInput(e)} />
                            <span class="span xright xbold xdark xw-auto">{((subTotal - totaldeductions) > 0 ? (subTotal - totaldeductions): 0  ).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</span>
                        </div>
                    </div>
                </div>
            </div>

            

            
        </div>
    </div>
    </div>
  )
}

export default Paystub;