import React, { useEffect, useState, useCallback } from "react";
import { BsX, BsExclamation, BsClock } from "react-icons/bs";
import { BiTask } from "react-icons/bi";
import { loadDashboardTaskData } from "../../../reducers/dashboard";
import ChartCard from "./ChartCard";
import DisplayTasks from "./DisplayTasks";
import { loadAgencyTasks } from "../../../reducers/tasks";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const AgencyTasks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tasks = useSelector((state) => state.tasks?.tasks);
  const loading = useSelector((state) => state.tasks?.tasksLoading);
  const tasksStats = useSelector((state) => state.dashboardData?.tasksData);
  const loadingStats = useSelector(
    (state) => state.dashboardData?.tasksLoading
  );

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );

  const [completed, setCompleted] = useState(false);
  const [due, setDue] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleCreateTaskClick = useCallback(
    () => navigate("/dashboard/createtask"),
    [navigate]
  );

  let filteredTasks = tasks;
  let taskData = tasks;
  // filter sites based on filters
  completed ? (
    (filteredTasks = filteredTasks?.filter(
      (task) => task.status === "COMPLETED"
    ))
  ) : (
    <></>
  );

  due ? (
    (filteredTasks = filteredTasks?.filter(
      (task) => new Date(task.deadline) < new Date()
    ))
  ) : (
    <></>
  );

  accepted ? (
    (filteredTasks = filteredTasks?.filter((task) => task.accepted === false))
  ) : (
    <></>
  );
  console.log(filteredTasks);
  useEffect(() => {
    if (!endDate) {
      return;
    }
    dispatch(
      loadAgencyTasks({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      })
    );
    dispatch(
      loadDashboardTaskData({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      })
    );
  }, [endDate]);

  if (loading || loadingStats) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }
  if (!tasksStats) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div>
          <Alert variant="info">No Tasks found.</Alert>
        </div>
      </div>
    );
  }
  return (
    <div className="task-dashboard">
      <div className="card card-1">
        <div className="d-flex mb-2">
          <div className="me-5">
            <span
              style={{ color: "white" }}
              className=" d-flex justify-content-center align-items-center user-card-badge p-2 display-1"
            >
              <BiTask />
            </span>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex">
              <div className="display-1 lh-1 ">
                {tasksStats[0]?.TOTAL ? tasksStats[0]?.TOTAL : 0}
              </div>{" "}
              <div className="align-self-end fw-light" style={{fontSize:"12px"}}>Tasks</div>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-2">
        <div className="d-flex mb-2">
          <div className="me-5">
            <span
              style={{ color: "white" }}
              className=" d-flex justify-content-center align-items-center user-card-logo p-2 display-1"
            >
              <BsX />
            </span>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex">
              <div className="display-1 lh-1 ">
                {tasksStats[0]?.NOTACCEPTED ? tasksStats[0]?.NOTACCEPTED : 0}
              </div>{" "}
              <div className="align-self-end fw-light" style={{fontSize:"12px"}}>Not Accepted</div>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-3">
        <div className="d-flex mb-2">
          <div className="me-5">
            <span
              style={{ color: "white" }}
              className=" d-flex justify-content-center align-items-center broadcasted-card-logo p-2 display-1"
            >
              <BsClock />
            </span>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex">
              <div className="display-1 lh-1 ">
                {tasksStats[0]?.DUE ? tasksStats[0]?.DUE : 0}
              </div>{" "}
              <div className="align-self-end fw-light" style={{fontSize:"12px"}}>Due</div>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-4">
        <div className="d-flex mb-2">
          <div className="me-5">
            <span
              style={{ color: "white" }}
              className=" d-flex justify-content-center align-items-center broadcasted-card-logo p-2 display-1"
            >
              <BsExclamation />
            </span>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex">
              <div className="display-1 lh-1 ">
                {tasksStats[0]?.NOTSTARTED ? tasksStats[0]?.NOTSTARTED : 0}
              </div>{" "}
              <div className="align-self-end fw-light" style={{fontSize:"12px"}}>Not Started</div>
            </div>
          </div>
        </div>
      </div>
      {/* send start and end date to Chart */}
      <div className="card card-5">
        <ChartCard />
      </div>

      <div className="card card-6">
        <div className="d-flex justify-content-between align-items-center fw-light ">
          <div className="d-flex gap-2">
            <div className="d-flex gap-1 ">
              <Form.Check
                type={"checkbox"}
                id={`break-checkbox-111`}
                onClick={() => setCompleted((prev) => !prev)}
              />
              Completed
            </div>

            <div className="d-flex gap-2 ">
              <Form.Check
                type={"checkbox"}
                id={`break-checkbox-112`}
                onClick={() => setDue((prev) => !prev)}
              />
              Due
            </div>
            <div className="d-flex gap-2 ">
              <Form.Check
                type={"checkbox"}
                id={`break-checkbox-113`}
                onClick={() => setAccepted((prev) => !prev)}
              />
              Not Accepted
            </div>
          </div>

          <div className="d-flex gap-2 align-items-center">
            {" "}
            <div>Date:</div>
            <div>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
              />
            </div>
          </div>
          <div>
            <Button
              className="button-flex-lg"
              variant="primary"
              onClick={handleCreateTaskClick}
            >
              Create Task
            </Button>
          </div>
        </div>
        <hr />
        <DisplayTasks tasks={filteredTasks} />
      </div>
    </div>
  );
};

export default AgencyTasks;
