import React from "react";

const Welcome = () => {
  const onThisPage = [
    {
      link: "#getstarted",
      desc: "Get started",
    },
    {
      link: "#messages",
      desc: "Important messages",
    },
    {
      link: "#sidebarmenu",
      desc: "Sidebar menu",
    },
    {
      link: "#onthispage",
      desc: "On this page",
    },
    {
      link: "#zoomimage",
      desc: "Zoom pictures",
    },
    {
      link: "#help",
      desc: "Need help?",
    }
  ];
  return (
    <div className="d-flex">
      <div className="docs-sub-left-content">
        <h1 id="getstarted">Alpha Scheduling Documentation</h1>
        <p>
          Welcome to Alpha Scheduling Documentation. The guide describes how to
          use and navigation through documentation.
        </p>

        <h2 id="messages">Important messages</h2>
        <p>
          While going through documentaion, you will see important messages for
          specific sections. Below are the types of messages with color codes.
        </p>
        <div className="bd-callout-info bd-callout ">
          <h5>Informative message</h5>
          <p>This message contains additional information about the section.</p>
        </div>
        <div className="bd-callout-warning bd-callout ">
          <h5>Warning message</h5>
          <p>
            This message contains warning information about the section.
            Ignoring these details might affect functionality.
          </p>
        </div>
        <div className="bd-callout-alert bd-callout ">
          <h5>Alert message</h5>
          <p>
            This message contains Important information about the section.
            Ignoring these details will affect functionality.
          </p>
        </div>
        <h2 id="sidebarmenu">Sidebar menu</h2>
        <p>Use left side menu to navigate through sections.</p>
        <h2 id="onthispage">On this page</h2>
        <p>
          Use right side <span className="text-highlight">On this page</span>{" "}
          section to quickly jump to specific section on any page.
        </p>
        <h2 id="zoomimage">Zoom pictures</h2>
        <p>
          You can click on any picture on any page to open picture in original size.
        </p>
        <h2 id="help">Need help?</h2>
        <p>
          Feeling stuck? we are here to help. Create a ticket under support section.
        </p>
      </div>
      <div className="docs-sub-right-content">
        <div>On this page</div>
        <hr />
        <ul>
          {onThisPage.map((item, index) => {
            return (
              <li key={index}>
                <a href={item.link}>{item.desc}</a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Welcome;
