import React from 'react';
import { Outlet } from "react-router-dom";

const AdminDashboard = () => {
  return (
    <>
    

    <div className="AgencyDashboard">
      
      <div className="AgencyDashboard_panel">
        <Outlet />
      </div>
    </div>
  </>
  )
}

export default AdminDashboard