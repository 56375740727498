import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import { loadAttendence } from "../../../reducers/attendence";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import AttendenceMap from "../AttendenceMap";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          MAP
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AttendenceMap site={props.site} clockin={props.clockin} clockout={props.clockout} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const Attendence = () => {
  const dispatch = useDispatch();
  const shifts = useSelector((state) => state.attendence.shifts);

  const [modalShow, setModalShow] = useState(false);
  const [siteCords, setSiteCords] = useState([]);
  const [clockinCords, setClockinCords] = useState([]);
  const [clockoutCords, setClockoutCords] = useState([]);


  //sort array NOT WORKING YET
  let sortedShifts=[]
  if(shifts){

    sortedShifts = [...shifts]
    sortedShifts.sort( function compareFn( a, b ) {
      let sTempDate = new Date(a.shiftDate);
      let sTempDate2 = new Date(a.startTime);
      let bTempDate = new Date(b.shiftDate);
      let bTempDate2 = new Date(b.startTime);
      
      sTempDate.setHours(sTempDate2.getHours(), sTempDate2.getMinutes(),0,0)
      bTempDate.setHours(bTempDate2.getHours(), bTempDate2.getMinutes(),0,0)
      
      if ( sTempDate.getTime() < bTempDate.getTime() ){
        return -1;
      }
      if ( sTempDate.getTime() > bTempDate.getTime() ){
        return 1;
      }
      return 0;
    } );
    
    
  }
  

  let today = new Date();
  let startDate = new Date();
  let endDate = new Date();
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 0, 0);
  //display state on screen
  const [displaystartDate, setDisplayStartDate] = useState(new Date());
  const [offSet, setOffSet] = useState(0);

  //calculate distance
  const distance=(lat1,
    lat2, lon1, lon2)=>{

    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 =  lon1 * Math.PI / 180;
    lon2 = lon2 * Math.PI / 180;
    lat1 = lat1 * Math.PI / 180;
    lat2 = lat2 * Math.PI / 180;

    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a = Math.pow(Math.sin(dlat / 2), 2)
    + Math.cos(lat1) * Math.cos(lat2)
    * Math.pow(Math.sin(dlon / 2),2);

    let c = 2 * Math.asin(Math.sqrt(a));

    // Radius of earth in kilometers. Use 3956
    // for miles
    let r = 6371;

    console.log(c * r)
    // calculate the result
    return(c * r);
  }

  useEffect(() => {
    dispatch(
      loadAttendence({
        startDate: startDate,
        endDate: endDate,
      })
    );
  }, []);

  useEffect(() => {
    handleChange();
  }, [offSet]);

  const handleChange = () => {
    startDate.setDate(startDate.getDate() + offSet);
    endDate.setDate(endDate.getDate() + offSet);
   
    setDisplayStartDate(startDate);
    
    dispatch(
      loadAttendence({
        startDate: startDate,
        endDate: endDate,
      })
    );
  };

  const handlePrevClick = () => {
    setOffSet((prev) => prev - 1);
  };

  const handleNextClick = () => {
    setOffSet((prev) => prev + 1);
  };

  if (!shifts) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="card ">
        <div className="displaysites-header">
          <h2>
            <span>Attendance </span>
          </h2>
        </div>
      </div>
      <div className="attendence-page-style card ">
        <div className="d-flex justify-content-center align-items-center gap-5 mb-3">
          <Button variant="primary" onClick={handlePrevClick}>
            <AiOutlineLeft />
          </Button>

          <div>{displaystartDate.toDateString()}</div>

          <Button variant="primary" onClick={handleNextClick}>
            <AiOutlineRight />
          </Button>
        </div>
        <div>
          {shifts.length === 0 ? (
            <Alert variant="info">No Shifts on selected date.</Alert>
          ) : (<>
            <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Employee</th>
                <th>Site Name</th>
                <th>Address</th>
                <th>Start</th>
                <th>Clock In</th>
                <th>Finish</th>
                <th>Clock Out </th>
              </tr>
            </thead>
            <tbody>
            
            {sortedShifts.map((shift, index)=>{
              today = new Date(shift.shiftDate)
            return(
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{shift.employeeName ? <Link to={`/dashboard/users/${shift.user_id}`}>{shift.employeeName}</Link> : shift.status}</td>
                <td><Link to={`/dashboard/sites/${shift.site_id._id}`}>{shift.site_id.name}</Link></td>
                <td>{shift.site_id.unit} {shift.site_id.street} {shift.site_id.city}</td>
                <td>{new Date(shift.startTime).getHours().toString().padStart(2, '0')}:{new Date(shift.startTime).getMinutes().toString().padStart(2, '0')}</td>
                <td>{shift.clockin.isclockedin ? <>{shift.clockin.date ? <div>{new Date(shift.clockin.date).getHours().toString().padStart(2, '0')}:{new Date(shift.clockin.date).getMinutes().toString().padStart(2, '0')} {((shift.clockin.Geoloc.x && shift.clockin.Geoloc.y) && (shift.site_id.Geoloc.y && shift.site_id.Geoloc.x)) ? (distance(shift.clockin.Geoloc.y, shift.site_id.Geoloc.y, shift.clockin.Geoloc.x, shift.site_id.Geoloc.x) > 1 ? <span style={{color:"red"}} className="cursor-pointer" onClick={() => {setModalShow(true); setSiteCords([shift.site_id.Geoloc.y, shift.site_id.Geoloc.x]); setClockinCords([shift.clockin.Geoloc.y, shift.clockin.Geoloc.x]); setClockoutCords([shift.clockout.Geoloc.y,shift.clockout.Geoloc.x])}} ><FaMapMarkerAlt /></span> : <span style={{color:"green"}} className="cursor-pointer" onClick={() => {setModalShow(true); setSiteCords([shift.site_id.Geoloc.y, shift.site_id.Geoloc.x]); setClockinCords([shift.clockin.Geoloc.y, shift.clockin.Geoloc.x]); setClockoutCords([shift.clockout.Geoloc.y,shift.clockout.Geoloc.x])}}><FaMapMarkerAlt /></span> ): <span style={{color:"grey"}} title="GPS Data Not Available."><FaMapMarkerAlt /></span>}</div> : <>{today.setHours(new Date(shift.startTime).getHours(),new Date(shift.startTime).getMinutes(),0,0) < new Date().getTime() ? <span className="text-danger">Missed</span> : "-"}</>} {shift.clockin.dispatchdate && <> D: {new Date(shift.clockin.dispatchdate).getHours().toString().padStart(2, '0')}:{new Date(shift.clockin.dispatchdate).getMinutes().toString().padStart(2, '0')}</>}</> : <>{today.setHours(new Date(shift.startTime).getHours(),new Date(shift.startTime).getMinutes(),0,0) < new Date().getTime() ? <span className="text-danger">Missed</span> : "-"}</>}</td>
                <td>{new Date(shift.endTime).getHours().toString().padStart(2, '0')}:{new Date(shift.endTime).getMinutes().toString().padStart(2, '0')}</td>
                <td>{shift.clockout.isclockedout ? <>{shift.clockout.date ? <>{new Date(shift.clockout.date).getHours().toString().padStart(2, '0')}:{new Date(shift.clockout.date).getMinutes().toString().padStart(2, '0')} {((shift.clockout.Geoloc.x && shift.clockout.Geoloc.y) && (shift.site_id.Geoloc.y && shift.site_id.Geoloc.x)) ? (distance(shift.clockout.Geoloc.y, shift.site_id.Geoloc.y, shift.clockout.Geoloc.x, shift.site_id.Geoloc.x) > 1 ? <span style={{color:"red"}} className="cursor-pointer" onClick={() => {setModalShow(true); setSiteCords([shift.site_id.Geoloc.y, shift.site_id.Geoloc.x]); setClockinCords([shift.clockin.Geoloc.y, shift.clockin.Geoloc.x]); setClockoutCords([shift.clockout.Geoloc.y,shift.clockout.Geoloc.x])}} ><FaMapMarkerAlt /></span> : <span style={{color:"green"}} className="cursor-pointer" onClick={() => {setModalShow(true); setSiteCords([shift.site_id.Geoloc.y, shift.site_id.Geoloc.x]); setClockinCords([shift.clockin.Geoloc.y, shift.clockin.Geoloc.x]); setClockoutCords([shift.clockout.Geoloc.y,shift.clockout.Geoloc.x])}}><FaMapMarkerAlt /></span> ): <span style={{color:"grey"}} title="GPS Data Not Available."><FaMapMarkerAlt /></span>}</> : <>{new Date(shift.endTime).getTime() < new Date().getTime() ? <span className="text-danger">Missed</span> : "-"}</>} {shift.clockout.dispatchdate && <> D: {new Date(shift.clockout.dispatchdate).getHours().toString().padStart(2, '0')}:{new Date(shift.clockout.dispatchdate).getMinutes().toString().padStart(2, '0')}</>}</> : <>{new Date(shift.endTime).getTime() < new Date().getTime() ? <span className="text-danger">Missed</span> : "-"}</>}</td>
              </tr>
            )
          })}
          
          </tbody>
          </Table>
          </>)}
          
        </div>
      </div>
      
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        site={siteCords.every(element => element === null) ? [] : siteCords}
        clockin={clockinCords.every(element => element === null) ? [] : clockinCords}
        clockout={clockoutCords.every(element => element === null) ? [] : clockoutCords}
        
      />
    </>
  );
};

export default Attendence;
