import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Alerts from "../Alerts";
import { setAlert } from "../../reducers/alert";
import { useSelector, useDispatch } from "react-redux";
import { uploadSiteDoc,downloadDoc, deleteSiteDoc, loadAgencySites } from "../../reducers/sites";
import { BsClock, BsDownload, BsTrash } from "react-icons/bs";
import ConfirmModal from "./ConfirmModal";

const ENTRY_LIMIT = 10;

const SiteDocuments = ({ site_id }) => {
  const dispatch = useDispatch();
  const sites = useSelector((state) => state.sites);
  const uploadLoading = useSelector((state) => state.sites.uploadDocLoading);
  const downloadDocLoading = useSelector((state) => state.sites.downloadDocLoading);
  const deleteDocLoading = useSelector((state) => state.sites.deleteDocLoading);
  const siteData = sites?.sites?.sites?.filter((site) => {
    return site.site_id._id === site_id;
  });
  const site = siteData !== undefined ? siteData[0].site_id : null;

  const [docTitle, setdocTitle] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [key, setkey] = useState("");
  const [document_id, setdocument_id] = useState(null);

  const [showConfirmModal, setshowConfirmModal] = useState(false);

  const handleDelete =()=>{
    setshowConfirmModal(false)
    dispatch(
        deleteSiteDoc({
            document_id,
            site_id,
            key
        })
      )
        .unwrap()
        .then((originalPromiseResult) => {
          dispatch(loadAgencySites({ site_id }));
          dispatch(
            setAlert({
              componentName: "SiteDocumentschanges",
              alertType: "success",
              msg: "Document is deleted.",
            })
          );
        })
        .catch((e) =>
          dispatch(
            setAlert({
              componentName: "SiteDocumentschanges",
              alertType: "danger",
              msg: "Error! Please try again.",
            })
          )
        );
    };

  const handleDownload =({key})=>{
    
    dispatch(
        downloadDoc({
            key,
        })
      )
        .unwrap()
        .then((originalPromiseResult) => {
          dispatch(loadAgencySites({ site_id }));
          dispatch(
            setAlert({
              componentName: "SiteDocumentschanges",
              alertType: "success",
              msg: "Downloading...",
            })
          );
        })
        .catch((e) =>
          dispatch(
            setAlert({
              componentName: "SiteDocumentschanges",
              alertType: "danger",
              msg: "Error! Please try again.",
            })
          )
        );
    };
  
  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    if (!docTitle || !uploadfile) {
      dispatch(
        setAlert({
          componentName: "SiteDocuments",
          alertType: "danger",
          msg: "Please fill all fields.",
        })
      );
      return;
    }

    const formData = new FormData();
    formData.append("docTitle", docTitle);
    formData.append("site_id", site_id);
    formData.append("site-document", uploadfile);
    dispatch(
      uploadSiteDoc({
        formData,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(loadAgencySites({ site_id }));
        dispatch(
          setAlert({
            componentName: "SiteDocuments",
            alertType: "success",
            msg: "Document is uploaded",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "SiteDocuments",
            alertType: "danger",
            msg: "Error! Please try again.",
          })
        )
      );
      setdocTitle("");
      setUploadfile("");
  };
  //handle file upload
  const onFileChange = (e) => {
    setUploadfile(e.target.files[0]);
  };

  if (!site || !siteData || !sites) {
    return <Alert variant="info">No Data found. Please try again.</Alert>;
  }

  return (
    <>
      <div className="my-3">
      <Alerts componentName={"SiteDocumentschanges"} />
      {(showConfirmModal && document_id) && <ConfirmModal 
      show={showConfirmModal}
      title={"Delete Document"} 
      msg={"Document will be permanently deleted. Do you want proceed?"} 
      onHide={() =>setshowConfirmModal(false)} 
      handleClick={handleDelete} 
      />}
        {site.documents.length === 0 ? (
          <Alert variant="info">
            No document is uploaded. Please add below.
          </Alert>
        ) : (
          site.documents.map((item, index) => {
            return (
              <div className="thin-border my-5" key={index}>
                <Row>
                  <div className="col-12 col-md-4 d-flex justify-content-between my-1">
                    <span className="fw-bold">Document :</span>
                    <span className="pe-3"> {item.docTitle}</span>
                  </div>
                  <div className="col-12 col-md-8 d-flex justify-content-end my-1 gap-3">
                    {downloadDocLoading ? <span className="cursor-pointer" ><BsClock /></span> : <span className="cursor-pointer" onClick={()=>{handleDownload({key: item.key})}} >
                      <BsDownload />
                    </span>}
                    {deleteDocLoading ? <span className="pe-3 cursor-pointer" ><BsClock /></span> : <span className="pe-3 cursor-pointer" onClick={()=>{setkey(item.key); setdocument_id(item._id); setshowConfirmModal(true)}} >
                      <BsTrash />
                    </span>}
                  </div>
                </Row>
              </div>
            );
          })
        )}
      </div>
      <Alerts componentName={"SiteDocuments"} />
      {site.documents.length >= ENTRY_LIMIT ? (
        <Alert variant="info">
          Limit reached. Please delete a document to add new.
        </Alert>
      ) : (
        <div className="thin-border my-5">
          <Form>
            <h2>Upload Document </h2>
            <hr />
            <Form.Group className="mb-3" controlId="formBasicText">
              <Form.Label>Document Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Document Name"
                required={"true"}
                value={docTitle}
                onChange={(e) => setdocTitle(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Select Document *Image/pdf/doc/excel/txt*</Form.Label>
              <Form.Control
                type="file"
                required={"true"}
                onChange={onFileChange}
              />
            </Form.Group>

            {uploadLoading ? (
              <Button variant="primary" disabled className="button-flex">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </Button>
            ) : (
              <Button
                variant="primary"
                type="submit"
                onClick={handleUploadSubmit}
              >
                Upload
              </Button>
            )}
          </Form>
        </div>
      )}
    </>
  );
};

export default SiteDocuments;
