import React, { useEffect, useState, useCallback } from "react";
import DisplayTasks from "./DisplayTasks";
import { getUserTasks } from "../../../reducers/tasks";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const UserTasks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tasks = useSelector((state) => state.tasks?.tasks);
  const loading = useSelector((state) => state.tasks?.tasksLoading);

  const [completed, setCompleted] = useState(false);
  const [due, setDue] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const handleCreateTaskClick = useCallback(
    () => navigate("/dashboard/createtask"),
    [navigate]
  );

  let filteredTasks = tasks;
  let taskData = tasks;
  // filter sites based on filters
  completed ? (
    (filteredTasks = filteredTasks?.filter(
      (task) => task.status === "COMPLETED"
    ))
  ) : (
    <></>
  );

  due ? (
    (filteredTasks = filteredTasks?.filter(
      (task) => new Date(task.deadline) < new Date()
    ))
  ) : (
    <></>
  );

  accepted ? (
    (filteredTasks = filteredTasks?.filter((task) => task.accepted === true))
  ) : (
    <></>
  );
  useEffect(() => {
    dispatch(getUserTasks());
  }, []);
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }
  if (!tasks) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div>
          <Alert variant="info">No Tasks found.</Alert>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="card ">
        <div className="displaysites-header">
          <h2>My Tasks</h2>
          <div>
            <Button
              className="button-flex-lg"
              variant="primary"
              onClick={handleCreateTaskClick}
            >
              Create Task
            </Button>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="d-flex justify-content-between align-items-center fw-light ">
          <div className="d-flex gap-2">
            <div className="d-flex gap-1 ">
              <Form.Check
                type={"checkbox"}
                id={`break-checkbox-111`}
                onClick={() => setCompleted((prev) => !prev)}
              />
              Completed
            </div>

            <div className="d-flex gap-2 ">
              <Form.Check
                type={"checkbox"}
                id={`break-checkbox-112`}
                onClick={() => setDue((prev) => !prev)}
              />
              Due
            </div>
            <div className="d-flex gap-2 ">
              <Form.Check
                type={"checkbox"}
                id={`break-checkbox-113`}
                onClick={() => setAccepted((prev) => !prev)}
              />
              Not Accepted
            </div>
          </div>
        </div>
        <hr />
        <DisplayTasks tasks={filteredTasks} />
      </div>
    </>
  );
};

export default UserTasks;
