import configData from "../config/default.json";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

const API_ENDPOINT = configData.API_ENDPOINT;

const initialState = {
  chartLoading: false,
  chartData: null,
  chartError: null,
  broadcastLoading: false,
  broadcastData: null,
  broadcastError: null,
  shiftsLoading: false,
  shiftsData: null,
  shiftsError: null,
  usersLoading: false,
  usersData: null,
  usersError: null,
  recentSitesLoading: false,
  recentSitesData: null,
  recentSitesError: null,
  tasksLoading: false,
  tasksData: null,
  tasksError: null,
  ticketsLoading: false,
  ticketsData: null,
  ticketsError: null,
};

export const getChartData = createAsyncThunk(
  "api/getChartData",
  async ({ startDate, endDate }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      startDate,
      endDate,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/agencyprofile/getchartdata`,
      body,
      config
    );
    return response.data;
  }
);

export const getBroadcastedData = createAsyncThunk(
  "api/getBroadcastedData",
  async ({ startDate, endDate }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      startDate,
      endDate,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/agencyprofile/getdashboardbroadcasteddata`,
      body,
      config
    );
    return response.data;
  }
);

export const getShiftsData = createAsyncThunk(
  "api/getShiftsData",
  async ({ startDate, endDate }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      startDate,
      endDate,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/agencyprofile/getshiftsdata`,
      body,
      config
    );
    return response.data;
  }
);

export const getUsersData2 = createAsyncThunk("api/getUsersData2", async () => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  const response = await axios.get(
    `${API_ENDPOINT}/api/agencyprofile/getdashboarduserdata`
  );
  return response.data;
});

export const getRecentSites = createAsyncThunk(
  "api/getRecentSites",
  async () => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const response = await axios.get(`${API_ENDPOINT}/api/sites/getrecentsite`);
    return response.data;
  }
);

//   Get dashboard data for Tasks
export const loadDashboardTaskData = createAsyncThunk(
  "api/loadDashboardTaskData",
  async ({ startDate, endDate }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      startDate,
      endDate,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/tasks/getdashboarddata`,
      body,
      config
    );
    return response.data;
  }
);

//   Get dashboard data for Tasks
export const loadDashboardTicketsData = createAsyncThunk(
  "api/loadDashboardTicketsData",
  async ({ startDate, endDate }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      startDate,
      endDate,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/support/getdashboardticketsdata`,
      body,
      config
    );
    return response.data;
  }
);

export const dashboardData = createSlice({
  name: "dashboardData",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getChartData.pending, (state, action) => {
        state.chartLoading = true;
      })
      .addCase(getChartData.fulfilled, (state, action) => {
        state.chartLoading = false;
        state.chartData = action.payload;
        state.chartError = null;
      })
      .addCase(getChartData.rejected, (state, action) => {
        state.chartLoading = false;
        state.chartData = null;
        state.chartError = action.error;
        console.log("error in getChartData");
        console.log(action);
      })
      .addCase(getBroadcastedData.pending, (state, action) => {
        state.broadcastLoading = true;
      })
      .addCase(getBroadcastedData.fulfilled, (state, action) => {
        state.broadcastLoading = false;
        state.broadcastData = action.payload;
        state.broadcastError = null;
      })
      .addCase(getBroadcastedData.rejected, (state, action) => {
        state.broadcastLoading = false;
        state.broadcastData = null;
        state.broadcastError = action.error;
        console.log("error in getBroadcastedData");
        console.log(action);
      })
      .addCase(getShiftsData.pending, (state, action) => {
        state.shiftsLoading = true;
      })
      .addCase(getShiftsData.fulfilled, (state, action) => {
        state.shiftsLoading = false;
        state.shiftsData = action.payload;
        state.shiftsError = null;
      })
      .addCase(getShiftsData.rejected, (state, action) => {
        state.shiftsLoading = false;
        state.shiftsData = null;
        state.shiftsError = action.error;
        console.log("error in getShiftsData");
        console.log(action);
      })
      .addCase(getUsersData2.pending, (state, action) => {
        state.usersLoading = true;
      })
      .addCase(getUsersData2.fulfilled, (state, action) => {
        state.usersLoading = false;
        state.usersData = action.payload;
        state.usersError = null;
      })
      .addCase(getUsersData2.rejected, (state, action) => {
        state.usersLoading = false;
        state.usersData = null;
        state.usersError = action.error;
        console.log("error in getUsersData2");
        console.log(action);
      })
      .addCase(getRecentSites.pending, (state, action) => {
        state.recentSitesLoading = true;
      })
      .addCase(getRecentSites.fulfilled, (state, action) => {
        state.recentSitesLoading = false;
        state.recentSitesData = action.payload;
        state.recentSitesError = null;
      })
      .addCase(getRecentSites.rejected, (state, action) => {
        state.recentSitesLoading = false;
        state.recentSitesData = null;
        state.recentSitesError = action.error;
        console.log("error in getRecentSites");
        console.log(action);
      })
      .addCase(loadDashboardTaskData.pending, (state, action) => {
        state.tasksLoading = true;
      })
      .addCase(loadDashboardTaskData.fulfilled, (state, action) => {
        state.tasksLoading = false;
        state.tasksData = action.payload;
        state.tasksError = null;
      })
      .addCase(loadDashboardTaskData.rejected, (state, action) => {
        state.tasksLoading = false;
        state.tasksData = null;
        state.tasksError = action.error;
        console.log("error in loadDashboardTaskData");
        console.log(action);
      })
      .addCase(loadDashboardTicketsData.pending, (state, action) => {
        state.ticketsLoading = true;
      })
      .addCase(loadDashboardTicketsData.fulfilled, (state, action) => {
        state.ticketsLoading = false;
        state.ticketsData = action.payload;
        state.ticketsError = null;
      })
      .addCase(loadDashboardTicketsData.rejected, (state, action) => {
        state.ticketsLoading = false;
        state.ticketsData = null;
        state.ticketsError = action.error;
        console.log("error in loadDashboardTicketsData");
        console.log(action);
      });
  },
});

export default dashboardData.reducer;
