import React, {useState, useEffect} from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';

function Items({ currentItems, startDate, endDate }) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Site Name</th>
          <th>Address</th>
          <th>City</th>
        </tr>
      </thead>
      <tbody>
        {currentItems.map((site, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>

              <td>
                <Link to={`/dashboard/sitereports/${site._id}`} state={{ startDate, endDate }}>
                  {site.name}
                </Link>
              </td>

              <td>
                {site.unit} {site.street}
              </td>
              <td>{site.city}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

const DisplaySiteReport = ({ sitesData , itemsPerPage, startDate, endDate}) => {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(sitesData);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % sitesData.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(sitesData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(sitesData.length / itemsPerPage));
  }, [itemOffset, itemsPerPage,sitesData]);

  useEffect(() => {
    setItemOffset(0)
  }, [ itemsPerPage]);

  
  return (
    <>
    <Items currentItems={currentItems} startDate={startDate} endDate={endDate}/>
    <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
      </>
  );
};

export default DisplaySiteReport;
