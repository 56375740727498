import React, { useEffect, useState, useCallback } from "react";
import {
  loadSitesReport
} from "../../../reducers/reports";
import { AiOutlineLeft, AiOutlineRight, AiOutlineWifi } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { BsX, BsExclamation, BsClock, BsCheck, BsHash } from "react-icons/bs";
import { AiOutlineSearch } from "react-icons/ai";
import {
  startOfWeek,
  endOfWeek,
  addWeeks,
  addMonths,
  endOfDay,
  endOfMonth,
  startOfMonth,
} from "date-fns";
import InputGroup from "react-bootstrap/InputGroup";
import DisplaySiteReport from "./DisplaySiteReport";

const SitesReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reportData = useSelector((state) => state.report.sitesreport);
  const loading = useSelector((state) => state.report.sitesreportLoading);

  const [view, setView] = useState("weekly");
  const offSet = 1;

  const [startDate, setStartDate] = useState(startOfWeek(new Date()));

  const [endDate, setEndDate] = useState(endOfWeek(new Date()));
  const handlePrevClick = () => {
    switch (view) {
      case "monthly":
        setStartDate((prev) => startOfMonth(addMonths(prev, -offSet)));
        setEndDate((prev) => endOfMonth(addMonths(prev, -offSet)));
        break;
      default:
        setStartDate((prev) => addWeeks(prev, -offSet));
        setEndDate((prev) => addWeeks(prev, -offSet));
    }
  };

  const handleNextClick = () => {
    switch (view) {
      case "monthly":
        setStartDate((prev) => startOfMonth(addMonths(prev, offSet)));
        setEndDate((prev) => endOfMonth(addMonths(prev, offSet)));
        break;
      default:
        setStartDate((prev) => addWeeks(prev, offSet));
        setEndDate((prev) => addWeeks(prev, offSet));
    }
  };

  const handleViewChange = (e) => {
    setView(e.target.value);
    switch (e.target.value) {
      case "weekly":
        setStartDate(startOfWeek(new Date()));
        setEndDate(endOfWeek(new Date()));
        break;
      case "monthly":
        setStartDate(startOfMonth(new Date()));
        setEndDate(endOfMonth(new Date()));
        break;
      default:
    }
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end ? endOfDay(end) : end);
  };

  // filters
  const [serachByName, setSearchByName] = useState("");
  const [serachByAddress, setSearchByAddress] = useState("");
  const [serachByCity, setSearchByCity] = useState("");
  const [perPage, setPerPage] = useState(10);

  // filter sites based on search
  let sitesData = reportData ? reportData?.sites : [];
  sitesData = sitesData?.filter((site) =>
    site.name.toLowerCase().includes(serachByName.toLowerCase())
  );
  sitesData = sitesData?.filter(
    (site) =>
    //uncomment below line in production
      // site.site_id?.streetNumber.toString().includes(serachByAddress.toLowerCase()) ||
      site.street.toLowerCase().includes(serachByAddress.toLowerCase())
  );
  sitesData = sitesData?.filter((site) =>
    site.city.toLowerCase().includes(serachByCity.toLowerCase())
  );

  useEffect(() => {
    if (!endDate) {
      return;
    }
    dispatch(
      loadSitesReport({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      })
    );
  }, [endDate]);
  return (
    <>
      <div>
        <div className="reports-stats-panel d-flex gap-3">
          <div className="card card-1">
            <div className="d-flex mb-2">
              <div className="me-3">
                <span
                  style={{ color: "white" }}
                  className=" d-flex justify-content-center align-items-center user-card-badge p-2 display-3"
                >
                  <BsHash />
                </span>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column ">
                  <div className="display-5 lh-1 ">
                    {reportData?.ShiftCount}
                  </div>{" "}
                  <div
                    className="align-self-end fw-light"
                    style={{ fontSize: "12px" }}
                  >
                    Shifts
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-2">
            <div className="d-flex mb-2">
              <div className="me-3">
                <span
                  style={{ color: "white" }}
                  className=" d-flex justify-content-center align-items-center broadcasted-card-logo p-2 display-3"
                >
                  <BsExclamation />
                </span>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column ">
                  <div className="display-5 lh-1 ">
                    {reportData?.invoiceOpen}
                  </div>{" "}
                  <div
                    className="align-self-end fw-light"
                    style={{ fontSize: "12px" }}
                  >
                    Open invoice
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-3">
            <div className="d-flex mb-2">
              <div className="me-3">
                <span
                  style={{ color: "white" }}
                  className=" d-flex justify-content-center align-items-center broadcasted-card-logo p-2 display-3"
                >
                  <BsClock />
                </span>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column ">
                  <div className="display-5 lh-1 ">
                    {reportData?.invoicePending}
                  </div>{" "}
                  <div
                    className="align-self-end fw-light"
                    style={{ fontSize: "12px" }}
                  >
                    Pending invoice
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-4">
            <div className="d-flex mb-2">
              <div className="me-3">
                <span
                  style={{ color: "white" }}
                  className=" d-flex justify-content-center align-items-center user-card-badge p-2 display-3"
                >
                  <BsCheck />
                </span>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column ">
                  <div className="display-5 lh-1 ">
                    {reportData?.invoicePaid}
                  </div>{" "}
                  <div
                    className="align-self-end fw-light"
                    style={{ fontSize: "12px" }}
                  >
                    Paid invoice
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-5">
            <div className="d-flex mb-2">
              <div className="me-3">
                <span
                  style={{ color: "white" }}
                  className=" d-flex justify-content-center align-items-center user-card-logo p-2 display-3"
                >
                  <BsX />
                </span>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column ">
                  <div className="display-5 lh-1 ">
                    {reportData?.invoiceCanceled}
                  </div>{" "}
                  <div
                    className="align-self-end fw-light"
                    style={{ fontSize: "12px" }}
                  >
                    Canceled invoice
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card ">
          <div className="d-flex gap-5">
            <h2 className="d-flex justify-content-center align-items-center gap-5 mb-3 me-5">
              <span>Show: </span>
              <span>
                <Form.Select
                  className="w-100"
                  aria-label="Default select example"
                  value={view}
                  onChange={(e) => handleViewChange(e)}
                >
                  <option value="weekly">Weekly</option>

                  <option value="monthly">Monthly</option>
                  <option value="custom">Custom</option>
                </Form.Select>
              </span>
            </h2>
            {view === "custom" ? (
              <div className="d-flex justify-content-center align-items-center gap-5 mb-3 me-5">
                {" "}
                <div>Date:</div>
                <div>
                  <DatePicker
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center gap-5 mb-3 ms-5">
                <Button variant="primary" onClick={handlePrevClick}>
                  <AiOutlineLeft />
                </Button>
                <div>
                  {startDate.toDateString()} - {endDate?.toDateString()}
                </div>
                <Button variant="primary" onClick={handleNextClick}>
                  <AiOutlineRight />
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="card">
          {loading ? (
            <h3>
              <Spinner animation="border" />
              Loading...
            </h3>
          ) : reportData?.sites?.length === 0 ? (
            <Alert variant="info">
              No Data. Please try selecting different dates.
            </Alert>
          ) : (
            <>
              
      

      <h3>Sites Reports</h3>

      <hr />
      {/* filters */}
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">
          <AiOutlineSearch />
        </InputGroup.Text>
        <Form.Control
          placeholder="Search by name"
          aria-label="search"
          aria-describedby="basic-addon1"
          value={serachByName}
          onChange={(e) => setSearchByName(e.target.value)}
        />
        <Form.Control
          placeholder="Search by address"
          aria-label="search"
          aria-describedby="basic-addon1"
          value={serachByAddress}
          onChange={(e) => setSearchByAddress(e.target.value)}
        />
        <Form.Control
          placeholder="Search by city"
          aria-label="search"
          aria-describedby="basic-addon1"
          value={serachByCity}
          onChange={(e) => setSearchByCity(e.target.value)}
        />

        <InputGroup.Text id="basic-addon2">
          <Form.Select
            defaultValue={perPage}
            onChange={(e) => setPerPage(Number(e.target.value))}
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </Form.Select>
        </InputGroup.Text>
      </InputGroup>
      {/* filters End */}
      <DisplaySiteReport sitesData={sitesData} itemsPerPage={perPage} startDate={startDate} endDate={endDate} />
    
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SitesReport;
