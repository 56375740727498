import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Alert from "react-bootstrap/Alert";

function Items({ currentItems }) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Email</th>
          <th>City</th>
          <th>Availability</th>
        </tr>
      </thead>
      <tbody>
        {currentItems.map((user, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>

              <td>
                <Link to={`/dashboard/users/${user._id}`}>{user.username}</Link>
              </td>
              <td>{user ? user.email : "-"}</td>
              <td>{user.profile ? user.profile.city : "-"}</td>
              <td>
                {user.profile ? (
                  <div className="availability-flex">
                    <span
                      className={
                        user.profile.availability[0].sun &&
                        user.profile.availability[0].sunTill
                          ? "bg-success"
                          : "bg-danger"
                      }
                    >
                      S
                    </span>
                    <span
                      className={
                        user.profile.availability[0].mon &&
                        user.profile.availability[0].monTill
                          ? "bg-success"
                          : "bg-danger"
                      }
                    >
                      M
                    </span>
                    <span
                      className={
                        user.profile.availability[0].tue &&
                        user.profile.availability[0].tueTill
                          ? "bg-success"
                          : "bg-danger"
                      }
                    >
                      T
                    </span>
                    <span
                      className={
                        user.profile.availability[0].wed &&
                        user.profile.availability[0].wedTill
                          ? "bg-success"
                          : "bg-danger"
                      }
                    >
                      W
                    </span>
                    <span
                      className={
                        user.profile.availability[0].thr &&
                        user.profile.availability[0].thrTill
                          ? "bg-success"
                          : "bg-danger"
                      }
                    >
                      T
                    </span>
                    <span
                      className={
                        user.profile.availability[0].fri &&
                        user.profile.availability[0].friTill
                          ? "bg-success"
                          : "bg-danger"
                      }
                    >
                      F
                    </span>
                    <span
                      className={
                        user.profile.availability[0].sat &&
                        user.profile.availability[0].satTill
                          ? "bg-success"
                          : "bg-danger"
                      }
                    >
                      S
                    </span>
                  </div>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

const DisplayUsers = ({ users, itemsPerPage }) => {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(users);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % users.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  useEffect(() => {
    if(!users){
      return
    }
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(users.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(users.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, users]);

  useEffect(() => {
    setItemOffset(0);
  }, [itemsPerPage]);

  if (!users || users.length === 0) {
    return <Alert variant="info">No Data.</Alert>;
  }
  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
};

export default DisplayUsers;
