import configData from "../config/default.json";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

const API_ENDPOINT = configData.API_ENDPOINT;

const initialState = {
  loading: false,
  shifts: null,
  error: null,
};

export const loadAttendence = createAsyncThunk(
  "api/loadAttendence",
  async ({ startDate, endDate }) => {
    const body = JSON.stringify({ startDate, endDate });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts/attendence/`,
      body,
      config
    );
    return response.data;
  }
);

export const attendence = createSlice({
  name: "attendence",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadAttendence.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadAttendence.fulfilled, (state, action) => {
        state.loading = false;
        state.shifts = action.payload;
        state.error = null;
      })
      .addCase(loadAttendence.rejected, (state, action) => {
        state.loading = false;
        state.shifts = null;
        state.error = action.error;
        console.log("error in loadAttendence");
        console.log(action);
      });
  },
});

export default attendence.reducer;
