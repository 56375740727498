import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducers/auth";
import alertReducer from "../reducers/alert";
import agencyProfileReducer from "../reducers/agencyProfile";
import sitesReducer from "../reducers/sites";
import siteReducer from "../reducers/site";
import usersReducer from "../reducers/users";
import userReducer from "../reducers/user";
import attendenceReducer from "../reducers/attendence";
import dashboardReducer from "../reducers/dashboard";
import tasksReducer from "../reducers/tasks";
import supprtReducer from "../reducers/support";
import adminReducer from "../reducers/admindata";
import reportReducer from "../reducers/reports";

export default configureStore({
  reducer: {
    auth: authReducer,
    alert: alertReducer,
    agencyProfile: agencyProfileReducer,
    sites: sitesReducer,
    site: siteReducer,
    users: usersReducer,
    user: userReducer,
    attendence: attendenceReducer,
    dashboardData: dashboardReducer,
    tasks: tasksReducer,
    support: supprtReducer,
    admindata: adminReducer,
    report:reportReducer
  },
  devTools: process.env.NODE_ENV !== "production" ? true : false,
});
