import React, {useState} from "react";
import DisplayTwoColData from "./DisplayTwoColData";
import Form from "react-bootstrap/Form";
import { setAlert } from "../../reducers/alert";
import { useSelector, useDispatch } from "react-redux";
import { loadShifts, broadcastShift } from "../../reducers/site";
import { useParams } from "react-router-dom";
import { param } from "express-validator";
import Alerts from "../Alerts";
import { Link } from "react-router-dom";

const DisplayShiftDetails = ({ shift }) => {
  let params = useParams();
  const dispatch = useDispatch();
  const [radio, setRadio] = useState(shift.boardcasted ? shift.boardcasted : false)
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const parseDate = (date) => {
    return new Date(Date.parse(date));
  };
  const shiftDate = parseDate(shift.shiftDate);
  const shiftStartTime = parseDate(shift.startTime);
  const shiftEndDate = parseDate(shift.endTime);
  let today = new Date(shift.shiftDate)

  const handleRadioUpdate1 = () => {
    dispatch(broadcastShift({ shift_id: shift._id }))
      .unwrap()
      .then((originalPromiseResult) => {
        setRadio((prev)=> !prev)
        dispatch(
          setAlert({
            componentName: "DisplayShiftDetails",
            alertType: "success",
            msg: "Settings are updated.",
          })
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        dispatch(
          setAlert({
            componentName: "DisplayShiftDetails",
            alertType: "danger",
            msg: "Error. Please try again.",
          })
        );
      });
  };

  return (
    <>
    <div className="shift-details-containter py-4 thin-border">
      <div>
        <DisplayTwoColData
          field={"Shift Date"}
          data={`${days[shiftDate.getDay()]} ${shiftDate.getDate()} ${
            months[shiftDate.getMonth()]
          } ${shiftDate.getFullYear()}`}
        />
        <DisplayTwoColData
          field={"Shift Timing"}
          data={`${shiftStartTime.getHours().toString().padStart(2, '0')}:${shiftStartTime.getMinutes().toString().padStart(2, '0')} - ${shiftEndDate.getHours().toString().padStart(2, '0')}:${shiftEndDate.getMinutes().toString().padStart(2, '0')} `}
        />
        <DisplayTwoColData
          field={"Clock in"}
          data={shift.clockin.isclockedin ? <>{shift.clockin.date ? <>{new Date(shift.clockin.date).getHours().toString().padStart(2, '0')}:{new Date(shift.clockin.date).getMinutes().toString().padStart(2, '0')}</> : <>{today.setHours(new Date(shift.startTime).getHours(),new Date(shift.startTime).getMinutes(),0,0) < new Date().getTime() ? <span className="text-danger">Missed</span> : "-"}</>} {shift.clockin.dispatchdate && <> D: {new Date(shift.clockin.dispatchdate).getHours().toString().padStart(2, '0')}:{new Date(shift.clockin.dispatchdate).getMinutes().toString().padStart(2, '0')}</>}</> : <>{today.setHours(new Date(shift.startTime).getHours(),new Date(shift.startTime).getMinutes(),0,0) < new Date().getTime() ? <span className="text-danger">Missed</span> : "-"}</>}
        />
        <DisplayTwoColData
          field={"clock out"}
          data={shift.clockout.isclockedout ? <>{shift.clockout.date ? <>{new Date(shift.clockout.date).getHours().toString().padStart(2, '0')}:{new Date(shift.clockout.date).getMinutes().toString().padStart(2, '0')}</> : <>{new Date(shift.endTime).getTime() < new Date().getTime() ? <span className="text-danger">Missed</span> : "-"}</>} {shift.clockout.dispatchdate && <> D: {new Date(shift.clockout.dispatchdate).getHours().toString().padStart(2, '0')}:{new Date(shift.clockout.dispatchdate).getMinutes().toString().padStart(2, '0')}</>}</> : <>{new Date(shift.endTime).getTime() < new Date().getTime() ? <span className="text-danger">Missed</span> : "-"}</>}
        />
      </div>
      <div>
        <DisplayTwoColData field={"Status"} data={`${shift.status} `} />
        <DisplayTwoColData
          field={"Assigned to"}
          data={shift.employeeName ? <Link to={`/dashboard/users/${shift.user_id}`}>
          {shift.employeeName}
        </Link> : null}
        />
        
        {/* broadcast */}
        <div className="display-company-data">
          <div className="display-info">Broadcasted :</div>
          <div className="display-data">
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={radio}
              onChange={handleRadioUpdate1}
            />
          </div>
        </div>
        <DisplayTwoColData
          field={"More Details"}
          data={<Link to={`/dashboard/shift/${shift._id}`}>
          Details
        </Link>}
        />
        <Alerts componentName={"DisplayShiftDetails"} />
      </div>
      
    </div>
    </>
  );
};

export default DisplayShiftDetails;
