import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Alerts from "../Alerts";
import { setAlert } from "../../reducers/alert";
import { useSelector, useDispatch } from "react-redux";
import Alert from "react-bootstrap/Alert";

import {
  loadAgencyProfile,
} from "../../reducers/agencyProfile";
import AgencyProfile from "./AgencyProfile";
import DisplayTwoColData from "./DisplayTwoColData";

const DisplayAgencyProfile = () => {
  const agencyprofile = useSelector((state) => state.agencyProfile);
  const dispatch = useDispatch();

  const [editProfile, setEditProfile] = useState(false);

  useEffect(() => {
    if (!agencyprofile.agencyprofile) {
      dispatch(loadAgencyProfile());
    }
  }, []);

  if (editProfile || !agencyprofile.agencyprofile) {
    return <AgencyProfile setEditProfile={setEditProfile} />;
  }

  return (
    <div className="card">
      {!agencyprofile.agencyprofile && (
        <Alert variant="danger">Please Create Profile Below to Proceed.</Alert>
      )}
      <Alerts componentName={"agencyprofile"} />

      <h2>Company Profile</h2>
      <hr />
      <DisplayTwoColData
        field={"Company"}
        data={agencyprofile?.agencyprofile?.agencyName}
      />
      <DisplayTwoColData
        field={"Phone"}
        data={agencyprofile?.agencyprofile?.phone}
      />
      <DisplayTwoColData
        field={"Unit"}
        data={agencyprofile?.agencyprofile?.unit}
      />
      <DisplayTwoColData
        field={"Street Number"}
        data={agencyprofile?.agencyprofile?.streetNumber}
      />
      <DisplayTwoColData
        field={"Street"}
        data={agencyprofile?.agencyprofile?.street}
      />
      <DisplayTwoColData
        field={"City"}
        data={agencyprofile?.agencyprofile?.city}
      />
      <DisplayTwoColData
        field={"Provience"}
        data={agencyprofile?.agencyprofile?.provience}
      />
      <DisplayTwoColData
        field={"Country"}
        data={agencyprofile?.agencyprofile?.country}
      />
      <DisplayTwoColData
        field={"Zip"}
        data={agencyprofile?.agencyprofile?.postal}
      />

      <Button
        className="button-flex"
        variant="primary"
        onClick={(e) => {
          setEditProfile(true);
        }}
      >
        Edit
      </Button>
    </div>
  );
};

export default DisplayAgencyProfile;
