import React from 'react'

const DisplayTwoColData = ({field, data}) => {
  return (
    <div className="display-company-data">
        <div className="display-info">{field} :</div>
        <div className="display-data">{data ? data : "-"}</div>
      </div>
  )
}

export default DisplayTwoColData