import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBroadcastedData } from "../../../reducers/dashboard";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { AiOutlineWifi } from "react-icons/ai";
import NavDropdown from "react-bootstrap/NavDropdown";
import DatePicker from "react-datepicker";
import { BsThreeDotsVertical } from "react-icons/bs";

const DatePIcket = ({ startDate, onChange, endDate }) => {
  return (
    <div className="dashboard-dacteselector">
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
      />
    </div>
  );
};

const BroadcastChart = () => {
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.dashboardData);
  const data = dashboardData.broadcastData;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [select, setSelect] = useState(false);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  console.log(select);
  useEffect(() => {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 0);
    dispatch(
      getBroadcastedData({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      })
    );
  }, []);
  useEffect(() => {
    if (!endDate) {
      return;
    }
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 0);
    dispatch(
      getBroadcastedData({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      })
    );
    setSelect(false);
  }, [endDate]);

  if (dashboardData.broadcastLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }
  if (!dashboardData.broadcastData) {
    return (
      <div>
        <Alert variant="info">No Data. Please try again later.</Alert>
      </div>
    );
  }
  return (
    <div className="d-flex flex-column justify-content-between">
      <div className="d-flex mb-2">
        <div className="me-5">
          <span className=" d-flex justify-content-center align-items-center broadcasted-card-logo p-2 display-1">
            <AiOutlineWifi />
          </span>
        </div>
        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex">
            <div className="display-1 lh-1 ">{data.broadcastedShifts}</div>{" "}
            <div className="align-self-end fw-light ">Broadcasted</div>
          </div>
        </div>

        <div className="d-flex w-100 justify-content-end">
          <span className="w-10 user-card-logo-dropdown">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelect((prev) => !prev);
              }}
            >
              <BsThreeDotsVertical />
            </span>
            {select && (
              <DatePIcket
                startDate={startDate}
                onChange={onChange}
                endDate={endDate}
              />
            )}
          </span>
        </div>
      </div>
      <div>
        <hr />
        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex">
            <div className="display-5 lh-1 fw-lighter">{data.applied}</div>{" "}
            <div className="align-self-end fw-lighter"> Users Applied</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BroadcastChart;
