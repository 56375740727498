import React,{ useState, useEffect, useRef  } from 'react';
import Table from 'react-bootstrap/Table';
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import { format } from 'date-fns';
import Form from 'react-bootstrap/Form';
import { CSVLink } from "react-csv";
import { BsDownload } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ConfirmModal from '../ConfirmModal';
import Paystub from './Paystub';




// handleUpdate callback function takes invoiceType, selected_ids
const EmpReportTable = ({data, handleUpdate,user, agencyprofile, ot, startDate, endDate}) => {

  
  let filtereddata = data ? data : [];
  let shiftHours = 0;
  let totalAmount = 0;
  let shift_ids = [];
  let excelData = []
  const csvLink = useRef()
  const [selected_ids, setselected_ids] = useState([])

  //action procees confirm
  const [showConfirmBox, setshowConfirmBox] = useState(false);
  const [confirmBoxTitle, setconfirmBoxTitle] = useState("");
  const [confirmBoxMsg, setconfirmBoxMsg] = useState("");
  const [updateActionType, setupdateActionType] = useState("");

  let empData = []
  //filters
  const [filter, setfilter] = useState("");
  
  
  // filter sites based on filters
  filter !== "" ? (
    (filtereddata = filtereddata?.filter(
      (item) => item.userPayment === filter
    ))
  ) : (
    <></>
  );

  

  const dataGrid = filtereddata && filtereddata.map((item, index)=>{
    let rates = item.site?.rates ? item.site.rates : [];
    let CLOCK_HOURS = (Math.round(((item.shiftsClockMins - item.breakClockMins) / 60 ) * 100 ) / 100);
    let currOt = null;   

    //find rate by coverage type
    let rateObj = rates.find((elm)=> elm.shiftType === item.shiftType)
    //empdata
    let empDataObj = {
      user_id: item.user_id,
      employeeName: item.employeeName,
      hours: CLOCK_HOURS,
      amount: rateObj ? (Math.round((CLOCK_HOURS  * (rateObj.employeeRegular / 100)) * 100 ) / 100) : 0 ,
    }

    //update emp data
    function upsertEmp(array, element) { // (1)
      if(element.user_id === null) return;
      const i = array.findIndex(_element => _element.user_id === element.user_id);
      if (i > -1) {
        if(array[i].hours > ot){
          currOt = element.hours;
        }
        else if(element.hours + array[i].hours > ot){
          currOt = (element.hours + array[i].hours) - ot;
        }
        let tempAmt = rateObj ? (currOt ? ((Math.round(((CLOCK_HOURS - currOt)  * (rateObj.employeeRegular / 100)) * 100 ) / 100) + (Math.round(((currOt)  * (rateObj.employeeOvertime / 100)) * 100 ) / 100)) : Math.round((CLOCK_HOURS  * (rateObj.employeeRegular / 100)) * 100 ) / 100) : 0
        array[i] = {
          ...array[i],
          hours: element.hours + array[i].hours,
          amount: tempAmt + array[i].amount,
        };
      } // (2)
      else {array.push(element);}
    }
    upsertEmp(empData, empDataObj)

    
    
    //get all shift_id in array
    shift_ids.push(item._id);

    

      //count total hours
      shiftHours = shiftHours + CLOCK_HOURS ;
      
      //calculate current shift price
      let currAmount = rateObj ? (currOt ? ((Math.round(((CLOCK_HOURS - currOt)  * (rateObj.employeeRegular / 100)) * 100 ) / 100) + (Math.round(((currOt)  * (rateObj.employeeOvertime / 100)) * 100 ) / 100)) : Math.round((CLOCK_HOURS  * (rateObj.employeeRegular / 100)) * 100 ) / 100) : 0;
      //count total Price
      totalAmount = totalAmount + currAmount;
      
      let timeSheetDif = ((Math.round(+item.shiftHours * 100 ) / 100) - CLOCK_HOURS);
      //create excel data object for excel export
      let excelObj = {
        No: index + 1,
        Site: item.site.name,
        Type: item.shiftType,
        Emp_Name: item.employeeName ? item.employeeName : "-",
        Status:item.userPayment,
        Start:format(new Date(item.startTime), "P HH:mm"),
        Clock_in: item.clockin.dispatchdate ? format(new Date(item.clockin.dispatchdate), "HH:mm") : (item.clockin.date ? format(new Date(item.clockin.date), "HH:mm") : "-"),
        End:format(new Date(item.endTime), "P HH:mm"),
        Clock_out: item.clockout.dispatchdate ? format(new Date(item.clockout.dispatchdate), "HH:mm") :(item.clockout.date ? format(new Date(item.clockout.date), "HH:mm") : "-"),
        Shift_Hours:(Math.round(+item.shiftHours * 100 ) / 100),
        Shift_Clock_Hours:CLOCK_HOURS,
        Break_Shift_Hours:(Math.round((item.breakTotalMinutes / 60 ) * 100 ) / 100),
        Break_Clock_Hours:(Math.round((item.breakClockMins / 60 ) * 100 ) / 100),
        Total_Hours:CLOCK_HOURS,
        Rate:rateObj ? "$"+(rateObj.employeeRegular / 100) : "-",
        Amount:currAmount

      };
      

      excelData = [...excelData, excelObj]
      
      
      return(
        
        
          <tr key={index}>
            <td><Form.Check
  inline
  name="group1"
  type="checkbox"
  onChange={()=>{selected_ids.includes(item._id) ? setselected_ids((prev)=>prev.filter((id)=> id !== item._id)) : setselected_ids((prev)=>[...prev, item._id])}}
  checked={selected_ids.includes(item._id)}
  id={`inline-checkbox-${index}`}
/></td>
          <td><Link to={`/dashboard/shift/${item._id}`}>{index + 1}</Link></td>
          
          <td>{ item.site.name ?  item.site.name : "-"}</td>
          <td>{item.shiftType}</td>
          <td><span className={`reports-invoice-status-${item.userPayment}`}>{item.userPayment}</span></td>
          <td>{format(new Date(item.startTime), "P HH:mm")}</td>
          <td>{item.clockin.dispatchdate ? format(new Date(item.clockin.dispatchdate), "HH:mm") : (item.clockin.date ? format(new Date(item.clockin.date), "HH:mm") : "-")}</td>
          
          <td>{format(new Date(item.endTime), "P HH:mm")}</td>
          <td>{item.clockout.dispatchdate ? format(new Date(item.clockout.dispatchdate), "HH:mm") :(item.clockout.date ? format(new Date(item.clockout.date), "HH:mm") : "-")}</td>
          
          <td>{(Math.round(+item.shiftHours * 100 ) / 100)}</td>
          
          <td><span className={(timeSheetDif > 0.5 || timeSheetDif < -0.5) ? "text-danger" : ""}>{CLOCK_HOURS}</span></td>

          <td>{(Math.round((item.breakTotalMinutes / 60 ) * 100 ) / 100)}</td>
          
          <td>{(Math.round((item.breakClockMins / 60 ) * 100 ) / 100)}</td>

          <td>{CLOCK_HOURS}</td>
          <td>{currOt ? currOt : 0}</td>
          <td>{rateObj ? "$"+(rateObj.employeeRegular / 100) : "-"}</td>
          <td><span className={currAmount <= 0 ? "text-danger" : ""}>${(currAmount).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</span></td>
          
        </tr>
      )
  })

  
  const handleExcelExport = async () => {
    csvLink.current.link.click()
  }

    if (!data || !filtereddata) {
        return (<div className="card"><Alert variant="info">No Data.</Alert></div>)
      }
      
      return (
        <>
        <div className="card">
        <div className='report-data-table'>
          
          <div className='d-flex justify-content-between'>
            <span>
              
            <ButtonGroup className="mb-2">
            <ToggleButton
            key={0}
            id={`radio-0`}
            type="radio"
            variant="outline-secondary"
            name="radio"
            value={""}
            checked={filter === ""}
            onChange={(e) => setfilter(e.currentTarget.value)}
          >
            All
          </ToggleButton>
          <ToggleButton
            key={1}
            id={`radio-1`}
            type="radio"
            variant="outline-secondary"
            name="radio"
            value={"open"}
            checked={filter === "open"}
            onChange={(e) => setfilter(e.currentTarget.value)}
            
          >
            Open
          </ToggleButton>
          <ToggleButton
            key={2}
            id={`radio-2`}
            type="radio"
            variant="outline-secondary"
            name="radio"
            value={"pending"}
            checked={filter === "pending"}
            onChange={(e) => setfilter(e.currentTarget.value)}
          >
            Pending
          </ToggleButton>
          <ToggleButton
            key={3}
            id={`radio-3`}
            type="radio"
            variant="outline-secondary"
            name="radio"
            value={"paid"}
            checked={filter === "paid"}
            onChange={(e) => setfilter(e.currentTarget.value)}
          >
            Paid
          </ToggleButton>
          <ToggleButton
            key={4}
            id={`radio-4`}
            type="radio"
            variant="outline-secondary"
            name="radio"
            value={"canceled"}
            checked={filter === "canceled"}
            onChange={(e) => setfilter(e.currentTarget.value)}
          >
            Canceled
          </ToggleButton>
       
      </ButtonGroup>
              
              
              </span>
            <div>
           { showConfirmBox && <ConfirmModal title={confirmBoxTitle} msg={confirmBoxMsg} onHide={()=>{
            setconfirmBoxTitle("");
            setconfirmBoxMsg("");
            setshowConfirmBox(false)
          }} handleClick={()=>handleUpdate(updateActionType, selected_ids)} show={showConfirmBox}/>}
            <DropdownButton
        as={ButtonGroup}
        title="Action"
        className='me-3'
        align="end"
        disabled={selected_ids.length === 0}
        id="bg-vertical-dropdown-1"
      >
        <Dropdown.Item eventKey="1" onClick={()=>{setconfirmBoxTitle("Update User Payment Type"); setconfirmBoxMsg("Do you want to Mark Selected Shifts Open ?");setshowConfirmBox(true); setupdateActionType("open"); }}>Mark Selected Open</Dropdown.Item>
        <Dropdown.Item eventKey="2" onClick={()=>{setconfirmBoxTitle("Update User Payment Type"); setconfirmBoxMsg("Do you want to Mark Selected Shifts Pending ?");setshowConfirmBox(true); setupdateActionType("pending"); }}>Mark Selected Pending</Dropdown.Item>
        <Dropdown.Item eventKey="2" onClick={()=>{setconfirmBoxTitle("Update User Payment Type"); setconfirmBoxMsg("Do you want to Mark Selected Shifts Paid ?");setshowConfirmBox(true); setupdateActionType("paid"); }}>Mark Selected Paid</Dropdown.Item>
        <Dropdown.Item eventKey="2" onClick={()=>{setconfirmBoxTitle("Update User Payment Type"); setconfirmBoxMsg("Do you want to Mark Selected Shifts Canceled ?");setshowConfirmBox(true); setupdateActionType("canceled"); }}>Mark Selected Canceled</Dropdown.Item>
      </DropdownButton>
      <Button variant="secondary" onClick={handleExcelExport}><BsDownload /> Download</Button>
      <CSVLink
         data={excelData}
         filename='report.csv'
         className='hidden'
         ref={csvLink}
         target='_blank'
      />
    </div>
            {/* <CSVLink data={excelData} onClick={(event, done) => {
    console.log(excelData)
      done(); // REQUIRED to invoke the logic of component
   
  }}>Export <BsDownload /></CSVLink> */}
          </div>
           
          
          <hr />
          
            <Table hover >
          <thead>
            <tr>
              <th><Form.Check
            inline
            onChange={()=> shift_ids.length === selected_ids.length ?  setselected_ids([]): setselected_ids([...shift_ids])}
            checked={shift_ids.length === selected_ids.length}
            name="group1"
            type="checkbox"
            id={`inline-checkbox-head-1`}
          /></th>
              <th>#</th>
              <th>Site</th>
              <th>Type</th>
              <th>Status</th>
              <th>Start</th>
              <th>Clock in</th>

              <th>End</th>
              <th>Clock out</th>
              
              <th>Shift Hours</th>
              
              <th>Clock Hours</th>
              <th>Break Shift Hours</th>
              
              <th>Break Clock Hours</th>
              <th>Total Hours</th>
              <th>OT</th>
              <th>Rate</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {dataGrid}
            
           
            <tr>
              <td colSpan={12}></td>
              <td><span className='fw-bold'>Total</span> </td>
              <td><span className='fw-bold'>{(Math.round(shiftHours * 100 ) / 100)}</span></td>
              <td><span className='fw-bold'>{shiftHours - ot > 0 ? (Math.round((shiftHours - ot) * 100 ) / 100) : 0}</span></td>
              <td></td>
              <td><span className='fw-bold'>${(totalAmount).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</span></td>
            </tr>
          </tbody>
        </Table>
        
        </div>
        </div>
        <Paystub hours={(Math.round(shiftHours * 100 ) / 100)} total={totalAmount}
          user={user}
          agencyprofile={agencyprofile}
          startDate={startDate}
           endDate={endDate}
          />


          {/* <EmpTable empData={empData} ot={ot} /> */}
        </>
      );
}

export default EmpReportTable;