import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { dispatchClockin, dispatchClockout } from "../../reducers/site";
import { assignShift } from "../../reducers/site";
import { setAlert } from "../../reducers/alert";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Alerts from "../Alerts";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";


const DispatchUser = (props) => {
  const dispatch = useDispatch();

  const shiftids = props.selectedshifts;
  const [confirm, setConfirm] = useState(false);
  const [clockinDate, setClockinDate] = useState(null);
  const [clockoutDate, setClockoutDate] = useState(null);

  const handleDispatchClockin = (user_id) => {
    if (!shiftids || shiftids.length === 0) {
      dispatch(
        setAlert({
          componentName: "DispatchUser",
          alertType: "danger",
          msg: "Please Select Shifts.",
        })
      );
      return console.log("Please Select Shifts.");
    }
    if (!clockinDate) {
      dispatch(
        setAlert({
          componentName: "DispatchUser",
          alertType: "danger",
          msg: "Please Select Date and Time.",
        })
      );
      return console.log("Please Select Shifts.");
    }
    setConfirm(true);

    shiftids.forEach((id, index) => {
      dispatch(
        dispatchClockin({
          dispatchDate: clockinDate,
          shift_id: shiftids[index],
        })
      )
        .unwrap()
        .then((originalPromiseResult) => {
          dispatch(
            setAlert({
              componentName: "DispatchUser",
              alertType: "success",
              msg: `User is clocked in for shift #${shiftids[index]}.`,
            })
          );
        })
        .catch((e) =>
          dispatch(
            setAlert({
              componentName: "DispatchUser",
              alertType: "danger",
              msg: `Error in saving data for shift #${shiftids[index]}. Please try again.`,
            })
          )
        );
    });
  };
  const handleDispatchClockout = (user_id) => {
    if (!shiftids || shiftids.length === 0) {
      dispatch(
        setAlert({
          componentName: "DispatchUser",
          alertType: "danger",
          msg: "Please Select Shifts.",
        })
      );
      return console.log("Please Select Shifts.");
    }
    if (!clockoutDate) {
      dispatch(
        setAlert({
          componentName: "DispatchUser",
          alertType: "danger",
          msg: "Please Select Date and Time.",
        })
      );
      return console.log("Please Select Shifts.");
    }
    setConfirm(true);

    shiftids.forEach((id, index) => {
      dispatch(
        dispatchClockout({
          dispatchDate: clockoutDate,
          shift_id: shiftids[index],
        })
      )
        .unwrap()
        .then((originalPromiseResult) => {
          dispatch(
            setAlert({
              componentName: "DispatchUser",
              alertType: "success",
              msg: `User is clocked out for shift #${shiftids[index]}.`,
            })
          );
        })
        .catch((e) =>
          dispatch(
            setAlert({
              componentName: "DispatchUser",
              alertType: "danger",
              msg: `Error in saving data for shift #${shiftids[index]}. Please try again.`,
            })
          )
        );
    });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Dispatch Clock in/out
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Alerts componentName={"DispatchUser"} />

          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center ">
              <DatePicker
                showTimeSelect
                selected={clockinDate}
                onChange={(date) => setClockinDate(date)}
                dateFormat="MMMM d, yyyy HH:mm"
                timeIntervals={5}
                timeFormat="HH:mm"
              />
              <span className="w-100 ms-3"><Button variant="primary" size="sm" onClick={handleDispatchClockin}>
                Clock In
              </Button></span>
            </div>
            <div className="d-flex align-items-center ">
              <DatePicker
                showTimeSelect
                selected={clockoutDate}
                onChange={(date) => setClockoutDate(date)}
                dateFormat="MMMM d, yyyy HH:mm"
                timeIntervals={5}
                timeFormat="HH:mm"
              />
              <span className="w-100 ms-3"><Button variant="primary" size="sm" onClick={handleDispatchClockout}>
                Clock Out
              </Button></span>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={confirm ? props.onHide : props.onCancel}
          >
            Close
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};

export default DispatchUser;
