import React, { useState } from "react";
import Login from "./components/Login";
import Register from "./components/Register";
import Contactus from "./components/Contactus";

import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useDispatch } from "react-redux";
import { emaillistadd } from "./reducers/support";

const Footer = () => {
  const dispatch = useDispatch();
  const [loginShow, setLoginShow] = useState(false);
  const [registerShow, setRegisterShow] = useState(false);
  const [contactShow, setContactShow] = useState(false);
  // emaillist
  const [emaillist, setEmaillist] = useState("");
  const [emailresult, setEmailresult] = useState("");
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  const handleemailsubmit = () => {
    if (!emaillist || !validateEmail(emaillist)) {
      return setEmailresult("empty");
    }
    dispatch(
      emaillistadd({
        email: emaillist,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        setEmailresult("success");
        setEmaillist("");
      })
      .catch((e) => {
        setEmailresult("error");
        setEmaillist("");
      });
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="footer-container">
      <div className="container footer-section pt-5">
        <div className="container footers-section ">
          <div className="row py-4">
            <div className="col-2">
              <h5>Home</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <Link
                    to="/"
                    className="nav-link p-0 text-muted"
                    onClick={scrollToTop}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <HashLink to="/#pricing" className="nav-link p-0 text-muted">
                    Pricing
                  </HashLink>
                </li>
                <li className="nav-item mb-2">
                  <span
                    className="nav-link p-0 text-muted cursor-pointer"
                    onClick={() => setLoginShow(!loginShow)}
                  >
                    Login
                  </span>
                </li>
                <li className="nav-item mb-2">
                  <span
                    className="nav-link p-0 text-muted cursor-pointer"
                    onClick={() => setRegisterShow(!registerShow)}
                  >
                    Sign Up
                  </span>
                </li>
              </ul>
            </div>

            <div className="col-2">
              <h5>Features</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <HashLink to="/#desktop" className="nav-link p-0 text-muted">
                    Scheduling Software
                  </HashLink>
                </li>
                <li className="nav-item mb-2">
                  <HashLink to="/#app" className="nav-link p-0 text-muted">
                    Mobile App
                  </HashLink>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/task-management"
                    className="nav-link p-0 text-muted"
                    onClick={scrollToTop}
                  >
                    Task Management
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/livesupport"
                    className="nav-link p-0 text-muted"
                    onClick={scrollToTop}
                  >
                    Integrated Support Center
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-2">
              <h5>Help</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <span
                    className="nav-link p-0 text-muted cursor-pointer"
                    onClick={() => setContactShow(!contactShow)}
                  >
                    Contact Us
                  </span>
                </li>
                <li className="nav-item mb-2">
                  <HashLink to="/#features" className="nav-link p-0 text-muted">
                    Documentation
                  </HashLink>
                </li>
                <li className="nav-item mb-2">
                  <HashLink to="/#faq" className="nav-link p-0 text-muted">
                    FAQs
                  </HashLink>
                </li>
              </ul>
            </div>

            <div className="col-4 offset-1">
              <form>
                <h5>Subscribe to our newsletter</h5>
                <p>Monthly digest of whats new and exciting from us.</p>
                <div className="d-flex w-100 gap-2">
                  <label for="newsletter1" className="visually-hidden">
                    Email address
                  </label>
                  <input
                    id="newsletter1"
                    type="text"
                    className={
                      emailresult === "empty"
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Email address"
                    value={emaillist}
                    onChange={(e) => setEmaillist(e.target.value)}
                  />
                  {emailresult === "success" ? (
                    <button
                      className="btn btn-success"
                      type="button"
                      disabled={true}
                    >
                      Subscribed
                    </button>
                  ) : emailresult === "error" ? (
                    <button
                      className="btn btn-danger"
                      type="button"
                      disabled={true}
                    >
                      Error
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={handleemailsubmit}
                    >
                      Subscribe
                    </button>
                  )}
                </div>
              </form>
              <div className="get-on-play-store">
                <h5>Download Alpha App:</h5>
                <div className="get-on-play-store-logo">
                  <a
                    href={
                      "https://apps.apple.com/us/app/alpha-scheduling/id1661999942?itsct=apps_box_badge&amp;itscg=30200"
                    }
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      display: "inline-block",
                      "border-radius": "10px",
                      height: "47px",
                    }}
                  >
                    <img
                      src={
                        "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1673395200"
                      }
                      alt="Download on the App Store"
                      style={{
                        "border-radius": "10px",
                        height: "47px",
                      }}
                    />
                  </a>
                  <a
                    href="http://play.google.com/store/apps/details?id=com.deepakpunia.nativeapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      alt="mobile_app"
                      width="180px"
                      height="70px"
                    />
                  </a>
                </div>
              </div>
              <div className="get-on-google-play-text mt-3">
                App Store and App Store Logo are registered trademarks of Apple
                Inc.
                <br />
                Google Play and the Google Play logo are trademarks of Google
                LLC.
              </div>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between align-items-center pb-3">
            <div>© 2023 Alpha Scheduling </div>
            <div>
              <Link to="/privacypolicy" className="nav-link p-0 text-muted">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Login show={loginShow} onHide={() => setLoginShow(false)} />
      <Register show={registerShow} onHide={() => setRegisterShow(false)} />
      <Contactus show={contactShow} onHide={() => setContactShow(false)} />
    </div>
  );
};

export default Footer;
