import configData from "../config/default.json";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

const API_ENDPOINT = configData.API_ENDPOINT;

const initialState = {
  sitesreport: null,
  sitesreportLoading: false,
  sitereport: null,
  sitereportLoading: false,
  reportError: null,
  actionLoading: false,
  userssreport: null,
  usersreportLoading: false,
  userreport: null,
  userreportLoading: false,
};

//   load employees report
export const loadusersReport = createAsyncThunk(
  "api/loadusersReport",
  async ({ startDate, endDate }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      startDate,
      endDate,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts/empreport`,
      body,
      config
    );
    return response.data;
  }
);

//   load sites report
export const loadSitesReport = createAsyncThunk(
  "api/loadSitesReport",
  async ({ startDate, endDate }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      startDate,
      endDate,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/sites/sitesreport`,
      body,
      config
    );
    return response.data;
  }
);

//   load site report
export const loadSiteReport = createAsyncThunk(
    "api/loadSiteReport",
    async ({ startDate, endDate, site_id }) => {
      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }
      const body = JSON.stringify({
        startDate,
        endDate,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${API_ENDPOINT}/api/shifts/sitereport/${site_id}`,
        body,
        config
      );
      return response.data;
    }
  );

//  update site invoice
// receives shift_id = ["_id", "_id", ...., ....] array of shift id's to update
export const updateInvoiceReport = createAsyncThunk(
  "api/updateInvoiceReport",
  async ({ invoiceType, shift_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      invoiceType,
      shift_id,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts/updateinvoice`,
      body,
      config
    );
    return response.data;
  }
);


//   load employee report
export const loadEmpReport = createAsyncThunk(
  "api/loadEmpReport",
  async ({ startDate, endDate, user_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      startDate,
      endDate,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts/userreport/${user_id}`,
      body,
      config
    );
    return response.data;
  }
);

//  update site invoice
// receives shift_id = ["_id", "_id", ...., ....] array of shift id's to update
export const updatePaymentReport = createAsyncThunk(
"api/updatePaymentReport",
async ({ userpaymentType, shift_id }) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const body = JSON.stringify({
    userpaymentType,
    shift_id,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await axios.post(
    `${API_ENDPOINT}/api/shifts/userpayment`,
    body,
    config
  );
  return response.data;
}
);
export const reports = createSlice({
  name: "reports",
  initialState,
  reducers: {
    // changeOffet(state, action) {
    //   state.offset += action.payload
    // },
    // changeView(state, action) {
    //   state.view = action.payload
    // },
  },
  extraReducers(builder) {
    builder
      .addCase(loadSitesReport.pending, (state, action) => {
        state.sitesreportLoading = true;
      })
      .addCase(loadSitesReport.fulfilled, (state, action) => {
        state.sitesreportLoading = false;
        state.sitesreport = action.payload;
        state.reportError = null;
      })
      .addCase(loadSitesReport.rejected, (state, action) => {
        state.sitesreportLoading = false;
        state.sitesreport = null;
        state.reportError = action.error;
        console.log("error in loadSitesReport");
        console.log(action);
      })
      .addCase(loadSiteReport.pending, (state, action) => {
        state.sitereportLoading = true;
      })
      .addCase(loadSiteReport.fulfilled, (state, action) => {
        state.sitereportLoading = false;
        state.sitereport = action.payload;
        state.reportError = null;
      })
      .addCase(loadSiteReport.rejected, (state, action) => {
        state.sitereportLoading = false;
        state.sitereport = null;
        state.reportError = action.error;
        console.log("error in loadSiteReport");
        console.log(action);
      })
      .addCase(updateInvoiceReport.pending, (state, action) => {
        state.actionLoading = true;
      })
      .addCase(updateInvoiceReport.fulfilled, (state, action) => {
        state.actionLoading = false;
        state.reportError = null;
      })
      .addCase(updateInvoiceReport.rejected, (state, action) => {
        state.actionLoading = false;
        state.reportError = action.error;
        console.log("error in updateInvoiceReport");
        console.log(action);
      })
      .addCase(loadusersReport.pending, (state, action) => {
        state.usersreportLoading = true;
      })
      .addCase(loadusersReport.fulfilled, (state, action) => {
        state.usersreportLoading = false;
        state.userssreport = action.payload;
        state.reportError = null;
      })
      .addCase(loadusersReport.rejected, (state, action) => {
        state.usersreportLoading = false;
        state.userssreport = null;
        state.reportError = action.error;
        console.log("error in loadusersReport");
        console.log(action);
      })
      .addCase(loadEmpReport.pending, (state, action) => {
        state.userreportLoading = true;
      })
      .addCase(loadEmpReport.fulfilled, (state, action) => {
        state.userreportLoading = false;
        state.userreport = action.payload;
        state.reportError = null;
      })
      .addCase(loadEmpReport.rejected, (state, action) => {
        state.userreportLoading = false;
        state.userreport = null;
        state.reportError = action.error;
        console.log("error in loadEmpReport");
        console.log(action);
      })
      .addCase(updatePaymentReport.pending, (state, action) => {
        state.actionLoading = true;
      })
      .addCase(updatePaymentReport.fulfilled, (state, action) => {
        state.actionLoading = false;
        state.reportError = null;
      })
      .addCase(updatePaymentReport.rejected, (state, action) => {
        state.actionLoading = false;
        state.reportError = action.error;
        console.log("error in updatePaymentReport");
        console.log(action);
      });
  },
});
// export const { changeOffet, changeView} = reports.actions
export default reports.reducer;
