import React from 'react'
import MySchedule from './MySchedule'

const Schedule = () => {
  return (
    <div className="card">
        <MySchedule />
    </div>
  )
}

export default Schedule