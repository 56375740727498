import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvent,
  useMap,
} from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import markerIconPng from "../../uploads/site.png";
import clockinIconPng from "../../uploads/clock-in.png";
import clockoutIconPng from "../../uploads/clock-out.png";
import Alert from "react-bootstrap/Alert";

//change map center with hook
function MyComponent({ center }) {
  const map = useMap();
  map.panTo(center);
  return null;
}
const AttendenceMap = ({ site = [], clockin = [], clockout = [] }) => {
  const mapRef = useRef();
  const [center, setCenter] = useState([]);

  useEffect(() => {
    setCenter(
      site.length !== 0 ? site : clockin.length !== 0 ? clockin : clockout
    );
  }, []);

  if (center.length === 0) {
    return (
      <div className="d-flex align-items-center justify-content-center h-100 w-100">
        <Alert variant="info">Map is not available. No Data.</Alert>
      </div>
    );
  }
  return (
    <div className="d-flex gap-5">
      <div className="d-flex flex-column justify-content-between align-items-center">
        <div className="cursor-pointer attendence-map-icons">
          {site.length !== 0 ? (
            <div
              className="d-flex flex-column justify-content-between align-items-center"
              onClick={() => setCenter(site)}
            >
              <img src={markerIconPng} alt="site" /> <span>Site</span>
            </div>
          ) : (
            <div
              className="d-flex flex-column justify-content-between align-items-center"
              title="Data not available."
            >
              <img src={markerIconPng} alt="site" /> <span>Site</span>
            </div>
          )}
        </div>
        <div className="cursor-pointer attendence-map-icons">
          {clockin.length !== 0 ? (
            <div
              className="d-flex flex-column justify-content-between align-items-center"
              onClick={() => setCenter(clockin)}
            >
              <img src={clockinIconPng} alt="clockin" /> <span>Clock in</span>
            </div>
          ) : (
            <div
              className="d-flex flex-column justify-content-between align-items-center"
              title="Data not available."
            >
              <img src={clockinIconPng} alt="clockin" /> <span>Clock in</span>
            </div>
          )}
        </div>
        <div className="cursor-pointer attendence-map-icons">
          {clockout.length !== 0 ? (
            <div
              className="d-flex flex-column justify-content-between align-items-center"
              onClick={() => setCenter(clockout)}
            >
              <img src={clockoutIconPng} alt="clockout" />{" "}
              <span>Clock Out</span>
            </div>
          ) : (
            <div
              className="d-flex flex-column justify-content-between align-items-center"
              title="Data not available."
            >
              <img src={clockoutIconPng} alt="clockout" />{" "}
              <span>Clock Out</span>
            </div>
          )}
        </div>
      </div>
      <div className="flex-fill user-card-logo">
        <MapContainer
          style={{ width: "100%", height: "440px" }}
          center={center}
          zoom={13}
          scrollWheelZoom={false}
          ref={mapRef}
        >
          {/* component to change center of map on center change */}
          <MyComponent center={center} />
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {site.length !== 0 && (
            <Marker
              icon={
                new Icon({
                  iconUrl: markerIconPng,
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                })
              }
              position={site}
            >
              <Popup>Site</Popup>
            </Marker>
          )}
          {clockin.length !== 0 && (
            <Marker
              icon={
                new Icon({
                  iconUrl: clockinIconPng,
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                })
              }
              position={clockin}
            >
              <Popup>clockin</Popup>
            </Marker>
          )}
          {clockout.length !== 0 && (
            <Marker
              icon={
                new Icon({
                  iconUrl: clockoutIconPng,
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                })
              }
              position={clockout}
            >
              <Popup>clockout</Popup>
            </Marker>
          )}
        </MapContainer>
      </div>
    </div>
  );
};

export default AttendenceMap;
