import React,{ useState, useEffect, useRef  } from 'react';
import Table from 'react-bootstrap/Table';
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import { format , areIntervalsOverlapping, max , min, differenceInMinutes ,endOfDay , startOfDay , addDays } from 'date-fns';
import Form from 'react-bootstrap/Form';
import { CSVLink } from "react-csv";
import { BsDownload } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ConfirmModal from '../ConfirmModal';
import Invoice from './Invoice';

const EmpTable = ({empData, ot})=>{
  console.log(empData)
  return(
<div className="card">
            <h2>Employees</h2>
            <hr />
    
            <Table hover >
          <thead>
            <tr>
              <th>#</th>
              <th>Emp Name</th>
              <th>Total Hours </th>
              <th>OT</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {empData.map((item, index)=>{
              return(
              <tr key={index}>
                <td>{index + 1}</td>
                <td><Link to={`/dashboard/users/${item.user_id}`}>{item.employeeName}</Link></td>
                <td>{item.hours}</td>
                <td>{(item.hours - ot) > 0 ? item.hours - ot : 0}</td>
                <td>{(item.amount).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</td>
              </tr>)
            })}
          </tbody>
        </Table>
          </div>
  )
}

// handleUpdate callback function takes invoiceType, selected_ids
const SiteReportTable = ({data, rates, handleUpdate, client, agencyprofile, ot}) => {

  
  let filtereddata = data ? data : [];
  let shiftHours = 0;
  let totalAmount = 0;
  let shift_ids = [];
  let excelData = [];
  //let premPayDays = ["02/07/2023", "02/10/2023"];
  const csvLink = useRef()
  const [selected_ids, setselected_ids] = useState([])

  //action procees confirm
  const [showConfirmBox, setshowConfirmBox] = useState(false);
  const [confirmBoxTitle, setconfirmBoxTitle] = useState("");
  const [confirmBoxMsg, setconfirmBoxMsg] = useState("");
  const [updateActionType, setupdateActionType] = useState("");

  let invoiceData = []
  let empData = []
  //filters
  const [filter, setfilter] = useState("");
  
  
  // filter sites based on filters
  filter !== "" ? (
    (filtereddata = filtereddata?.filter(
      (item) => item.invoice === filter
    ))
  ) : (
    <></>
  );

  const getOverlappingMonthsInterval = (r1, r2) => {
    try{if (areIntervalsOverlapping(r1, r2)) {
      const start = max([r1.start, r2.start])
      const end = min([r1.end, r2.end])
      return differenceInMinutes (end, start) 
    } else return 0}
    catch(e){
      console.log(e)
      return 0;
    }
  }

  const dataGrid = filtereddata && rates && filtereddata.map((item, index)=>{
    let CLOCK_HOURS = (Math.round(((item.shiftsClockMins - item.breakClockMins) / 60 ) * 100 ) / 100);
    let currOt = null;   
    let currPremium = null;

    // //check for premium pay 
    // const matchStart = premPayDays.find(element => element === format(new Date(item.startTime), 'MM/dd/yyyy'));
    // const matchEnd = premPayDays.find(element => element === format(new Date(item.endTime), 'MM/dd/yyyy'));
    // if( matchStart && matchEnd && item.startTime >= item.endTime){
    //   console.log("both matched")
    //   currPremium = CLOCK_HOURS;
      
    // } else if(matchStart){
    //   console.log("start matched")
      

    //   const range1 = { start: startOfDay(new Date(matchStart)), end: addDays(new Date(matchStart),1) }
    //   const range2 = { start: new Date(item.startTime), end: new Date(item.endTime) }
    //   const dif = getOverlappingMonthsInterval(range1, range2);
    //   if(dif > 0){
    //     currPremium = dif / 60;
        
    //   }
      
    // }else if(matchEnd){
    //   console.log("end matched")
    //   const range1 = { start: startOfDay(new Date(matchEnd)), end: addDays(new Date(matchEnd), 1) }
    //   const range2 = { start: new Date(item.startTime), end: new Date(item.endTime) }
    //   const dif = getOverlappingMonthsInterval(range1, range2);
    //   if(dif > 0){
    //     currPremium = dif / 60;
    //   }
      
    // }

    //find rate by coverage type
    let rateObj = rates.find((elm)=> elm.shiftType === item.shiftType)
    //empdata
    let empDataObj = {
      user_id: item.user_id,
      employeeName: item.employeeName,
      hours: CLOCK_HOURS,
      premhours:currPremium ? currPremium : 0,
      amount: rateObj ? (Math.round((CLOCK_HOURS  * (rateObj.clientRegular / 100)) * 100 ) / 100) : 0 ,
    }

    //update emp data
    function upsertEmp(array, element) { // (1)
      if(element.user_id === null) return;
      const i = array.findIndex(_element => _element.user_id === element.user_id);
      if (i > -1) {
        if(array[i].hours > ot){
          currOt = element.hours;
        }
        else if(element.hours + array[i].hours > ot){
          currOt = (element.hours + array[i].hours) - ot;
        }
        let tempAmt = rateObj ? (currOt ? ((Math.round(((CLOCK_HOURS - currOt)  * (rateObj.clientRegular / 100)) * 100 ) / 100) + (Math.round(((currOt)  * (rateObj.clientOvertime / 100)) * 100 ) / 100)) : Math.round((CLOCK_HOURS  * (rateObj.clientRegular / 100)) * 100 ) / 100) : 0
        array[i] = {
          ...array[i],
          hours: element.hours + array[i].hours,
          amount: tempAmt + array[i].amount,
        };
      } // (2)
      else {array.push(element);}
    }
    upsertEmp(empData, empDataObj)

    console.log(currOt)
    
    //get all shift_id in array
    shift_ids.push(item._id);

    

      //count total hours
      shiftHours = shiftHours + CLOCK_HOURS ;
      
      //calculate current shift price
      let currAmount = rateObj ? (currOt ? ((Math.round(((CLOCK_HOURS - currOt)  * (rateObj.clientRegular / 100)) * 100 ) / 100) + (Math.round(((currOt)  * (rateObj.clientOvertime / 100)) * 100 ) / 100)) : Math.round((CLOCK_HOURS  * (rateObj.clientRegular / 100)) * 100 ) / 100) : 0;
      //count total Price
      totalAmount = totalAmount + currAmount;
      
      let timeSheetDif = ((Math.round(+item.shiftHours * 100 ) / 100) - CLOCK_HOURS);
      //create excel data object for excel export
      let excelObj = {
        No: index + 1,
        Type: item.shiftType,
        Emp_Name: item.employeeName ? item.employeeName : "-",
        Status:item.invoice,
        Start:format(new Date(item.startTime), "P HH:mm"),
        Clock_in:item.clockin.dispatchdate ? format(new Date(item.clockin.dispatchdate), "HH:mm") : (item.clockin.date ? format(new Date(item.clockin.date), "HH:mm") : "-"),
        End:format(new Date(item.endTime), "P HH:mm"),
        Clock_out:item.clockout.dispatchdate ? format(new Date(item.clockout.dispatchdate), "HH:mm") :(item.clockout.date ? format(new Date(item.clockout.date), "HH:mm") : "-"),
        Shift_Hours:(Math.round(+item.shiftHours * 100 ) / 100),
        Shift_Clock_Hours:CLOCK_HOURS,
        Break_Shift_Hours:(Math.round((item.breakTotalMinutes / 60 ) * 100 ) / 100),
        Break_Clock_Hours:(Math.round((item.breakClockMins / 60 ) * 100 ) / 100),
        Hours_Charged:CLOCK_HOURS,
        Rate:rateObj ? "$"+(rateObj.clientRegular / 100) : "-",
        Amount:currAmount

      };
      //get shift types
      let pdfDataObj = {
        Type: item.shiftType,
        Hours_Charged:CLOCK_HOURS,
        Rate:rateObj ? (rateObj.clientRegular / 100) : 0,
        Amount:currAmount
      };
      function upsert(array, element) { // (1)
        const i = array.findIndex(_element => _element.Type === element.Type);
        if (i > -1) {
          array[i] = {
            ...array[i],
            Hours_Charged: element.Hours_Charged + array[i].Hours_Charged,
            Amount: element.Amount + array[i].Amount
          };
        } // (2)
        else {array.push(element);}
      }
      upsert(invoiceData, pdfDataObj)

      excelData = [...excelData, excelObj]
      
      
      return(
        
        
          <tr key={index}>
            <td><Form.Check
  inline
  name="group1"
  type="checkbox"
  onChange={()=>{selected_ids.includes(item._id) ? setselected_ids((prev)=>prev.filter((id)=> id !== item._id)) : setselected_ids((prev)=>[...prev, item._id])}}
  checked={selected_ids.includes(item._id)}
  id={`inline-checkbox-${index}`}
/></td>
          <td><Link to={`/dashboard/shift/${item._id}`}>{index + 1}</Link></td>
          <td>{item.shiftType}</td>
          <td>{item.employeeName ? item.employeeName : "-"}</td>
          <td><span className={`reports-invoice-status-${item.invoice}`}>{item.invoice}</span></td>
          <td>{format(new Date(item.startTime), "P HH:mm")}</td>
          <td>{item.clockin.dispatchdate ? format(new Date(item.clockin.dispatchdate), "HH:mm") : (item.clockin.date ? format(new Date(item.clockin.date), "HH:mm") : "-")}</td>
          
          <td>{format(new Date(item.endTime), "P HH:mm")}</td>
          <td>{item.clockout.dispatchdate ? format(new Date(item.clockout.dispatchdate), "HH:mm") :(item.clockout.date ? format(new Date(item.clockout.date), "HH:mm") : "-")}</td>
          
          <td>{(Math.round(+item.shiftHours * 100 ) / 100)}</td>
          
          <td><span className={(timeSheetDif > 0.5 || timeSheetDif < -0.5) ? "text-danger" : ""}>{CLOCK_HOURS}</span></td>

          <td>{(Math.round((item.breakTotalMinutes / 60 ) * 100 ) / 100)}</td>
          
          <td>{(Math.round((item.breakClockMins / 60 ) * 100 ) / 100)}</td>

          <td>{CLOCK_HOURS}</td>
          <td>{currOt ? currOt : 0}</td>
          <td>{rateObj ? "$"+(rateObj.clientRegular / 100) : "-"}</td>
          <td><span className={currAmount <= 0 ? "text-danger" : ""}>${(currAmount).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</span></td>
          
        </tr>
      )
  })

  console.log("empData",empData)
  const handleExcelExport = async () => {
    csvLink.current.link.click()
  }

    if (!data || !filtereddata) {
        return (<div className="card"><Alert variant="info">No Data.</Alert></div>)
      }
      if (!rates) {
        return (<div className="card"><Alert variant="info">No rates data!</Alert></div>);
      }
      return (
        <>
        <div className="card">
        <div className='report-data-table'>
          
          <div className='d-flex justify-content-between'>
            <span>
              
            <ButtonGroup className="mb-2">
            <ToggleButton
            key={0}
            id={`radio-0`}
            type="radio"
            variant="outline-secondary"
            name="radio"
            value={""}
            checked={filter === ""}
            onChange={(e) => setfilter(e.currentTarget.value)}
          >
            All
          </ToggleButton>
          <ToggleButton
            key={1}
            id={`radio-1`}
            type="radio"
            variant="outline-secondary"
            name="radio"
            value={"open"}
            checked={filter === "open"}
            onChange={(e) => setfilter(e.currentTarget.value)}
            
          >
            Open
          </ToggleButton>
          <ToggleButton
            key={2}
            id={`radio-2`}
            type="radio"
            variant="outline-secondary"
            name="radio"
            value={"pending"}
            checked={filter === "pending"}
            onChange={(e) => setfilter(e.currentTarget.value)}
          >
            Pending
          </ToggleButton>
          <ToggleButton
            key={3}
            id={`radio-3`}
            type="radio"
            variant="outline-secondary"
            name="radio"
            value={"paid"}
            checked={filter === "paid"}
            onChange={(e) => setfilter(e.currentTarget.value)}
          >
            Paid
          </ToggleButton>
          <ToggleButton
            key={4}
            id={`radio-4`}
            type="radio"
            variant="outline-secondary"
            name="radio"
            value={"canceled"}
            checked={filter === "canceled"}
            onChange={(e) => setfilter(e.currentTarget.value)}
          >
            Canceled
          </ToggleButton>
       
      </ButtonGroup>
              
              
              </span>
            <div>
           { showConfirmBox && <ConfirmModal title={confirmBoxTitle} msg={confirmBoxMsg} onHide={()=>{
            setconfirmBoxTitle("");
            setconfirmBoxMsg("");
            setshowConfirmBox(false)
          }} handleClick={()=>handleUpdate(updateActionType, selected_ids)} show={showConfirmBox}/>}
            <DropdownButton
        as={ButtonGroup}
        title="Action"
        className='me-3'
        align="end"
        disabled={selected_ids.length === 0}
        id="bg-vertical-dropdown-1"
      >
        <Dropdown.Item eventKey="1" onClick={()=>{setconfirmBoxTitle("Update Invoice Type"); setconfirmBoxMsg("Do you want to Mark Selected invoices Open ?");setshowConfirmBox(true); setupdateActionType("open"); }}>Mark Selected Open</Dropdown.Item>
        <Dropdown.Item eventKey="2" onClick={()=>{setconfirmBoxTitle("Update Invoice Type"); setconfirmBoxMsg("Do you want to Mark Selected invoices Pending ?");setshowConfirmBox(true); setupdateActionType("pending"); }}>Mark Selected Pending</Dropdown.Item>
        <Dropdown.Item eventKey="2" onClick={()=>{setconfirmBoxTitle("Update Invoice Type"); setconfirmBoxMsg("Do you want to Mark Selected invoices Paid ?");setshowConfirmBox(true); setupdateActionType("paid"); }}>Mark Selected Paid</Dropdown.Item>
        <Dropdown.Item eventKey="2" onClick={()=>{setconfirmBoxTitle("Update Invoice Type"); setconfirmBoxMsg("Do you want to Mark Selected invoices Canceled ?");setshowConfirmBox(true); setupdateActionType("canceled"); }}>Mark Selected Canceled</Dropdown.Item>
      </DropdownButton>
      <Button variant="secondary" onClick={handleExcelExport}><BsDownload /> Download</Button>
      <CSVLink
         data={excelData}
         filename='report.csv'
         className='hidden'
         ref={csvLink}
         target='_blank'
      />
    </div>
            {/* <CSVLink data={excelData} onClick={(event, done) => {
    console.log(excelData)
      done(); // REQUIRED to invoke the logic of component
   
  }}>Export <BsDownload /></CSVLink> */}
          </div>
           
          
          <hr />
          
            <Table hover >
          <thead>
            <tr>
              <th><Form.Check
            inline
            onChange={()=> shift_ids.length === selected_ids.length ?  setselected_ids([]): setselected_ids([...shift_ids])}
            checked={shift_ids.length === selected_ids.length}
            name="group1"
            type="checkbox"
            id={`inline-checkbox-head-1`}
          /></th>
              <th>#</th>
              <th>Type</th>
              <th>Emp Name</th>
              <th>Status</th>
              <th>Start</th>
              <th>Clock in</th>

              <th>End</th>
              <th>Clock out</th>
              
              <th>Shift Hours</th>
              
              <th>Clock Hours</th>
              <th>Break Shift Hours</th>
              
              <th>Break Clock Hours</th>
              <th>Hours Charged</th>
              <th>OT</th>
              <th>Rate</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {dataGrid}
            
           
            <tr>
              <td colSpan={12}></td>
              <td><span className='fw-bold'>Total</span> </td>
              <td><span className='fw-bold'>{(Math.round(shiftHours * 100 ) / 100)}</span></td>
              <td><span className='fw-bold'>{shiftHours - ot > 0 ? (Math.round((shiftHours - ot) * 100 ) / 100) : 0}</span></td>
              <td></td>
              <td><span className='fw-bold'>${(totalAmount).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}</span></td>
            </tr>
          </tbody>
        </Table>
        
        </div>
        </div>
        <Invoice data={invoiceData} total={(totalAmount).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
          client={client}
          agencyprofile={agencyprofile}
          />


          <EmpTable empData={empData} ot={ot} />
        </>
      );
}

export default SiteReportTable