import React, { useEffect, useState } from "react";
import { loadFilteredShift } from "../../../reducers/site";
import { useSelector, useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import configData from "../../../config/default.json";
import { AiOutlineLeft, AiOutlineRight, AiOutlineWifi } from "react-icons/ai";

import { Link } from "react-router-dom";

const Shifts = () => {
  const dispatch = useDispatch();
  const shifts = useSelector((state) => state.site.filteredshifts);
  let shiftData = shifts?.filter((shift) => shift.shifts.length !== 0);
  const [view, setView] = useState("daily");
  const [offSet, setOffSet] = useState(0);

  //display state on screen
  const [displaystartdate, setDisplaystartdate] = useState(new Date());
  const [displaystartWeek, setDisplaystartWeek] = useState(new Date());
  const [displayEndWeek, setDisplayEndWeek] = useState(new Date());

  const [shiftsFilter, setShiftsFilter] = useState(
    configData.SHIFT_STATUS.OPEN
  );

  let startdate = new Date();
  let endDate = new Date();
  let weekEndDate = new Date();
  let weekStartDate = new Date();
  startdate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 0, 0);

  useEffect(() => {
    dispatch(
      loadFilteredShift({
        startDate: startdate,
        endDate: endDate,
        status: shiftsFilter,
      })
    );
  }, []);

  useEffect(() => {
    handleChange();
  }, [shiftsFilter, view, offSet]);

  const handleChange = () => {
    if (view === "daily") {
      startdate.setDate(startdate.getDate() + offSet);
      endDate.setDate(endDate.getDate() + offSet);

      setDisplaystartdate(startdate);
      dispatch(
        loadFilteredShift({
          startDate: startdate,
          endDate: endDate,
          status: shiftsFilter,
        })
      );
    }
    if (view === "weekly") {
      startdate.setDate(startdate.getDate() + offSet * 7);
      endDate.setDate(endDate.getDate() + offSet * 7);

      const curDate = startdate.getDate();
      let weekStart = curDate - startdate.getDay();
      weekStartDate = new Date(startdate.setDate(weekStart));
      weekEndDate = new Date(startdate.setDate(weekStartDate.getDate() + 6));
      weekEndDate.setHours(23, 59, 0, 0);
      

      setDisplaystartWeek(weekStartDate);
      setDisplayEndWeek(weekEndDate);
      dispatch(
        loadFilteredShift({
          startDate: weekStartDate,
          endDate: weekEndDate,
          status: shiftsFilter,
        })
      );
    }
  };

  const handleViewChange = (e) => {
    setView(e.target.value);
  };

  const handleFilterChange = (e) => {
    setShiftsFilter(e.target.value);
  };

  const handlePrevClick = () => {
    setOffSet((prev) => prev - 1);
  };

  const handleNextClick = () => {
    setOffSet((prev) => prev + 1);
  };

  if (!shifts) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="card ">
        <div className="displaysites-header">
          <h2 className="d-flex align-items-center gap-5">
            <span>Shifts: </span>
            <span>
              <Form.Select
                className="w-100"
                aria-label="Default select example"
                value={shiftsFilter}
                onChange={(e) => handleFilterChange(e)}
              >
                <option value={configData.SHIFT_STATUS.OPEN}>Open</option>
                <option value={configData.SHIFT_STATUS.COVERED}>Covered</option>
              </Form.Select>
            </span>
          </h2>
          <h2 className="d-flex align-items-center gap-5">
            <span>Show: </span>
            <span>
              <Form.Select
                className="w-100"
                aria-label="Default select example"
                value={view}
                onChange={(e) => handleViewChange(e)}
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
              </Form.Select>
            </span>
          </h2>
        </div>
      </div>

      <div className="card ">
        <div className="d-flex justify-content-center align-items-center gap-5 mb-3">
          <Button variant="primary" onClick={handlePrevClick}>
            <AiOutlineLeft />
          </Button>
          <div>
            {" "}
            {view === "weekly" ? (
              <>
                {" "}
                {displaystartWeek.toDateString()} -{" "}
                {displayEndWeek.toDateString()}
              </>
            ) : (
              <>{displaystartdate.toDateString()}</>
            )}
          </div>
          <Button variant="primary" onClick={handleNextClick}>
            <AiOutlineRight />
          </Button>
        </div>
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Site Name</th>
                <th>Address</th>
                <th>City</th>
                <th>Shifts </th>
              </tr>
            </thead>
            <tbody>
              {shiftData.length === 0 && (
                <tr>
                  <td colSpan={5}>No Shift for selected date.</td>
                </tr>
              )}
              {shiftData.map((site, index) => {
                let counter = 0;

                site.shifts.forEach((shift) => {
                  if (shift.shift_id) {
                    counter++;
                  }
                });

                if (counter === 0) {
                  return <></>;
                }
                return (
                  <>
                    <tr key={index}>
                      <td>{index + 1}</td>

                      <td>
                        <Link to={`/dashboard/sites/${site._id}`}>
                          {site.name}
                        </Link>
                      </td>

                      <td>
                        {site.unit} {site.street}
                      </td>
                      <td>{site.city}</td>
                      <td>{counter}</td>
                    </tr>
                    {site.shifts.map((shift) => {
                      if (!shift.shift_id) {
                        return <></>;
                      }

                      return (
                        <>
                          <tr key={index}>
                            <td></td>

                            <td colSpan={3}>
                              <span className="ms-2">
                                <Link
                                  to={`/dashboard/shift/${shift.shift_id._id}`}
                                >
                                  {new Date(
                                    shift.shift_id.shiftDate
                                  ).toDateString()}
                                </Link>
                              </span>
                            </td>

                            <td>
                              <span
                                className={
                                  shift.shift_id.status === "OPEN"
                                    ? "text-danger"
                                    : "text-success"
                                }
                              >
                                {shift.shift_id.status}
                              </span>
                              <span>
                                {shift.shift_id.boardcasted && (
                                  <span
                                    title="This shift is Broadcasted"
                                    className="ps-1 "
                                  >
                                    <AiOutlineWifi />
                                  </span>
                                )}
                              </span>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default Shifts;
