import React, { useState } from "react";
import DisplayShiftDetails from "./DisplayShiftDetails";

const DisplayShiftBar = ({ shift, renderSelected }) => {
  const [showDetails, setShowDetails] = useState(false);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const parseDate = (date) => {
    return new Date(Date.parse(date));
  };
  const shiftDate = parseDate(shift.shiftDate);
  const shiftStartDate = parseDate(shift.startTime);
  const shiftEndDate = parseDate(shift.endTime);
  let clockin = null;
  let clockout = null;
  let clockOverTime = null;
  if (shift.clockin.isclockedin) {
    clockin = shift.clockin.dispatchdate
      ? new Date(shift.clockin.dispatchdate)
      : new Date(shift.clockin.date);
  }
  if (shift.clockout.isclockedout) {
    clockout = shift.clockout.dispatchdate
      ? new Date(shift.clockout.dispatchdate)
      : new Date(shift.clockout.date);
  }
 

  const shiftStart =
    ((shiftStartDate.getHours() * 60 + shiftStartDate.getMinutes()) / 5) *
    0.34722222222;
  let shiftEnd =
    ((shiftEndDate.getHours() * 60 + shiftEndDate.getMinutes()) / 5) *
    0.34722222222;
  let shiftEndOverTime = null;
  if (
    shiftEndDate.getDate() > shiftDate.getDate() ||
    shiftEndDate.getMonth() !== shiftStartDate.getMonth()
  ) {
    shiftEndOverTime = shiftEnd;
    shiftEnd === 0 ? (shiftEndOverTime = null) : (shiftEndOverTime = shiftEnd);
    shiftEnd = 100;
  }

  let clockinMark = null;
  let clockoutMark = null;

  //setting if clockedin and clockedout
  if (clockin && clockout) {
    clockinMark =
      ((clockin.getHours() * 60 + clockin.getMinutes()) / 5) * 0.34722222222;
    clockoutMark =
      ((clockout.getHours() * 60 + clockout.getMinutes()) / 5) * 0.34722222222;

    if (
      clockout.getDate() > clockin.getDate() ||
      clockout.getMonth() !== clockin.getMonth()
    ) {
      clockOverTime = clockoutMark;

      clockoutMark = 100;
    }
  }

  //setting if clocked in but not clockedout
  if (clockin && !clockout) {
    clockinMark =
      ((clockin.getHours() * 60 + clockin.getMinutes()) / 5) * 0.34722222222;
    clockoutMark = clockinMark;
  }

  //setting if not clocked in but clockedout
  if (!clockin && clockout) {
    clockoutMark =
      ((clockout.getHours() * 60 + clockout.getMinutes()) / 5) * 0.34722222222;
    clockinMark = clockoutMark;
  }

  const monthIndex = shiftDate.getMonth();
  const monthName = months[monthIndex];
  const dayName = days[shiftDate.getDay()];

  //set shift bar color
  const shiftBarColor = (shift) => {
    if (shift.status === "COVERED") {
      return "shift-timing shift-covered";
    }
    if (shift.status === "OPEN" && shift.boardcasted) {
      return "shift-timing shift-broadcasted";
    }
    if (shift.status === "OPEN") {
      return "shift-timing shift-open";
    }
    return "shift-timing shift-open";
  };

  // setStyle(shiftStart, shiftEnd);
  // calculate percentage >> 1440 mins in 24 hours/ 5 mins block = 288 blocks (0.34722222222% each block)
  // get minutes from shift timing >> divide into 5 mins block >> calc percentahe then show on screen.
  return (
    <>
      <div className="d-flex w-100 justify-content-between align-items-center gap-2">
        <div className="align-self-start">
          <input
            style={{ marginTop: "65px" }}
            name="travelling"
            onChange={renderSelected}
            type="checkbox"
            value={shift._id}
          />
        </div>
        <div className="w-100">
          <div
            onClick={() => setShowDetails((prev) => !prev)}
            className="shift-container "
          >
            <div className="shift-display-flex">
              <div className="shift-display-data">
                {dayName} {shiftDate.getDate()} {monthName}
              </div>
              <div className="shift-shiftbar">
                <div
                  id="shift-timing"
                  className={shiftBarColor(shift)}
                  style={{
                    left: `${shiftStart}%`,
                    width: `${shiftEnd - shiftStart}%`,
                  }}
                ></div>
                {(clockinMark || clockoutMark) && (
                  <div
                    id="shift-timing"
                    className="shift-timing shift-clocked"
                    style={{
                      left: `${clockinMark}%`,
                      width: `${
                        clockoutMark < clockinMark
                          ? "0"
                          : clockoutMark - clockinMark
                      }%`,
                    }}
                  ></div>
                )}
              </div>
            </div>

            {shiftEndOverTime && (
              <div className="shift-display-flex-2">
                <div className="shift-display-data">
                  {days[shiftEndDate.getDay()]} {shiftEndDate.getDate()}{" "}
                  {months[shiftEndDate.getMonth()]}
                </div>
                <div className="shift-shiftbar">
                  <div
                    id="shift-timing"
                    className={shiftBarColor(shift)}
                    style={{ left: `0`, width: `${shiftEndOverTime}%` }}
                  ></div>
                  {clockOverTime && (
                    <div
                      id="shift-timing"
                      className="shift-timing shift-clocked"
                      style={{
                        left: `-2%`,
                        width: `${clockOverTime}%`,
                      }}
                    ></div>
                  )}
                </div>
              </div>
            )}
          </div>
          {showDetails && <DisplayShiftDetails shift={shift} />}
        </div>
      </div>
    </>
  );
};

export default DisplayShiftBar;
