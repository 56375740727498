import configData from "../config/default.json";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { saveAs } from 'file-saver';

const API_ENDPOINT = configData.API_ENDPOINT;

const initialState = {
  loading: false,
  site: null,
  sites: null,
  error: null,
  uploadDocLoading: false,
  deleteDocLoading: false,
  downloadDocLoading: false
};

export const createSite = createAsyncThunk(
  "api/createSite",
  async ({
    name,
    country,
    provience,
    city,
    unit,
    street,
    geox,
    geoy,
    streetNumber,
    postal,
  }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      name,
      country,
      provience,
      city,
      unit,
      street,
      geox,
      geoy,
      streetNumber,
      postal,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/sites/createsite`,
      body,
      config
    );
    return response.data;
  }
);

export const updateSite = createAsyncThunk(
  "api/updateSite",
  async ({
    site_id,
    name,
    country,
    provience,
    city,
    unit,
    street,
    geox,
    geoy,
    streetNumber,
    postal,
  }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      name,
      country,
      provience,
      city,
      unit,
      street,
      geox,
      geoy,
      streetNumber,
      postal,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/sites/updatesite/${site_id}`,
      body,
      config
    );
    return response.data;
  }
);
export const addSiteNote = createAsyncThunk(
  "api/addShiftNote",
  async ({ noteTitle, noteDetails, flag, site_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      noteTitle,
      noteDetails,
      flag,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/sites/addnote/${site_id}`,
      body,
      config
    );
    return response.data;
  }
);
export const addSiteOrders = createAsyncThunk(
  "api/addSiteOrders",
  async ({ SiteOrderTitle, SiteOrderDetails, site_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      SiteOrderTitle,
      SiteOrderDetails,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/sites/addsiteorders/${site_id}`,
      body,
      config
    );
    return response.data;
  }
);
export const deleteSiteOrders = createAsyncThunk(
  "api/deleteSiteOrders",
  async ({ siteorders_id, site_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      siteorders_id,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/sites/deletesiteorders/${site_id}`,
      body,
      config
    );
    return response.data;
  }
);

export const addSiteRate = createAsyncThunk(
  "api/addSiteRate",
  async ({
    shiftType,
    clientRegular,
    clientPremium,
    clientOvertime,
    employeeRegular,
    employeePremium,
    employeeOvertime,
    site_id,
  }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      shiftType,
      clientRegular,
      clientPremium,
      clientOvertime,
      employeeRegular,
      employeePremium,
      employeeOvertime,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/sites/addsiterate/${site_id}`,
      body,
      config
    );
    return response.data;
  }
);

export const updateSiteRate = createAsyncThunk(
  "api/updateSiteRate",
  async ({
    rate_id,
      clientRegular,
      clientPremium,
      clientOvertime,
      employeeRegular,
      employeePremium,
      employeeOvertime,
    site_id,
  }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      rate_id,
      clientRegular,
      clientPremium,
      clientOvertime,
      employeeRegular,
      employeePremium,
      employeeOvertime,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/sites/updatesiterate/${site_id}`,
      body,
      config
    );
    return response.data;
  }
);

export const deleteSiteRate = createAsyncThunk(
  "api/deleteSiteRate",
  async ({ rate_id, site_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      rate_id,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/sites/deletesiterate/${site_id}`,
      body,
      config
    );
    return response.data;
  }
);

export const loadAgencySites = createAsyncThunk(
  "api/loadAgencySites",
  async () => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const response = await axios.get(`${API_ENDPOINT}/api/sites/getagencysite`);
    return response.data;
  }
);

export const uploadSiteDoc = createAsyncThunk(
  "api/uploadSiteDoc",
  async ({ formData}) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const response = await axios.post(
      `${API_ENDPOINT}/api/sites/uploaddocument`,
      formData
    );
    return response.data;
  }
);

export const deleteSiteDoc = createAsyncThunk(
  "api/deleteSiteDoc",
  async ({ site_id, document_id, key }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({
      site_id, document_id, key
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/sites/deletedocument`,
      body,
      config
    );
    return response.data;
  }
);

export const downloadDoc = createAsyncThunk(
  "api/downloadDoc",
  async ({  key }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    
    const response = await axios.get(
      `${API_ENDPOINT}/api/userprofile/downloaddocument/${key}`
    );
    saveAs(response.data.downloadUrl);
    return response.data;
  }
);

export const sites = createSlice({
  name: "sites",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createSite.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createSite.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createSite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        console.log("error in createSite");
        console.log(action);
      })
      .addCase(loadAgencySites.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadAgencySites.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.sites = action.payload;
      })
      .addCase(loadAgencySites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.sites = null;
        console.log("error in loadAgencySites");
        console.log(action);
      })
      .addCase(updateSite.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateSite.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateSite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        console.log("error in updateSite");
        console.log(action);
      })
      .addCase(addSiteNote.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addSiteNote.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(addSiteNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        console.log("error in addSiteNote");
        console.log(action);
      })
      .addCase(addSiteOrders.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addSiteOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(addSiteOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        console.log("error in addSiteOrders");
        console.log(action);
      })
      .addCase(deleteSiteOrders.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteSiteOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteSiteOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        console.log("error in deleteSiteOrders");
        console.log(action);
      })
      .addCase(addSiteRate.pending, (state, action) => {})
      .addCase(addSiteRate.fulfilled, (state, action) => {})
      .addCase(addSiteRate.rejected, (state, action) => {
        state.error = action.error;
        console.log("error in addSiteRate");
        console.log(action);
      })
      .addCase(deleteSiteRate.pending, (state, action) => {})
      .addCase(deleteSiteRate.fulfilled, (state, action) => {})
      .addCase(deleteSiteRate.rejected, (state, action) => {
        state.error = action.error;
        console.log("error in deleteSiteRate");
        console.log(action);
      })
      .addCase(updateSiteRate.pending, (state, action) => {})
      .addCase(updateSiteRate.fulfilled, (state, action) => {})
      .addCase(updateSiteRate.rejected, (state, action) => {
        state.error = action.error;
        console.log("error in updateSiteRate");
        console.log(action);
      })
      .addCase(uploadSiteDoc.pending, (state, action) => {
        state.uploadDocLoading = true;
      })
      .addCase(uploadSiteDoc.fulfilled, (state, action) => {
        state.uploadDocLoading = false;
      })
      .addCase(uploadSiteDoc.rejected, (state, action) => {
        state.error = action.error;
        state.uploadDocLoading = false;
        console.log("error in uploadSiteDoc");
        console.log(action);
      })
      .addCase(deleteSiteDoc.pending, (state, action) => {
        state.deleteDocLoading = true;
      })
      .addCase(deleteSiteDoc.fulfilled, (state, action) => {
        state.deleteDocLoading = false;
      })
      .addCase(deleteSiteDoc.rejected, (state, action) => {
        state.error = action.error;
        state.deleteDocLoading = false;
        console.log("error in deleteSiteDoc");
        console.log(action);
      })
      .addCase(downloadDoc.pending, (state, action) => {
        state.downloadDocLoading = true;
      })
      .addCase(downloadDoc.fulfilled, (state, action) => {
        state.downloadDocLoading = false;
      })
      .addCase(downloadDoc.rejected, (state, action) => {
        state.error = action.error;
        state.downloadDocLoading = false;
        console.log("error in downloadDoc");
        console.log(action);
      });
  },
});

export default sites.reducer;
