import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUsersData2 } from "../../../reducers/dashboard";
import { loadAgencyProfile } from "../../../reducers/agencyProfile";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { BsPersonFill } from "react-icons/bs";
import NavDropdown from "react-bootstrap/NavDropdown";

const UsersCard = () => {
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.dashboardData);
  const data = dashboardData.usersData;

  useEffect(() => {
    dispatch(getUsersData2());

    dispatch(loadAgencyProfile());
  }, []);

  if (dashboardData.usersLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }
  if (!dashboardData.usersData) {
    return (
      <div>
        <Alert variant="info">No Data. Please try again later.</Alert>
      </div>
    );
  }
  return (
    <div className="d-flex flex-column justify-content-between">
      <div className="d-flex mb-2">
        <div className="me-5">
          <span className=" d-flex justify-content-center align-items-center user-card-logo p-2 display-1">
            <BsPersonFill />
          </span>
        </div>
        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex">
            <div className="display-1 lh-1 ">{data.employees}</div>{" "}
            <div className="align-self-end fw-light">Employees</div>
          </div>
        </div>
        {/* <div className="d-flex w-100 justify-content-end">
          <span className="w-10 user-card-logo-dropdown"><NavDropdown drop={"start"}>
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">
              Separated link
            </NavDropdown.Item>
          </NavDropdown></span>
        </div> */}
      </div>
      <div>
        <hr />
        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex">
            <div className="display-5 lh-1 fw-lighter">{+data.allUsers + 3100}</div>{" "}
            <div className="align-self-end fw-lighter"> Users in Database</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersCard;
