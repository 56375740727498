import React from 'react';
import ImageModal from "./ImageModal";
import Image1 from "./assets/create-shift-5.jpg";
import Image2 from "./assets/create-shift-6.jpg";
import Image3 from "./assets/create-shift-7.jpg";
import Image4 from "./assets/create-shift-8.jpg";
import Image5 from "./assets/create-shift-9.jpg";
import Image6 from "./assets/actions-shift-2.jpg";
import Image7 from "./assets/actions-shift-3.jpg";

const ShiftActionsDocs = () => {
  const [imageShow, setImageShow] = React.useState(false);
  const [imageData, setImageData] = React.useState(null);
    const onThisPage = [
        {
          link: "#shiftactions",
          desc: "Shift Actions",
        },
        {
          link: "#assignshift",
          desc: "Assign Shift",
        },
        {
          link: "#shiftcolorcodes",
          desc: "Shift Color Codes",
        },
        {
          link: "#dispatch",
          desc: "Dispatch Clock in and Clock out",
        },
        {
          link: "#visualclock",
          desc: "Visiual Clock in and Clock out",
        },
        {
          link: "#shiftdetails",
          desc: "Shift Details",
        },
        {
          link: "#map",
          desc: "Map",
        }
      ];
      return (
        <div className="d-flex">
          <div className="docs-sub-left-content">
            <h1 id="shiftactions">Shift Actions</h1>
            <p>
            Shift actions can be performed on any shift. This guide will help you understand shift actions.
            </p>
            <div className="bd-callout-info bd-callout ">
          <h5>Permissions</h5>
          <p>
            Admin, Administrator and Schedular has permission to perform shift actions.
          </p>
        </div>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">Actions</span> dropdown on Schedule tab to see available actions.</p>
        <p><i class="docs-arrow"></i>More actions are available on shift details page.</p>
        
        

        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image1);
            setImageShow(true);
          }}
        >
          <img src={Image1} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="assignshift">Assign Shift</h2>
        <p><i class="docs-arrow"></i>Select <span className="text-highlight">Assign</span> action.</p>
        <p><i class="docs-arrow"></i>Click <span className="text-highlight">Assign</span> button in same row as username to whom shift is to be assigned.</p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image2);
            setImageShow(true);
          }}
        >
          <img src={Image2} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="shiftcolorcodes">Shift Color Codes</h2>
        <p><i class="docs-arrow"></i>Color codes are to differencitae between shifts easily.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Green</span><sup>1</sup> Covered shift.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Yellow</span><sup>2</sup> Broacasted shift.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Red</span><sup>3</sup> Open shift.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Details</span><sup>4</sup> Click on details to go to shift details page.</p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image3);
            setImageShow(true);
          }}
        >
          <img src={Image3} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="dispatch">Dispatch clock in and clock out</h2>
        <p><i class="docs-arrow"></i>Select <span className="text-highlight">Clock In/Out</span> action.</p>
        <p><i class="docs-arrow"></i>Select clock in or clock out date and time.</p>
        <p><i class="docs-arrow"></i>Click<span className="text-highlight">Clock In</span> or <span className="text-highlight">Clock Out</span> button.</p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image4);
            setImageShow(true);
          }}
        >
          <img src={Image4} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="visualclock">Visiual Clock in and Clock out</h2>
        <p><i class="docs-arrow"></i>Employee clock in and clock out are visible on shift.</p>
        <p><i class="docs-arrow"></i>Dispatch clock in or clock out will override employee clock in and clock out.</p>
        <p><i class="docs-arrow"></i>Employee clock in and clock out time are available anytime on shift details page.</p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image5);
            setImageShow(true);
          }}
        >
          <img src={Image5} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="shiftdetails">Shift Details</h2>
        <p><i class="docs-arrow"></i><span className="text-highlight">Break Dispatch</span><sup>1</sup> Dispatch employee for breaks.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Dispatch</span><sup>2</sup> Dispatch shift clock in and clock out.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Broadcast</span><sup>3</sup> Broadcast shift to global network.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Local Broadcast</span><sup>4</sup> Broadcast shift to local network.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Assign</span><sup>5</sup> Assign employee to shift.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Remove</span><sup>6</sup> Remove employee from shift.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Applied</span><sup>7</sup> Users applied to Broacasted shift.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Map</span><sup>8</sup> Open map for current shift.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Delete</span><sup>9</sup> Delete shift.</p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image6);
            setImageShow(true);
          }}
        >
          <img src={Image6} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="map">Map</h2>
        <p><i class="docs-arrow"></i>Map can show site location, employee clock in location and employee clockout locaton.</p>
        <p><i class="docs-arrow"></i>Use button on left side to navigate on map.</p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image7);
            setImageShow(true);
          }}
        >
          <img src={Image7} alt="create site 1" width={"100%"}></img>
        </div>
        


            {imageShow && imageData && (
          <ImageModal
            show={imageShow}
            onHide={() => setImageShow(false)}
            imageData={imageData}
          />
        )}
          </div>
          <div className="docs-sub-right-content">
            <div>On this page</div>
            <hr />
            <ul>
              {onThisPage.map((item, index) => {
                return (
                  <li key={index}>
                    <a href={item.link}>{item.desc}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      
);
}

export default ShiftActionsDocs