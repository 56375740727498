import React, { useEffect, useState, useCallback } from "react";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import DisplayTickets from "./DisplayTickets";
import { loadAgencyTickets, getAllsupportTicketsAdmin } from "../../../reducers/support";
import { loadDashboardTicketsData } from "../../../reducers/dashboard";
import { BsCheck, BsExclamation, BsClock } from "react-icons/bs";
import TicketChartCard from "./TicketChartCard";
import CreateTicket from "./CreateTicket";

const AgencyTickets = () => {
  const dispatch = useDispatch();
  const tickets = useSelector((state) => state.support?.tickets);
  const role = useSelector((state) => state.auth?.user?.roles);
  const loading = useSelector((state) => state.support?.ticketsLoading);
  const ticketsStats = useSelector((state) => state.dashboardData?.ticketsData);
  const loadingStats = useSelector(
    (state) => state.dashboardData?.ticketsLoading
  );

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );

  const [completed, setCompleted] = useState(false);
  const [due, setDue] = useState(false);
  const [createButton, setCreateButton] = useState(false);

  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  let filteredTickets = tickets;
  // filter sites based on filters
  completed ? (
    (filteredTickets = filteredTickets?.filter(
      (ticket) => ticket.solved === true
    ))
  ) : (
    <></>
  );

  due ? (
    (filteredTickets = filteredTickets?.filter(
      (ticket) => ticket.solved === false
    ))
  ) : (
    <></>
  );

  useEffect(() => {
    if (!endDate) {
      return;
    }
    dispatch(
      loadDashboardTicketsData({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      })
    );
    if(role.includes("admin")){
      dispatch(
        getAllsupportTicketsAdmin()
      );
      return;
    }
    
    dispatch(
      loadAgencyTickets({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      })
    );
    
  }, [endDate]);

  if (loading || loadingStats) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }
  if (!ticketsStats) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div>
          <Alert variant="info">No Data found.</Alert>
        </div>
      </div>
    );
  }

  return (
    <div className="support-dashboard-style">
      <div className="card card-1">
        <div className="d-flex justify-content-between align-items-center fw-light ">
          <div className="d-flex gap-2">
            <h2>Support</h2>
          </div>
          <div>
            <Button
              className="button-flex-lg"
              variant="success"
              onClick={() => setCreateButton((prev) => !prev)}
            >
              Create Ticket
            </Button>
          </div>
        </div>
        <hr />
        {createButton && <CreateTicket />}
        <div className="d-flex justify-content-between align-items-center fw-light ">
          <div className="d-flex gap-2">
            <div className="d-flex gap-1 ">
              <Form.Check
                type={"checkbox"}
                id={`break-checkbox-111`}
                onClick={() => setCompleted((prev) => !prev)}
              />
              Solved
            </div>

            <div className="d-flex gap-2 ">
              <Form.Check
                type={"checkbox"}
                id={`break-checkbox-112`}
                onClick={() => setDue((prev) => !prev)}
              />
              Pending
            </div>
          </div>

          <div className="d-flex gap-2 align-items-center">
            <div>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <DisplayTickets tickets={filteredTickets} />
        </div>
      </div>
      <div className="card card-2">
        <div className="d-flex mb-2">
          <div className="me-5">
            <span
              style={{ color: "white" }}
              className=" d-flex justify-content-center align-items-center user-card-badge p-2 display-1"
            >
              <BsCheck />
            </span>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex">
              <div className="display-1 lh-1 ">
                {ticketsStats[0]?.COMPLETED ? ticketsStats[0]?.COMPLETED : 0}
              </div>{" "}
              <div
                className="align-self-end fw-light"
                style={{ fontSize: "12px" }}
              >
                Solved
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-3">
        <div className="d-flex mb-2">
          <div className="me-5">
            <span
              style={{ color: "white" }}
              className=" d-flex justify-content-center align-items-center user-card-logo p-2 display-1"
            >
              <BsClock />
            </span>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex">
              <div className="display-1 lh-1 ">
                {ticketsStats[0]?.DUE ? ticketsStats[0]?.DUE : 0}
              </div>{" "}
              <div
                className="align-self-end fw-light"
                style={{ fontSize: "12px" }}
              >
                Due
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-4">
        <TicketChartCard
          total={ticketsStats[0]?.TOTAL ? ticketsStats[0]?.TOTAL : 0}
          solved={ticketsStats[0]?.COMPLETED ? ticketsStats[0]?.COMPLETED : 0}
          due={ticketsStats[0]?.DUE ? ticketsStats[0]?.DUE : 0}
          loading={loadingStats}
        />
      </div>
    </div>
  );
};

export default AgencyTickets;
