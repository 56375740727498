import React, { useState } from "react";
import Register from "./components/Register";

const Cta = () => {
    const [registerShow, setRegisterShow] = useState(false);
  return (
   
    <div className="cta-container">
    <div className="container cta-section py-5 d-flex justify-content-center align-items-center">
      <div className="container ctas-section ">
        <div className="d-flex flex-column justify-content-between align-items-center gap-3 text-center">
          <h1
            className="display-5 fw-bold lh-1 mb-3"
            data-aos="fade-up"
            data-aos-duration="20"
            data-aos-delay="20"
            data-aos-easing="ease-in-out"
          >
            Try Alpha Scheduling for Free
          </h1>
          <p
            className="lead"
            data-aos="fade-up"
            data-aos-duration="20"
            data-aos-delay="100"
            data-aos-easing="ease-in-out"
          >
            Try alpha employee scheduling software and see how we can help
            your business run smoothly. It's a reliable tool for scheduling
            employees. Alpha scheduling saves managers and employees the
            frustration of a missed shift or day off.
          </p>
          <div
            className="d-flex justify-content-start align-items-center gap-3"
            data-aos="fade-up"
            data-aos-duration="20"
            data-aos-delay="200"
            data-aos-easing="ease-in-out"
          >
            <div
              className="shaddow-button py-3 px-5 button-font"
              onClick={() => setRegisterShow(!registerShow)}
            >
              Start Free
            </div>
          </div>
        </div>
      </div>
    </div>
    <Register show={registerShow} onHide={() => setRegisterShow(false)} />
  </div>
  
  )
}

export default Cta