import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { AiOutlineSearch, AiOutlinePlus } from "react-icons/ai";
import CreateUser from "./CreateUser";

const Users = () => {
  const [createUserShow, setCreateUserShow] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className="card ">
        <div className="displaysites-header">
          <h2>Employees</h2>
          <span className="displaysites-header-btn-container">
            <Button
              className="button-flex-sm me-4"
              variant="primary"
              onClick={() => setCreateUserShow(true)}
            >
              <AiOutlinePlus />
            </Button>
            <Button
              className="button-flex-lg"
              variant="primary"
              onClick={(e) => {
                navigate("/dashboard/users");
              }}
            >
              Employees
            </Button>
            {/* alpha data access is disabled */}
            {/* <Button
              className="button-flex-lg"
              variant="primary"
              onClick={(e) => {
                navigate("/dashboard/users/employees");
              }}
            >
              Database
            </Button> */}
            <Button
              variant="primary"
              onClick={(e) => {
                navigate("/dashboard/users/search");
              }}
            >
              <AiOutlineSearch />
            </Button>
          </span>
        </div>
        <CreateUser
          show={createUserShow}
          onHide={() => setCreateUserShow(false)}
        />
      </div>
      <Outlet />
    </>
  );
};

export default Users;
