import React from "react";
import { Link } from "react-router-dom";

const NoAccess = () => {
  return (
    <div>
      <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="text-center">
          <h1 class="display-1 fw-bold">Access Restricted</h1>
          <p class="fs-3">
            {" "}
            <span class="text-danger">Opps!</span> You don't have permission to
            access this page.
          </p>
          <p class="lead">If you think this is an error, please contact us.</p>
          <Link to="/">
            <span className="btn btn-primary">Go Home</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoAccess;
