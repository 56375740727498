import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { removeUserFromShift } from "../../reducers/site";
import { setAlert } from "../../reducers/alert";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Alerts from "../Alerts";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

const RemoveUserFromShift = (props) => {
  const dispatch = useDispatch();
  const shiftids = props.selectedshifts;

  const [confirm, setConfirm] = useState(false);

  const handleRemove = () => {
    
    if (!shiftids || shiftids.length === 0) {
      dispatch(
        setAlert({
          componentName: "RemoveUserFromShift",
          alertType: "danger",
          msg: "Please Select Shifts.",
        })
      );
      return console.log("Please Select Shifts.");
    }
    setConfirm(true);
    shiftids.forEach((id, index) => {
      dispatch(
        removeUserFromShift({
          shift_id: shiftids[index],
        })
      )
        .unwrap()
        .then((originalPromiseResult) => {
          dispatch(
            setAlert({
              componentName: "RemoveUserFromShift",
              alertType: "success",
              msg: `User is removed from shift #${shiftids[index]}.`,
            })
          );
        })
        .catch((e) =>
          dispatch(
            setAlert({
              componentName: "RemoveUserFromShift",
              alertType: "danger",
              msg: `Error in saving data for shift #${shiftids[index]}. Please try again.`,
            })
          )
        );
    });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           Remove Employee
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Alerts componentName={"RemoveUserFromShift"} />
          Employees will be removed from all selected shifts. Click save to proceed.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={confirm ? props.onHide : props.onCancel}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleRemove}>
            Remove
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};

export default RemoveUserFromShift;
