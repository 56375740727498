import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Alerts from "../Alerts";
import { setAlert } from "../../reducers/alert";
import { useSelector, useDispatch } from "react-redux";
import { addSiteRate, deleteSiteRate, updateSiteRate, loadAgencySites } from "../../reducers/sites";
import { BsPencil,BsTrash  } from "react-icons/bs";
import ConfirmModal from "./ConfirmModal";

const ENTRY_LIMIT = 10;

const SiteRates = ({ site_id }) => {
  const [addLoading, setaddLoading] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);

  const [showEditBox, setshowEditBox] = useState(false);
  const [showConfirmModal, setshowConfirmModal] = useState(false);
  const [rate_id, setrate_id] = useState(null);

  const [shiftType, setshiftType] = useState("");
  const [clientRegular, setclientRegular] = useState("");
  const [clientRegularErr, setclientRegularErr] = useState(false);
  const [clientPremium, setclientPremium] = useState("");
  const [clientPremiumErr, setclientPremiumErr] = useState(false);
  const [clientOvertime, setclientOvertime] = useState("");
  const [clientOvertimeErr, setclientOvertimeErr] = useState(false);
  const [employeeRegular, setemployeeRegular] = useState("");
  const [employeeRegularErr, setemployeeRegularErr] = useState(false);
  const [employeePremium, setemployeePremium] = useState("");
  const [employeePremiumErr, setemployeePremiumErr] = useState(false);
  const [employeeOvertime, setemployeeOvertime] = useState("");
  const [employeeOvertimeErr, setemployeeOvertimeErr] = useState(false);

  const dispatch = useDispatch();
  const sites = useSelector((state) => state.sites);
  const siteData = sites?.sites?.sites?.filter((site) => {
    return site.site_id._id === site_id;
  });
  const site = siteData !== undefined ? siteData[0].site_id : null;


  const handleUpdate =()=>{
    setaddLoading(true);
    if (
      !shiftType ||
      !clientRegular ||
      !clientPremium ||
      !clientOvertime ||
      !employeeRegular ||
      !employeePremium ||
      !employeeOvertime ||
      clientRegularErr ||
      clientPremiumErr ||
      clientOvertimeErr ||
      employeeRegularErr ||
      employeePremiumErr ||
      employeeOvertimeErr
    ) {
      dispatch(
        setAlert({
          componentName: "SiteRates",
          alertType: "danger",
          msg: "Please fill all fields.",
        })
      );
      setaddLoading(false);
      return;
    }
    dispatch(
        updateSiteRate({
            rate_id,
        clientRegular:
          (+clientRegular).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) * 100,
        clientPremium:
          (+clientPremium).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) * 100,
        clientOvertime:
          (+clientOvertime).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) * 100,
        employeeRegular:
          (+employeeRegular).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) * 100,
        employeePremium:
          (+employeePremium).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) * 100,
        employeeOvertime:
          (+employeeOvertime).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) * 100,
        site_id,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(loadAgencySites({ site_id }));
        dispatch(
          setAlert({
            componentName: "SiteRates",
            alertType: "success",
            msg: "Coverage Rate is updated.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "SiteRates",
            alertType: "danger",
            msg: "Error! Please try again.",
          })
        )
      );
    setshiftType("");
    setclientRegular("");
    setclientPremium("");
    setclientOvertime("");
    setemployeeRegular("");
    setemployeePremium("");
    setemployeeOvertime("");
    setaddLoading(false);
    setshowEditBox(false)
  }

  const handleCancel=()=>{
    setshiftType("");
    setclientRegular("");
    setclientPremium("");
    setclientOvertime("");
    setemployeeRegular("");
    setemployeePremium("");
    setemployeeOvertime("");
    setshowEditBox(false)
  }
  
  const handleDelete = () =>{
    setshowConfirmModal(false);
    setdeleteLoading(true);
    dispatch(
        deleteSiteRate({
            rate_id,
            site_id
        })
      )
        .unwrap()
        .then((originalPromiseResult) => {
          dispatch(loadAgencySites({ site_id }));
          dispatch(
            setAlert({
              componentName: "SiteRatesUpdates",
              alertType: "success",
              msg: "Coverage Rate is deleted.",
            })
          );
          setdeleteLoading(false);
        })
        .catch((e) =>
          {dispatch(
            setAlert({
              componentName: "SiteRatesUpdates",
              alertType: "danger",
              msg: "Error! Please try again.",
            })
          )
          setdeleteLoading(false);}
        );

  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setaddLoading(true);
    if (
      !shiftType ||
      !clientRegular ||
      !clientPremium ||
      !clientOvertime ||
      !employeeRegular ||
      !employeePremium ||
      !employeeOvertime ||
      clientRegularErr ||
      clientPremiumErr ||
      clientOvertimeErr ||
      employeeRegularErr ||
      employeePremiumErr ||
      employeeOvertimeErr
    ) {
      dispatch(
        setAlert({
          componentName: "SiteRates",
          alertType: "danger",
          msg: "Please fill all fields.",
        })
      );
      setaddLoading(false);
      return;
    }
    dispatch(
      addSiteRate({
        shiftType,
        clientRegular:
          (+clientRegular).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) * 100,
        clientPremium:
          (+clientPremium).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) * 100,
        clientOvertime:
          (+clientOvertime).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) * 100,
        employeeRegular:
          (+employeeRegular).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) * 100,
        employeePremium:
          (+employeePremium).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) * 100,
        employeeOvertime:
          (+employeeOvertime).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) * 100,
        site_id,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(loadAgencySites({ site_id }));
        dispatch(
          setAlert({
            componentName: "SiteRates",
            alertType: "success",
            msg: "Coverage Type is added.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "SiteRates",
            alertType: "danger",
            msg: "Error! Please try again.",
          })
        )
      );
    setshiftType("");
    setclientRegular("");
    setclientPremium("");
    setclientOvertime("");
    setemployeeRegular("");
    setemployeePremium("");
    setemployeeOvertime("");
    setaddLoading(false);
  };
  if (!site || !siteData || !sites) {
    return <Alert variant="info">No Data found. Please try again.</Alert>;
  }

  const test = (value) => {
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (validated) {
      return true;
    } else {
      return false;
    }
  };
  const handleCRR = (value) => {
    if (test(value)) {
      setclientRegularErr(false);
      setclientRegular(value);
    } else {
      setclientRegularErr(true);
    }
  };
  const handleCPR = (value) => {
    if (test(value)) {
      setclientPremiumErr(false);
      setclientPremium(value);
    } else {
      setclientPremiumErr(true);
    }
  };
  const handleCOR = (value) => {
    if (test(value)) {
      setclientOvertimeErr(false);
      setclientOvertime(value);
    } else {
      setclientOvertimeErr(true);
    }
  };
  const handleERR = (value) => {
    if (test(value)) {
      setemployeeRegularErr(false);
      setemployeeRegular(value);
    } else {
      setemployeeRegularErr(true);
    }
  };
  const handleEPR = (value) => {
    if (test(value)) {
      setemployeePremiumErr(false);
      setemployeePremium(value);
    } else {
      setemployeePremiumErr(true);
    }
  };
  const handleEOR = (value) => {
    if (test(value)) {
      setemployeeOvertimeErr(false);
      setemployeeOvertime(value);
    } else {
      setemployeeOvertimeErr(true);
    }
  };
  return (
    <>
      <div className="my-3">
      <Alerts componentName={"SiteRatesUpdates"} />
      {(showConfirmModal && rate_id) && <ConfirmModal 
      show={showConfirmModal}
      title={"Delete Coverage Type"} 
      msg={"Shifts with deleted coverage type will not have access to rates. Do you want to delete this coverage rate?"} 
      onHide={() =>setshowConfirmModal(false)} 
      handleClick={handleDelete} 
      />}
        {site.rates.length === 0 ? (
          <Alert variant="info">No Coverage Rate. Please add below.</Alert>
        ) : (
          site.rates.map((item, index)=>{
            return(
                <div className="thin-border my-5" key={index}>
            <Row className="mb-3">
              <div className="col-12 col-md-4 d-flex justify-content-between my-1"><span className="fw-bold">Coverage Type :</span><span className="pe-3"> {item.shiftType}</span></div>
              <div className="col-12 col-md-8 d-flex justify-content-end my-1 gap-3"><span className="cursor-pointer" 
              onClick={()=>{setrate_id(item._id); setshowEditBox(true); setshiftType(item.shiftType); setclientRegular(+item.clientRegular / 100); 
              setclientPremium(+item.clientPremium / 100); 
              setclientOvertime(+item.clientOvertime / 100); setemployeeRegular(+item.employeeRegular / 100); setemployeePremium(+item.employeePremium / 100); setemployeeOvertime(+item.employeeOvertime / 100);}}>
                <BsPencil /></span><span className="pe-3 cursor-pointer" onClick={()=>{setrate_id(item._id); setshowConfirmModal(true)}}><BsTrash /></span></div>
            </Row>
            <Row className="mb-3">
              <div className="col-12 col-md-4 d-flex justify-content-between my-1"><span className="fw-bold">Client Regular Rate :</span><span className="pe-3"> ${+item.clientRegular / 100}</span></div>

              <div className="col-12 col-md-4 d-flex justify-content-between my-1"><span className="fw-bold">Client Premium Rate :</span><span className="pe-3"> ${+item.clientPremium / 100}</span></div>
              <div className="col-12 col-md-4 d-flex justify-content-between my-1"><span className="fw-bold">Client Overtime Rate :</span><span className="pe-3"> ${+item.clientOvertime / 100}</span></div>
            </Row>

            <Row className="mb-3">
              <div className="col-12 col-md-4 d-flex justify-content-between my-1"><span className="fw-bold">Employee Regular Rate :</span><span className="pe-3"> ${+item.employeeRegular / 100}</span></div>

              <div className="col-12 col-md-4 d-flex justify-content-between my-1"><span className="fw-bold">Employee Premium Rate :</span><span className="pe-3"> ${+item.employeePremium / 100}</span></div>
              <div className="col-12 col-md-4 d-flex justify-content-between my-1"><span className="fw-bold">Employee Overtime Rate :</span><span className="pe-3"> ${+item.employeeOvertime / 100}</span></div>
            </Row>
          </div>
            )
          })
        )}
      </div>
      <Alerts componentName={"SiteRates"} />
      {site.rates.length >= ENTRY_LIMIT ? (
          <Alert variant="info">Limit reached. Please delete a coverage rate to add new.</Alert>
        ) : <div className="thin-border my-5">
        <Form>
          <Row className="mb-3">
            <Form.Group className="col-12 col-md-4" controlId="formGridEmail">
              <Form.Label>Coverage Type</Form.Label>
              <Form.Control
                type="text"
                placeholder="Coverage Type"
                disabled={showEditBox}
                value={shiftType}
                onChange={(e) => setshiftType(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group
              className="col-12 col-md-4"
              controlId="formGridstreetNumber"
            >
              <Form.Label>Client Regular Rate</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  className={clientRegularErr ? "is-invalid" : ""}
                  type="number"
                  value={clientRegular}
                  onChange={(e) => {
                    handleCRR(e.target.value);
                  }}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group
              className="col-12 col-md-4"
              controlId="formGridPassword"
            >
              <Form.Label>Client Premium Rate</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  className={clientPremiumErr ? "is-invalid" : ""}
                  type="number"
                  value={clientPremium}
                  onChange={(e) => {
                    handleCPR(e.target.value);
                  }}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="col-12 col-md-4" controlId="formGridZip">
              <Form.Label>Client Overtime Rate</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  className={clientOvertimeErr ? "is-invalid" : ""}
                  type="number"
                  value={clientOvertime}
                  onChange={(e) => {
                    handleCOR(e.target.value);
                  }}
                />
              </InputGroup>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group className="col-12 col-md-4" controlId="formGridCity">
              <Form.Label>Employee Regular Rate</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  className={employeeRegularErr ? "is-invalid" : ""}
                  type="number"
                  value={employeeRegular}
                  onChange={(e) => {
                    handleERR(e.target.value);
                  }}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="col-12 col-md-4" controlId="formGridstate">
              <Form.Label>Employee Premium Rate</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  className={employeePremiumErr ? "is-invalid" : ""}
                  type="number"
                  value={employeePremium}
                  onChange={(e) => {
                    handleEPR(e.target.value);
                  }}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="col-12 col-md-4" controlId="formGridpostal">
              <Form.Label>Employee Overtime Rate</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  className={employeeOvertimeErr ? "is-invalid" : ""}
                  type="number"
                  value={employeeOvertime}
                  onChange={(e) => {
                    handleEOR(e.target.value);
                  }}
                />
              </InputGroup>
            </Form.Group>
          </Row>

          {addLoading ? (
            <Button variant="primary" disabled className="button-flex">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : ( showEditBox ? 
            (
                <>
                <Button
              variant="primary"
              type="submit"
              className="button-flex"
              onClick={handleUpdate}
            >
              Update
            </Button>
            <Button
              variant="secondary"
              type="submit"
              className="button-flex"
              onClick={handleCancel}
            >
              Cancel
            </Button>
                </>
            )
            :
            <Button
              variant="primary"
              type="submit"
              className="button-flex"
              onClick={handleSubmit}
            >
              Add
            </Button>
          )}
        </Form>
      </div>}
    </>
  );
};

export default SiteRates;
