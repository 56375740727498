import React, { useState } from "react";
import "./schedulingfront.css";
import Footer from "./Footer";
import MainMenu from "./MainMenu";
import Login from "./components/Login";
import Register from "./components/Register";
import Cta from "./Cta";
import {
  BsGeoAltFill,
  BsWifi,
  BsFillCheckSquareFill,
  BsFillHandThumbsUpFill,
  BsCheck2Square,
  BsArrowRight,
  BsArrowLeft,
  BsCalendarDate,
} from "react-icons/bs";
import { AiOutlineUsergroupAdd, AiOutlineFileSearch } from "react-icons/ai";
import { BiTask } from "react-icons/bi";
import { MdSupportAgent } from "react-icons/md";
import Image1 from "./uploads/schedule-1.jpg";
import Image2 from "./uploads/schedule-2.jpg";

const MobileAppFront = () => {
  const [loginShow, setLoginShow] = useState(false);
  const [registerShow, setRegisterShow] = useState(false);
  return (
    <>
      <MainMenu />
      <div className="header-container">
        <div className="container hero-section" id="home">
          
          <div className="container">
            <div className="header-content-section">
              <div className="left-content">
                <h1
                  className="display-5 fw-bold lh-2 mb-3"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="20"
                  data-aos-easing="ease-in-out"
                >
                  Scheduling Module
                </h1>
                <p
                  className="lead pe-1"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="100"
                  data-aos-easing="ease-in-out"
                >
                  Schedule your employees efficiently with our lighting fast
                  alpha scheduling module. Try us for a month!
                </p>
                <div
                  className="d-flex justify-content-start align-items-center gap-3 mt-2"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="200"
                  data-aos-easing="ease-in-out"
                >
                  <div
                    className="shaddow-button py-3 px-5 button-font"
                    onClick={() => setRegisterShow(!registerShow)}
                  >
                    Try Free
                  </div>
                  <div
                    className="shaddow-outline-button py-3 px-5 button-font"
                    onClick={() => setRegisterShow(!registerShow)}
                  >
                    Sign Up
                  </div>
                </div>
              </div>
              <div
                className="right-content"
                data-aos="fade-left"
                data-aos-duration="20"
                data-aos-delay="100"
                data-aos-easing="ease-in-out"
              >
                <div className="schedulingfront-hero-card"></div>
              </div>
            </div>
          </div>
          <Login show={loginShow} onHide={() => setLoginShow(false)} />
          <Register show={registerShow} onHide={() => setRegisterShow(false)} />
        </div>
        <div class="hero-wave">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
      <div className="container mt-5">
        <div className="container">
          <div
            className="d-flex flex-column justify-content-start align-items-center "
            data-aos="fade-up"
            data-aos-duration="20"
            data-aos-delay="200"
            data-aos-offset="10"
            data-aos-easing="ease-in-out"
          >
            <div>
              <h2 className="display-5 fw-bold lh-1 mb-3 mt-5">
                Scheduling Features
              </h2>
            </div>
            <div className="mb-5">
              <h3 className="lead">
                The only scheduling software you will need.
              </h3>
            </div>
          </div>

          <div className="feature-section-content d-flex justify-content-between align-items-center gap-2 my-5">
            <div
              className="feature-left-content d-flex align-items-center "
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="200"
              data-aos-offset="10"
              data-aos-easing="ease-in-out"
            >
              <div className="feature-main-image-container">
                <img
                  src={Image2}
                  alt="showcase"
                  width="100%"
                  className="feature-main-image-itself"
                />
                <div className="top-left-floating-patter"> </div>
                {/* <div className="bottom-left-floating-patter"> </div>
                <div className="top-right-floating-patter"> </div> */}
                <div className="bottom-right-floating-patter"> </div>
              </div>
            </div>
            <div className="feature-right-content">
              <h2
                className="display-5 fw-bold lh-1 mb-3"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="20"
                data-aos-easing="ease-in-out"
              >
                Lighting Fast
              </h2>
              <p
                className="lead"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="100"
                data-aos-easing="ease-in-out"
              >
                Schedule your remote employees with Alpha Scheduling software.
                If you have a team that is spread out, alpha will help keep
                track of your remote team. Our mobile app is loaded with
                powerfull features.
              </p>
              <div
                className="d-flex flex-column justify-content-start lead my-4"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="150"
                data-aos-easing="ease-in-out"
              >
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <span className="list-check">
                    <BsCheck2Square />
                  </span>
                  <span className="ms-5">
                    <strong>Increase efficiency</strong>
                  </span>
                </div>
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <span className="list-check">
                    <BsCheck2Square />
                  </span>
                  <span className="ms-5">
                    <strong>No more long page loads</strong>
                  </span>
                </div>
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <span className="list-check">
                    <BsCheck2Square />
                  </span>
                  <span className="ms-5">
                    <strong>Unmattched performance and speed</strong>
                  </span>
                </div>
              </div>
              {/* <div
                  className="d-flex justify-content-start align-items-center gap-3"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="200"
                  data-aos-easing="ease-in-out"
                >
                  <div
                    className="shaddow-button py-3 px-5 button-font"
                    onClick={() => setRegisterShow(!registerShow)}
                  >
                    Try Free
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Feature #2 */}
      <div className="py-5">
        <div className="container ">
          <div className="container ">
            <div className="feature2-section-content d-flex justify-content-between align-items-center gap-2 my-5">
              <div className="feature-left-content">
                <h1
                  className="display-5 fw-bold lh-2 mb-3"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="20"
                  data-aos-easing="ease-in-out"
                >
                  Multiple Roles
                </h1>
                <p
                  className="lead"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="100"
                  data-aos-easing="ease-in-out"
                >
                  Alpha scheduling software keeps your business operations in
                  check with visual information right on your fingertips.
                  Multiple dashboard with smart tracking allows businesses to
                  stay on top of important tasks.
                </p>
                <div
                  className="d-flex flex-column justify-content-start lead my-4"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="150"
                  data-aos-easing="ease-in-out"
                >
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>Employee Management</strong>
                    </span>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>Task Management</strong>
                    </span>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>Smart Tracking With Geofencing</strong>
                    </span>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <span className="list-check">
                      <BsCheck2Square />
                    </span>
                    <span className="ms-5">
                      <strong>24/7 Support</strong>
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="feature-left-content d-flex align-items-center "
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="200"
                data-aos-offset="10"
                data-aos-easing="ease-in-out"
              >
                <div className="feature-main-image-container">
                  <img
                    src={Image2}
                    alt="showcase"
                    width="100%"
                    className="feature-main-image-itself-op"
                  />
                  {/* <div className="top-left-floating-patter"> </div> */}
                  <div className="bottom-left-floating-patter"> </div>
                  <div className="top-right-floating-patter"> </div>
                  {/* <div className="bottom-right-floating-patter"> </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Feature #2 End*/}
      {/* feature 3 */}
      <div className="container mt-5">
        <div className="container">
          

          <div className="feature-section-content d-flex justify-content-between align-items-center gap-2 my-5">
            <div
              className="feature-left-content d-flex align-items-center "
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="200"
              data-aos-offset="10"
              data-aos-easing="ease-in-out"
            >
              <div className="feature-main-image-container">
                <img
                  src={Image2}
                  alt="showcase"
                  width="100%"
                  className="feature-main-image-itself"
                />
                <div className="top-left-floating-patter"> </div>
                {/* <div className="bottom-left-floating-patter"> </div>
                <div className="top-right-floating-patter"> </div> */}
                <div className="bottom-right-floating-patter"> </div>
              </div>
            </div>
            <div className="feature-right-content">
              <h2
                className="display-5 fw-bold lh-1 mb-3"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="20"
                data-aos-easing="ease-in-out"
              >
                Manage Sites and Shifts
              </h2>
              <p
                className="lead"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="100"
                data-aos-easing="ease-in-out"
              >
                Schedule your remote employees with Alpha Scheduling software.
                If you have a team that is spread out, alpha will help keep
                track of your remote team. Our mobile app is loaded with
                powerfull features.
              </p>
              <div
                className="d-flex flex-column justify-content-start lead my-4"
                data-aos="fade-up"
                data-aos-duration="20"
                data-aos-delay="150"
                data-aos-easing="ease-in-out"
              >
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <span className="list-check">
                    <BsCheck2Square />
                  </span>
                  <span className="ms-5">
                    <strong>Increase efficiency</strong>
                  </span>
                </div>
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <span className="list-check">
                    <BsCheck2Square />
                  </span>
                  <span className="ms-5">
                    <strong>No more long page loads</strong>
                  </span>
                </div>
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <span className="list-check">
                    <BsCheck2Square />
                  </span>
                  <span className="ms-5">
                    <strong>Unmattched performance and speed</strong>
                  </span>
                </div>
              </div>
              {/* <div
                  className="d-flex justify-content-start align-items-center gap-3"
                  data-aos="fade-up"
                  data-aos-duration="20"
                  data-aos-delay="200"
                  data-aos-easing="ease-in-out"
                >
                  <div
                    className="shaddow-button py-3 px-5 button-font"
                    onClick={() => setRegisterShow(!registerShow)}
                  >
                    Try Free
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Services */}
      <div className="services-container" id="features">
        <div className="container services-section py-5">
          <div className="servicess-section container d-flex flex-column justify-content-center align-items-center">
            <div
              className="d-flex flex-column justify-content-start align-items-center mt-5 mb-3"
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="10"
              data-aos-easing="ease-in-out"
            >
              <div>
                <h2 className="display-5 fw-bold lh-1 my-3">More Features</h2>
              </div>
              <div>
                <h3 className="lead">All powerfull features at one place.</h3>
              </div>
            </div>

            <div
              class="row g-5 pt-5"
              data-aos="fade-up"
              data-aos-duration="20"
              data-aos-delay="200"
              data-aos-easing="ease-in-out"
            >
              <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                  <div class="icon">
                    <AiOutlineUsergroupAdd />
                  </div>
                  <div class="feature-content">
                    <h2>Employee Management</h2>
                    <p className="lead">
                      Employee management module makes staff management quick
                      and easy. Alpha database will give you access to new
                      profiles.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                  <div class="icon">
                    <BiTask />
                  </div>
                  <div class="feature-content">
                    <h2>Task Management</h2>
                    <p className="lead">
                      Create, assigned, track tasks with just few clicks.
                      Monitor tasks status with powerfull admin dashboards.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-4">
                <div class="feature-box-1">
                  <div class="icon">
                    <BsGeoAltFill />
                  </div>
                  <div class="feature-content">
                    <h2>Attendance</h2>
                    <p className="lead">
                      Monitor remote employees attendance with location tags.
                      Smart tracking will make sure employee is at right
                      location.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services End */}
      <Cta />
      <Footer />
    </>
  );
};



export default MobileAppFront