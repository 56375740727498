import configData from "../config/default.json";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

const API_ENDPOINT = configData.API_ENDPOINT;

const initialState = {
  loading: false,
  shifts: null,
  notes: null,
  filteredshifts: null,
  shift: null,
  error: null,
  smallLoading: false,
  deleteLoading: false,
};

export const createShift = createAsyncThunk(
  "api/createShift",
  async ({ site_id, shiftDate, shiftHours, breakTime, startTime, endTime, shiftType }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    console.log("1", breakTime);
    const body = JSON.stringify({
      site_id,
      shiftDate,
      breakTime,
      startTime,
      endTime,
      shiftHours,
      shiftType
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts/createshift`,
      body,
      config
    );
    return response.data;
  }
);

export const createmultipleShift = createAsyncThunk(
  "api/createmultipleShift",
  async ({
    shiftType,
    site_id,
    shiftDate,
    shiftHours,
    breakTime,
    startTime,
    endTime,
    shiftDates,
    nextDay,
  }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    console.log("1", breakTime);
    const body = JSON.stringify({
      site_id,
      shiftType,
      shiftDate,
      breakTime,
      startTime,
      endTime,
      shiftHours,
      shiftDates,
      nextDay,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts/createmultipleshift`,
      body,
      config
    );
    return response.data;
  }
);

export const broadcastShift = createAsyncThunk(
  "api/broadcastShift",
  async ({ shift_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts/broadcast/${shift_id}`,
      config
    );
    return response.data;
  }
);

export const localbroadcastShift = createAsyncThunk(
  "api/localbroadcastShift",
  async ({ shift_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts/localbroadcast/${shift_id}`,
      config
    );
    return response.data;
  }
);

export const loadShifts = createAsyncThunk(
  "api/loadShifts",
  async ({ site_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const response = await axios.get(
      `${API_ENDPOINT}/api/shifts/getshifts/${site_id}`
    );
    return response.data;
  }
);

export const loadShiftDetails = createAsyncThunk(
  "api/loadShiftDetails",
  async ({ shift_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const response = await axios.get(
      `${API_ENDPOINT}/api/shifts/getshift/${shift_id}`
    );
    return response.data;
  }
);

export const assignShift = createAsyncThunk(
  "api/assignShift",
  async ({ shift_id, user_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({ user_id });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts/assign/${shift_id}`,
      body,
      config
    );
    return response.data;
  }
);

export const removeUserFromShift = createAsyncThunk(
  "api/removeUserFromShift",
  async ({ shift_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts//removeemployee/${shift_id}`,
      config
    );
    return response.data;
  }
);

export const loadFilteredShift = createAsyncThunk(
  "api/loadFilteredShift",
  async ({ startDate, endDate, status }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({ startDate, endDate, status });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts/allagencyshifts`,
      body,
      config
    );
    return response.data;
  }
);

export const dispatchClockin = createAsyncThunk(
  "api/dispatchClickin",
  async ({ dispatchDate, shift_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({ dispatchDate });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts/dispatchclockin/${shift_id}`,
      body,
      config
    );
    return response.data;
  }
);

export const dispatchBreakClockin = createAsyncThunk(
  "api/dispatchBreakClockin",
  async ({ dispatchDate, shift_id, break_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({ dispatchDate, break_id });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts/dispatchbreakclockin/${shift_id}`,
      body,
      config
    );
    return response.data;
  }
);

export const dispatchClockout = createAsyncThunk(
  "api/dispatchClockout",
  async ({ dispatchDate, shift_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({ dispatchDate });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts/dispatchclockout/${shift_id}`,
      body,
      config
    );
    return response.data;
  }
);

export const dispatchBreakClockout = createAsyncThunk(
  "api/dispatchBreakClockout",
  async ({ dispatchDate, shift_id, break_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const body = JSON.stringify({ dispatchDate, break_id });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts/dispatchBreakClockout/${shift_id}`,
      body,
      config
    );
    return response.data;
  }
);

export const deleteShift = createAsyncThunk(
  "api/deleteShift",
  async ({ shift_id }) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_ENDPOINT}/api/shifts/deleteshift/${shift_id}`,
      config
    );
    return response.data;
  }
);

// export const loadAgencySites = createAsyncThunk("api/loadAgencySites", async () => {
//     if (localStorage.token) {
//       setAuthToken(localStorage.token);
//     }
//     const response = await axios.get(
//       `${API_ENDPOINT}/api/sites/getagencysite`
//     );
//     return response.data;
//   });

export const site = createSlice({
  name: "site",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadShifts.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadShifts.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.shifts = action.payload;
      })
      .addCase(loadShifts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        console.log("error in loadShifts");
        console.log(action);
      })
      .addCase(createShift.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createShift.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createShift.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        console.log("error in createShift");
        console.log(action);
      })
      .addCase(createmultipleShift.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createmultipleShift.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createmultipleShift.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        console.log("error in createmultipleShift");
        console.log(action);
      })
      .addCase(broadcastShift.pending, (state, action) => {
        state.smallLoading = true;
      })
      .addCase(broadcastShift.fulfilled, (state, action) => {
        state.smallLoading = false;
        state.error = null;
      })
      .addCase(broadcastShift.rejected, (state, action) => {
        state.smallLoading = false;
        state.error = action.error;
        console.log("error in broadcastShift");
        console.log(action);
      })
      .addCase(localbroadcastShift.pending, (state, action) => {
        state.smallLoading = true;
      })
      .addCase(localbroadcastShift.fulfilled, (state, action) => {
        state.smallLoading = false;
        state.error = null;
      })
      .addCase(localbroadcastShift.rejected, (state, action) => {
        state.smallLoading = false;
        state.error = action.error;
        console.log("error in localbroadcastShift");
        console.log(action);
      })
      .addCase(assignShift.pending, (state, action) => {
        state.smallLoading = true;
      })
      .addCase(assignShift.fulfilled, (state, action) => {
        state.smallLoading = false;
        state.error = null;
      })
      .addCase(assignShift.rejected, (state, action) => {
        state.smallLoading = false;
        state.error = action.error;
        console.log("error in assignShift");
        console.log(action);
      })
      .addCase(removeUserFromShift.pending, (state, action) => {
        state.smallLoading = true;
      })
      .addCase(removeUserFromShift.fulfilled, (state, action) => {
        state.smallLoading = false;
        state.error = null;
      })
      .addCase(removeUserFromShift.rejected, (state, action) => {
        state.smallLoading = false;
        state.error = action.error;
        console.log("error in removeUserFromShift");
        console.log(action);
      })
      .addCase(loadFilteredShift.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadFilteredShift.fulfilled, (state, action) => {
        state.loading = false;
        state.filteredshifts = action.payload;
        state.error = null;
      })
      .addCase(loadFilteredShift.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        console.log("error in loadFilteredShift");
        console.log(action);
      })
      .addCase(dispatchClockin.pending, (state, action) => {
        state.smallLoading = true;
      })
      .addCase(dispatchClockin.fulfilled, (state, action) => {
        state.smallLoading = false;
        state.error = null;
      })
      .addCase(dispatchClockin.rejected, (state, action) => {
        state.smallLoading = false;
        state.error = action.error;
        console.log("error in dispatchClockin");
        console.log(action);
      })
      .addCase(dispatchClockout.pending, (state, action) => {
        state.smallLoading = true;
      })
      .addCase(dispatchClockout.fulfilled, (state, action) => {
        state.smallLoading = false;
        state.error = null;
      })
      .addCase(dispatchClockout.rejected, (state, action) => {
        state.smallLoading = false;
        state.error = action.error;
        console.log("error in dispatchClockout");
        console.log(action);
      })
      .addCase(loadShiftDetails.pending, (state, action) => {
        state.smallLoading = true;
      })
      .addCase(loadShiftDetails.fulfilled, (state, action) => {
        state.smallLoading = false;
        state.shift = action.payload;
        state.error = null;
      })
      .addCase(loadShiftDetails.rejected, (state, action) => {
        state.smallLoading = false;
        state.error = action.error;
        state.shift = null;
        console.log("error in loadShiftDetails");
        console.log(action);
      })
      .addCase(deleteShift.pending, (state, action) => {
        state.deleteLoading = true;
      })
      .addCase(deleteShift.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.error = null;
      })
      .addCase(deleteShift.rejected, (state, action) => {
        state.deleteLoading = false;
        state.error = action.error;
        console.log("error in deleteShift");
        console.log(action);
      })
      .addCase(dispatchBreakClockin.pending, (state, action) => {})
      .addCase(dispatchBreakClockin.fulfilled, (state, action) => {
        state.error = null;
      })
      .addCase(dispatchBreakClockin.rejected, (state, action) => {
        state.error = action.error;
        console.log("error in dispatchBreakClockin");
        console.log(action);
      })
      .addCase(dispatchBreakClockout.pending, (state, action) => {})
      .addCase(dispatchBreakClockout.fulfilled, (state, action) => {
        state.error = null;
      })
      .addCase(dispatchBreakClockout.rejected, (state, action) => {
        state.error = action.error;
        console.log("error in dispatchBreakClockout");
        console.log(action);
      });
  },
});

export default site.reducer;
