import React, { useState  } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { createUser } from "../../../reducers/auth";
import { setAlert } from "../../../reducers/alert";
import Alerts from "../../Alerts";
import DisplayTwoColData from "../DisplayTwoColData";
import { loadAgencyUsers } from "../../../reducers/users";
//import { useNavigate } from "react-router-dom";
//import UserRoles from '../config/default.json'

const CreateUser = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [phone, setPhone] = useState();
  const [complete, setcomplete] = useState(false);

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.registerAppLoading);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleCreateUserSubmit = (e) => {
    e.preventDefault();
    if (!email || !pass || !phone || !name || !validateEmail(email)) {
      dispatch(
        setAlert({
          componentName: "CreateUser",
          alertType: "danger",
          msg: "Please check required fields.",
        })
      );

      return console.log("please input all fields");
    }
    dispatch(createUser({ name, email, password: pass, phone }))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(
          setAlert({
            componentName: "CreateUser",
            alertType: "success",
            msg: "Employee Account is created. Employee can use below credentials to login into app to complete profile.",
          })
        );
        setcomplete(true);
        dispatch(loadAgencyUsers());
      })
      .catch((e) => {
        dispatch(
          setAlert({
            componentName: "CreateUser",
            alertType: "danger",
            msg: "Error! Please try again.",
          })
        );
      });
  };
  const handlecreatenew = () =>{
    setName("");
    setEmail("");
    setPass("");
    setPhone("");
    setcomplete(false);
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="custom-homapge-modal"
      centered
    >
      {complete ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2>Account is created</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alerts componentName={"CreateUser"} />

            <DisplayTwoColData field={"Name"} data={name ? name : "-"} />
            <DisplayTwoColData field={"Email"} data={email ? email : "-"} />
            <DisplayTwoColData field={"Password"} data={pass ? pass : "-"} />
            <DisplayTwoColData field={"Phone"} data={phone ? phone : "-"} />
          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button variant="primary" onClick={handlecreatenew}>
            Create New
          </Button>
        </Modal.Footer>
     
        </>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2>Create Employee Account</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alerts componentName={"CreateUser"} />
            <Form>
              <Form.Group className="mb-3" controlId="formBasicUserName">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  size="lg"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  size="lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  size="lg"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicUserName">
                <Form.Label>Phone </Form.Label>
                <Form.Control
                  type="number"
                  size="lg"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>

              {loading ? (
                <Button variant="primary" size="lg" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Loading...</span>
                </Button>
              ) : (
                <Button
                  variant="primary"
                  type="submit"
                  onClick={handleCreateUserSubmit}
                >
                  Create
                </Button>
              )}
              <br />
            </Form>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};

export default CreateUser;
