import React,{ useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Alert from "react-bootstrap/Alert";
import { ImFlag } from "react-icons/im";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

function Items({ currentItems }) {
    if (!currentItems) {
      return <Alert variant="info">No Data.</Alert>;
    }
    return (
      <div className='task-data-table'>
          <Table hover >
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Submitted By</th>
            <th>Priority</th>
            <th>Reply</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((ticket, index)=>{
              
              return(
                  <tr key={index}>
                  <td><Link to={`/dashboard/support/${ticket._id}`}>{index + 1}</Link></td>
                  <td><Link to={`/dashboard/support/${ticket._id}`}>{ticket.ticketTitle.substring(0, 20)}</Link></td>
                  <td>{ticket.createdBy}</td>
                  <td>{ticket.priority === "HIGH" ? <span style={{color:"red"}}><ImFlag /></span> : (ticket.priority === "NORMAL" ? <span style={{color:"green"}}><ImFlag /></span> : <span style={{color:"yellow"}}><ImFlag /></span>)}</td>
                  <td>{ticket.replies.length}</td>
                  <td className={ticket.solved === false ? 'task-status-style' : "task-status-style-2"}>{ticket.solved === false ? "Pending" :"Solved"}</td>
                  
                </tr>
              )
          })}
        </tbody>
      </Table>
          
      </div>
    );
  }

  
const DisplayTickets = ({tickets}) => {
    const itemsPerPage=100;
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(tickets);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
  
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % tickets.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };
  
    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(tickets.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(tickets.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, tickets]);
  
    useEffect(() => {
      setItemOffset(0);
    }, [itemsPerPage]);
      
        if (!tickets || tickets.length === 0) {
          return (
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <Alert variant="info">No Tickets found.</Alert>
              </div>
            </div>
          );
        }
    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="< previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </>
    )
  }

export default DisplayTickets