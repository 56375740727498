import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Alerts from "../Alerts";
import { setAlert } from "../../reducers/alert";
import { useSelector, useDispatch } from "react-redux";
import { createSite, loadAgencySites } from "../../reducers/sites";
import { OpenStreetMapProvider } from "leaflet-geosearch";

const CreateSite = () => {
  const provider = new OpenStreetMapProvider();

  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [postal, setPostal] = useState("");
  const [provience, setProvience] = useState("");
  const [country, setCountry] = useState("");
  const [geox, setGeox] = useState(null);
  const [geoy, setGeoy] = useState(null);
  const [validate, setValidate] = useState(false);
  const [validating, setValidating] = useState(false);

  const dispatch = useDispatch();
  const sites = useSelector((state) => state.sites);

  const getCords = async (address, city) => {
    setValidating(true);
    let data = await provider.search({ query: address });
    data.forEach((obj, index) => {
      // filter result  by city if multiple results ins search
      if (obj.label.toLowerCase().includes(city.toLowerCase())) {
        setGeox(obj.x);
        setGeoy(obj.y);
      }
    });
    setValidating(false);
    setValidate(true);
  };

  const validateHandle = (e) => {
    e.preventDefault();
    if (
      !name ||
      !street ||
      !city ||
      !provience ||
      !country ||
      !postal ||
      !streetNumber
    ) {
      dispatch(
        setAlert({
          componentName: "createsite",
          alertType: "danger",
          msg: "Please fill all fields.",
        })
      );
      return console.log("please input all fields");
    }
    getCords(`${streetNumber} ${street} ${city} ${provience} ${country}`, city);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !name ||
      !street ||
      !city ||
      !provience ||
      !country ||
      !streetNumber ||
      !postal
    ) {
      dispatch(
        setAlert({
          componentName: "createsite",
          alertType: "danger",
          msg: "Please fill all fields.",
        })
      );
      return console.log("please input all fields");
    }
    dispatch(
      createSite({
        name,
        unit,
        street,
        city,
        provience,
        country,
        geox,
        geoy,
        streetNumber,
        postal,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(loadAgencySites());
        dispatch(
          setAlert({
            componentName: "createsite",
            alertType: "success",
            msg: "Site is created.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "createsite",
            alertType: "danger",
            msg: "Error in Saving Data. Please try again.",
          })
        )
      );

    setName("");
    setUnit("");
    setStreet("");
    setCity("");
    setProvience("");
    setCountry("");
    setStreetNumber("");
    setPostal("");
    setGeox(null);
    setGeoy(null);
    setValidate(false);
    setValidating(false);
  };
  return (
    <div className="card">
      <Alerts componentName={"createsite"} />
      <Form>
        <h3>Create Site</h3>
        <hr />
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Site Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Site Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Row className="mb-3">
          <Form.Group className="col-12 col-md-3" controlId="formGridEmail">
            <Form.Label>Unit</Form.Label>
            <Form.Control
              type="number"
              placeholder="Unit (Not Required)"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="col-12 col-md-3" controlId="formGridstreetNumber">
            <Form.Label>Street Number</Form.Label>
            <Form.Control
              type="number"
              placeholder="Street Number (required)"
              value={streetNumber}
              onChange={(e) => setStreetNumber(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="col-12 col-md-3" controlId="formGridPassword">
            <Form.Label>Street</Form.Label>
            <Form.Control
              type="text"
              placeholder="Street Name"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="col-12 col-md-3" controlId="formGridZip">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group className="col-12 col-md-4" controlId="formGridCity">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              placeholder="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="col-12 col-md-4" controlId="formGridstate">
            <Form.Label>Provience/State</Form.Label>
            <Form.Control
              type="text"
              placeholder="Provience"
              value={provience}
              onChange={(e) => setProvience(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="col-12 col-md-4" controlId="formGridpostal">
            <Form.Label>Zip</Form.Label>
            <Form.Control
              type="text"
              placeholder="Provience"
              value={postal}
              onChange={(e) => setPostal(e.target.value)}
            />
          </Form.Group>
        </Row>

        {sites.loading || validating ? (
          <Button variant="primary" disabled className="button-flex">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="visually-hidden">Loading...</span>
          </Button>
        ) : validate ? (
          <>
            <Button
              variant="primary"
              type="submit"
              className="button-flex"
              onClick={handleSubmit}
            >
              Create
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="primary"
              type="submit"
              className="button-flex"
              onClick={validateHandle}
            >
              validate
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};

export default CreateSite;
