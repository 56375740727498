import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { loadAgencyUsers } from "../../reducers/users";
import { assignShift } from "../../reducers/site";
import { setAlert } from "../../reducers/alert";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Alerts from "../Alerts";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

const AssignUser = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const shiftids = props.selectedshifts;
  useEffect(() => {
    dispatch(loadAgencyUsers());
  }, []);

  const handleAssign = (user_id) => {
    if (!shiftids || shiftids.length === 0) {
      dispatch(
        setAlert({
          componentName: "AssignUser",
          alertType: "danger",
          msg: "Please Select Shifts.",
        })
      );
      return console.log("Please Select Shifts.");
    }
    shiftids.forEach((id, index) => {
      dispatch(
        assignShift({
          user_id,
          shift_id: shiftids[index],
        })
      )
        .unwrap()
        .then((originalPromiseResult) => {
          dispatch(
            setAlert({
              componentName: "AssignUser",
              alertType: "success",
              msg: `User is Assigned to shift #${shiftids[index]}.`,
            })
          );
        })
        .catch((e) =>
          dispatch(
            setAlert({
              componentName: "AssignUser",
              alertType: "danger",
              msg: `Error in saving data for shift #${shiftids[index]}. Please try again.`,
            })
          )
        );
    });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {users.loading && (
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      )}
      {!users.users ? (
        <Alert variant="info">No Data.</Alert>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Assign Employee
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Alerts componentName={"AssignUser"} />
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Assign</th>
                </tr>
              </thead>
              <tbody>
                {users.users[0].employees && users.users[0].employees.map((user, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>

                      <td>
                        <Link to={`/dashboard/users/${user.user_id._id}`}>
                          {user.user_id.username}
                        </Link>
                      </td>
                      <td>
                        <span
                          onClick={() => handleAssign(user.user_id._id)}
                          style={{ cursor: "pointer" }}
                        >
                          <Button variant="outline-primary" size="sm">Assign</Button>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default AssignUser;
