import React, { useState, useEffect } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { login, loadUser } from "../reducers/auth";
import { setAlert } from "../reducers/alert";
import Alerts from "./Alerts";
import { useNavigate } from "react-router-dom";
import UserRoles from "../config/default.json";
import Forgotpass from "./Forgotpass";
import { BsArrowLeft } from "react-icons/bs";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [forgotpass, setForgotpass] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth);

  if (user.isAuthenticated && user.user?.roles.includes(UserRoles.ROLE.ADMIN)) {
    navigate("/dashboard");
  } else if (
    user.isAuthenticated &&
    (user.user?.roles.includes(UserRoles.ROLE.AGENCY_ADMIN) ||
      user.user?.roles.includes(UserRoles.ROLE.AGENCY_TEAM) ||
      user.user?.roles.includes(UserRoles.ROLE.AGENCY_USER))
  ) {
    navigate("/dashboard");
  } else if (
    user.isAuthenticated &&
    user.user?.roles.includes(UserRoles.ROLE.USER)
  ) {
    navigate("/userdashboard");
  }

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  useEffect(() => {
    if (user.token) {
      dispatch(loadUser());
    }
  }, []);

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    if (!email || !pass || !validateEmail(email)) {
      dispatch(
        setAlert({
          componentName: "login",
          alertType: "danger",
          msg: "Please fill all required fields.",
        })
      );
      return console.log("please input all fields");
    }
    dispatch(login({ email, password: pass }))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(loadUser());
        dispatch(
          setAlert({
            componentName: "login",
            alertType: "success",
            msg: "Logged in.",
          })
        );
      })
      .catch((e) =>
        dispatch(
          setAlert({
            componentName: "login",
            alertType: "danger",
            msg: "Erron in Login. Please try again.",
          })
        )
      );
    setEmail("");
    setPass("");
  };

  if (forgotpass) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="custom-homapge-modal"
        centered
      >
        <Forgotpass />
        <div
          className="mt-3 cursor-pointer d-flex align-items-center gap-2"
          onClick={() => setForgotpass(false)}
        >
          <BsArrowLeft />
          Go Back
        </div>
      </Modal>
    );
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="custom-homapge-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>Login</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alerts componentName={"login"} />
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              size="lg"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              size="lg"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
          </Form.Group>

          {user.loading ? (
            <Button variant="primary" size="lg" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="primary"
              size="lg"
              type="submit"
              onClick={handleLoginSubmit}
            >
              Login
            </Button>
          )}
          <div
            className="mt-3 cursor-pointer"
            onClick={() => setForgotpass(true)}
          >
            Forgot password?
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Login;
