import React from 'react';
import ImageModal from "./ImageModal";
import Image1 from "./assets/attendence-1.jpg";
import Image2 from "./assets/attendence-2.jpg";
import { FaMapMarkerAlt } from "react-icons/fa";

const MonitorAttendenceDocs = () => {
    const [imageShow, setImageShow] = React.useState(false);
    const [imageData, setImageData] = React.useState(null);
      const onThisPage = [
          {
            link: "#attendance",
            desc: "Attendance",
          },
          {
            link: "#descriptions",
            desc: "Descriptions",
          },
          {
            link: "#map",
            desc: "Map",
          }
        ];
        return (
          <div className="d-flex">
            <div className="docs-sub-left-content">
              <h1 id="attendance">Attendance</h1>
              <p>
              Attendance is monitoring employees if they are on site or not.
              </p>
              
              <div className="bd-callout-info bd-callout ">
              <h5>Permissions</h5>
          <p>
            Admin, Administrator and Schedular has permission to perform view Attendance.
          </p>
        </div>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">Attendance</span> under Schedule on menu.</p>
        
        

        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image1);
            setImageShow(true);
          }}
        >
          <img src={Image1} alt="create site 1" width={"100%"}></img>
        </div>

              <h2 id="descriptions">Descriptions</h2>
        <p><i class="docs-arrow"></i><span className="text-highlight">Employee</span><sup>1</sup> Assigned employee name or <span className="text-highlight">OPEN</span> if shift is not aassigned to anyone..</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Site Name</span><sup>2</sup> Site name.</p>
        <p><i class="docs-arrow"></i><span className="text-highlight">Clock in</span><sup>3</sup> <span className="text-highlight">Clock in time</span> if employee clocked in. 
        <span className="text-highlight">Missed</span> if employee did not clock in on time. <span className="text-highlight">D-TIME</span> If employee is dispatched.
        <span className="text-highlight" style={{"color":"green"}}><FaMapMarkerAlt /></span>IF emolpyee clock in/out is on site.<span className="text-highlight" style={{"color":"red"}}><FaMapMarkerAlt /></span>IF emolpyee clock in/out is away from site. 
        <span className="text-highlight"><FaMapMarkerAlt /></span>IF GPS data is not available.</p>
        <p id="map"><i class="docs-arrow"></i>Click on <span className="text-highlight" style={{"color":"red"}}><FaMapMarkerAlt /></span> to open map.</p>
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image2);
            setImageShow(true);
          }}
        >
          <img src={Image2} alt="create site 1" width={"100%"}></img>
        </div>
              {imageShow && imageData && (
            <ImageModal
              show={imageShow}
              onHide={() => setImageShow(false)}
              imageData={imageData}
            />
          )}
            </div>
            <div className="docs-sub-right-content">
              <div>On this page</div>
              <hr />
              <ul>
                {onThisPage.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.link}>{item.desc}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        
  );
}

export default MonitorAttendenceDocs