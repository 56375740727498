import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRecentSites } from "../../../reducers/dashboard";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";




const SitesCard = () => {
    const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.dashboardData);
  const data = dashboardData.recentSitesData;

  useEffect(() => {
    dispatch(
      getRecentSites()
    );
  }, []);

  if (dashboardData.recentSitesLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }
  if (!dashboardData.recentSitesData) {
    return (
      <div>
        <Alert variant="info">No Data. Please try again later.</Alert>
      </div>
    );
  }
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Recent Created Sites</th>
          
          <th>Created By</th>
          
        </tr>
      </thead>
      <tbody>
        {data.recentSites.map((site)=>{
          return(
            <tr>
          <td><Link to={`/dashboard/sites/${site._id}`}>{site.name}</Link></td>
          
          <td>{site.createdBy}</td>
          
        </tr>
          )
        })}
        
        
      </tbody>
    </Table>
 
  )
}

export default SitesCard