import React from 'react';
import ImageModal from "./ImageModal";
import Image1 from "./assets/tasks-3.jpg";
import Image2 from "./assets/tasks-accept.jpg";
import Image3 from "./assets/tasks-4.jpg";
import Image4 from "./assets/tasks-5.jpg";
import Image5 from "./assets/tasks-6.jpg";
import Image6 from "./assets/tasks-7.jpg";
import { Link } from "react-router-dom";

const MyTasksDocs = () => {
  const [imageShow, setImageShow] = React.useState(false);
    const [imageData, setImageData] = React.useState(null);
      const onThisPage = [
          {
            link: "#mytasks",
            desc: "My Tasks",
          },
          {
            link: "#accepttask",
            desc: "Accept Task",
          },
          {
            link: "#starttask",
            desc: "Start Task",
          },
          {
            link: "#addnotes",
            desc: "Add Notes",
          },
          {
            link: "#completetask",
            desc: "Complete Task",
          }
        ];
        return (
          <div className="d-flex">
            <div className="docs-sub-left-content">
              <h1 id="mytasks">My Tasks</h1>
              <p>
              My Tasks page will show tasks which are asigned to logged in user.
              </p>
              
              <div className="bd-callout-info bd-callout ">
              <h5>Permissions</h5>
          <p>
            Admin, Administrator and Schedular has permission to perform view My Tasks.
          </p>
        </div>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">My Tasks</span> under Tasks on menu.</p>
        <p><i class="docs-arrow"></i>Click on any tasks from list to view task details.</p>
        
        

        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image1);
            setImageShow(true);
          }}
        >
          <img src={Image1} alt="create site 1" width={"100%"}></img>
        </div>

              <h2 id="accepttask">Accept Task</h2>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">Accept Task</span> button to accept assigned task.</p>
        
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image2);
            setImageShow(true);
          }}
        >
          <img src={Image2} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="starttask">Start Task</h2>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">Start</span> button to start assigned task.</p>
        <p><i class="docs-arrow"></i>Refer to <span className="text-highlight"><Link to="/dashboard/docs/monitortasks">
                  <span className="docs-menu-text">Monitor Tasks</span>
                </Link></span> section on more details on task priority and task status.</p>
        
        
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image3);
            setImageShow(true);
          }}
        >
          <img src={Image3} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="addnotes">Add Notes</h2>
        <p><i class="docs-arrow"></i>Type note on <span className="text-highlight">Add Note</span> textarea.</p>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">Add Note</span> button to add note to task.</p>
        
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image4);
            setImageShow(true);
          }}
        >
          <img src={Image4} alt="create site 1" width={"100%"}></img>
        </div>

        <h2 id="completetask">Complete Task</h2>
        <p><i class="docs-arrow"></i>Click on <span className="text-highlight">Complete</span> button to complete task.</p>
        
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image5);
            setImageShow(true);
          }}
        >
          <img src={Image5} alt="create site 1" width={"100%"}></img>
        </div>

        
        <p><i class="docs-arrow"></i>Success message will show up once task is mark complete.</p>
        
        <div
          className="docs-image-lg"
          onClick={() => {
            setImageData(Image6);
            setImageShow(true);
          }}
        >
          <img src={Image6} alt="create site 1" width={"100%"}></img>
        </div>
              {imageShow && imageData && (
            <ImageModal
              show={imageShow}
              onHide={() => setImageShow(false)}
              imageData={imageData}
            />
          )}
            </div>
            <div className="docs-sub-right-content">
              <div>On this page</div>
              <hr />
              <ul>
                {onThisPage.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.link}>{item.desc}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        
  );
}

export default MyTasksDocs