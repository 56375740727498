import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartCard = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.dashboardData?.tasksData);
  const loading = useSelector((state) => state.dashboardData?.tasksLoading);

  const data = {
    labels: ["Not Started", "Started", "Not Accepted", "Completed"],
    datasets: [
      {
        label: "Tasks",
        data: [
          tasks[0]?.NOTSTARTED,
          tasks[0]?.STARTED,
          tasks[0]?.NOTACCEPTED,
          tasks[0]?.COMPLETED,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.9)",
          "rgba(54, 162, 235, 0.9)",
          "rgba(255, 206, 86, 0.9)",
          "rgba(75, 192, 192, 0.9)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>
          <Spinner animation="border" />
          Loading...
        </h3>
      </div>
    );
  }
  if (!tasks || tasks.length === 0) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div>
          <Alert variant="info">No Data.</Alert>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Doughnut data={data} />
      <span className="m-5">
        <hr />
      </span>
      <div className="d-flex flex-column gap-1 fw-light">
        <div className="d-flex justify-content-between">
          <div>Total</div>
          <div>{tasks[0]?.TOTAL}</div>
        </div>
        <div className="d-flex justify-content-between">
          <div>Not Started</div>
          <div>{tasks[0]?.NOTSTARTED}</div>
        </div>
        <div className="d-flex justify-content-between">
          <div>Started</div>
          <div>{tasks[0]?.STARTED}</div>
        </div>
        <div className="d-flex justify-content-between">
          <div>Completed</div>
          <div>{tasks[0]?.COMPLETED}</div>
        </div>
        <div className="d-flex justify-content-between">
          <div>Accepted</div>
          <div>{tasks[0]?.ACCEPTED}</div>
        </div>
        <div className="d-flex justify-content-between">
          <div>Not Accepted</div>
          <div>{tasks[0]?.NOTACCEPTED}</div>
        </div>
      </div>
    </div>
  );
};

export default ChartCard;
