import React, { useState, useEffect } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DisplayUsers from "./DisplayUsers";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Alerts from "../../Alerts";
import { setAlert } from "../../../reducers/alert";
import { useSelector, useDispatch } from "react-redux";
import { searchUser } from "../../../reducers/user";
import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineSearch } from "react-icons/ai";

const SearchUser = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.searchUsers);
  let loading = useSelector((state) => state.user.searchLoading);

  const [serachByEmail, setSearchByEmail] = useState("");

  const handleSearch = () => {
    dispatch(searchUser({ email: serachByEmail }));
  };

  return (
    <div className="card">
      <h3>Search</h3>

      <hr />
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">
          <AiOutlineSearch />
        </InputGroup.Text>

        <Form.Control
          placeholder="Search by Email"
          aria-label="search"
          aria-describedby="basic-addon1"
          value={serachByEmail}
          onChange={(e) => setSearchByEmail(e.target.value)}
        />

        {loading ? (
          <Button variant="outline-primary" id="button-addon2" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </Button>
        ) : (
          <Button
            variant="outline-primary"
            id="button-addon2"
            onClick={handleSearch}
          >
            Search
          </Button>
        )}
      </InputGroup>
      {loading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : !users || users.length === 0 ? (
        <Alert variant="info">No Data. Search in alpha network database.</Alert>
      ) : (
        <DisplayUsers users={users} itemsPerPage={50} />
      )}
    </div>
  );
};

export default SearchUser;
